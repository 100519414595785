import { Layout, Menu, Dropdown } from "antd";
import { loginOut } from "@/models/login";
import { useModel } from "@model";
import { DownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import noBody from "@/static/images/noBody.png";
import Iconfont from "@components/Iconfont";
import uface from "@widget/uface";
import Fullscreen from "./Fullscreen";
import api from "@api";
export default () => {
  const { userinfo } = useModel("login", ({ userinfo }) => ({ userinfo }));
  return (
    <Layout.Header className="flex header">
      <Link to="/">
        <div className="header-logo">
          <div className="logo-circle">
            <img
              src={
                userinfo.company_logo
                  ? userinfo.company_logo
                  : require("@/static/images/company.png")
              }
              alt=""
            />
          </div>
          <span>嘉丰伟业管理端</span>
        </div>
      </Link>
      <div className="header-right">
        <Fullscreen container=".admin-box > div:last-child"></Fullscreen>
        <a className="contact-us" href="http://www.tyjfwy.com/" target="_blank">
          <Iconfont
            type="icon-lianxiwomen"
            style={{ fontSize: "22px" }}
          ></Iconfont>
          <span>联系我们</span>
        </a>
        <Dropdown
          menu={{
            items: [
              {
                key: 2,
                label: (
                  <div
                    onClick={() =>
                      uface.makeForm({
                        title: "修改密码",
                        request: "user.changPassword",
                        syncToRequest: ({ oldPassword, newPassword }) => ({
                          data: {
                            old_password: oldPassword,
                            new_password: newPassword,
                          },
                        }),
                        rules: {
                          oldPassword: {
                            required: true,
                          },
                          newPassword: {
                            required: true,
                          },
                          repeatPass: [
                            {
                              required: true,
                            },
                            {
                              message: "两次密码不一致!",
                              validate(field, _, data) {
                                return field === data.newPassword;
                              },
                            },
                          ],
                        },
                        columns: [
                          {
                            title: "原密码",
                            name: "oldPassword",
                            type: "password",
                          },
                          {
                            title: "新密码",
                            name: "newPassword",
                            type: "password",
                          },
                          {
                            title: "确认密码",
                            name: "repeatPass",
                            type: "password",
                          },
                        ],
                      })
                    }
                  >
                    修改密码
                  </div>
                ),
              },
              {
                key: 3,
                label: <div onClick={loginOut}>退出登录</div>,
              },
            ],
          }}
          arrow={true}
        >
          <div className="userinfo">
            {/*<img src={noBody} height="30" />*/}
            <Iconfont
              type="icon-morentouxiang"
              style={{ fontSize: "22px" }}
            ></Iconfont>
            <p>
              欢迎您，<span>{userinfo.managename}</span>
            </p>
            <DownOutlined style={{ fontSize: "14px" }} />
          </div>
        </Dropdown>
      </div>
    </Layout.Header>
  );
};
