import { useEffect, useRef, useState } from "react";
import { Image } from "antd";
import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";
import { download } from "@utils/image";
import { useZIndex } from "@utils/hooks";
import { random } from "@utils/str";
import { appendStyle } from "@utils/dom";
import "./index.less";
const APP = ({ src, width, height, ...rest }) => {
  const [visible, setVisible] = useState(false);
  const { nextZIndex } = useZIndex();
  const [maskClass, setMaskClass] = useState(random(6, "k-antd-mask"));
  useEffect(() => {
    const el =
      appendStyle(`.${maskClass} .ant-image-preview-mask{z-index:${nextZIndex()};}
		.${maskClass} .ant-image-preview-wrap{z-index:${nextZIndex()};}
	`);
    return () => {
      el.remove();
    };
  }, []);
  return (
    <>
      <div className="k-image">
        <img src={src} {...rest} />
        <div className="k-image-mask">
          <div className="flex flex-middle" onClick={() => setVisible(true)}>
            预览
            <EyeOutlined
              style={{ fontSize: "22px", color: "#fff", marginLeft: "6px" }}
            />
          </div>
          <div className="flex flex-middle" onClick={() => download(src)}>
            下载
            <DownloadOutlined
              style={{ fontSize: "22px", color: "#fff", marginLeft: "6px" }}
            />
          </div>
        </div>
      </div>
      <Image
        rootClassName={maskClass}
        width={width}
        height={height}
        style={{
          display: "none",
        }}
        src={src}
        preview={{
          visible,
          src,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />
    </>
  );
};
export default APP;
