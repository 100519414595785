import { Input, InputNumber, Switch, Popover } from "antd";
import DatePickerField from "./DatePicker";
import RangeDatePickerField from "./RangePicker";
import Form, { Field } from "../../../BaseForm";
import ImageUpload from "./ImageUpload";
import VideoUpload from "./VideoUpload";
import FileUpload from "./FileUpload";
import TreeSelect from "./TreeSelect";
import TabSelect from "./TabsSelect";
import TabsTreeSelect from "./TabsTreeSelect";
import PageSelect from "./PageSelect";
import SelectField from "./Select";
import CascaderField from "./Cascader";
import RadioField from "./Radio";
import CheckboxField from "./Checkbox";
import React, { useContext, useEffect, useRef } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { useState } from "react";
const { Dependency } = Form;
/**  提取ReactDOM内title作为placeholder
 * 处理因title传入ReactDOM导致placeholder显示错误
 * */
function findTitle(reactDom) {
  if (typeof reactDom === "string") return reactDom;
  else if (
    typeof ReactDOM === "object" &&
    reactDom.props &&
    reactDom.props.children &&
    reactDom.props.children.length > 0
  )
    return findTitle(reactDom.props.children[0]);
  return "";
}
const isInValid = (val) =>
  val === undefined || val === null || Number.isNaN(val);
const InputField = React.forwardRef(
  (
    {
      name,
      title,
      type,
      placeholder,
      isSearch,
      onChange,
      onBlur,
      addonAfter,
      showValue,
      onViewValueChange = () => {},
      ...rest
    },
    ref
  ) => {
    console.log(rest, title, rest.value, "input-field", addonAfter);
    // const inputRef = useRef(null);
    useEffect(() => {
      onViewValueChange(rest.value);
    }, [rest.value]);
    // useEffect(() => {
    //   if (inputRef.current) {
    //     const inputEl = inputRef.current.input;
    //     inputEl.addEventListener("mousewheel", (e) => {
    //       // e.preventDefault();
    //       // window.scroll(e.wheelDeltaX, e.wheelDeltaY);
    //       return false;
    //     });
    //     inputEl.addEventListener("DOMMouseScroll", (e) => e.preventDefault());
    //     inputEl.addEventListener("keydown", (e) => {
    //       if (["ArrowUp", "ArrowDown", "Enter"].includes(e.key))
    //         e.preventDefault();
    //     });
    //   }
    // }, []);
    const [open, setOpen] = useState(false);
    const popoverRef = useRef(null);
    useEffect(() => {
      if (showValue) {
        const node = popoverRef.current.triggerRef.current.input;
        const onHover = () => {
          if (rest.value) setOpen(true);
        };
        const onOut = () => {
          setOpen(false);
        };
        node.addEventListener("mouseover", onHover);
        window.addEventListener("mouseout", onOut);
        return () => {
          node.removeEventListener("mouseover", onHover);
          node.removeEventListener("mouseout", onOut);
        };
      }
    }, [rest.value]);
    const inputDom = (
      <Input
        ref={ref}
        type={type === "number" ? "text" : type}
        allowClear
        placeholder={placeholder || `请输入${findTitle(title) || ""}`}
        prefix={
          isSearch ? <SearchOutlined style={{ color: "#c1c1c1" }} /> : null
        }
        //   onMousewheel={(e) => console.log(e)}
        //   onDOMMouseScroll={(e) => e.preventDefault()}
        //   value={isInValid(value) ? "" : value}
        onChange={(e) => {
          onViewValueChange(e);
          onChange && onChange(e);
        }}
        onBlur={(e) => {
          onBlur && onBlur(e);
        }}
        {...(type === "number"
          ? {
              /**
 * 浮点数
 * 负数
            // 会导致触发2次onChange
*/
              onBlur: (e) => {
                const num = e.target.value;
                const match =
                  String(num) && String(num).match(/^-?\d+(\.[\de|+]+)?/);
                const val = match ? Number(match[0]) : "";
                onViewValueChange(val);
                onChange && onChange({ target: { value: val } });
                onBlur && onBlur(val);
              },
            }
          : {})}
        {...rest}
        {...(rest.hasOwnProperty("value")
          ? { value: isInValid(rest.value) ? "" : rest.value }
          : {})}
        addonAfter={
          typeof addonAfter === "function" ? addonAfter(name) : addonAfter
        }
      ></Input>
    );
    return showValue ? (
      <Popover
        ref={popoverRef}
        open={open}
        placement="bottom"
        content={isInValid(rest.value) ? null : rest.value}
        trigger="hover"
      >
        {inputDom}
      </Popover>
    ) : (
      inputDom
    );
    //   return (
    //     <Input
    //       ref={inputRef}
    //       allowClear
    //       placeholder={placeholder || `请输入${title || ""}`}
    //       prefix={isSearch ? <SearchOutlined style={{ color: "#c1c1c1" }} /> : null}
    //       //   onMousewheel={(e) => console.log(e)}
    //       //   onDOMMouseScroll={(e) => e.preventDefault()}
    //       onChange={(e) => {
    //         onViewValueChange(e);
    //         onChange && onChange(e);
    //       }}
    //       {...rest}
    //     ></Input>
    //   );
    //   return type === "number" ? (
    //     <InputNumber
    //       placeholder={placeholder || `请输入${title || ""}`}
    //       //   prefix={isSearch ? <SearchOutlined style={{ color: "#c1c1c1" }} /> : null}
    //       onPressEnter={(e) => e.preventDefault()}
    //       onChange={(e) => {
    //         // const match = String(e) && String(e).match(/\d+\.?\d*/);
    //         // const val = match ? Number(match[0]) : e;
    //         onViewValueChange(e);
    //         onChange && onChange(e);
    //       }}
    //       {...rest}
    //     ></InputNumber>
    //   ) : (
    //     <Input
    //       type={type}
    //       allowClear
    //       placeholder={placeholder || `请输入${title || ""}`}
    //       prefix={isSearch ? <SearchOutlined style={{ color: "#c1c1c1" }} /> : null}
    //       onPressEnter={(e) => e.preventDefault()}
    //       onChange={(e) => {
    //         onViewValueChange(e);
    //         onChange && onChange(e);
    //       }}
    //       {...rest}
    //     ></Input>
    //   );
  }
);

const Password = ({ placeholder, ...rest }) => {
  return (
    <Input.Password
      placeholder={placeholder || `请输入${findTitle(rest.title) || ""}`}
      {...rest}
    />
  );
};

const TextAreaField = ({
  isSearch,
  placeholder,
  onViewValueChange = () => {},
  ...rest
}) => (
  <Input.TextArea
    rows={rest.rows || 4}
    placeholder={placeholder || `请输入${findTitle(rest.title) || ""}`}
    {...rest}
  ></Input.TextArea>
);
const SwitchField = ({
  value,
  isSearch,
  activeValue = 1,
  inActiveValue = 0,
  onViewValueChange,
  onChange = () => {},
  ...rest
}) => (
  <Switch
    {...rest}
    checked={value === activeValue}
    onChange={(e) => onChange(e ? activeValue : inActiveValue)}
  ></Switch>
);
const ImageUploadField = ({ name, ...rest }) => (
  <ImageUpload size="small" {...rest}></ImageUpload>
);
const valueTypes = {
  text: InputField,
  password: Password,
  switch: SwitchField,
  select: SelectField,
  cascader: CascaderField,
  radio: RadioField,
  checkbox: CheckboxField,
  "tree:select": TreeSelect,
  "tab:select": TabSelect,
  "select:tab:tree": TabsTreeSelect,
  "page:select": PageSelect,
  textarea: TextAreaField,
  date: DatePickerField,
  "range:date": RangeDatePickerField,
  "upload:image": ImageUploadField,
  "upload:video": VideoUpload,
  "upload:file": FileUpload,
  dependency: Dependency,
};
const defaultFormItemProps = {
  imageUpload: {
    updateType: "updateFileValue",
    shouldUpdate: true,
  },
};
/**
 * 自定义valueType
 * <ValueTypeContext.Provider>
 *    value={
 *      valueTypeMap:{
 *    		link:({title})<a>{title}</a>
 *   }
 * }
 */
const ValueTypeContext = React.createContext({});
const FormMakerField = ({
  valueType = "text",
  name,
  title,
  formItemProps = {},
  fieldProps = {},
  request,
  initialValue,
  hidden,
  readonly,
  ...rest
}) => {
  const customValueType = useContext(ValueTypeContext);
  const FormField =
    typeof valueType === "function" ? valueType : valueTypes[valueType] || null;
  console.log("field maker render", name, title, valueType, rest);
  if (readonly) return <Field readonly={readonly} name={name}></Field>;
  const defaultFormProps =
    defaultFormItemProps[valueType] ||
    (customValueType.valueTypeMap
      ? typeof valueType === "function"
        ? false
        : customValueType.valueTypeMap[valueType]
      : false) ||
    null;
  return FormField ? (
    valueType === "dependency" ? (
      <FormField
        name={name}
        title={title}
        {...rest}
        {...fieldProps}
      ></FormField>
    ) : (
      <Form.Item
        name={name}
        title={title}
        initialValue={initialValue}
        valueType={valueType}
        style={{ display: hidden ? "none" : "" }}
        {...defaultFormProps}
        {...formItemProps}
      >
        <FormField
          name={name}
          title={title}
          request={request}
          {...rest}
          {...fieldProps}
        ></FormField>
      </Form.Item>
    )
  ) : null;
};
export default FormMakerField;
export {
  InputField as Input,
  Password,
  SwitchField as Switch,
  SelectField as Select,
  CascaderField as Cascader,
  RadioField as Radio,
  TreeSelect,
  TabSelect,
  TabsTreeSelect,
  PageSelect,
  TextAreaField as TextArea,
  DatePickerField as DatePicker,
  RangeDatePickerField as DateRangePicker,
  ImageUploadField as ImageUpload,
  ValueTypeContext,
};
