import countDown from './core/countDown';
import day from './core/Day';
/**
 * 数据函数
 */
const datafn = {
    countDown,
    day,
};
export default datafn;
