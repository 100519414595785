/**
 * 16进制转RGB
 * hex=>rgb 就是将hex拆分为3个数字，再以基数16转为10进制(分别为rgb得3个值)
 */
export function hex2RGB(color) {
  // 16进制颜色值的正则
  var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
  // 把颜色值变成小写
  color = color.toLowerCase();
  if (reg.test(color)) {
    // 如果只有三位的值，需变成六位，如：#fff => #ffffff
    if (color.length === 4) {
      var colorNew = "#";
      for (var i = 1; i < 4; i += 1) {
        colorNew += color.slice(i, i + 1).concat(color.slice(i, i + 1));
      }
      color = colorNew;
    }
    // 处理六位的颜色值，转为RGB
    var colorChange = [];
    for (var i = 1; i < 7; i += 2) {
      colorChange.push(parseInt("0x" + color.slice(i, i + 2)));
    }
    return colorChange.join(",");
  } else {
    return color;
  }
}
export function RGB2hex(...rgb) {
  return (
    "#" +
    rgb.reduce((accu, cur) => {
      accu += cur.toString(16);
      return accu;
    }, "")
  );
}
function rangNum(num) {
  return num > 255 ? 255 : num < 0 ? 0 : num;
}
/**
 * rgb生成近似颜色值
 * 步骤
 * 选取主色调
 * 其它2中颜色依次递减
 *
 * threshold 色阶偏差值
 * offset 色深偏差值
 *
 * mode shallow 浅色系  值越大 deep 深色系 值越小
 * threshold和offset应同步增长
 */
function getShallowRGB(maxColorObject, R, G, B, options = {}) {
  const { threshold = 25, offset = 25, num = 3, mode = "shallow" } = options;
  let result = [];
  const maxColor = maxColorObject.name;
  if (maxColor == "R") {
    for (let i = 0; i < num; i++) {
      const level = mode === "shallow" ? i : -i;
      result.push(
        [R + offset * level, G + threshold * level, B + threshold * level].map(
          (d) => rangNum(d)
        )
      );
    }
  } else if (maxColor == "G") {
    for (let i = 0; i < num; i++) {
      const level = mode === "shallow" ? i : -i;
      result.push(
        [R + threshold * level, G + offset * level, B + threshold * level].map(
          (d) => rangNum(d)
        )
      );
    }
  } else if (maxColor == "B") {
    for (let i = 0; i < num; i++) {
      const level = mode === "shallow" ? i : -i;
      result.push(
        [R + threshold * level, G + threshold * level, B + offset * level].map(
          (d) => rangNum(d)
        )
      );
    }
  }
  console.log(num, result, "lll", threshold, offset);
  return result;
}
//找到最大的RGB
function findMaxRGB(R, G, B) {
  const arr = [
    {
      name: "R",
      value: R,
    },
    {
      name: "G",
      value: G,
    },
    {
      name: "B",
      value: B,
    },
  ];
  const max = Math.max(...arr.map((d) => d.value));
  return arr.find((d) => d.value == max);
}

export function getSimilarRGB(rgb, ...rest) {
  const [R, G, B] = rgb;
  //找到最大的色值
  const maxColor = findMaxRGB(R, G, B);
  //生成相近色
  let shallowColorArray = getShallowRGB(maxColor, R, G, B, ...rest);
  return shallowColorArray.map((d) => getRGBText(d));
}
export function getSimilarHex(hex, ...rest) {
  return getShallowRGB(hex2RGB(hex), ...rest).map((d) => RGB2hex(...d));
}

export function getRGBText(rgb) {
  if (typeof rgb === "string") return rgb;
  return "rgb(" + rgb.join(",") + ")";
}
