import judgeif from "../judgeif";

const today = new Date(),
    curYear = today.getFullYear(),
    curMonth = today.getMonth() + 1,
    curDay = today.getDate(),
    curHour = today.getHours(),
    curMinute = today.getMinutes(),
    curSecond = today.getSeconds();

class Day {
    constructor(tpl = 'Y-m-d', prefix = '-') {
        this.tplStr = tpl;
        this.str = this.parseTpl(tpl);
        this.prefix = prefix;
    }

    get value() {
        return this.get();
    }

    set value(str) {
        this.str = str;
    }
    get( tpl = this.tplStr) {
        return tpl
            .replace(/\bY\b/g, this.year)
            .replace(/\by\b/g, this.year)
            .replace(/\bm\b/g, this.dayFillZero(this.month))
            .replace(/\bd\b/g, this.dayFillZero(this.day))
            .replace(/\bh\b/g, this.dayFillZero(this.hour))
            .replace(/\bi\b/g, this.dayFillZero(this.minute))
            .replace(/\bs\b/g, this.dayFillZero(this.second))
            .replace(/\bld\b/g,new Date(this.year,this.month,0).getDate());
    }
    // 检测模板，尝试校正
    checkTpl(tpl) {
        if (!(tpl.includes('Y') || tpl.includes('y'))) tpl = 'Y-' + tpl;
        return tpl;
    }
    parseTpl(tpl) {
        tpl = this.checkTpl(tpl);
        const str = tpl
            .replace(/\bY\b/g, curYear)
            .replace(/\by\b/g, String(curYear).substr(2))
            .replace(/\bm\b/g, this.dayFillZero(curMonth))
            .replace(/\bd\b/g, this.dayFillZero(curDay))
            .replace(/\bh\b/g, this.dayFillZero(curHour))
            .replace(/\bi\b/g, this.dayFillZero(curMinute))
            .replace(/\bs\b/g, this.dayFillZero(curSecond))
            .replace(/\bld\b/g,0);
        const [, year, month, day, hour, minute, second] = str.match(/(\d+)\D?(\d+)?\D*(\d+)?\s?(\d+)?\D?(\d+)?\S?(\d+)?/);
        this.year = Number(year);
        // if (this.year.length < 4) this.year = curYear.substring(0, 2) + this.year; //处理 年份-20的场景
        this.month = Number(month);
        this.day = day===0? new Date(year,month,0).getDate():Number(day);
        if (hour) this.hour = Number(hour);
        if (minute) this.minute = Number(minute);
        if (second) this.second = Number(second);
        return str;
    }

    // 日期填充前导零
    dayFillZero(str, prefix = this.prefix) {
        if (typeof str === 'string') {
            const dayArr = str.split(/\D+/);
            return dayArr.reduce((accumulator, current) => accumulator + prefix + (Number(current) > 9 ? Number(current) : '0' + Number(current)), '').substr(1);
        }
        return Number(str) > 9 ? Number(str) : ('0' + Number(str));
    }

    /**
     * 修改日期范围值
     * @param str
     * @param num
     * @param prefix
     * @returns {Moment}
     */
    around(param) {
        for (let type in param) {
            const num = param[type];
            judgeif.call(this, type, {
                hour() {
                    const time = new Date(this.str).getTime(),
                        date = new Date(time + num * 60 * 60 * 1000);
                    this.year = date.getFullYear();
                    this.month = date.getMonth() + 1;
                    this.day = date.getDate();
                    this.hour = date.getHours();
                    this.minute = date.getMinutes();
                    this.second = date.getSeconds();
                },
                day() {
                    const time = new Date(this.str).getTime(),
                        date = new Date(time + num * 24 * 60 * 60 * 1000);
                    this.year = date.getFullYear();
                    this.month = date.getMonth() + 1;
                    this.day = date.getDate();
                },
                month() {
                    let {
                        year,
                        month
                    } = this.month, plus = num > 0;
                    month += num;
                    const yearAdd = Math.floor(Math.abs(num) / 12),
                        monthAdd = Math.floor(Math.abs(num) % 12);
                    if (plus) {
                        year += yearAdd;
                        month += monthAdd;
                    } else {
                        year -= yearAdd;
                        month -= monthAdd;
                    }
                    this.year = year;
                    this.month = month;
                }
            });
        }
        return this;
    }
}

export default function(...rest){
    return new Day(...rest);
}