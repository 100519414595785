import typeUtil from "./typeUtil.js";
import deepClone from "./clone";

class ArrUtil {
  constructor(arr, isClone = true) {
    if (isClone) this.arr = deepClone(arr);
    else this.arr = arr;
  }

  get value() {
    return this.arr;
  }

  set value(value) {
    this.arr = value;
  }

  get length() {
    return typeof this.arr === "object" ? this.arr.length : undefined;
  }

  /**
   * [isArrayEmpty 判断数组是否为空]
   * @method isArrayEmpty
   * '@date 2018-08-18'
   * '@author zkq'
   * @return {Boolean}      [description]
   */
  isEmpty() {
    const arr = this.arr;
    return Array.isArray(arr) && arr.length == 0;
  }

  /**
   * [findInArray 查找某一项是否在数组中]
   * '@date 2018-08-18'
   * '@author zkq'
   * @param  {[type]}    cell  [description]
   * @param  {[type]}    array [description]
   * @return {[type]}          [description]
   */
  isIn(cell) {
    return this.arr.some((item) => {
      return item === cell;
    });
  }

  /**
   * 去除无效值
   */
  compact() {
    this.arr = this.arr.filter(Boolean);
    return this;
  }

  forEach(fn) {
    for (let [key, value] of this.arr) {
      fn(value, key, this.arr);
    }
    return this;
  }

  map(fn) {
    let temp = [];
    for (let [index, value] of this.arr.entries()) {
      temp.push(fn(value, index, this.arr));
    }
    this.arr = temp;
    return this;
  }

  sort() {
    this.arr = this.arr.sort();
    return this;
  }

  filter(fn) {
    let temp = [];
    for (let [index, value] of this.arr) {
      if (fn(value, index, this.arr)) {
        temp.push(value);
      }
    }
    this.arr = temp;
    return this;
  }

  /**
   * 多字段筛选
   * @param filterArr 条件数组
   * @param fn 判断依据
   * @returns {ArrUtil}
   */
  filterByArr(filterArr, fn = (item, current) => item === current) {
    const arr = this.arr;
    this.arr = filterArr.reduce((accumulator, current) => {
      accumulator.push(...arr.filter((item) => fn(item, current)));
    }, []);
    return this;
  }

  /**
   * 多条件筛选
   * @param filters
   *   {age:12,name:['a','b']}
   * @returns {ArrUtil}
   */
  filterMulti(filters, flag = "and") {
    const arr = this.arr,
      filtersKey = Object.keys(filters);
    let temp = arr.filter((item) => {
      const fnName = flag === "and" ? "every" : "some";
      return filtersKey[fnName]((key) => {
        if (typeof filters[key] === "string") filters[key] = [filters[key]];
        if (filters[key].length <= 0) return true;
        return filters[key].includes(item[key]);
      });
    });
    this.arr = temp;
    return this;
  }

  reduce(fn, initial) {
    let accumulator;
    for (let [index, value] of this.arrr) {
      if (index === 0) {
        accumulator = initial ? this.arr[0] : undefined;
        continue;
      }
      accumulator = fn.call(undefined, accumulator, value, index, this.arr);
    }
    this.arr = accumulator;
    return this;
  }
  deepReduce(fn, initial) {
    this.reduce((accu, cur, ...rest) => {
      return fn(
        accu,
        {
          ...cur,
          children:
            cur.children && cur.children.length > 0
              ? new ArrUtil(cur.children).deepReduce(fn, []).value
              : null,
        },
        ...rest
      );
    }, initial);
    return this;
  }

  findIndex(fn) {
    for (let [index, value] of this.arr) {
      if (fn(value, index, this.arr)) {
        return index;
      }
    }
  }

  find(fn) {
    for (let [index, value] of this.arr) {
      if (fn(value, index, this.arr)) {
        return value;
      }
    }
  }

  indexOf(fn, val) {
    return this.findIndex((value) => typeUtil("isSame")(value, val));
  }

  every(fn) {
    for (let [index, value] of this.arr) {
      if (!fn(value, index, this.arr)) return false;
    }
    return true;
  }

  some(fn) {
    for (let [index, value] of this.arr) {
      if (fn(value, index, this.arr)) return true;
    }
    return false;
  }

  includes(val) {
    return this.arr.some((value) => typeUtil("isSame")(value, val));
  }

  /**
   * @description 根据索引删除数组的某个值
   * @param {*} i
   */
  del(i) {
    this.arr = this.arr.filter((item, index) => {
      if (index === i) {
        return false;
      }
      return item;
    });
    return this;
  }

  /**
   * 排除原数组中含忽略（exclude）的其它项
   * @param exclude
   * @returns {ArrUtil}
   */
  exclude(exclude) {
    const arr = this.arr;
    let temp = [];
    arr.forEach((item) => {
      if (typeof exclude === "string") {
        if (item !== exclude) temp.push(item);
      } else if (typeof exclude === "object") {
        if (!exclude.includes(item)) temp.push(item);
      }
    });
    this.arr = temp;
    return this;
  }

  /**
   * @description 扁平化数组, 由于累加值必须是数组，因此初始值为数组，防止返回为其它类型
   * @param {*} arr
   */
  flattenDeep(isDeep = false) {
    const arr = this.arr;
    this.arr = Array.isArray(arr)
      ? arr.reduce((accumulator, current) => {
          if (isDeep) {
            accumulator.push(...new ArrUtil(current).flattenDeep(true));
          } else {
            accumulator = accumulator.concat(current);
          }
          return accumulator;
        }, [])
      : [arr];
    return this;
  }

  //  方法1：利用对象访问属性的方法，判断对象中是否存在key
  // var result = [];
  // var obj = {};
  // for (var i = 0; i < arr.length; i++) {
  //     if (!obj[arr[i].key]) {
  //         result.push(arr[i]);
  //         obj[arr[i].key] = true;
  //     }
  // }
  // console.log(result);
  // [{key: "01", value: "乐乐"},{key: "02", value: "博博"},{key: "03", value: "淘淘"},{key: "04", value: "哈哈"}]

  //  方法2：利用reduce方法遍历数组,reduce第一个参数是遍历需要执行的函数，第二个参数是item的初始值
  // var obj = {};
  // arr = arr.reduce(function (item, next) {
  //     obj[next.key] ? '' : obj[next.key] = true && item.push(next);
  //     return item;
  // }, []);
  // console.log(arr);
  // [{key: "01", value: "乐乐"},{key: "02", value: "博博"},{key: "03", value: "淘淘"},{key: "04", value: "哈哈"}]

  /**
   * 数组去重 以key为id
   * @param {*} key   以之判断的key  对象数组判断依据
   */
  uniqueByKey(key = "key") {
    const arr = this.arr;
    if (arr.length <= 0) return this;
    // 单一数组
    let temp = {};
    if (typeof arr[0] !== "object") {
      this.arr = arr.reduce((accumulator, current) => {
        if (!temp[current]) (temp[current] = true) && accumulator.push(current);
        return accumulator;
      }, []);
      return this;
    }
    // 复杂数组
    this.arr = arr.reduce((accumulator, current) => {
      if (!temp[current[key]])
        (temp[current[key]] = true) && accumulator.push(current); //以对象属性不重复的特性，将temp->key作为是否此键值存在判断依据
      return accumulator;
    }, []);
    return this;
  }

  /**
   * 提取对象数组相应键
   * @param {*} keys
   */
  extract(keys = []) {
    if (typeof keys === "string") keys = [keys];
    let temp = [];
    this.arr.forEach((item, index) => {
      keys.forEach((key) => {
        if (
          typeof item === "object" &&
          Object.property.hasOwnProperty.call(item, key)
        ) {
          if (temp[index]) temp[index][key] = item[key];
          else
            temp[index] = [
              {
                [key]: item[key],
              },
            ];
        }
      });
    });
    this.arr = temp;
    return this;
  }
  chunk(num) {
    const arr = this.arr,
      LEN = arr.length;
    let chunkNum = num, //分割块大小
      index = 0; //当传入数组时，所使用的值索引
    let temp = [],
      i = 1, //每次循环计数器
      j = 0,
      cursor = 1; //当前指针
    while (cursor <= LEN) {
      if (typeof num === "object") {
        chunkNum = num[index];
      }
      if (!temp[j]) temp[j] = [];
      temp[j].push(arr[cursor - 1]);
      if (i % chunkNum === 0) {
        j++;
        i = 0;
        if (typeof num === "object") index++;
      }
      i++;
      cursor++;
    }
    this.arr = temp;
    return this;
  }
  column(key) {
    this.arr = this.arr.map((item) => item[key]);
    return this;
  }
  /**
   *根据键值分割数组
   * @param key
   * @returns {ArrUtil}
   */
  columnByKey(key) {
    let temp = {};
    this.arr.forEach((item) => {
      temp[key] ? temp[key].push(item) : (temp[key] = [item]);
    });
    this.arr = Object.keys(temp).map((key) => temp[key]);
    return this;
  }

  /**
   * 根据给定键值分割数组
   */
  columnByArr(column, fn) {
    const arr = this.arr;
    let temp = [];
    column.forEach((key) => {
      temp.push({
        key,
        arr: arr.filter((item) => fn(item, key)),
      });
    });
    this.arr = temp;
    return this;
  }
}

export default ArrUtil;
