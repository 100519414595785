import React, { useEffect, useRef } from "react";
import purefn from "@widget/purefn";
let num = 1;
function animate(cb) {
  let cancelID;
  let isStop;
  const timer = (cb) => {
    if (isStop) return;
    if (window.requestAnimationFrame) return window.requestAnimationFrame(cb);
    else return setTimeout(cb, 1000 / 60);
  };
  const fn = () => {
    if (isStop) return;
    cb();
    console.log("1111111" + num, isStop);
    console.log("2222222222" + num, isStop);
    cancelID = timer(fn);
    num++;
  };
  cancelID = timer(fn);
  return () => {
    console.log("stop");
    isStop = true;
    // if (window.requestAnimationFrame) {
    //   //   cancelID.forEach((e) => window.cancelAnimationFrame(e));
    //   window.cancelAnimationFrame(cancelID);
    // } else {
    //   //   cancelID.forEach((e) => window.clearTimeout(e));
    //   window.clearTimeout(cancelID);
    // }
  };
}
class Animate {
  constructor({ useAnimation = true, interval = 1000 / 60 } = { interval }) {
    this.isStop = false;
    this.cancelID = null;
    this.useAnimation = useAnimation;
    this.interval = interval;
  }
  timer = (cb) => {
    if (this.isStop) return;
    if (this.useAnimation && window.requestAnimationFrame)
      return window.requestAnimationFrame(cb);
    else return setTimeout(cb, this.interval);
  };
  run = (cb) => {
    const fn = () => {
      console.log("iiii", this.isStop);
      if (this.isStop) return;
      cb();
      if (this.isStop) return;
      this.cancelID = this.timer(fn);
    };
    this.cancelID = this.timer(fn);
  };
  stop = () => {
    console.log("stop");
    this.isStop = true;
    if (this.useAnimation && window.requestAnimationFrame) {
      window.cancelAnimationFrame(this.cancelID);
    } else {
      window.clearTimeout(this.cancelID);
    }
  };
}
function scrollContainer(className, { x, y, offset = 1 }) {
  let node =
      className instanceof HTMLElement
        ? className
        : document.querySelector(className),
    width = node.clientWidth,
    height = node.clientHeight,
    scrollWidth = node.scrollWidth,
    scrollHeight = node.scrollHeight;
  if (!x && scrollWidth <= width) return;
  if (!y && scrollHeight <= height) return;
  let offsetScroll = offset;
  console.log(offsetScroll, "ararararar");
  const scrollNode = () =>
    node.scroll(
      scrollWidth > width ? offsetScroll : 0,
      scrollHeight > height ? offsetScroll : 0
    );
  scrollNode();
  const animate = new Animate({ useAnimation: false, interval: 40 });
  //   let b = 5;
  //   animate.run(() => {
  //     b--;
  //     if (b <= 0) {
  //       animate.stop();
  //       console.log("uuuuuuuuuuuuuuu2222222222222", animate.isStop);
  //       return false;
  //     }
  //   });
  //   return;
  animate.run(() => {
    width = node.clientWidth;
    height = node.clientHeight;
    scrollWidth = node.scrollWidth;
    scrollHeight = node.scrollHeight;
    offsetScroll += offset;
    //触底
    if (
      scrollWidth > width &&
      scrollWidth - Math.ceil(node.scrollLeft) <= width
    ) {
      console.log("uuuuuuuuuuuuuuu");
      node.scroll(scrollWidth, 0);
      animate.stop();
      //   setTimeout(() => {
      console.log("uuuuuuuuuuuuuuu2222222222222");
      //避免stop后再执行一次animate导致死循环
      node.scroll(0, 0);
      scrollContainer(className, { x, y, offset });
      //   }, 200);
      return;
    }
    if (
      scrollHeight > height &&
      scrollHeight - Math.ceil(node.scrollTop) <= height
    ) {
      console.log("kkkkkkkkk", scrollHeight, node.scrollTop, height);
      node.scroll(0, scrollHeight);
      animate.stop();
      node.scroll(0, 0);
      scrollContainer(className, { x, y, offset });
      return;
    }
    scrollNode();
  });
  return animate.stop;
}
/**
 * 自动循环容器内容，第一个子元素为容器
 * 使用
 * <Loop style={{width:'200px'}}>auauauuauauauauau</Loop>
 */
const Loop = ({
  scroll = {
    x: true,
    y: true,
  },
  children,
}) => {
  const elRef = useRef(null);
  useEffect(() => {
    let stop;
    const timer = setTimeout(() => {
      stop = scrollContainer(elRef.current, scroll);
    }, 80);
    const fn = () => {
      if (stop) stop();
      if (timer) clearTimeout(timer);
      stop = scrollContainer(elRef.current, scroll);
    };
    // const defn = purefn.debounce(fn, 3000);
    return () => {
      if (timer) clearTimeout(timer);
      if (stop) stop();
      //   window.removeEventListener("fullscreenchange", fn);
    };
  }, []);
  return React.cloneElement(children, {
    ref: elRef,
    style: Object.assign(
      {},
      { overflow: "hidden" },
      children.props.style || {}
    ),
  });
};
export default Loop;
