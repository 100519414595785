import fileSave from "./fileSave";
function handleImg(img) {
  return new Promise((resolve) => {
    const options = {
      maxWidth: 624,
    };
    const images = [];
    for (let i = 0; i < img.length; i++) {
      img[i].setAttribute("crossOrigin", "Anonymous");
      img[i].onload = () => {
        // Calculate dimensions of output image
        const w = Math.min(img[i].width, options.maxWidth);
        const h = img[i].height
          ? img[i].height * (w / img[i].width)
          : img[i].width / (img[i].naturalWidth / img[i].naturalHeight);
        // Create canvas for converting image to data URL
        const canvas = document.createElement("canvas");
        canvas.width = w;
        canvas.height = h;
        // Draw image to canvas
        const context = canvas.getContext("2d");
        context.drawImage(img[i], 0, 0, w, h);
        // Get data URL encoding of image
        const uri = canvas.toDataURL("image/png");
        // img[i].setAttribute("src", img[i].src);
        // img[i].width = w;
        // img[i].height = h;
        // Save encoded image to array
        images[i] = {
          type: uri.substring(uri.indexOf(":") + 1, uri.indexOf(";")),
          encoding: uri.substring(uri.indexOf(";") + 1, uri.indexOf(",")),
          location: img[i].getAttribute("src"),
          data: uri.substring(uri.indexOf(",") + 1),
        };
        if (images.filter(Boolean).length == img.length) resolve(images);
      };
    }
  });
}
// https://github.com/markswindoll/jQuery-Word-Export
async function htmlToWord({ fileName = "文档", html = "" } = {}) {
  //   const doc = {
  //     mhtml: {
  //       top:
  //         "Mime-Version: 1.0\nContent-Base: " +
  //         window.location.href +
  //         '\nContent-Type: Multipart/related; boundary="NEXT.ITEM-BOUNDARY";type="text/html"\n\n--NEXT.ITEM-BOUNDARY\nContent-Type: text/html; charset="utf-8"\nContent-Location: ' +
  //         window.location.href +
  //         "\n\n<!DOCTYPE html>\n<html>\n_html_</html>",
  //       head: '<head>\n<meta http-equiv="Content-Type" content="text/html; charset=utf-8">\n<style>\n_styles_\n</style>\n</head>\n',
  //       body: "<body>_body_</body>",
  //     },
  //   };
  const doc = {
    mhtml: {
      top:
        "Mime-Version: 1.0\nContent-Base: " +
        window.location.href +
        '\nContent-Type: Multipart/related; boundary="NEXT.ITEM-BOUNDARY";type="text/html"\n\n--NEXT.ITEM-BOUNDARY\nContent-Type: text/html; charset="utf-8"\nContent-Location: ' +
        window.location.href +
        `\n\n<!DOCTYPE html>\n<html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:w="urn:schemas-microsoft-com:office:word" xmlns:m="http://schemas.microsoft.com/office/2004/12/omml" xmlns="http://www.w3.org/TR/REC-html40">\n_html_</html>`,
      head: '<head>\n<meta http-equiv="Content-Type" content="text/html; charset=utf-8">\n<!--[if gte mso 9]><xml><w:WordDocument><w:View>Print</w:View><w:TrackMoves>false</w:TrackMoves><w:TrackFormatting/><w:ValidateAgainstSchemas/><w:SaveIfXMLInvalid>false</w:SaveIfXMLInvalid><w:IgnoreMixedContent>false</w:IgnoreMixedContent><w:AlwaysShowPlaceholderText>false</w:AlwaysShowPlaceholderText><w:DoNotPromoteQF/><w:LidThemeOther>EN-US</w:LidThemeOther><w:LidThemeAsian>ZH-CN</w:LidThemeAsian><w:LidThemeComplexScript>X-NONE</w:LidThemeComplexScript><w:Compatibility><w:BreakWrappedTables/><w:SnapToGridInCell/><w:WrapTextWithPunct/><w:UseAsianBreakRules/><w:DontGrowAutofit/><w:SplitPgBreakAndParaMark/><w:DontVertAlignCellWithSp/><w:DontBreakConstrainedForcedTables/><w:DontVertAlignInTxbx/><w:Word11KerningPairs/><w:CachedColBalance/><w:UseFELayout/></w:Compatibility><w:BrowserLevel>MicrosoftInternetExplorer4</w:BrowserLevel><m:mathPr><m:mathFont m:val="Cambria Math"/><m:brkBin m:val="before"/><m:brkBinSub m:val="--"/><m:smallFrac m:val="off"/><m:dispDef/><m:lMargin m:val="0"/> <m:rMargin m:val="0"/><m:defJc m:val="centerGroup"/><m:wrapIndent m:val="1440"/><m:intLim m:val="subSup"/><m:naryLim m:val="undOvr"/></m:mathPr></w:WordDocument></xml><![endif]-->\n<style>\n_styles_\n</style>\n</head>\n',
      body: "<body>_body_</body>",
    },
  };
  // generate selected element before manipulating it
  const wrap = document.createElement("div");
  wrap.innerHTML = html;
  document.body.appendChild(wrap);

  // Remove hidden elements from the output
  Array.from(wrap.children).forEach((el) => {
    if (el.hasAttribute("hidden")) el.remove();
  });

  // Embed all images using Data URLs
  const img = wrap.querySelectorAll("img");
  const images = await handleImg(img);
  console.log("kkkk", images);

  // Prepare bottom of mhtml file with image data
  let mhtmlBottom = "\n";
  for (let i = 0; i < images.length; i++) {
    mhtmlBottom += "--NEXT.ITEM-BOUNDARY\n";
    mhtmlBottom += "Content-Location: " + images[i].location + "\n";
    mhtmlBottom += "Content-Type: " + images[i].type + "\n";
    mhtmlBottom += "Content-Transfer-Encoding: " + images[i].encoding + "\n\n";
    mhtmlBottom += images[i].data + "\n\n";
  }
  mhtmlBottom += "--NEXT.ITEM-BOUNDARY--";

  //TODO: load css from included stylesheet
  const styles = "";

  // Aggregate parts of the file together
  const fileContent =
    doc.mhtml.top.replace(
      "_html_",
      doc.mhtml.head.replace("_styles_", styles) +
        doc.mhtml.body.replace("_body_", wrap.innerHTML)
    ) + mhtmlBottom;
  // Create a Blob with the file contents
  const blob = new Blob([fileContent], {
    type: "application/msword;charset=utf-8",
    // type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8"
  });
  fileSave(blob, fileName + ".doc");
  document.body.removeChild(wrap);
}
export default htmlToWord;
