import purefn from "@widget/purefn";
const Empty = {
  name: "Empty",
  render: () => <></>,
};
/**
 * 
 * @description all模式渲染后端返回路由
 * @param constantRoutes
  `title` varchar(50) CHARACTER      DEFAULT ''  '导航标题',
  `component` varchar(100) CHARACTER      DEFAULT ''  '本地组件路径',
  `hidden` tinyint(1)  DEFAULT '0'  '是否隐藏菜单;1：true隐藏；0: false显示',
  `path` varchar(100) CHARACTER      DEFAULT ''  '路由地址',
  `always_show` tinyint(1)  DEFAULT '0'  '当只有一个子菜单时是否显示导航，否则子菜单覆盖导航',
  `icon` varchar(100) CHARACTER      DEFAULT ''  '菜单的字体图标',
  `active_menu` varchar(50) CHARACTER     DEFAULT ''  '激活的菜单',
  `is_dynamic_title` tinyint(1)  DEFAULT '0'  '是否是动态标题',
  `name` varchar(50) CHARACTER     DEFAULT ''  '组件name',
  `redirect` varchar(100) CHARACTER     DEFAULT ''  '重定向路由',
  `affix` tinyint(1)  DEFAULT '0'  '是否固定到标签栏',
  `rule_type` varchar(10) CHARACTER      DEFAULT 'nav'  '权限类型；nav(导航)；button(按钮) home_nov(手机端首页导航)',
  `button_identifier` varchar(20)   DEFAULT ''  '按钮的唯一标识符，只有当rule_type=button时必填；常用的有：add/delete/edit',
  `button_name` varchar(20)   DEFAULT ''  '按钮标识符对应的名称，只有当rule_type=button时必填',
  `is_button_show` int(2)  DEFAULT '0'  '按钮是否显示，0不显示，1显示',
  `home_show_type` tinyint(1)  DEFAULT '0'  '手机端首页显示样式 0 默认显示 1在办案件显示 2 咨询显示样式 3 默认显示样式 ',
  `is_cache` tinyint(1) DEFAULT '0'  '是否设置缓存',
  `is_control` tinyint(1) DEFAULT '0'  '是否控制权限',
 * @returns {*}
 */
export function asyncRouterHandle(asyncRouter, parentPath = "") {
  let asyncPathFlag = 1;
  return asyncRouter.map((route) => {
    if (route.component) {
      if (route.component === "Layout") {
        // route.component = (resolve) => require([`@/${path}`], resolve)
        route.component = import("@/layout");
      } else if (route.component === "Empty") {
        // route.component = () => import('@/layout/Empty')
        route.component = Empty;
      } else if (typeof route.component === "string") {
        let path = "pages/" + route.component;
        if (
          new RegExp("^/pages/.*$").test(route.component) ||
          new RegExp("^pages/.*$").test(route.component)
        ) {
          path = route.component;
        } else if (new RegExp("^/.*$").test(route.component)) {
          path = "pages" + route.component;
        } else if (new RegExp("^@pages/.*$").test(route.component)) {
          path = route.component.slice(1);
        } else if (new RegExp("^@/pages/.*$").test(route.component)) {
          path = route.component.slice(2);
        } else {
          path = "pages/" + route.component;
        }
        // route.component = (resolve) => require([`@/${path}`], resolve)
        try {
          route.Component = require(`@/${path}`).default;
        } catch {
          // 当查找不到本地组件
          route.Component = require("@/pages/NotFound");
          route.hidden = true;
        }
      }
      //   判断title
      if (route.meta) {
        if (!route.meta.title || route.meta.is_dynamic_title == 1)
          route.meta.title = "query.title";
        route.meta.id = route.id;
        if (route.meta.is_cache) route.meta.cache = true;
      } else route.meta = { id: route.id };
    } else delete route.component;
    route.path = handlerNestedPath(parentPath, route.path);
    if (route.children && route.children.length)
      route.children = asyncRouterHandle(route.children, route.path);

    if (route.children && route.children.length === 0) delete route.children;

    /** 将同一组件 不同路径path?state=x 处理成多个导航
    [{
        path:'state?state=2',
        name:'verifying1'
    },{
        path:'state?state=3',
        name:'verifying2'
    }]
    由于vue-router只提供path,name方式跳转路由，因此不能通过自定义query afterEach方式鉴别同一path路由，需为name指定唯一值
    */
    if (route.path && route.path.includes("?")) {
      if (route.meta) {
        route.meta.query = purefn.StrUtil.queryString(route.path.split("?")[1]);
        route.meta.reload = true;
      } else
        route.meta = {
          query: purefn.StrUtil.queryString(route.path.split("?")[1]),
          reload: true,
        };
      // 由于处理后相同的path会影响导航选中
      route.path = route.path.split("?")[0];
      //		route.path=route.path.split('?')[0]+asyncPathFlag;
      //		route.name=route.name+asyncPathFlag;
      //		asyncPathFlag++;
    }
    //由于为修改考虑，送出的字段和sql表一致，小驼峰式需要单独处理
    route.spreadChild = !!route.spread_child;

    return route;
  });
}

/**
 * 处理 嵌套路由 / 字符
 */
export function handlerNestedPath(...rest) {
  return rest.reduce((accu, cur) => {
    let tmp = cur;
    if (cur.startsWith("/")) {
      if (accu === "/") tmp = cur;
      else if (accu.endsWith("/"))
        tmp = accu.substring(0, accu.lastIndexOf("/")) + cur;
    } else {
      if (accu.endsWith("/")) tmp = accu + cur;
      else tmp = accu + "/" + cur;
    }
    return tmp;
  });
}
