import { Context } from "./useProvider";
import { Row, Col } from "antd";
import Field from "./useForm/Field";
import "./index.less";
import { purefn, aider, uface } from "@widget";
import addUnit from "@/common/utils/addUnit";
import { useRefState } from "@hooks";
import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import className from "@utils/className";
import { set, get } from "@/utils/field";
import Tip from "../../Tip";
const getType = purefn.typeUtil("getType");
/**
 * UI控制和校验逻辑 负责校验及错误信息的展示，需要保存两个属性，校验状态和错误信息，当前校验通过时错误信息为空
	 * title: String,
            labelWidth: [String,Number],
			uLabelPosition
            wrapperWidth:[String,Number],
            name: String,
            required: {
                type: Boolean,
                default: false,
            },
			labelAlign  标签文本对齐方式
			layout 对齐方式  	horizontal | vertical | inline
	*/
export default (props) => {
  const {
    labelWidth: labelWidthProp,
    labelAlign: labelAlignProps,
    wrapperWidth: wrapperWidthProp,
    bodyStyle,
    name,
    title,
    tooltip,
    style = {},
    showLabel = true,
    layout: layoutProps,
    formItemRender,
    fieldRender,
    required,
    children,
    updateType,
    valueType,
    initialValue: initialValueFieldProps,
    //当动态更改Filed children属性时，应手动设置以更新
    shouldUpdate: shouldUpdateProps = (prevValue, curValue) =>
      !purefn.deepEqual(prevValue, curValue),
  } = props;
  console.log("form item render");
  const formContext = useContext(Context);
  const layout = layoutProps || formContext.layout;
  const [labelAlign, setLabelAlign] = useState(labelAlignProps);
  const showMessage = useMemo(() => {
    return formContext.errorType.includes("message");
  });
  const fieldValue = useMemo(() => {
    return formContext.getFieldValue(name);
  }, [formContext, name]);

  let initialValue = useMemo(() => {
    //   保存初始值
    return {
      value: fieldValue,
    };
  }, []);

  //   处理rules
  let rulesRef = useRef({});
  const [isRequired, setIsRequired] = useState(false);
  //   FormItem 属性required注入rules，方便使用
  const setRequired = () => {
    //     set(rulesRef.current, name, get(formContext.rules, name) || {});
    //     if (!name) return;
    //     if (required) {
    //       const names =
    //         typeof name === "string"
    //           ? [name]
    //           : name.reduce((accu, cur, cursor) => {
    //               if (cursor === name.length - 1) accu.push(cur);
    //               else accu.push(...[cur, "fields"]);
    //               return accu;
    //             }, []);
    //       if (typeof name === "string") {
    //         if (rulesRef.current)
    //           set(rulesRef.current, [...names, "required"], true);
    //         else rulesRef.current = { [name]: { required: true } };
    //       } else {
    //         let tmpRules = rulesRef.current,
    //           curRule = tmpRules,
    //           cursor = 0,
    //           LEN = name.length - 1;
    //         while (cursor <= LEN) {
    //           let key = name[cursor];
    //           if (cursor === LEN) {
    //             curRule[key] = { required: true };
    //             cursor++;
    //             continue;
    //           }
    //           if (!curRule[key])
    //             curRule[key] = {
    //               fields: {},
    //             };
    //           if (!curRule[key].fields) curRule[key] = { fields: {} };
    //           curRule = curRule[key].fields;
    //           cursor++;
    //         }
    //         rulesRef.current = tmpRules;
    //       }
    //       return;
    //     }
    const rules = get(formContext.rules, name) || {};
    if (getType(rules) === "Object" && rules.required) setIsRequired(true);
    if (getType(rules) === "Array")
      setIsRequired(rules.some((item) => item.required));
  };
  //   指定title
  const getRules = () => {
    if (!name) return {};
    const rules = {};
    set(rules, name, get(formContext.rules, name) || {});

    // 生成validate rules,{data:[{a:'xxx'}]} 由Validate自动遍历data,无需嵌套为{data:{0:{a}}}
    const names =
      typeof name === "string"
        ? [name]
        : name
            .filter((d) => typeof d !== "number")
            .reduce((accu, cur) => {
              accu.push(...[cur, "fields"]);
              return accu;
            }, [])
            .slice(0, -1);
    const fieldRule = get(formContext.rules, names) || {};
    set(rulesRef.current, names, fieldRule || {});
    // console.log("aiaiaiiai", names, fieldRule, rulesRef.current);
    if (getType(fieldRule) === "Object") {
      set(rulesRef.current, [...names, "label"], title);
      if (required) set(rulesRef.current, [...names, "required"], title);
    } else if (getType(fieldRule) === "Array") {
      if (required)
        set(rulesRef.current, names, [{ required: true }, ...fieldRule]);
      fieldRule.forEach((_, index) => {
        set(rulesRef.current, [...names, index, "label"], title || "");
      });
    }

    return rulesRef.current;
  };

  const resetField = () => {
    formContext.setFieldValue(name, initialValue);
  };

  const [validateMessage, setValidateMessage] = useState("");
  const [validateStatus, setValidateStatus] = useState("");
  const validate = (cb) => {
    // setValidateMessage("validating");
    const rules = getRules();
    // 验证字段的规则
    // {
    //     username: {
    //         required: true,
    //         "required|msg": "请输入姓名"
    //     },
    //     age: {
    //         required: true,
    //         min: 18,
    //         "required|msg": "请输入年龄",
    //         "min|msg": "年龄必须大于18"
    //     },
    //     subscription: {
    //         required: true,
    //         min: 1,
    //         "required|msg": "请输入预约金",
    //         "min|msg": "请输入预约金",
    //         test: 1000
    //     }
    // };
    console.log("form-item-validate", rules);
    const validate = new aider.Validate(rules);

    // 自定义验证规则,如果校验信息已在`messages`配置过，则会被覆盖.
    // validate.addRule({
    //     name: "test",
    //     msg: "错误!",
    /**
     *@description 校验规则
     * @param {*} value 相应校验字段的值
     * @param {*} ruleValue 相应校验规则的值
     * @param {*} filed 相应校验的字段
     * @param {*} data 需要校验的全部字段(check传入)
     * @returns
     */
    //     rule: (value, ruleValue) => {
    //         return value < ruleValue;
    //     }
    // });
    if (!validate.check(formContext.getStore())) {
      const error = validate.error;
      console.warn(error);
      setValidateMessage(error[0].message);
      setValidateStatus("error");
      if (formContext.errorType.includes("toast"))
        uface.showToast({
          title: error[0].message,
          icon: "warning",
        });
      cb(error[0].message);
      return false;
    }
    setValidateStatus("success");
    cb(false);
  };
  useEffect(() => {
    if (!labelAlign && formContext.labelAlign)
      setLabelAlign(formContext.labelAlign);
    setRequired();
    const validateInstance = { validate, resetField };
    formContext.store.add(validateInstance);
    return () => {
      formContext.store.delete(validateInstance);
    };
  }, []);

  // style
  const [labelWidth, setLabelWidth] = useState(labelWidthProp);
  const labelRef = useRef(null);
  useEffect(() => {
    if (labelRef.current && layout === "horizon")
      setTimeout(() => {
        formContext.event.emit(
          "label-width",
          Math.ceil(labelRef.current.clientWidth) + 1 //防止因小数部分截取导致label换行，向上取1
        );
      }, 200);

    if (!labelWidth)
      formContext.event.on("set-label-width", (width) => {
        setLabelWidth(width);
      });
  }, []);

  const labelStyle = useMemo(() => {
    let style = {};
    const labelWidthRes =
      labelWidth === 0 || labelWidth ? labelWidth : formContext.labelWidth;

    if (labelWidthRes || labelWidthRes === 0) {
      style.flex = `0 0 ${addUnit(labelWidthRes)}`;
    }
    if (labelAlign) {
      if (["left", "right"].includes(labelAlign)) style.textAlign = labelAlign;
      if (labelAlign === "justify") style.textAlignLast = "justify";
    }
    if (layout === "vertical") {
      style.paddingLeft = 0;
      style.paddingRight = 0;
    }
    return style;
  }, [labelWidth, formContext, layout]);
  //   只要有一项required 对齐label
  const [labelSpace, setLabelSapce] = useState(!!required);
  useEffect(() => {
    formContext.event.emit("required", required);
    if (!required && layout !== "vertical")
      formContext.event.on("set-required", (val) => {
        setLabelSapce(!!val);
      });
  }, [required]);

  const wrapperStyle = useMemo(() => {
    let style = Object.assign({}, bodyStyle || {});
    const wrapperWidth =
      wrapperWidthProp === 0 || wrapperWidthProp
        ? wrapperWidthProp
        : formContext.wrapperWidth;

    if (wrapperWidth || wrapperWidth === 0) {
      style.width = addUnit(wrapperWidth);
    }
    return style;
  });
  const [prevValue, setPrevValue] = useRefState(
    formContext.getFieldValue(name)
  );
  const randomNum = () => Math.floor(Math.random() * 1000);
  const [shouldUpdateDependency, setShouldUupdateDependency] = useRefState(
    randomNum()
  );
  useEffect(() => {
    //   卸载form字段，防止判断form item显示时上选择值还在
    return () => {
      if (name) return () => formContext.setFieldValue(name, undefined);
    };
  }, []);
  const shouldUpdate = name
    ? typeof shouldUpdateProps === "boolean"
      ? shouldUpdateProps
      : shouldUpdateProps(prevValue.current, formContext.getFieldValue(name))
    : true;
  if (shouldUpdate) setShouldUupdateDependency(randomNum());
  console.log(
    title,
    name,
    shouldUpdate,
    prevValue.current,
    formContext.getFieldValue(name),
    shouldUpdateDependency.current,
    getRules(),
    showLabel
  );
  const childrenDOM = useMemo(() => {
    if (name) setPrevValue(formContext.getFieldValue(name));
    let childrenInject =
      typeof children === "function" ? children(formContext) : children;
    return React.Children.map(childrenInject, (child) => {
      return fieldRender ? (
        name ? (
          fieldRender(
            formContext.getFieldValue(name),
            <Field
              name={name}
              title={title}
              updateType={updateType}
              valueType={valueType}
              initialValue={initialValueFieldProps}
              updater={shouldUpdateDependency.current}
            >
              {child}
            </Field>
          )
        ) : (
          fieldRender()
        )
      ) : name ? (
        <Field
          name={name}
          title={title}
          updateType={updateType}
          valueType={valueType}
          initialValue={initialValueFieldProps}
          updater={shouldUpdateDependency.current}
        >
          {child}
        </Field>
      ) : (
        child
      );
    });
  }, [updateType, shouldUpdateDependency.current]);
  return (
    <Row
      className={className("form-item", `u-form-item-${layout}`)}
      style={style}
      wrap={false}
    >
      {showLabel && title ? (
        <Col
          ref={labelRef}
          className={className("form-item-label", {
            "is-required": required || isRequired,
          })}
          style={labelStyle}
        >
          {labelSpace ? <>&nbsp;&nbsp;</> : null}
          {title}
          {tooltip ? <Tip title={tooltip}></Tip> : null}
        </Col>
      ) : null}
      <Col className="form-item-control" flex="auto" style={wrapperStyle}>
        {childrenDOM}
        <div
          className={className("form-item-message", {
            "is-active":
              showMessage && validateMessage && validateStatus === "error",
          })}
        >
          {validateMessage}
        </div>
      </Col>
    </Row>
  );
};
