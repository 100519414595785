import React, { useEffect, useState } from "react";
import {
  ReloadOutlined,
  SettingOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import {
  requestFullscreen,
  exitFullscreen,
  isFullScreen,
} from "@utils/fullscreen";
import Iconfont from "@components/Iconfont";
import { Tooltip, Space, Popover, Checkbox, Col, Row, Button } from "antd";
import uface from "@widget/uface";
import { useForm } from "@components/Form";
import { isSameName } from "@utils/field";
import className from "@/utils/className";
import "./ToolBar.less";
const FullScreenIcon = ({ title }) => {
  const [fullscreen, setFullscreen] = useState(false);
  const switchFullscreen = () => {
    if (fullscreen) exitFullscreen();
    else requestFullscreen(document.querySelector(".table-area"));
    setFullscreen(!fullscreen);
  };
  useEffect(() => {
    const judgeFullScreen = () => {
      setFullscreen(Boolean(isFullScreen()));
    };
    window.addEventListener("resize", judgeFullScreen, { passive: true });
    return () => {
      window.removeEventListener("resize", judgeFullScreen);
    };
  }, []);
  return (
    <Tooltip title={title}>
      {fullscreen ? (
        <Button
          onClick={switchFullscreen}
          icon={<FullscreenOutlined></FullscreenOutlined>}
        ></Button>
      ) : (
        <Button
          onClick={switchFullscreen}
          icon={<FullscreenExitOutlined></FullscreenExitOutlined>}
        ></Button>
      )}
    </Tooltip>
  );
};
const FieldCheckList = ({ columns: propsColumns, onColumnChange }) => {
  const [columns] = useState(propsColumns);
  const [checked, setChecked] = useState(columns.map((item) => item.key));
  useEffect(() => {
    setChecked(propsColumns.map((item) => item.key));
  }, [propsColumns]);
  return (
    <Checkbox.Group
      style={{ width: "200px" }}
      value={checked}
      onChange={(e) => {
        onColumnChange(
          columns.filter((item) => (item.key ? e.includes(item.key) : true))
        );
      }}
    >
      <Row>
        {columns
          .filter((item) => item.key)
          .map((item) => (
            <Col key={item.key || Math.random() * 10} span={24}>
              <Checkbox value={item.key}>{item.title}</Checkbox>
            </Col>
          ))}
      </Row>
    </Checkbox.Group>
  );
};
const ColumnSetting = ({ title, columns, onColumnChange, ...restProps }) => {
  const [initColumns] = useState(columns);
  return (
    <Popover
      title={
        <div className="flex flex-between">
          <span>列展示</span>
          <a onClick={() => onColumnChange(initColumns)}>重置</a>
        </div>
      }
      placement="bottomRight"
      trigger="click"
      content={
        <FieldCheckList
          columns={columns}
          onColumnChange={onColumnChange}
          {...restProps}
        ></FieldCheckList>
      }
    >
      <Tooltip title={title}>
        <Button icon={<SettingOutlined></SettingOutlined>}></Button>
      </Tooltip>
    </Popover>
  );
};
const Reload = ({ title, action }) => (
  <Tooltip title={title}>
    <Button
      onClick={() => action.reload()}
      icon={<ReloadOutlined></ReloadOutlined>}
    ></Button>
  </Tooltip>
);
const Filter = ({ title, action, filterColumns, queryForm }) => {
  return filterColumns.length > 3 ? (
    <Tooltip title={title}>
      <Button
        onClick={() => {
          console.log(queryForm.getStore());
          uface.makeForm({
            title: "筛选条件",
            initialValue: queryForm.getStore(),
            columns: filterColumns.map((item) => ({
              ...item,
              title: item.title || "搜索",
            })),
            success(res, formInstance) {
              queryForm.setStore(res);
              queryForm.setRaw(formInstance.getRaw());
              action.reload(res);
            },
          });
        }}
        icon={<FilterOutlined />}
      ></Button>
    </Tooltip>
  ) : null;
};
const buttonText = [
  {
    text: "筛选",
    Icon: Filter,
  },
  {
    text: "刷新",
    Icon: Reload,
  },
  {
    text: "列设置",
    Icon: ColumnSetting,
  },
  {
    text: "全屏",
    Icon: FullScreenIcon,
  },
];
/**内置操作栏
 * toolBarRender 右侧内置工具栏追加项
 * operateBarRender 左侧自定义区域
 * */
const ToolBar = ({
  searchArea,
  headerTitle,
  operateBarRender,
  toolBarRender,
  columns,
  queryForm,
  filterColumns,
  action,
  onColumnChange,
  ...rest
}) => {
  const viewStore = queryForm.getRaw("text");
  const viewFilter = Object.keys(viewStore)
    .filter((key) => viewStore[key] !== undefined && viewStore[key] !== "")
    .map((key) => ({
      ...filterColumns.find((item) => isSameName(item.name, key)),
      value: viewStore[key],
    }));
  const viewFilterRender = ({ style = {} } = {}) => (
    <div className="table-toobar-filter" style={style}>
      高级筛选条件：
      {viewFilter.map((item, index) => (
        <div className="item" key={index}>
          <span className="text">
            {item.title && `${item.title}：`}
            {item.value}
          </span>
          <Iconfont
            type="icon-shanchu"
            style={{ fontSize: "10px", color: "#3e3d3d" }}
            onClick={() => {
              queryForm.setFieldValue(item.name, undefined);
              action.reload(queryForm.getStore());
            }}
          ></Iconfont>
        </div>
      ))}
      <div
        className="blue-color pointer"
        onClick={() => {
          filterColumns.forEach((item) =>
            queryForm.setFieldValue(item.name, undefined)
          );
          action.reload();
        }}
      >
        清除筛选
      </div>
    </div>
  );
  const operateActions = operateBarRender
    ? operateBarRender(action).filter(Boolean)
    : [];
  // 操作列表
  const actions = toolBarRender ? toolBarRender(action).filter(Boolean) : [];
  const toolBarDefault = buttonText.map((item, index) => {
    const { Icon } = item;
    return (
      <Icon
        key={index}
        title={item.text}
        columns={columns}
        queryForm={queryForm}
        filterColumns={filterColumns}
        action={action}
        onColumnChange={onColumnChange}
        {...rest}
      ></Icon>
    );
  });
  // return (
  //   <div className="table-toolbar">
  //     <div className="table-toolbar-title">{headerTitle}</div>
  //     <div className="table-toobar-operate">
  //       <Space>
  //         {actions.map((item, index) => {
  //           return React.cloneElement(item, {
  //             key: 10 + index, //避免和内部工具栏冲突
  //             ...item.props,
  //           });
  //         })}
  //         {toolBarDefault}
  //       </Space>
  //     </div>
  //   </div>
  // );
  return (
    <div className="table-toolbar-wrap">
      {headerTitle ? <div className="title-name">{headerTitle}</div> : null}
      <div className="table-toolbar">
        {operateBarRender && operateActions.length > 0 ? (
          <div className="table-toolbar-operate left-operate">
            {operateActions.map((item, index) => {
              return React.cloneElement(item, {
                key: index,
                ...item.props,
              });
            })}
          </div>
        ) : null}
        <div
          className={className(
            "table-toobar-operate",
            operateBarRender
              ? null
              : actions.length > 0 || filterColumns.length > 0
              ? ["flex-between", "flex-grow", "flex-right"]
              : ["flex-grow", "flex-right"]
          )}
        >
          {searchArea({
            after:
              viewFilter.length > 0 &&
              filterColumns.length > 3 &&
              viewFilter.length <= 2
                ? viewFilterRender({
                    style: {
                      marginLeft: "8px",
                      alignItems: "center",
                      height: "32px",
                      marginBottom: 0,
                    },
                  })
                : null,
          })}
          <div className="gap-left-8" style={{ marginBottom: "24px" }}>
            {actions.map((item, index) => {
              return React.cloneElement(item, {
                key: Math.random() * 1000, //避免和内部工具栏冲突
                ...item.props,
              });
            })}
            {toolBarDefault}
          </div>
        </div>
      </div>
      {viewFilter.length > 0 &&
      filterColumns.length > 3 &&
      viewFilter.length > 2
        ? viewFilterRender()
        : null}
    </div>
  );
};
export default ToolBar;
