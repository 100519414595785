import { useState } from "react";
import { asyncRouterHandle } from "@utils/asyncRouter";
import { constantRoutes } from "@config";
import api from "@api";
export default () => {
  const [asyncRoutes, setAsyncRoutes] = useState(
    asyncRouterHandle(constantRoutes)
  );
  const [loading, setLoading] = useState(true);
  const setAsyncRouter = () =>
    api.auth.getLeftMenu().then((res) => {
      setAsyncRoutes(
        asyncRouterHandle(
          constantRoutes.concat(res).concat([
            {
              path: "/*",
              id: 9999,
              hidden: true,
              component: "NotFound",
            },
          ])
        )
      );
      setTimeout(() => {
        setLoading(false);
      }, 0);
    });
  const findParentPathForChildOne = (path, routes, parent) =>
    routes.reduce((accu, cur) => {
      if (accu) return accu;
      if (cur.path === path) return parent.path;
      else if (cur.children)
        return findParentPathForChildOne(path, cur.children, cur);
      else return accu;
    }, "");
  const findParentPathForChild = (path, routes) =>
    routes
      .filter((route) => route.children)
      .map((route) => findParentPathForChildOne(path, route.children, route))
      .filter((route) => route);
  const getChildStr = (str, childStr) => {
    if (str === "/") return childStr === str;
    if (childStr === "/") return childStr === str;
    const strs = str.split("/");
    if (
      childStr.split("/").reduce((accu, cur, index) => {
        return accu && cur === strs[index];
      }, true)
    )
      return [
        str.indexOf(childStr[0]),
        str.lastIndexOf(childStr[childStr.length - 1]),
      ];
    return false;
  };
  /**
   * 获取面包屑导航
   */
  const getBreadcrumbList = (path, arr = asyncRoutes) =>
    path === "/"
      ? []
      : arr.reduce((accu, cur) => {
          const childStr = getChildStr(path, cur.path);
          if (childStr) accu.push(cur);
          if (cur.children && cur.children.length > 0)
            accu.push(...getBreadcrumbList(path, cur.children));
          return accu;
        }, []);
  /**
   * 获取此路由的绝对路径
   */
  const getPath = (id, arr = asyncRoutes) =>
    arr.reduce((accu, cur) => {
      if (accu) return accu;
      if (id === cur.id) return cur;
      if (cur.children && cur.children.length > 0)
        return getPath(id, cur.children);
      return accu;
    }, "");
  // 是否存在此路由
  const hasRoute = (path, arr = asyncRoutes) =>
    arr.reduce((accu, cur) => {
      if (accu) return accu;
      if (cur.path.includes(path)) return true;
      if (cur.children && cur.children.length > 0)
        return hasRoute(path, cur.children);
      return accu;
    }, false);
  // 通过path是获取路由
  const getRouteByPath = (path, arr = asyncRoutes) =>
    arr.reduce((accu, cur) => {
      console.log(cur);
      if (accu) return accu;
      if (cur.path === path) return cur;
      if (cur.children && cur.children.length > 0)
        return getRouteByPath(path, cur.children);
      return accu;
    }, false);
  return {
    loading,
    asyncRoutes,
    setAsyncRouter,
    findParentPathForChild,
    getBreadcrumbList,
    getPath,
    hasRoute,
    getRouteByPath,
  };
};
