import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import "antd/dist/antd.css";
import "./style/index.less";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "@model";
import "./static/iconfont/iconfont.css";
// 国际化
import zhCN from "antd/locale/zh_CN";
import { ConfigProvider } from "antd";
import { theme } from "./config";
/**
 * React.StrictMode调用多次问题
 * https://juejin.cn/post/6975431850537779230
 * https://juejin.cn/post/7092696529105846303
 * 在 React.StrictMode 下，React 通过重复调用组件的一些钩子，从而使副作用更容易暴露出来；同时，它仅在开发环境中运行，不会影响生产构建
 */
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ConfigProvider locale={zhCN} theme={theme}>
      <Provider>
        <App></App>
      </Provider>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
