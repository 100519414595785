import Table from "@components/Table";
import { Button, Tag, Image } from "antd";
import Link from "@components/Link";
import { TableDropDown } from "@components/Table";
import uface from "@widget/uface";
import api from "@api";
import { PlusOutlined } from "@ant-design/icons";
/**
 * 信息
 */
export default () => {
  const columns = [
    {
      title: "标题",
      key: "case_title",
    },
    {
      title: "类别",
      render: (row) => (
        <Tag color="purple" onClick={(e) => e.stopPropagation()}>
          {row.type_name}
        </Tag>
      ),
    },
    {
      title: "大图",
      render: (row) => (
        <Image
          src={row.case_img_full}
          style={{ height: "48px" }}
          onClick={(e) => e.stopPropagation()}
        ></Image>
      ),
    },
    {
      title: "小图",
      render: (row) => (
        <Image src={row.case_sml_full} style={{ height: "48px" }}></Image>
      ),
    },
    {
      width: 140,
      title: "操作",
      valueType: "operation",
      render: (row, action) => [
        <Link to="/product/index/operate" query={{ id: row.id }}>
          编辑
        </Link>,
        <a
          className="danger-color"
          onClick={(e) => {
            e.stopPropagation();
            action.delete(
              "Client/deleteClient",
              { id: row.id },
              row.case_title
            );
          }}
        >
          删除
        </a>,
      ],
    },
  ];
  const searchColumns = [{ name: "keywords", placeholder: "可输入标题名搜索" }];
  return (
    <div>
      <Table
        columns={columns}
        request="Client/clientList"
        search={{ columns: searchColumns }}
        toolbar={{
          operateRender: () => [
            <Link to="/product/index/operate">
              <Button type="primary" icon={<PlusOutlined />}>
                新增
              </Button>
            </Link>,
          ],
        }}
        onRow={(e) => ({
          onClick: () =>
            api["Client/clientDetail"]({ data: { id: e.id } }).then((row) =>
              uface.showDrawer({
                title: "预览",
                width: 600,
                showCancel: false,
                showConfirm: false,
                //   getContainer: () => document.querySelector(".admin-box"),
                content: (
                  <div className="new-detail">
                    <h1>{row.case_title}</h1>
                    <div className="news-date">{row.publishdate}</div>
                    <h2>简介：</h2>
                    <div className="news-intro">{row.case_intro}</div>
                    <h2>内容详情：</h2>
                    <div
                      className="news-content"
                      dangerouslySetInnerHTML={{ __html: row.case_content }}
                    ></div>
                  </div>
                ),
              })
            ),
        })}
      ></Table>
    </div>
  );
};
