import Table from "@components/Table";
import { uface } from "@widget";
import { Button, Tag, Descriptions, Image } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import api from "@api";
function setUse(data, action) {
  uface
    .showModal({
      content: `确定要${data.is_used == 1 ? "启用" : "停用"}吗`,
    })
    .then((confirm) => {
      if (confirm) {
        api["Solution/setTypeUsedOrUn"]({ data }).then((res) => {
          if (res.result) action.reload();
        });
      }
    });
}
/**
 * 类别
 */
export default () => {
  const showOperate = (request, data = {}, reload) => {
    uface.makeForm({
      title: "新增类别",
      request,
      initialValue: data,
      columns: [
        {
          title: "名称",
          name: "type_name",
        },
        {
          title: "简介",
          name: "type_intro",
          valueType: "textarea",
          autoSize: {
            minRows: 5,
          },
        },
        {
          title: "排序",
          type: "number",
          name: "sort",
        },
        {
          title: "封面图",
          name: "coverImg[]",
          valueType: "upload:image",
          limit: 1,
        },
      ],
      success() {
        reload();
      },
    });
  };
  const columns = [
    {
      title: "类别",
      key: "type_name",
    },
    {
      title: "排序",
      key: "sort",
    },
    {
      title: "图片",
      render: (row) => (
        <Image src={row.type_img_full} style={{ height: "48px" }}></Image>
      ),
    },
    {
      title: "状态",
      render: (row) =>
        row.is_used == 1 ? (
          <Tag color="green">启用</Tag>
        ) : (
          <Tag color="red">停用</Tag>
        ),
    },
    {
      width: 130,
      title: "操作",
      valueType: "operation",
      render: (row, action) => [
        row.is_used == 1 ? (
          <a
            onClick={(e) => {
              e.stopPropagation();
              setUse({ id: row.id, is_used: row.is_used == 1 ? 2 : 1 }, action);
            }}
          >
            停用
          </a>
        ) : (
          <a
            onClick={(e) => {
              e.stopPropagation();
              setUse({ id: row.id, is_used: row.is_used == 1 ? 2 : 1 }, action);
            }}
          >
            启用
          </a>
        ),
        <a
          onClick={(e) => {
            e.stopPropagation();
            showOperate(
              "Solution/editSolutionType",
              {
                type_name: row.type_name,
                id: row.id,
                sort: row.sort,
                type_intro: row.type_intro,
                "coverImg[]": [{ path: row.type_img_full }],
              },
              action.reload
            );
          }}
        >
          编辑
        </a>,
        <a
          className="danger-color"
          onClick={(e) => {
            e.stopPropagation();
            action.delete(
              "Solution/delSolutionType",
              { id: row.id },
              row.type_name
            );
          }}
        >
          删除
        </a>,
      ],
    },
  ];
  const searchColumns = [
    { name: "keywords", placeholder: "可输入类别名称筛选" },
  ];
  return (
    <div>
      <Table
        columns={columns}
        request="Solution/pageGetSolutionType"
        search={{ columns: searchColumns }}
        toolbar={{
          operateRender: (action) => [
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() =>
                showOperate("Solution/addSolutionType", {}, action.reload)
              }
            >
              新增
            </Button>,
          ],
        }}
        onRow={(row) => ({
          onClick: () =>
            uface.showDrawer({
              title: "预览",
              width: 600,
              showCancel: false,
              showConfirm: false,
              //   getContainer: () => document.querySelector(".admin-box"),
              content: (
                <Descriptions
                  column={1}
                  bordered
                  labelStyle={{ width: "90px" }}
                >
                  <Descriptions.Item label="名称">
                    {row.type_name}
                  </Descriptions.Item>
                  <Descriptions.Item label="简介">
                    {row.type_intro}
                  </Descriptions.Item>
                  <Descriptions.Item label="排序">{row.sort}</Descriptions.Item>
                  <Descriptions.Item label="附件">
                    <Image src={row.type_img_full}></Image>
                  </Descriptions.Item>
                </Descriptions>
              ),
            }),
        })}
      ></Table>
    </div>
  );
};
