
import {
    storage,
} from '@config';
import storageCache from './storageCache';
export default function (key,value) {
    key = window.location.host+'-'+key;
    value = typeof value==='object'? JSON.stringify(value):value;
	storageCache[key]=value;
    if (storage) {
        if ('localStorage' === storage) {
            return localStorage.setItem(key, value)
        } else if ('sessionStorage' === storage) {
            return sessionStorage.setItem(key, value)
        } else {
            return localStorage.setItem(key, value)
        }
    } else {
        return localStorage.setItem(key, value)
    }
}