import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
const Tip = ({ title }) => {
  return (
    <Tooltip title={title}>
      <InfoCircleOutlined style={{ color: "#707070", marginLeft: "2px" }} />
    </Tooltip>
  );
};
export default Tip;
