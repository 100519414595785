import RichText from "@components/RichText";
import Form, { useForm, Select, FormMaker } from "@components/Form";
import {
  Input,
  Card,
  Button,
  Row,
  Col,
  Tree,
  Checkbox,
  InputNumber,
} from "antd";
import api from "@api";
import { useNavigate } from "react-router-dom";
import { useUrlQuery, useMount } from "@hooks";
import { useRef, useState } from "react";
import { pick } from "@utils/field";
import uface from "@widget/uface";
const { Field } = FormMaker;
function getDeepIDs(data, valueName) {
  return data.reduce((accu, cur) => {
    accu.push(cur[valueName]);
    if (cur.children && cur.children.length > 0)
      accu.push(...getDeepIDs(cur.children, valueName));
    return accu;
  }, []);
}
export default () => {
  const [{ id }] = useUrlQuery();
  const navigate = useNavigate();
  const [formInstance] = useForm();
  const submit = (data, files) => {
    if (id)
      api["News/updateNews"]({ data: { id, ...data }, files }).then((res) => {
        if (res.result) navigate("/news/news");
      });
    else
      api["News/addNews"]({ data: data, files }).then((res) => {
        if (res.result) navigate("/news/news");
      });
  };
  useMount(() => {
    if (id)
      api["News/newsDetail"]({ data: { id } }).then((detail) => {
        formInstance.setFields(
          pick(
            detail,
            "news_title",
            "news_intro",
            "news_content",
            "news_sort",
            "seo_title",
            "seo_keyword",
            "seo_descrip",
            ({ show_type, news_img_full }) => ({
              show_type: +show_type,
              "coverImg[]": [{ path: news_img_full }],
            })
          )
        );
      });
  });
  return (
    <Card>
      <Form
        form={formInstance}
        submitter={{ container: ".admin-box" }}
        onCancel={() => uface.jump()}
        onSubmit={submit}
      >
        <Form.Item required title="标题" name="news_title">
          <Input placeholder="请输入标题"></Input>
        </Form.Item>
        <Form.Item required title="简介" name="news_intro">
          <Input.TextArea autoSize={{ minRows: 6 }}></Input.TextArea>
        </Form.Item>
        <Field
          title="封面图"
          name="coverImg[]"
          valueType="upload:image"
          limit={1}
        ></Field>
        <Form.Item title="显示方式" name="show_type">
          <Select
            options={[
              {
                label: "轮播",
                value: 1,
              },
              {
                label: "列表",
                value: 2,
              },
            ]}
          ></Select>
        </Form.Item>

        <Form.Item title="排序" name="news_sort">
          <InputNumber />
        </Form.Item>
        <Form.Item title="seo标题" name="seo_title">
          <Input />
        </Form.Item>
        <Form.Item title="seo关键词" name="seo_keyword">
          <Input />
        </Form.Item>
        <Form.Item title="seo描述" name="seo_descrip">
          <Input.TextArea autoSize={{ minRows: 6 }}></Input.TextArea>
        </Form.Item>
        <Form.Item required title="内容" name="news_content">
          <RichText></RichText>
        </Form.Item>
      </Form>
    </Card>
  );
};
