import React from "react";
import Form, { useForm } from "../../BaseForm";
import { Row, Col, Button } from "antd";
import "./index.less";
function getSize(fieldName, columns) {
  return (
    {
      text: 8,
      dateRange: 8,
    }[fieldName] || 7
  );
  // Math.ceil(20/columns.length);
}
const FilterContent = ({ searchText, children, columns, ...props }) => {
  // totalSpan 筛选控件占的位置，计算 offset 保证查询按钮在最后一列
  let totalSpan = 0;
  let itemLength = 0;
  const itemsDom = React.Children.toArray(children)
    .filter(Boolean)
    .map((child, index) => {
      return React.cloneElement(child, {
        xs: "",
        md: "",
        // lg:getSize(columns[index].valueType||'text',columns),
        lg: "",
        ...(columns[index] ? columns[index].colProps || {} : {}),
      });
    });
  const submitter = (
    <Button
      className="search-btn"
      style={{ margin: "0 0 24px 20px" }}
      type="primary"
      htmlType="submit"
    >
      {searchText}
    </Button>
  );
  const reset = (
    <Button type="primary" htmlType="reset" style={{ margin: "0 0 24px 20px" }}>
      重置
    </Button>
  );
  return (
    <React.Fragment>
      {itemsDom}
      {submitter}
      {reset}
    </React.Fragment>
  );
};
/**
 * 筛选表单
 */
export default ({
  searchText = "搜索",
  children,
  columns = [],
  grid,
  after,
  ...restProps
}) => {
  console.log("aiaiaiai", restProps);
  const childArea = grid ? (
    React.Children.map(children, (child) => {
      return (
        <FilterContent
          searchText={searchText}
          children={child.props.children}
          columns={columns}
        ></FilterContent>
      );
    })
  ) : (
    <FilterContent
      searchText={searchText}
      children={children}
      columns={columns}
    ></FilterContent>
  );
  return columns.length > 0 ? (
    <Form
      className="query-filter flex flex-wrap"
      submitter={false}
      labelAlign="left"
      {...restProps}
    >
      {childArea}
      {after}
    </Form>
  ) : null;
};
