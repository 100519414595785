import Table from "@components/Table";
import { Button, Tag, Switch, Image } from "antd";
import Link from "@components/Link";
import { TableDropDown } from "@components/Table";
import uface from "@widget/uface";
import api from "@api";
import { PlusOutlined } from "@ant-design/icons";
/**
 * 信息
 */
export default () => {
  const columns = [
    {
      title: "标题",
      key: "solution_name",
    },
    {
      title: "简介",
      key: "solution_intro",
    },
    {
      title: "类别",
      render: (row) => <Tag color="purple">{row.type_name}</Tag>,
    },
    {
      title: "大图",
      render: (row) => (
        <Image
          src={row.solution_img_full}
          style={{ height: "48px" }}
          onClick={(e) => e.stopPropagation()}
        ></Image>
      ),
    },
    {
      title: "小图",
      render: (row) => (
        <Image
          src={row.solution_sml_full}
          style={{ height: "48px" }}
          onClick={(e) => e.stopPropagation()}
        ></Image>
      ),
    },
    {
      title: "是否热门",
      render: (row) =>
        row.is_hot == 1 ? (
          <Tag color="green">是</Tag>
        ) : (
          <Tag color="yellow">否</Tag>
        ),
    },
    {
      title: "是否启用",
      render: (row, action) => (
        <Switch
          defaultChecked={row.is_used == 1}
          checked={row.is_used == 1}
          onChange={(checked, e) => {
            e.stopPropagation();
            uface
              .showModal({ title: `确定要${checked ? "启用" : "关闭"}吗?` })
              .then((confirm) => {
                if (confirm)
                  api["Solution/setSolutionUsedOrUn"]({
                    data: { id: row.id, is_used: checked ? 1 : 2 },
                  }).then((res) => {
                    if (res.result) action.reload();
                  });
              });
          }}
        ></Switch>
      ),
    },
    {
      width: 140,
      title: "操作",
      valueType: "operation",
      render: (row, action) => [
        <Link to="/solution/index/operate" query={{ id: row.id }}>
          编辑
        </Link>,
        <a
          className="danger-color"
          onClick={(e) => {
            e.stopPropagation();
            action.delete(
              "Solution/delSolution",
              { id: row.id },
              row.solution_name
            );
          }}
        >
          删除
        </a>,
      ],
    },
  ];
  const searchColumns = [{ name: "keywords", placeholder: "可输入标题名搜索" }];
  return (
    <div>
      <Table
        columns={columns}
        request="Solution/pageGetSolutionList"
        search={{ columns: searchColumns }}
        toolbar={{
          operateRender: () => [
            <Link to="/solution/index/operate">
              <Button type="primary" icon={<PlusOutlined />}>
                新增
              </Button>
            </Link>,
          ],
        }}
        onRow={(e) => ({
          onClick: () =>
            api["Solution/getSolutionDetail"]({ data: { id: e.id } }).then(
              (row) =>
                uface.showDrawer({
                  title: "预览",
                  width: 600,
                  showCancel: false,
                  showConfirm: false,
                  //   getContainer: () => document.querySelector(".admin-box"),
                  content: (
                    <div className="new-detail">
                      <h1>{row.solution_name}</h1>
                      <h2>内容详情：</h2>
                      <div
                        className="news-content"
                        dangerouslySetInnerHTML={{ __html: row.content }}
                      ></div>
                    </div>
                  ),
                })
            ),
        })}
      ></Table>
    </div>
  );
};
