const storage = "localStorage";
const pageParams = {
  //Table组件配置项
  page: 0, //定义 页数计算的索引
  pageSize: 10, //定义 请求后台页数的字段
};
const tokenName = "token";
//id以负数，避免和数据库索引重复
const constantRoutes = [
  {
    id: -9999,
    path: "/",
    component: "Home",
    index: true, //默认路由
    affix: true,
    title: "工作台",
    icon: "icon-gongzuotai",
  },
  {
    id: -9998,
    path: "/changePassword",
    component: "system/changePassword",
    title: "修改密码",
    hidden: true,
  },
];
const getEnvs = () =>
  Object.keys(process.env).filter((key) => /^REACT_ADMIN_/i.test(key));
const theme = {
  token: {
    colorPrimary: "#166EFF",
  },
};
const IconfontUrl = "//at.alicdn.com/t/c/font_3477363_bk3gfynvxct.js";
export {
  storage,
  constantRoutes,
  tokenName,
  pageParams,
  getEnvs,
  theme,
  IconfontUrl,
};
