import compress from "@utils/compressImage";
import { purefn, uface } from "@widget";
import "./index.less";
const imgExt = ".jpg|.jpeg|.gif|.bmp|.png|"; //全部图片格式类型
export function getBase64(file) {
  return new Promise((resolve) => {
    // 预览图片
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      // const img=new Image();
      // img.onload=function(){
      //     console.log(img.width,img.height)
      // }
      // img.src=e.target.result;
      resolve(e.target.result);
    };
  });
}
/**
	 * 
	 * // 最多可以选择的图片张数
            count: {
                type: [Number,String],
                default: -1
            },
            size: {
                type: [Number, String],
                default: ''
            },
            quality:{
                type:[Number,String],
                default:0.5
            },
            qualitySize:{
                type:[Number,String],
                default:2
            },
            disabled:{
                type:Boolean,
                default:false
            }
	*/
export default ({
  onChange,
  children,
  disabled = false,
  count = -1,
  size = 10, //M
  quality = 0.5,
  qualitySize = 2,
  className = "",
}) => {
  async function handleFile(e) {
    let event = [];
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      if (count > 0 && i + 1 > count) continue;
      let file = files[i],
        limitSize = 1048576 * Number(size);
      // 检测文件大小 1024*1024=1048576(1M)
      if (size && file.size > limitSize) {
        uface.showToast({
          title: "上传图片大小不得超过" + size + "M",
          icon: "warning",
        });
        continue;
      }
      if (file.size > qualitySize * 1048576) {
        const compressFile = await compress(file, {
          type: "file",
          compress: {
            quality: quality,
          },
        });
        if (compressFile) file = compressFile;
      }
      const base64 = await getBase64(file);
      event.push({
        base64,
        file,
      });
    }
    onChange(event);
    // 清空，防止无法上传同一文件
    e.target.value = "";
  }
  const checkExt = (file) => {
    const ext = file.name.substr(file.name.lastIndexOf(".")).toLowerCase();
    if (imgExt.indexOf(ext + "|") == -1) {
      uface.$showToast({ icon: "warning", title: "该文件类型不允许上传!" });
      return false;
    }
    return true;
  };
  return (
    <div className={`wrapper ${className}`}>
      {disabled ? null : (
        <input
          type="file"
          accept="image/*"
          multiple={count > 1}
          onChange={handleFile}
        />
      )}
      {children}
    </div>
  );
};
