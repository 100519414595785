import { useModel } from "@model";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
const Collapse = () => {
  const { collapsed, setCollapsed } = useModel("ui");
  return collapsed ? (
    <MenuUnfoldOutlined
      className="header__trigger custom-trigger"
      onClick={setCollapsed}
    />
  ) : (
    <MenuFoldOutlined
      className="header__trigger custom-trigger"
      onClick={setCollapsed}
    />
  );
};
export default Collapse;
