    import utils from '../utils';
    import {
        strtoBuffer,
        filetoBuffer
    } from "@/utils/file";

    function generateBoundary() {
        // return '------WebKitFormBoundaryZP5QwA8BAtfuYb56';
        let boundary = '------';
        for (let i = 0; i < 24; i++) {
            boundary += Math.floor(Math.random() * 10).toString(16);
        }
        return boundary;
    }
    const LINE_BREAK = '\r\n';
    const mimeType = {
        'jpg': 'image/jpeg',
        'bmp': 'image/bmp',
        'gif': 'image/gif',
        'png': 'image/png'
    };
    /**
     * 转换TypedArray 直接读取ArrayBuffer为undefined
     */
    async function toBuffer(str) {
        return new Uint8Array(await strtoBuffer(str));
    }
    /**
     * obj:{
                innername:'innername',
                innerage:23,
                inneraddress:[
                    {
                        city:'shanghai',
                        area:'pudong'
                    },
                    {
                        city:'jiangsu',
                        area:'nanjing'
                    },
                ]
            }
            =>
            obj[innername] 
    obj[innerage] 
    obj[inneraddress][][city] 
    obj[inneraddress][][area] 
    obj[inneraddress][][city] 
    obj[inneraddress][][area] 
     */
    function transformData(data,temp={},keyP='',depth=0) {
        if(data instanceof Array){
            data.forEach((item,index)=>{
                if(typeof item==='object') transformData(item,temp,`${keyP}[${index}]`,depth+1)
                else temp[keyP+'[]']=item;
            })
        } else {
            // object
            Object.keys(data).forEach(key=>{
               if(typeof data[key]==='object') transformData(data[key],temp,depth===0?`${keyP}${key}`:`${keyP}[${key}]`,depth+1);
               else temp[depth===0?`${keyP}${key}` :`${keyP}[${key}]`]=data[key];
            })
        }
        return temp;
    }
    /**
     * multipart-data
     * Content-Type :multipart/form-data; boundary=[boundary]
     * 
     * --[boundary]
     * Content-Disposition:form-data`1  ;name="user"
     * 
     * a
     * [boundary]
     * Content-Disposition:form-data; name="image";filename=[affix]
     * Content-Type: image/gif
     * 
     * [binary]
     * --[boundary]--
     */
    export default async function (config) {
        let {
            data,
            header
        } = config;
        if (utils.isFormData(data)) {
            delete header['Content-Type']; // Let the browser set it
            return data;
        }
        let requestData;
        // 由于content-type 默认使用utf8 因此buffer在表示层传输会用utf8读取， 若buffer使用utf16,则在使用utf8变长字节读取会导致字母空出一位
        if (config.files && config.files.length > 0) {
            const boundary = generateBoundary(),
                buffers = [];
            config.header['Content-Type'] = `multipart/form-data; boundary=${boundary}`;
            let keyStr = '',
                multipart = `--${boundary}${LINE_BREAK}`;
                const transformD=transformData(data);
            Object.keys(transformD).forEach(key => {
                keyStr += `${multipart}Content-Disposition: form-data; name="${key}"${LINE_BREAK}${LINE_BREAK}${transformD[key]}${LINE_BREAK}`;
            })
            buffers.push(await toBuffer(keyStr));
            config.files.forEach(async item => {
                const file = item.file,
                    filename = file.name.match(/^(.*)\./)[1];
                buffers.push(await toBuffer(`${multipart}Content-Disposition: form-data; name="${item.name}"; filename="${filename}"${LINE_BREAK}Content-type:${file.type}${LINE_BREAK}${LINE_BREAK}`))
                buffers.push(new Uint8Array(await filetoBuffer(item.file)));
            })
            buffers.push(await toBuffer(`--${boundary}--${LINE_BREAK}`));
            // bufild buffer
            const LEN = buffers.reduce((accu, cur) => cur.length + accu, 0),
                buf = new ArrayBuffer(LEN),
                buffer = new Uint8Array(buf);
            let sum = 0;
            for (let i = 0; i < buffers.length; i++) {
                for (let j = 0; j < buffers[i].length; j++) {
                    buffer[j + sum] = buffers[i][j];
                }
                sum += buffers[i].length;
            }
            requestData = buffer;
        } else requestData = await strtoBuffer(JSON.stringify(data));
        return requestData;
    }