/**
 * 防抖 时间段内频繁触发同一个事件时，只执行最后一次的事
 * https://www.cnblogs.com/star91/p/qian-ru-qian-chu-han-shu-fang-dou-debounce-yu-jie-.html
 *
 */
export default function debounce(fn, time, stopFn) {
  let timer = null;
  if (stopFn) stopFn(() => timer && clearTimeout(timer));
  return function () {
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      fn.call(this, ...arguments);
    }, time);
  };
}
