import typeUtil from "./typeUtil";
const hasOwnProperty = Object.prototype.hasOwnProperty;

const getType = typeUtil("getType");

function getReg(a) {
  var c = a.lastIndexOf("/");
  var reg = a.substring(1, c);
  var escMap = {
    '"': '\\"',
    "\\": "\\\\",
    "\\b": "\\b",
    "\\f": "\\f",
    "\\n": "\\n",
    "\\r": "\\r",
    "\\t": "\\t",
    "\\w": "\\w",
    "\\s": "\\s",
    "\\d": "\\d",
  };
  for (var i in escMap) {
    if (reg.indexOf(i)) {
      reg.replace(i, escMap[i]);
    }
  }
  var attr = a.substring(c + 1);
  return new RegExp(reg, attr);
}

function cloneType(value) {
  let temp;
  if (getType(value) === "Function") {
    // function内必须不包含任何外部变量，否则无法找到报错
    // temp = new Function("return " + value.toString())();
    temp = value;
  } else if (getType(value) === "RegExp") {
    temp = getReg(value.toString());
  } else {
    temp = value;
  }
  return temp;
}

/**
 * 深拷贝 不处理File,ArrayBUffer等数据
 * @param obj
 * @param map
 * @returns {{}|*}
 */
function clone(obj, replacer = (e) => e, map = new Map()) {
  let res;
  if (getType(obj) === "Array") {
    res = [];
  } else if (getType(obj) === "Object") {
    res = {};
  } else {
    return replacer(cloneType(obj));
  }
  // if (obj.page) {obj.pages=obj.page;
  //     delete obj.page;}
  // map.set(obj,obj);
  for (let item in obj) {
    //   若为数组，item为下标索引，若为object，item为键名
    if (hasOwnProperty.call(obj, item)) {
      if (obj[item] === obj) continue; //存在自身引用则跳出当前循环
      if (map.has(obj[item])) {
        res[item] = replacer(map.get(obj[item]));
      } else if (typeof obj === "object") {
        map.set(obj[item], obj[item]);
        res[item] = replacer(clone(obj[item], replacer, map));
      } else {
        //   Function,RegExp
        res[item] = replacer(cloneType(obj[item]));
      }
    }
  }
  return res;
}

export default clone;
