import typeUtil from "./typeUtil";
const getType = typeUtil("getType");

function judgeif(value, branch) {
  const _this = this;
  if (getType(value) === "Array") {
    return value.map((item) => judgeif.call(this, item, branch));
  } else {
    let actions, type;
    if (getType(branch) === "Array") {
      type = branch
        .map((item) => item[0])
        .reduce((accumulator, current) => {
          if (getType(current) === "Regexp") return "regexp";
          if (getType(current) === "Boolean") return "boolean";
          return accumulator ? accumulator : getType(current);
        }, "");
      // 表达式作为判断条件 [1==1,function(){}]
      if (type === "boolean") {
        branch.map((item) => {
          if (item[0]) {
            if (typeof item[1] === "function") {
              item[1].call(this, value);
            } else if (typeof item[1] === "object") {
              judgeif.call(this, value, item[1]);
            }
          } else if (item[0] === "default") {
            if (typeof item[1] === "function") {
              item[1].call(this, value);
            } else if (typeof item[1] === "object") {
              judgeif.call(this, value, item[1]);
            }
          }
        });
      } else actions = new Map(branch);
    } else if (getType(branch) === "Object") {
      // branch={a(){}}
      type = Object.keys(branch).reduce((accumulator, current) => {
        if (getType(branch[current]) === "Regexp") return "Regexp";
        return accumulator ? accumulator : getType(branch[current]);
      }, "");
      actions = new Map();
      // 匹配 'a|b'场景
      Object.keys(branch).forEach((key) => {
        if (key.includes("|")) {
          key.split("|").forEach((item) => {
            const fn = actions.get(item);
            if (fn) {
              actions.set(item, () => {
                branch[item]();
                fn();
              });
            } else {
              actions.set(item, branch[key]);
            }
          });
        } else if (actions.get(key)) {
          const fn = actions.get(key);
          actions.set(key, () => {
            branch[key]();
            fn();
          });
        } else {
          actions.set(
            typeof value === "number" ? Number(key) : key,
            branch[key]
          );
        }
      });
    }
    if (type === "regexp") {
      // [/a/,()=>{}]
      const element = [...actions].find(([key]) =>
          getType(key) === "Regexp" ? key.test(value) : key == value
        ),
        action = element ? element[1] : actions.get("default");
      return typeof action === "function" ? action.call(_this) : action;
    } else if (!(type === "boolean")) {
      const action = actions.get(value) || actions.get("default");
      return typeof action === "function" ? action.call(_this) : action;
    }
  }
}
export default judgeif;
