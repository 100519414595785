import Table from "@components/Table";
import { Button, Tag, Descriptions } from "antd";
import { uface, purefn } from "@widget";
import { pick } from "@utils/field";
import { PlusOutlined } from "@ant-design/icons";
import api from "@api";
const getDescDetail = (row) => ({
  "平台(模块名)": () => (row.module === "manage" ? "平台" : "手机"),
  功能: () =>
    row.rule_type === "nav" ? (
      <Tag color="purple">页面</Tag>
    ) : (
      <Tag color="purple">按钮</Tag>
    ),
  标识符: "button_identifier",
  ...(row.rule_type == "nav"
    ? {
        标题: "title",
        是否是动态标题: "is_dynamic_title",
        图标: "icon",
        组件地址: "component",
        路由地址: "path",
        是否默认路由: () =>
          row.index ? <Tag color="red">否</Tag> : <Tag color="green">是</Tag>,
        是否必须路由: () =>
          row.must_show ? (
            <Tag color="red">否</Tag>
          ) : (
            <Tag color="green">是</Tag>
          ),
        路由参数: "query",
        隐藏: () =>
          row.hidden ? <Tag color="red">否</Tag> : <Tag color="green">是</Tag>,
        是否展开子路由: () =>
          row.spread_child ? (
            <Tag color="red">否</Tag>
          ) : (
            <Tag color="green">是</Tag>
          ),
      }
    : {
        按钮名: "title",
        是否显示: () =>
          row.is_button_show ? (
            <Tag color="red">否</Tag>
          ) : (
            <Tag color="green">是</Tag>
          ),
      }),
});
const moduleList = [
  {
    label: "管理端",
    value: "manage",
  },
  {
    label: "手机端",
    value: "mobile",
  },
];
const Modules = () => {
  const operate = (request, query, reload) => {
    uface.makeForm({
      title: `${query ? "编辑" : "新增"}模块`,
      request,
      width: 600,
      initialValue: query || {},
      syncToRequest: (data) => ({
        data: purefn.merge({}, data, {
          data: { pid: data.data.pid === "无" ? 0 : data.data.pid },
        }),
      }),
      columns: [
        {
          title: "上级模块",
          valueType: "tree:select",
          request: api["Auth/getSysModuleList"]({
            data: { module: "manage" },
          }),
          name: ["data", "pid"],
          fieldProps: {
            labelName: "title",
            valueName: "id",
          },
        },
        {
          title: "平台(模块名)",
          name: ["data", "module"],
          valueType: "select",
          options: moduleList,
          formItemProps: {
            initialValue: "manage",
          },
        },
        {
          title: "模块功能",
          name: ["data", "rule_type"],
          valueType: "select",
          formItemProps: {
            initialValue: "nav",
            tooltip: (
              <div>
                <p>页面：配置页面路由(附带权限)</p>
                <p>按钮：配置按钮权限</p>
              </div>
            ),
          },
          options: [
            {
              label: "页面",
              value: "nav",
            },
            {
              label: "按钮",
              value: "button",
            },
          ],
        },
        {
          title: "标识符",
          name: ["data", "button_identifier"],
          formItemProps: {
            tooltip:
              "按钮(页面也可作为按钮控制)的唯一标识符，建议：add/delete/edit等通用语义文字内容",
          },
        },
        {
          valueType: "dependency",
          name: [["data", "rule_type"]],
          columns: ({ data: { rule_type } }) => {
            if (rule_type === "nav")
              return [
                {
                  title: "标题",
                  name: ["data", "title"],
                },
                {
                  title: "动态标题",
                  name: ["data", "is_dynamic_title"],
                  valueType: "switch",
                  tooltip: "是否是动态标题",
                },
                {
                  title: "图标",
                  key: "icon",
                  name: ["data", "icon"],
                },
                {
                  title: "组件地址",
                  name: ["data", "component"],
                  formItemProps: {
                    tooltip: "本地引用组件路径",
                  },
                },
                {
                  title: "路由地址",
                  name: ["data", "path"],
                  formItemProps: {
                    tooltip: "浏览器地址栏",
                    required: true,
                  },
                },
                {
                  title: "默认路由",
                  name: ["data", "index"],
                  valueType: "switch",
                  formItemProps: {
                    tooltip: "如果没有URL匹配，默认显示的路由",
                  },
                },
                {
                  title: "必须路由",
                  name: ["data", "must_show"],
                  valueType: "switch",
                  formItemProps: {
                    tooltip: "默认不受控制送出的路由权限",
                  },
                },
                {
                  title: "路由参数",
                  name: ["data", "query"],
                  formItemProps: {
                    tooltip: "浏览器地址栏查询参数",
                  },
                },
                {
                  title: "隐藏",
                  name: ["data", "hidden"],
                  valueType: "switch",
                  formItemProps: {
                    tooltip: "是否在左侧菜单隐藏",
                  },
                },
                {
                  title: "是否展开子路由",
                  name: ["data", "spread_child"],
                  valueType: "switch",
                  formItemProps: {
                    tooltip:
                      "当子路由不是当前路由的嵌套路由，但权限中需显示为父子关系时使用",
                  },
                },
              ];
            else if (rule_type === "button")
              return [
                {
                  title: "按钮名",
                  name: ["data", "title"],
                  formItemProps: {
                    tooltip: "显示在权限配置页面的权限名",
                  },
                },
                {
                  title: "是否显示",
                  name: ["data", "is_button_show"],
                  valueType: "switch",
                  formItemProps: {
                    tooltip: (
                      <div>
                        <p>显示：显示按钮但禁用</p>
                        <p>不显示：不显示按钮</p>
                      </div>
                    ),
                  },
                },
              ];
            return null;
          },
        },
      ],
      success() {
        reload();
      },
    });
  };
  const sortOperate = (id, sort, reload) =>
    uface.makeForm({
      title: "修改排序",
      request: "Auth/setSysModuleSort",
      initialValue: {
        id,
      },
      columns: [
        {
          name: "sort",
          initialValue: sort,
        },
      ],
      success() {
        reload();
      },
    });
  const columns = [
    {
      title: "名称",
      key: "title",
    },
    {
      title: "类型",
      key: "rule_type",
      render: ({ rule_type }) =>
        rule_type === "nav" ? (
          <Tag color="purple">页面</Tag>
        ) : (
          <Tag color="purple">按钮</Tag>
        ),
    },
    {
      title: "是否显示",
      key: "hidden",
      render: ({ rule_type, hidden, is_button_show }) =>
        rule_type === "nav" ? (
          hidden ? (
            <Tag color="red">否</Tag>
          ) : (
            <Tag color="green">是</Tag>
          )
        ) : is_button_show == 1 ? (
          <Tag color="green">是</Tag>
        ) : (
          <Tag color="red">否</Tag>
        ),
    },
    {
      title: "排序",
      key: "sort",
    },
    {
      title: "操作",
      width: 140,
      valueType: "operation",
      render: (row, action) => [
        <a
          onClick={(e) => {
            e.stopPropagation();
            operate(
              "Auth/editSysModule",
              pick(
                row,
                "id",
                [
                  "spread_child",
                  ({ spread_child }) => ({
                    data: { spread_child: !!spread_child },
                  }),
                ],
                {
                  "title,component,module,pid,path,hidden,icon,rule_type,button_identifier,button_name,is_button_show,query":
                    (d) => ({
                      data: {
                        ...d,
                        pid: Number(d.pid) || "无",
                      },
                    }),
                }
              ),
              action.reload
            );
          }}
        >
          编辑
        </a>,
        <a
          className="danger-color"
          onClick={(e) => {
            e.stopPropagation();
            action.delete("Auth/delSysModule", { id: row.id }, row.title);
          }}
        >
          删除
        </a>,
        <a
          onClick={(e) => {
            e.stopPropagation();
            sortOperate(row.id, row.sort, action.reload);
          }}
        >
          排序
        </a>,
      ],
    },
  ];
  return (
    <Table
      request={(param) =>
        api["Auth/getSysModuleList"]({
          data: param,
        })
      }
      search={{
        columns: [
          {
            title: "管理端",
            name: "module",
            valueType: "select",
            options: moduleList,
            initialValue: "manage",
          },
        ],
      }}
      toolbar={{
        operateRender: (action) => [
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() =>
              operate("Auth/addSysModule", undefined, action.reload)
            }
          >
            新增
          </Button>,
        ],
      }}
      columns={columns}
      onRow={(row) => ({
        onClick: () => {
          const descDetail = getDescDetail(row);
          uface.showDrawer({
            width: "450px",
            title: "详情",
            // headerStyle: { display: "none" },
            showCancel: false,
            showConfirm: false,
            // getContainer: () => document.querySelector(".admin-box"),
            content: () => (
              <div className="user-detail">
                <div className="user-detail-info">
                  <Descriptions
                    labelStyle={{
                      width: "130px",
                      color: "#909599",
                      fontSize: "14px",
                    }}
                    column={1}
                  >
                    {Object.keys(descDetail).map((key, index) => {
                      return (
                        <Descriptions.Item label={key} key={index}>
                          {typeof descDetail[key] === "function"
                            ? descDetail[key](row)
                            : row[descDetail[key]]}
                        </Descriptions.Item>
                      );
                    })}
                  </Descriptions>
                </div>
              </div>
            ),
          });
        },
      })}
    ></Table>
  );
};
export default Modules;
