import api from "@api";
import { uface } from "@widget";
import { tokenName } from "@config";
import { useState } from "react";
export function loginOut() {
  uface
    .showModal({ content: "确定要退出吗", icon: "warning" })
    .then((confirm) => {
      if (confirm)
        api.login.loginOut().then((res) => {
          if (res.result) {
            uface.removeStorage(tokenName);
            // uface.jump({ path: "login" });
            window.location.href = window.location.origin + "/login";
          }
        });
    });
}
export default () => {
  const [userinfo, setUserInfo] = useState(uface.getStorage("userinfo") || {});
  const setUserinfo = (info) => {
    setUserInfo(info);
    uface.setStorage("userinfo", info);
  };
  return {
    userinfo,
    setUserinfo,
  };
};
