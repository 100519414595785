import ObjUtil from "./ObjUtil";
import StrUtil from "./StrUtil";
import md5 from "@/plugins/md5";
import emitter from "@emitter";
import showToast from "./uShowToast";
const REMPARAM = ["caseid", "casedetailid", "disabled"]; //保留参数
function remParamF(config) {
  if (config.query) {
    const prevParam = StrUtil.queryString(window.location.href, {
      fullMatch: true,
    });
    Object.keys(prevParam).forEach((key) => {
      if (REMPARAM.includes(key) && !config.query.hasOwnProperty(key))
        config.query[key] = prevParam[key];
    });
  }
}
/**
 * 可对原文进行hash，传递从参数为（hash记得要有自己的秘钥）：
vipsDTO.vipId=00000001&sign=dsdksdkfi3kdksldlf
获取的时候对原文hash，用得到的hash值和传过来的hash进行比较，不一致则传递的值被改动过
*/
function ectypeParam(config) {
  if (config.query && Object.keys(config.query).length > 0) {
    console.log(
      config,
      ObjUtil.queryString(new ObjUtil(config.query).sort().value, {
        exclude: ["sign", "title"],
      })
    );
    config.query.sign = md5(
      ObjUtil.queryString(new ObjUtil(config.query).sort().value, {
        exclude: ["sign", "title"],
        clearEmpty: true,
      })
    ); //处理$jump({ path: 'assessing',query:{auticateid:row.auticateid,...$route.query} })导致携带sign
  }
}
export function checkParam(href) {
  const query = StrUtil.queryString(href),
    sign = query.sign || "";
  if (Object.keys(query).length <= 0) return false;
  if (!query.sign) return;
  console.log(
    query,
    ObjUtil.queryString(new ObjUtil(query).sort().value, {
      exclude: ["sign", "title"],
    }),
    ObjUtil.queryString(query),
    sign
  );
  if (
    md5(
      ObjUtil.queryString(new ObjUtil(query).sort().value, {
        exclude: ["sign", "title"],
      })
    ) !== sign
  )
    jump("/404");
}
const router = window.history;
/**
 *
 *
 * @param {*} mode
 * @param {*} path
 */
function jump(config = { mode: "back" }, { remParam = true } = {}) {
  if (!config) throw new Error("jump parameter cannot be null");
  if (typeof config === "string") config = { path: config };
  if ((config.path && !config.mode) || config.name) config.mode = "to";
  // if(config.name&&!router.hasRoute(config.name)){
  // 	showToast({title:'无此权限!',icon:'warning'});
  // 	return false;
  // }
  // if(remParam) remParamF(config);
  // 对参数进行加密
  // ectypeParam(config);
  const mode = config.mode;
  const queryStr = ObjUtil.queryString(config.query || {});
  if (mode === "to")
    return emitter.emit(
      "navigate",
      config.path + (queryStr ? "?" + queryStr : "")
    );
  else if (mode === "redirect")
    return emitter.emit("navigate", config.path, { replace: true });
  else if (mode === "go") return router.go(config.level || 1);
  else if (mode === "back")
    window.history.length > 1
      ? router.go(-1)
      : router.push({
          path: "/",
          query: config.query,
        });
  // 跳转
  else if (mode === "assign" || mode === "replace" || mode === "open") {
    const path = config.path,
      href = path.startsWith("http")
        ? path
        : process.env.VUE_APP_URL +
          (path.startsWith("/") ? path : "/" + path) +
          (config.query ? "?" + ObjUtil.queryString(config.query) : "");
    if (mode === "assign") return (window.location.href = href);
    if (mode === "replace") return window.location.replace(href);
    console.log(href);
    if (mode === "open") return window.open(href);
  } else
    return jump({
      mode: "back",
      query: config.query,
    });
}
export default jump;
