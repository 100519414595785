import request from './adapter';
export default function (config) {
    return new Promise((resolve, reject) => {
        const conf = {
            url: config.url.startsWith('http')? config.url:config.baseUrl + "/" + config.url,
            success(res) {
                if (res.statusCode >= 500) {
                    reject(["服务器错误", config]);
                    return false;
                }
                resolve(res);
            },
            fail(err) {
                reject({
                    err,
                    config
                });
            },
        };
        // for(let key in config.data){
        //     if(typeof config.data[key]){
        //         if(config.data.hasOwnProperty(key)&&typeof config.data[key]==='object'){
        //             config.data[key]=JSON.stringify(config.data[key]);
        //         }
        //     }
        // }
        if (config.files && config.files.length <= 0) {
            config.method = "post";
        }
        request(Object.assign({}, config, conf));
    });
}