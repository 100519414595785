import React from "react";
import { Resizable } from "react-resizable";
/**
 * 可伸缩列
 * https://blog.csdn.net/foolcuntry/article/details/123715338
 * react resizeable https://github.com/react-grid-layout/react-resizable
 */
const ResizeableTitle = ({
  width,
  onResize,
  slash,
  slashTextIntervel: slashTextIntervelProps,
  ...rest
}) => {
  const slashTextIntervel = slashTextIntervelProps || 22,
    slashLen = slash && slash.length,
    halfLen = slash && Math.ceil(slash.length / 2);
  const thArea = slash ? (
    <th className="relative" {...rest}>
      {slash.map((d, i) => (
        <React.Fragment key={i}>
          <span
            className="slash-left"
            style={
              i + 1 <= halfLen
                ? {
                    left: 18 + i * slashTextIntervel + "px",
                    bottom: 8 + i * slashTextIntervel + "px",
                    //   top:( (100 / halfLen) * (halfLen - (i + 1)))/2 + "%",
                  }
                : {
                    right: 8 + (i + 1 - halfLen) * slashTextIntervel + "px",
                    top: i * 8 + "px",
                  }
            }
          >
            {d}
          </span>
          {i == slash.length - 1 ? null : (
            <div
              className="slash"
              style={
                i + 1 <= halfLen
                  ? {
                      top: (100 / halfLen) * (halfLen - (i + 1)) + "%",
                    }
                  : {
                      left: (100 / halfLen) * (i + 1 - halfLen) + "%",
                    }
              }
              //   style={{
              //     transform: `rotate(${Math.floor(90 / slashLen) * (i + 1)}deg)`,
              //   }}
            ></div>
          )}
        </React.Fragment>
      ))}
    </th>
  ) : (
    <th {...rest}></th>
  );
  if (!width || !/d+/.test(width)) return thArea;
  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      //   onResize={(_,{size})=>console.log(size)}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      {thArea}
    </Resizable>
  );
};
export default ResizeableTitle;
