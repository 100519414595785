import "./index.less";
import { useEffect, useState } from "react";
import className from "@utils/className";
// 底部操作栏
export default ({
  position = "fixed",
  container,
  isBottom: isBottomProps = false,
  children,
}) => {
  const [leftStyle, setLeftStyle] = useState({});
  //   const [collapse, setCollapse] = useState(false);
  //   emitter.on("collapse", (collapse) => {
  //     setCollapse(collapse);
  //   });
  const [isBottom, setIsBottom] = useState(isBottomProps);
  const onScroll = () => {
    // 变量scrollTop是滚动条滚动时，滚动条上端距离顶部的距离
    var scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    // 变量windowHeight是可视区的高度
    var windowHeight =
      document.documentElement.clientHeight || document.body.clientHeight;
    // 变量scrollHeight是滚动条的总高度（当前可滚动的页面的总高度）
    var scrollHeight =
      document.documentElement.scrollHeight || document.body.scrollHeight;
    // 滚动条到底部
    if (Math.ceil(scrollTop + windowHeight) >= scrollHeight) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };
  const getLeft = () => {
    if (container) {
      // 同时设置left和transition,避免出现fixedBar移动情况
      if (position.includes("fixed")) {
        if (typeof container === "string") {
          const left = document.querySelector(container)?.offsetLeft + "px",
            offsetWidth = document.querySelector(container)?.offsetWidth,
            width = offsetWidth + "px";
          if (position.includes("right"))
            setLeftStyle({
              right: 0,
              width,
              justifyContent: offsetWidth < 600 ? "flex-end" : "",
            });
          else
            setLeftStyle({
              left,
              right: 0,
              transition: left > 0 ? "all 0.3s, box-shadow 0.5s" : "none",
            });
        } else {
          const left = container.offsetLeft,
            width = container.offsetWidth;
          if (position.includes("right"))
            setLeftStyle({
              right: 0,
              width,
              justifyContent: "flex-end",
            });
          else
            setLeftStyle({
              left,
              right: 0,
              transition: left > 0 ? "all 0.3s, box-shadow 0.5s" : "none",
            });
        }
      }
    }
  };
  useEffect(() => {
    setTimeout(() => {
      getLeft();
    }, 20);
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  });
  return (
    <div>
      <div
        className={className("fixed-bar", { shadow: !isBottom })}
        style={leftStyle}
      >
        {children}
      </div>
      <div></div>
    </div>
  );
};
