import { useEffect, useMemo, useRef, useState } from "react";
import { Image } from "antd";
import "./index.less";
import ChooseFile from "@components/ChooseFile";
import { uface } from "@widget";
import { getBase64 } from "@components/ChooseImage";
import { getObjectURL } from "@utils/file";

/**
 *  size:{
                type:String,
                default:'default'
            },
            limit:Number
*/
export default ({
  size = "small",
  limit,
  value,
  handleValue = (e) =>
    e ? (typeof e !== "object" ? [e] : e.filter(Boolean) || []) : [],
  onChange,
  onDelete,
}) => {
  const files = useRef([]),
    [imgs, setImgs] = useState([]);
  const style = useMemo(() => {
    const map = {
      large: {},
      default: {
        width: "100px",
        height: "100px",
      },
      small: {
        width: "80px",
        height: "80px",
      },
    };
    return map[size] || map.default;
  }, [size]);
  const add = async (uploadFiles) => {
      console.log(uploadFiles, "choose file");
      if (limit && limit > uploadFiles.length) {
        uface.showToast({ title: `最大上传${limit}个!`, icon: "warning" });
        return false;
      }
      const imgData = [];
      for (let [i, itemFile] of uploadFiles.entries()) {
        if (/image.*/.test(itemFile.type))
          imgData.push({ type: "image", src: await getBase64(itemFile) });
        else if (/video.*/.test(itemFile.type))
          imgData.push({ type: "video", src: getObjectURL(itemFile) });
        else imgData.push({ type: "file", name: itemFile.name });
      }
      setImgs([...imgs, ...imgData]);
      files.current.push(...uploadFiles);
      onChange(files.current);
    },
    remove = (delIndex) => {
      setImgs(imgs.filter((_, index) => index !== delIndex));
      files.current.splice(delIndex, 1);
      onChange(files.current);
    };
  const [showImg, setShowImg] = useState(handleValue(value));
  useEffect(() => {
    console.log("aakakak", value);
    setShowImg(handleValue(value));
  }, [value]);
  return (
    <div className="file-upload flex flex-wrap">
      {limit == 1 && showImg.length + imgs.length == 1 ? null : (
        <div className="text-center">
          <ChooseFile
            count={9}
            accept={["doc", "excel", "image", "pdf", "txt", "video"]}
            onChange={add}
          >
            <div className="upload-btn" style={style}>
              <i className="large iconfont icon-jia"></i>
              <div className="text">上传</div>
            </div>
          </ChooseFile>
        </div>
      )}
      {showImg.map((item, index) =>
        item.type == 1 ? (
          <div
            className="upload-btn relative"
            style={style}
            key={item.id || index}
          >
            <i
              className="iconfont icon-shanchu11 del-icon"
              onClick={() =>
                onDelete
                  ? onDelete(item, () =>
                      setShowImg(showImg.filter((_, i) => index !== i))
                    )
                  : setShowImg(showImg.filter((_, i) => index !== i))
              }
            ></i>
            <Image
              src={typeof item === "string" ? item : item.path}
              width={"100%"}
              height={"100%"}
            />
          </div>
        ) : (
          <div className="file-item upload-relative" key={index}>
            <i
              className="iconfont icon-shanchu11 del-icon"
              onClick={() =>
                onDelete
                  ? onDelete(item, () =>
                      setShowImg(showImg.filter((_, i) => index !== i))
                    )
                  : setShowImg(showImg.filter((_, i) => index !== i))
              }
            ></i>
            {item.title}
          </div>
        )
      )}
      {imgs.map((item, index) =>
        item.type === "image" ? (
          <div className="upload-btn upload-relative" style={style} key={index}>
            <i
              className="iconfont icon-shanchu11 del-icon"
              onClick={() => remove(index)}
            ></i>
            <Image src={item.src} width={"100%"} height={"100%"} />
          </div>
        ) : item.type === "video" ? (
          <div className="upload-btn upload-relative" style={style} key={index}>
            <i
              className="iconfont icon-shanchu11 del-icon"
              onClick={() => remove(index)}
            ></i>
            <video width={"100%"} height={"100%"} autoPlay>
              <source src={item.src} type="video/mp4" />
              <source src={item.src} type="video/ogg" />
              <source src={item.src} type="video/webm" />
            </video>
          </div>
        ) : (
          <div className="file-item upload-relative" key={index}>
            <i
              className="iconfont icon-shanchu11 del-icon"
              onClick={() => remove(index)}
            ></i>
            {item.name}
          </div>
        )
      )}
    </div>
  );
};
