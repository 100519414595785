import React from "react";
import { Row, Col } from "antd";
import Form, { useForm } from "../../BaseForm";
import ModalForm from "../../layouts/ModalForm";
import DrawerForm from "../../layouts/DrawerForm";
import QueryFilter from "../../layouts/QueryFilter";
import Field from "./Field";
const FormLayouts = {
  Form,
  ModalForm,
  DrawerForm,
  QueryFilter,
};
const handleName = (name) =>
  name ? (typeof name === "string" ? name : name.join(",")) : "";

/**
 * @description JSON表单
 * @param layoutType  使用的表单布局模式
 * 		Form  基本的表单类型
 * 		ModalForm 弹框表单 抽屉表单
 * 		DrawerForm
 * @param columns:[{   表单的定义，一般是 json 对象
 *   title:String,  标题的内容，在 form 中是 label
 *   valueType:String  input/select 表单元素类型
 *   name: 与实体映射的 key 'a.a'也可使用
 * valueEnum: 选项
 * formItemProps 传递给 Form.Item 的配置
 * request  从远程请求网络数据，一般用于选择类组件
 *  columns 嵌套子项
 *  initialValue
 *  render
 * }]
 * @valueType
 *    password	密码输入框
money	金额输入框
textarea	文本域
date	日期
dateTime	日期时间
dateWeek	周
dateMonth	月
dateQuarter	季度输入
dateYear	年份输入
dateRange	日期区间
dateTimeRange	日期时间区间
time	时间
timeRange	时间区间
text	文本框
select	下拉框
treeSelect	树形下拉框
checkbox	多选框
rate	星级组件
radio	单选框
radioButton	按钮单选框
progress	进度条
percent	百分比组件
digit	数字输入框
second	秒格式化
avatar	头像
code	代码框
switch	开关
fromNow	相对于当前时间
image	图片
jsonCode	代码框，但是带了 json 格式化
color	颜色选择器
cascader	级联选择器 
 */
const FormMaker = ({ layoutType = "Form", columns, grid = true, ...props }) => {
  const BaseForm = FormLayouts[layoutType] || Form;
  const renderItems = (columns = [], i = "") =>
    columns
      ? columns.filter(Boolean).map(({ colProps, render, ...item }, index) => {
          let columnsRender = null;
          if (item.columns) {
            // grid = false;
            /**
             * columns为function(依赖项) 传递给FormField作为children(用作函数插槽，dependency)
             * 		由于依赖切换后Field并未发生改变，因此要更新UI，需要给每个Field加formItemProps.shouldUpdate
             * columns为array，递归处理
             */
            if (typeof item.columns === "function")
              columnsRender = (...rest) => {
                const columnsChild = item.columns(...rest);
                return renderItems(
                  columnsChild &&
                    columnsChild.map((d) => {
                      if (d.formItemProps) d.formItemProps.shouldUpdate = true;
                      else d.formItemProps = { shouldUpdate: true };
                      return d;
                    })
                );
              };
            else if (item.columns instanceof Array && item.columns.length > 0)
              columnsRender = renderItems(item.columns, index);
          }
          return render ? (
            React.cloneElement(
              render(
                columnsRender ||
                  (item.valueType ? (
                    <Field
                      isSearch={layoutType === "QueryFilter"}
                      {...item}
                    ></Field>
                  ) : null),
                props.form
              ),
              { key: index + i + handleName(item.name) || "" }
            )
          ) : grid ? (
            <Col
              key={index + i + handleName(item.name) || ""}
              style={{ display: item.hidden ? "none" : "" }}
              {...(colProps || { xs: 24, md: 24, lg: 24 })}
            >
              <Field isSearch={layoutType === "QueryFilter"} {...item}>
                {columnsRender}
              </Field>
            </Col>
          ) : (
            <Field
              key={index + i + handleName(props.name) || ""}
              isSearch={layoutType === "QueryFilter"}
              {...item}
            >
              {columnsRender}
            </Field>
          );
        })
      : null;
  // 每次重新render，每次都会重新渲染
  const formChildrArea = renderItems(columns);
  return grid ? (
    <BaseForm grid={grid} columns={columns} {...props}>
      <Row>{formChildrArea}</Row>
    </BaseForm>
  ) : (
    <BaseForm grid={grid} columns={columns} {...props}>
      {formChildrArea}
    </BaseForm>
  );
};
export default FormMaker;
FormMaker.Field = Field;
export * from "./Field";
