import { useState } from "react";
import React from "react-dom/client";
import api from "@api";
export default () => {
  const [buttonAuth, setButtonAuth] = useState([]);
  /**
   * ReactNode
   * 操作标识符
   * defaultDom 若没有权限，默认显示的内容
   */
  const hasButton = (dom, actionName, defaultDom = null) => {
    const action = buttonAuth.find(
      (item) => item.button_identifier === actionName
    );
    if (action) {
      if (action.is_button_show == 1)
        return React.cloneElement(dom, { disabled: true });
      else return defaultDom;
    }
    return dom;
  };
  const setButton = (state) => {
    api["Auth/getOperateAuthBySysId"]({
      data: { sys_id: state.id },
      loading: false,
    }).then((res) => {
      setButtonAuth(res);
    });
  };
  return {
    buttonAuth,
    hasButton,
    setButton,
  };
};
