import api from "@api";
import { useState } from "react";
import { useMount } from "@hooks";
import { isPromise } from "@utils/is";
export default (request, { query = {}, initial = [] } = {}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(initial);
  const fetchData = () => {
    if (!request) {
      setLoading(false);
      return;
    }
    const onSuccess = (res) => {
      setData(res);
      setTimeout(() => {
        setLoading(false);
      }, 0);
    };
    if (typeof request === "string")
      api[request]({ loading: false, data: query }).then(onSuccess);
    else if (typeof request === "function") request().then(onSuccess);
    else if (isPromise(request)) request.then(onSuccess);
  };
  useMount(() => {
    fetchData();
  });
  return {
    loading,
    data,
    setData,
    reload: fetchData,
  };
};
