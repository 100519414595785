import ReactDOM from "react-dom/client";
import { useZIndex } from "@utils/hooks";
/**
 * 由于amdin框架是导航型，因此只保证内容区loading
 * 当dialog或popup在最顶层 loading   不保证loading间隔可判断DOM style状态 可能DOM隐藏loading仍判断为此target
 * admin-box存在滚动 有可能会看不到loading图标 所以不使用loading
 * body loading
 */
function getTarget() {
  let dislogEl;
  const hasDialog =
    Array.from(document.querySelectorAll(".ant-modal-wrap") || []).reduce(
      (accu, cur) => {
        if (cur.style.display !== "none")
          dislogEl = cur.querySelector(".ant-modal");
        return accu || cur.style.display !== "none";
      },
      false
    ) ||
    Array.from(document.querySelectorAll(".ant-drawer") || []).reduce(
      (accu, cur) => {
        if (cur.style.display !== "none")
          dislogEl = cur.querySelector(".ant-drawer-content-wrapper");
        return accu || cur.style.display !== "none";
      },
      false
    ) ||
    Array.from(document.querySelectorAll(".popup-warp") || []).reduce(
      (accu, cur) => {
        if (cur.style.display !== "none")
          dislogEl = cur.querySelector(".popup");
        return accu || cur.style.display !== "none";
      },
      false
    );
  if (hasDialog) return dislogEl;
  else if (document.querySelector(".admin-box")) return ".admin-box";
  else return "body";
}
const Loading = ({ title = "加载中", style }) => {
  const { nextZIndex } = useZIndex();
  return (
    <div
      className="uface-loading-wrp"
      style={Object.assign({}, style, { zIndex: nextZIndex() }) || {}}
    >
      <div className="loading-wrp">
        <span className="dot dot-spin">
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </span>
      </div>
      <h1>{title}</h1>
    </div>
  );
};
function getOffsetLeft(node) {
  if (!node) return 0;
  let cur = node,
    offset = node.offsetLeft || 0;
  while (cur.offsetParent) {
    offset += cur.offsetParent.offsetLeft || 0;
    cur = cur.offsetParent;
  }
  return offset;
}
function getOffsetTop(node) {
  if (!node) return 0;
  let cur = node,
    offset = node.offsetTop || 0;
  while (cur.offsetParent) {
    offset += cur.offsetParent.offsetTop || 0;
    cur = cur.offsetParent;
  }
  return offset;
}
/**
 * `[showLoading description]`  加载中
 * @method showLoading
 * '@date 2018-08-21'
 * '@author zkq'
 * @param  {String}    [title='加载中']     [description]
 * @param  {Boolean}   [mask=true]       [description]
 * @return {[type]}                      [description]
 */
function showLoading({
  title,
  target: container,
  fullscreen = false,
  position = "left",
} = {}) {
  if (!window.loading) window.loading = [];
  const target = container || getTarget(),
    div = document.createElement("div");
  let wrapContainer =
    typeof target === "string" ? document.querySelector(target) : target;
  // 防止dom元素未渲染完毕取不到完成的位置信息
  let instance;
  setTimeout(() => {
    if(!wrapContainer.offsetWidth||!wrapContainer.offsetHeight) return;
    instance = ReactDOM.createRoot(div).render(
      <Loading
        title={title}
        style={{
          position: "fixed",
          left: position === "left" ? getOffsetLeft(wrapContainer) + "px" : "",
          right: position === "right" ? 0 : "",
          top: getOffsetTop(wrapContainer) + "px",
          width: wrapContainer.offsetWidth + "px",
          height: wrapContainer.offsetHeight + "px",
          backgroundColor: "rgb(255 255 255 / 90%)",
        }}
      ></Loading>
    );
  }, 80); //getOffsetTop会出现偶尔刷新获取距离不准的情况
  // 避免影响Drawer的direction属性
  //   if (getComputedStyle(wrapContainer).position === "static")
  // wrapContainer.style.position = "relative";
  //   wrapContainer.appendChild(div);
  document.body.appendChild(div);
  window.loading.push({
    remove: () => {
      if (instance) instance.unmount();
      instance = null;
      div.remove();
      //   wrapContainer.removeChild(
      //     wrapContainer.querySelector(".uface-loading-wrp")
      //   );
    },
  });
}
export default showLoading;
