import { Modal, Spin, Button } from "antd";
import { useEffect, useState } from "react";
import Form from "../../BaseForm";
import { FullscreenExitOutlined, FullscreenOutlined } from "@ant-design/icons";
import { useZIndex } from "@utils/hooks";
import "./index.less";
/**
 * 校验提交地另一种方式 将modal包裹再form里， 由于modal内部自行插入元素，不能直接将form包裹modal
 * footer={<Submitter submitter={{ position: "bottom-right" }}></Submitter>}
okButtonProps={{ htmlType: "submit" }}
modalRender={(modal) => (
        <Form form={form} onSubmit={onSuccess}>
          {modal}
        </Form>
      )}

*/
export default ({
  children,
  title,
  loading,
  visible,
  form,
  rules,
  afterVisible = () => {},
  onCancel = () => {},
  width = 500,
  onSuccess = () => {},
  initialValue,
  ...rest
}) => {
  const [fullscreen, setFullscreen] = useState(false);
  const { nextZIndex } = useZIndex();
  useEffect(() => {
    afterVisible();
  }, []);
  return (
    <Modal
      wrapClassName="modal-form"
      title={title || "提示"}
      width={fullscreen ? "100%" : width || 800}
      zIndex={nextZIndex()}
      //   style={fullscreen ? { top: 0, paddingBottom: 0 } : {}}
      onCancel={onCancel}
      onOk={() => form.submit()}
      open={visible}
      //   destroyOnClose={true}
      centered={true}
      maskClosable={false}
      forceRender
      bodyStyle={
        fullscreen
          ? { height: "calc(100vh - 108px)", overflow: "auto" }
          : { maxHeight: "calc(100vh - 200px)", overflow: "auto" }
      }
      {...rest}
    >
      <Button
        type="text"
        className="ant-modal-fullscreen"
        icon={fullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
        onClick={() => setFullscreen(!fullscreen)}
      ></Button>
      <Spin spinning={loading}>
        <Form
          form={form}
          rules={rules}
          initialValue={initialValue}
          onSubmit={onSuccess}
        >
          {children}
        </Form>
      </Spin>
    </Modal>
  );
};
