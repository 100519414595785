import { Drawer, Spin, Space, Button } from "antd";
import Form from "../../BaseForm";
import FixedBar from "@components/FixedBar";
import { CloseOutlined } from "@ant-design/icons";
import { useZIndex } from "@utils/hooks";
export default ({
  title,
  width = 500,
  form,
  rules,
  loading,
  visible: propVisible,
  setVisible,
  children,
  afterVisible = () => {},
  onCancel = () => {},
  onSuccess = () => {},
  ...props
}) => {
  const { nextZIndex } = useZIndex();
  return (
    <Drawer
      title={title || "提示"}
      width={width || 80}
      zIndex={nextZIndex()}
      open={propVisible}
      forceRender
      //bodyStyle={{ paddingBottom: 80 }}
      closable={false}
      onClose={() => {
        onCancel();
      }}
      //   destroyOnClose={true}
      afterOpenChange={afterVisible}
      extra={
        <Space>
          {/* <Button onClick={onCancel}>取消</Button>
          <Button onClick={() => form.submit()} type="primary">
            提交
          </Button> */}
          <CloseOutlined
            style={{ cursor: "pointer" }}
            onClick={() => {
              setVisible(false);
            }}
          />
        </Space>
      }
      {...props}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          rules={rules}
          submitter={{
            position: "fixed-right",
            container: ".ant-drawer-wrapper-body",
          }}
          onCancel={onCancel}
          onSubmit={() => onSuccess()}
        >
          {children}
        </Form>
      </Spin>
    </Drawer>
  );
};
