import { Layout } from "antd";
import { useModel } from "@model";
import { Routes, Route } from "react-router-dom";
import React from "react";
import { handlerNestedPath } from "@utils/asyncRouter";
import { useMount } from "@hooks";
import Breadcrumb from "./Breadcrumb";
function copy(fn) {
  return new Function(`return ${fn.toString()}`)();
}
const cache = {};
function getPage(id, Component) {
  return <Component key={id}></Component>;
  console.log(cache, id, "akakkakak");
  if (cache[id]) return cache[id];
  else {
    const page = <Component key={id}></Component>;
    cache[id] = page;
    return page;
  }
}
/**
 * 空白路由
 * [{
 * path:'/a',
 * children:[{
 * path:'b', //没有component,不渲染，但是仍作为子路由的父级 不渲染组件，仅作为地址栏上父级路由参数名
 * children:[{
 * 	path:'c'
 * }]
 * 	  }]
 * }]
 * 组件路由
 * [{
 * path:'/a',
 * children:[{
 *	 path:'b',
 *    component:'B',//有component，说明父组件需渲染，引入<Outlet/>渲染子组件，地址参数和组件均存在
 *   children:[{
 * path:'c',
 * component:'C'
 *   }]
 * }]
 * }]
 * 展开路由
 * [{
 * path:'/a',
 * children:[{
 * path:'b',
 * component:'B',
 * spreadChild:true,//子路由不作为入口在B上渲染，会将子路由展开作为B的兄弟,地址上c作为b的子级，组件渲染上C和B同级
 * children:[{
 * path:'c',
 * component:'C
 * }]
 * }]
 * }]
 */
const createRoute = (route) => {
  let { path, id, Component, index } = route;
  //   Component = Component ? copy(Component) : Component;
  const renderChild = () =>
    route.children.map((childRoute) => createRoute(childRoute));
  return route.children ? (
    Component ? (
      route.spreadChild ? (
        <React.Fragment key={id}>
          <Route path={path} element={getPage(id, Component)}></Route>
          {renderChild()}
        </React.Fragment>
      ) : (
        <Route key={id} path={path} element={getPage(id, Component)}>
          {renderChild()}
        </Route>
      )
    ) : (
      renderChild()
    )
  ) : (
    <Route
      key={id}
      index={!!index}
      path={index ? false : path}
      element={getPage(id, Component)}
    ></Route>
  );
};
export default () => {
  const { asyncRoutes, setAsyncRouter } = useModel("router");
  useMount(() => {
    setAsyncRouter();
  });
  return (
    <Layout.Content className="admin-box">
      <Breadcrumb></Breadcrumb>
      <Routes>{asyncRoutes.map((route) => createRoute(route))}</Routes>
    </Layout.Content>
  );
};
