import { Menu, Spin } from "antd";
import { useModel } from "@model";
import { Link, useLocation } from "react-router-dom";
import { handlerNestedPath } from "@utils/asyncRouter";
import { useState, useCallback, useMemo, useEffect } from "react";
import emitter from "@emitter";
import Iconfont from "@components/Iconfont";
function handleRoutes(routes) {
  return routes
    .filter((item) => item.hidden !== true)
    .reduce((accu, cur) => {
      if (cur.children && handleRoutes(cur.children).length > 0)
        accu.push({
          title: cur.title,
          label: cur.title,
          key: cur.path,
          icon: cur.icon ? (
            <Iconfont type={cur.icon} style={{ fontSize: "18px" }} />
          ) : null,
          children: handleRoutes(cur.children),
        });
      else
        accu.push({
          key: cur.path,
          title: cur.title,
          label: (
            <Link to={cur.path} state={cur.meta}>
              <span>{cur.title}</span>
            </Link>
          ),
          icon: cur.icon ? (
            <Iconfont type={cur.icon} style={{ fontSize: "18px" }} />
          ) : null,
        });
      return accu;
    }, []);
}
// 使用index作为索引，防止使用path作为key时使用默认路由导致重复
export default () => {
  const location = useLocation();
  const { asyncRoutes, loading, findParentPathForChild } = useModel(
    "router",
    ({ asyncRoutes, loading, findParentPathForChild }) => ({
      asyncRoutes,
      loading,
      findParentPathForChild,
    })
  );
  const [open, setOpen] = useState([location.pathname]);
  const onSelectItem = ({ key }) => setOpen([key]);
  const firstOpenMenu = findParentPathForChild(location.pathname, asyncRoutes);
  const [openMenu, setOpenMenu] = useState([firstOpenMenu]);
  useMemo(() => {
    setOpenMenu(firstOpenMenu);
    setOpen([location.pathname]); //通过地址搜索打开的导航（包括查询参数）
  }, [asyncRoutes, location.pathname]);

  const { setButton } = useModel("auth");
  const { getRouteByPath } = useModel("router");
  useEffect(() => {
    const adminBoxEl = document.querySelector(".admin-box");
    if (adminBoxEl) adminBoxEl.scroll(0, 0);
    emitter.emit("locationChange", location);
    //   监听路由，判断按钮权限
    if (location.state) setButton(location.state);
    else {
      //若不是从左侧导航进入，而是从jump跳转，没有state，手动搜索当前route
      const route = getRouteByPath(location.pathname);
      console.log("aoaoaooaoaoaoao", route, location.pathname);
      if (route) setButton({ id: route.id });
    }
  }, [location, asyncRoutes]);

  const onSelect = (keys) => setOpenMenu([keys[keys.length - 1]]);
  console.log("权限组", asyncRoutes, handleRoutes(asyncRoutes));
  return (
    <Spin spinning={loading} wrapperClassName="layout-side">
      <Menu
        theme="light"
        mode="inline"
        inlineIndent={28}
        items={handleRoutes(asyncRoutes)}
        selectedKeys={open}
        openKeys={openMenu}
        onOpenChange={onSelect}
        onClick={onSelectItem}
      ></Menu>
    </Spin>
  );
};
