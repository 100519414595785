import { useModel } from "@model";
import purefn from "@widget/purefn";
import api from "@api";
import { isPromise } from "@utils/is";
/*
 * Desc: 自定义hooks
 * File Created: 2020-08-24 22:45:40
 */
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
/**
 * 实现mounted
 */
export function useMount(cb) {
  return useEffect(cb, []);
}
/**
 * 只能修改onload时的值，如首次加载时的请求
 */
export function useMountState(param) {
  const [state, setState] = useState(param);
  const isMount = useRef(false);
  useMount(() => {
    isMount.current = true;
    return () => {
      isMount.current = false;
    };
  });
  const setMountState = (newState) => {
    if (isMount.current) setState(newState);
  };
  return [state, setMountState];
}
export function useUnmount(cb) {
  const fnRef = useRef(null);
  fnRef.current = cb; //实时取到cb的引用
  return useEffect(() => () => fnRef.current(), []);
}
/**
 * 判断首次加载
 * @return boolean
 */
export function useFirstMount() {
  const isFirst = useRef(true);
  if (isFirst.current) {
    isFirst.current = false;
    return true;
  }
  return isFirst.current;
}
export function useFirstEffect(effect, deps) {
  const isFirst = useFirstMount();
  useEffect(() => {
    if (isFirst) return effect();
  }, deps);
}
/**
 * 布尔开关
 * @param init boolean
 */
export function useSwitch(init = false) {
  const [switcher, setSwitcher] = useState(init);
  const turnOn = () => setSwitcher(true);
  const turnOff = () => setSwitcher(false);
  return [switcher, { turnOn, turnOff, setSwitcher }];
}
/**
 * 不触发更新的持久状态
 */
export function useRefState(state) {
  const stateRef = useRef(state);
  return [stateRef, (newState) => (stateRef.current = newState)];
}
/**
 * v-model
 */
export function useVModel() {
  const [model, setModel] = useState(undefined);
  return [model, (e) => setModel(e.target.value)];
}
/**
 * 只有当初始化完毕之后重新渲染才执行
 */
export function useUpdate(fn) {
  const [isFirst, setIsFirst] = useRefState(true);
  useEffect(() => {
    setIsFirst(false);
  }, []);
  useEffect(() => {
    if (!isFirst.current) fn();
  });
}
/**
 * 初次加载时不执行，当依赖改变时执行
 */
export function useUpdateEffect(effect, deps) {
  const isFirst = useFirstMount();
  useEffect(() => {
    if (!isFirst) return effect();
  }, deps);
}

export function useButtonAuth(action) {
  const hasAuth = useModel("auth", ({ hasAuth }) => hasAuth);
  return hasAuth(action);
}
const isValid = (val) => val !== undefined && val !== null;
const cleanObject = (obj) => {
  if (!obj) return {};
  return Object.keys(obj).reduce((accu, key) => {
    if (isValid(obj[key])) accu[key] = obj[key];
    return accu;
  }, {});
};
const setSearchParams = (query, { title = "", replace = false } = {}) => {
  if (replace) window.history.replaceState(query, title, "?" + query);
  else window.history.pushState(query, title, "?" + query);
};
export function useUrlQuery() {
  //   const [, setSearchParams] = useSearchParams();
  //   const location = useLocation();
  const [query, setQuery] = useState(
    purefn.StrUtil.queryString(window.location.search)
  );
  useEffect(() => {
    setQuery(purefn.StrUtil.queryString(window.location.search));
  }, [window.location.search]);
  return [
    query,
    (params, opts) =>
      setSearchParams(purefn.ObjUtil.queryString(cleanObject(params)), opts),
  ];
}
export function usePath() {
  const location = useLocation();
  return [location.pathname];
}
/**
 * network request
 * manual 手动发起请求
 */
export function useRequest({
  request,
  config: param = {},
  manual = false,
  onSuccess = (e) => e,
  onFirstSuccess = () => {},
} = {}) {
  let initialValue = { loading: true, data: [], isFinish: false };
  if (!request) initialValue = { loading: false, data: [], isFinish: true };
  const [loading, setLoading] = useState(initialValue.loading);
  const [data, setData] = useState(initialValue.data);
  const [isFinish, setIsFinish] = useState(initialValue.isFinish);
  const permanentRef = useRef(null);
  const isFirst = useRef(true);
  const reload = (query, { permanent = false } = {}) => {
    if (!request) return;
    if (permanent)
      permanentRef.current = Object.assign({}, permanentRef.current, query);
    setLoading(true);
    const onSuccessHandle = (res) => {
      const newData = onSuccess(res);
      setData(newData);
      if (isFirst.current) {
        isFirst.current = false;
        onFirstSuccess(newData);
      }
      setTimeout(() => {
        setLoading(false);
        setIsFinish(true);
      }, 0);
    };
    const requestParam = {
      ...param,
      data: Object.assign(
        {},
        param.data || {},
        query,
        permanentRef.current || {}
      ),
    };
    if (typeof request === "string")
      api[request](requestParam).then(onSuccessHandle);
    else if (typeof request === "function")
      request(requestParam).then(onSuccessHandle);
    else if (isPromise(request)) request.then(onSuccessHandle);
  };
  useMount(() => {
    if (!manual) reload();
    else setLoading(false);
  });
  return {
    loading,
    reload,
    data,
    setData,
    isFinish,
  };
}
export { default as useDrag } from "./useDrag";
export { default as useZIndex } from "./useZIndex";

/**
 * 防抖
 *
 * const state={num:1}
 * const debounceVal=useDebounceState(state,1000)
 * useEffect(()=>{
 * 	防抖后触发
 * },[debounceVal])
 *  */
export function useDebounceState(state, delay) {
  const [debounceValue, setDebounceValue] = useState(state);
  useEffect(() => {
    let timer = setTimeout(() => {
      setDebounceValue(state);
    }, delay);
    return () => {
      clearTimeout(timer);
    };
  }, [state, delay]);
  return debounceValue;
}

/**
 * const state={num:1}
 * useDebounceEffect(()=>{
 * },[state],1000)
 */
export function useDebounceEffect(effect, deps, delay) {
  const unEffectRef = useRef(null);
  useEffect(() => {
    let timer = setTimeout(() => {
      unEffectRef.current = effect();
    }, delay);
    return () => {
      clearTimeout(timer);
      if (unEffectRef.current) unEffectRef.current();
    };
  }, [...deps, delay]);
}

export function useThrottleEffect(effect, deps, delay) {
  const isdelay = useRef(false);
  const unEffectRef = useRef(null);
  useEffect(() => {
    if (!isdelay.current) return;
    setTimeout(() => {
      unEffectRef.current = effect();
      isdelay.current = false;
    }, delay);
    isdelay.current = true;
    return unEffectRef.current;
  }, [...deps, delay]);
}

/**
 * deps深度比较
 */
export function useCompareEffect(effect, deps) {
  const depsRef = useRef(deps),
    signalRef = useRef(0);
  if (!purefn.deepEqual(depsRef.current, deps)) {
    signalRef.current = signalRef.current + 1;
    depsRef.current = deps;
  }
  useEffect(effect, [signalRef.current]);
}

/**
 * 浅复制
 * const [state,setSatate]=useMergedState({a:'xxx'})
 * setState({b:'bbb'}) {a:'xxx',b:'bbb'}
 */
export function useMergedState(obj) {
  const [state, setState] = useState(obj);
  const set = (d) =>
    setState({
      ...obj,
      ...(typeof d === "function" ? d(obj) : d),
    });
  return [state, set];
}

/**
 * 保证拿到最新的值 闭包问题
 */
export function useLatest(value) {
  const ref = useRef(value);
  ref.current = value;
  return ref;
}
