import { message } from "antd";
/**
 * [showToast description] 显示消息提示
 * @method showToast
 * '@date 2018-08-13'
 * '@author zkq'
 * @param  {[type]}  title           [description] 必填
 * @param  {[type]}  icon            [description] 必填 success/warning/info/error/warn/loading
 * @param  {Number}  [duration=1400] [description]
 * @param useHTML  是否将 message 属性作为 HTML 片段处理
 * @return {[type]}                  [description]
 */
function showToast({
  title = "成功",
  icon = "open",
  duration = 3,
  close = false,
} = {}) {
  // title无效会导致Notification报错
  return new Promise((resolve) => {
    if (title)
      message[icon]({
        // offset: 60,
        content: title,
        // type: icon,
        // dangerouslyUseHTMLString: useHTML,
        duration: close ? 0 : duration,
        // showClose:true,
        //   onClose(instance) {},
      });
    //避免因toast等待太长导致toast消失后才能在then里调用后续函数,但延时仍是必要的，停留一段时间让用户看清提示
    setTimeout(() => {
      resolve();
    }, 20);
  });
}
export default showToast;
