import { Button, Form, Input, Select } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import Iconfont from "@components/Iconfont";
import { useMount } from "@hooks";
import api from "@api";
import { useNavigate, Link } from "react-router-dom";
import { uface } from "@widget";
import { useModel } from "@model";
import { useEffect, useState } from "react";
const FormItem = Form.Item;
const { Option } = Select;

const Login = () => {
  const [form] = Form.useForm();
  const [company, setCompany] = useState([]);
  const [comID, setComID] = useState(null);
  const naviate = useNavigate();
  const { setUserinfo } = useModel("login", ({ setUserinfo }) => ({
    setUserinfo,
  }));
  useMount(() => {
    if (uface.getStorage("token")) naviate("/");
  });

  const handleSubmit = (values) => {
    api.login.login({ data: values }).then((res) => {
      if (res.result) {
        setUserinfo(res.data);
        naviate("/");
      }
    });
  };

  return (
    <div className="login">
      <div className="login-box">
        <div className="login-left">
          <div className="top-line"></div>
          <h1>嘉丰官网管理</h1>
          <h2>录入、查询。</h2>
          <div className="text-right">
            <img src={require("@/static/images/login-left.png")} />
          </div>
        </div>
        <div className="login-form-box">
          <div className="login-form">
            <h1>登录</h1>
            <Form form={form} onFinish={handleSubmit}>
              <FormItem
                name="phone"
                rules={[{ required: true, message: "请输入账号!" }]}
              >
                <Input
                  prefix={
                    <Iconfont
                      type="icon-shouye-shouye"
                      style={{ fontSize: "20px", color: "#B9BED1" }}
                    ></Iconfont>
                  }
                  placeholder="请输入负责人手机号"
                  autoComplete="off"
                  autoCapitalize="off"
                  maxLength={11}
                />
              </FormItem>
              <FormItem
                name="password"
                rules={[{ required: true, message: "请输入密码!" }]}
              >
                <Input.Password
                  prefix={
                    <Iconfont
                      type="icon-suoping"
                      style={{ fontSize: "20px", color: "#B9BED1" }}
                    ></Iconfont>
                  }
                  placeholder="请输入密码"
                  autoComplete="off"
                  autoCapitalize="off"
                />
              </FormItem>
              <FormItem>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  style={{
                    width: "100%",
                    height: "42px",
                    borderColor: "transparent",
                    backgroundColor: "#4E6EF2",
                    borderRadius: "28px",
                  }}
                >
                  登录
                </Button>
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
      <div className="copyright">
        <img src={require("@/static/images/login-icon.png")} alt="" />
        太原嘉丰伟业科技有限公司
      </div>
    </div>
  );
};

export default Login;
