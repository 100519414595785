const list = {
  // 权限
  getLeftMenu: "Auth/getAdminLeftMenu", //获取律所后台端左侧导航菜单列表
  getNoButton: "Auth/get_opr_auth", //律师后台根据左侧菜单主键id查询当前页面没有权限的button

  addAuth: "Auth/add_role",
  editAuth: "Auth/mod_role",
  getAuthForAdd: "Auth/initial_role_add",
  getAuthForEdit: "Auth/initial_role_edit",
  delAuth: "Auth/del_role",
};

export default list;
