export default class Diaptch {
    data = {};
    callbacks = {};
    update = (namespace) => {
        (this.callbacks[namespace] || []).forEach((cb) => {
            try {
                const data = this.data[namespace];
                cb(data);
            } catch {
				cb(undefined)
			}
        });
    };
}
