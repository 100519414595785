//Returns true if it is a DOM node
function isNode(o) {
  return typeof Node === "object"
    ? o instanceof Node
    : o &&
        typeof o === "object" &&
        typeof o.nodeType === "number" &&
        typeof o.nodeName === "string";
}

//Returns true if it is a DOM element
function isElement(o) {
  return typeof HTMLElement === "object"
    ? o instanceof HTMLElement //DOM2
    : o &&
        typeof o === "object" &&
        o !== null &&
        o.nodeType === 1 &&
        typeof o.nodeName === "string";
}
function getClassName(classList) {
  if (!classList) return "";
  let temp = "";
  for (let i = 0; i < classList.length; i++) {
    temp += "." + classList[i];
  }
  return temp;
}
function findParent(node, className) {
  let tmp = node,
    parent = null;
  while (tmp.parentNode) {
    tmp = tmp.parentNode;
    const clsName =
      tmp.nodeName.toLocaleLowerCase() + getClassName(tmp.classList);
    // console.log(clsName, className, className.split(/\.|#/));
    if (
      className
        .split(/\.|#/)
        .reduce((accu, cur) => accu && clsName.includes(cur), true)
    ) {
      parent = tmp;
      return parent;
    }
  }
  return parent;
}
class Dom {
  constructor({ el }) {
    this.ele = isElement(el) ? el : document.querySelector(el);
    if (!this.ele) throw new Error("el is not a node!");
    if (this.ele.length > 1) throw new Error("el should be a one node!");
    if (!isElement(this.ele)) throw new Error("el is not a dom! el is " + el);
    this.eventStore = {};
  }

  get el() {
    return this.ele;
  }

  set el(val) {
    this.ele = val;
  }
  static isNode(p) {
    return isNode(p);
  }
  static isElement(p) {
    return isElement(p);
  }
  static getElements(str) {
    return str
      .split(",")
      .map((item) => String(item).trim())
      .reduce((accu, el) => {
        accu = accu.concat(...document.querySelectorAll(el));
        return accu;
      }, [])
      .filter((el) => el && isElement(el));
  }
  static parseDom(str) {
    const div = document.createElement("div");
    div.innerHTML = str;
    return div.childNodes.length > 1 ? div.childNodes : div.childNodes[0];
  }
  // 动态加载script脚本
  static loadScript(src) {
    const dom = document.createElement("script");
    dom.type = "text/javascript";
    dom.src = src;
    document.appendChild(dom);
  }

  // 是否聚焦 监听事件
  static onKeyBoard(focus, blur) {
    // H5页面关闭软键盘：
    //  document.activeElement.blur();
    const winHeight = window.innerHeight;
    window.addEventListener("resize", function () {
      var thisHeight = this.innerHeight;
      if (winHeight - thisHeight > 50) {
        //窗口发生改变(大),故此时键盘弹出
        //当软键盘弹出，在这里面操作
        focus();
      } else {
        //窗口发生改变(小),故此时键盘收起
        //当软键盘收起，在此处操作
        blur();
      }
    });
  }

  /**
   * 获取浏览器可用的transform属性名
   */
  static usableTransfrom() {
    const transformName = {
      standard: "transform",
      webkit: "webkitTransform",
      Moz: "MozTransform",
      O: "OTransform",
      ms: "msTransform",
    };
    const elStyle = document.createElement("div").style;
    for (let key in transformName) {
      if (elStyle[transformName[key]] !== undefined) return transformName[key];
    }
    return false;
  }

  /**
   * 由于图片在加载完毕之前无法取到大小信息
   * @param {*} src
   */
  static getImgSize(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      if (typeof src === "string") img.src = src;
      else img.src = src.src;
      img.onload = function () {
        resolve({
          width: img.width,
          height: img.height,
        });
      };
      img.onerror = reject;
    });
  }
  observer(fn, config = ["attributes", "childList", "subtree"]) {
    const targetNode = this.el,
      observerConfig = config.reduce((accu, cur) => {
        accu[cur] = true;
        return accu;
      }, {}),
      cb = (mutationList, observer) => {
        fn(mutationList, observer);
      };
    const observer = (this.observer = new MutationObserver(cb));
    observer.observe(targetNode, observerConfig);
    return this;
  }
  unObserver() {
    if (this.observer) this.observer.disconnect();
    return this;
  }

  // 返回元素样式 非IE  微信环境工具最新测试可用
  getStyle(property) {
    let temp = {};
    const style = document.defaultView.getComputedStyle(this.el, null);
    const width = Number(style.width.substr(0, style.width.indexOf("px"))),
      height = Number(style.height.substr(0, style.height.indexOf("px")));
    temp = Object.assign({}, style, {
      width,
      height,
    });
    if (property) {
      return temp[property];
    } else {
      return temp;
    }
  }

  /**
   * 获取浏览器滚动距离
   */
  getScrollOffset() {
    if (window.pageXOffset) {
      return {
        x: window.pageXOffset,
        y: window.pageYOffset,
      };
    } else {
      return {
        x: document.body.scrollLeft + document.documentElement.scrollLeft,
        y: document.body.scrollTop + document.documentElement.scrollTop,
      };
    }
  }

  //  添加事件
  addEevent(type, fn, capture) {
    if (!this.eventStore[type]) this.eventStore[type] = [];
    this.eventStore[type].push(fn);
    this.el.addEventListener(type, fn, {
      capture: !!capture,
    });
    return this;
  }

  //  移除事件
  removeEvent(type, capture) {
    if (!this.eventStore[type]) return this;
    this.eventStore[type].forEach((fn) => {
      this.el.removeEventListener(type, fn, {
        capture: !!capture,
      });
    });
    return this;
  }

  //  获取元素偏移量
  offset() {
    let left = 0,
      top = 0,
      el = this.el;
    while (el) {
      left += el.offsetLeft;
      top += el.offsetTop;
      el = el.offsetParent;
    }
    return {
      top,
      left,
    };
  }

  hasClass(className) {
    const reg = new RegExp("(^||\\s)" + className + "($|\\s)", "g");
    return reg.test(this.el.className);
  }

  addClass(className) {
    if (this.hasClass(className)) return;
    let curClass = this.el.className.split(" ");
    curClass.push(className);
    this.el.className = curClass.join(" ");
    return this;
  }

  removeClass(className) {
    if (!this.hasClass(className)) return;
    const reg = new RegExp("(\\s|^)" + className + "(\\s|$)");
    this.el.className = this.el.className.replace(reg, " ");
    return this;
  }

  setStyle(option) {
    if (typeof option !== "object") return;
    const el = this.el;
    Object.keys(option).forEach((style) => {
      el.style[style] = option[style];
    });
    return this;
  }

  //  节点类
  removeChild(child) {
    this.el.removeChild(child);
    return this;
  }

  // 向实例元素内第一个前插入元素
  prepend(target) {
    const ele = this.ele,
      firstChild = ele.firstChild;
    if (firstChild) {
      this.ele.insertBefore(target, firstChild);
    } else {
      target.appendChild(this.ele);
    }
  }

  setEle(el) {
    this.el = typeof el === "string" ? document.querySelector(el) : el;
    if (!(el instanceof HTMLElement)) throw new Error("el is not a dom!");
    this.eventStore = {};
  }
  parent(className) {
    this.el = findParent(this.el, className);
    return this;
  }
  index() {
    const children = this.el?.parentNode?.children;
    if (!children) return -1;
    let index;
    for (let i = 0; i < children.length; i++) {
      if (children[i] === this.el) {
        index = i;
        break;
      }
    }
    return index;
  }

  destroy() {
    this.el = null;
    this.eventStore = {};
  }
}

export default Dom;
