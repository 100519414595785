import { Button, Space, Spin, Dropdown } from "antd";
import uface from "@widget/uface";
import { pick } from "@utils/field";
import {
  PlusOutlined,
  DownOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
  DashOutlined,
} from "@ant-design/icons";
import Iconfont from "@components/Iconfont";
import { useRequest } from "@utils/hooks";
import api from "@api";
import "./index.less";
import { useMemo, useState } from "react";
import { useUrlQuery } from "@utils/hooks";
export const apis = {
  add: "BaseMsg/addClass",
  delete: "BaseMsg/delClass",
  edit: "BaseMsg/editClass",
  list: "BaseMsg/getClassList",
};
const LeftBar = ({ title = "分类", type, onChange = () => {} }) => {
  const [urlQuery, setUrlQuery] = useUrlQuery();
  const { loading, data, reload } = useRequest({
    request: apis.list,
    config: { data: { pageSize: 0, type } },
    onSuccess: ({ data: d }) => [
      { class_name: "全部", id: 0, active: true },
      ...d,
    ],
    onFirstSuccess: (da) => {
      if (urlQuery.class_id)
        setActiveIndex(da.findIndex((d) => d.id == urlQuery.class_id));
    },
  });
  const [activeIndex, setActiveIndex] = useState(0);
  const operate = (request, query, reload) =>
    uface.makeForm({
      title: (query ? "编辑" : "新增") + title,
      request,
      initialValue: query || {},
      syncToRequest: (data) => ({ data: Object.assign({}, data, { type }) }),
      columns: [
        {
          title: title + "名称",
          name: "class_name",
          formItemProps: {
            required: true,
          },
        },
      ],
      success() {
        reload();
      },
    });
  const [fold, setFold] = useState(false);
  return (
    <div className="left-bar-wrap">
      <div
        className="fold-icon"
        onClick={() => {
          setFold(!fold);
        }}
      >
        {fold ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
      </div>
      <div className={"left-bar " + (fold ? "fold" : "")}>
        <div className="title flex-middle">
          <span>{title}</span>
          <Button>
            <a onClick={() => operate(apis.add, undefined, reload)}>
              <PlusOutlined />
              新增
            </a>
          </Button>
        </div>
        <Spin spinning={loading}>
          {loading ? null : (
            <div className="list">
              {data.map((node, i) => (
                <div
                  key={node.id}
                  className={"item " + (activeIndex == i ? "active" : "")}
                  onClick={() => {
                    onChange(node.id);
                    setActiveIndex(i);
                  }}
                >
                  <div>{node.class_name}</div>
                  {node.id == 0 ? null : (
                    <Dropdown
                      menu={{
                        items: [
                          {
                            key: "1",
                            label: (
                              <a
                                onClick={(e) => {
                                  e.stopPropagation();
                                  operate(
                                    apis.edit,
                                    pick(node, "class_name", "type", "id"),
                                    reload
                                  );
                                }}
                              >
                                编辑
                              </a>
                            ),
                          },
                          {
                            key: "2",
                            label: (
                              <a
                                className="danger-color"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  uface
                                    .showModal({
                                      title: "警告",
                                      icon: "warning",
                                      content: (
                                        <div>
                                          确定要删除
                                          <span className="red-color">
                                            {node.class_name}
                                          </span>
                                          吗?
                                        </div>
                                      ),
                                    })
                                    .then((confirm) => {
                                      if (confirm)
                                        api[apis.delete]({
                                          data: { id: node.id },
                                        }).then((res) => {
                                          if (res.result) {
                                            if (activeIndex == i) {
                                              setActiveIndex(0);
                                              onChange(0);
                                            }
                                            reload();
                                          }
                                        });
                                    });
                                }}
                              >
                                删除
                              </a>
                            ),
                          },
                        ],
                      }}
                    >
                      <Button type="text" icon={<DashOutlined />}></Button>
                    </Dropdown>
                  )}
                </div>
              ))}
            </div>
          )}
        </Spin>
      </div>
    </div>
  );
};
export default LeftBar;
