import { random } from "@/utils/str";
import load from "@utils/dynamicLoadScript";
import { useMount } from "@hooks";
import uface from "@widget/uface";
import { useRef } from "react";
const apiSrc = "http://39.100.53.148:8081/web-apps/apps/api/documents/api.js",
  // callbackUrl="http://39.105.105.74:8081/webeditor-ajax.php?type=track&aaa=aaa";
  callbackUrl = "http://20210910.law.tyjfwy.com/api/Test/saveWordFile?aaa=aaa";
function getDocumentType(suffix) {
  const typeMap = {
    word: [
      "doc",
      "docm",
      "docx",
      "docxf",
      "dot",
      "dotm",
      "dotx",
      "epub",
      "fodt",
      "fb2",
      "htm",
      "html",
      "mht",
      "odt",
      "oform",
      "ott",
      "oxps",
      "pdf",
      "rtf",
      "txt",
      "djvu",
      "xml",
      "xps",
    ],
    cell: [
      "csv",
      "fods",
      "ods",
      "ots",
      "xls",
      "xlsb",
      "xlsm",
      "xlsx",
      "xlt",
      "xltm",
      "xltx",
    ],
    slide: [
      "fodp",
      "odp",
      "otp",
      "pot",
      "potm",
      "potx",
      "pps",
      "ppsm",
      "ppsx",
      "ppt",
      "pptm",
      "pptx",
    ],
  };
  for (let [key, value] of Object.entries(typeMap)) {
    if (value.includes(suffix)) return key;
  }
}
/**
 * onlyoffice https://api.onlyoffice.com/?_ga=2.209418386.1636952159.1673061843-659840757.1672384002
 * https://api.onlyoffice.com/editors/config/document
	 * {
			title:{
				type:String
			},
			key:{
                type:[Number,String],
                default(){
                    return guid(13,false);
                }
            },
			//optional : edit/view/preview/
			mode: {
				type: String,
				default: 'edit'
			},
			width: {
				type: String,
				default: '100%'
			},
			height: {
				type: String,
				default: '100%'
			},
			url:String,
			saveUrl:String
		}
	*/
function Office({
  url: fileURL,
  saveUrl,
  title,
  key = random(13),
  mode = "view",
  width = "100%",
  height = "100%",
  onChange = () => {},
}) {
  console.log(key);
  const editorRef = useRef(null);
  const platform = useRef("desktop"); //  查看平台
  const docEditor = useRef(null);
  function close() {
    if (editorRef.current) editorRef.requestClose();
  }
  function destroy() {
    console.log("office destroyed");
    if (editorRef.current) editorRef.current.destroyEditor();
  }
  function refresh() {
    destroy();
    init();
  }
  function download(type = "docx") {
    docEditor.current.downloadAs(type);
  }
  function init() {
    load(apiSrc, (err) => {
      if (err) {
        uface.showToast({ title: err.message, icon: "warning" });
        return;
      }
      initDocEditor();
    });
  }
  function getUrl() {
    if (fileURL.startsWith("http")) return fileURL;
    else return `${process.env.VUE_APP_IMGURL}/${fileURL}`;
  }
  /**
   * 初始化文档编辑器
   * @param {string} docserviceApiUrl 文档服务API url
   * @param {object} config 文件相关配置信息
   */
  function initDocEditor() {
    if (!fileURL) return;
    const url = getUrl(),
      // height=this.height,
      config = {
        type: platform,
        document: {
          permissions: {
            comment: false,
          },
          fileType: url.match(/\.(\w+)$/)[1],
          key: random(13), //每次初始化重新生成key,防止新文档打开时nlyoffice使用历史缓存文件
          title: title || "文档",
          url,
        },
        documentType: getDocumentType(url.match(/\.(\w+)$/)[1]),
        width: width,
        // height: /100vh/.test(height)?eval(height.replace(/100vh/g,document.body.clientHeight))+'px':height,
        height: "100%",
        editorConfig: {
          lang: "zh", //  语言设置为中文
          customization: {
            anonymous: {
              request: false,
            },
            comments: false,
            feedback: false,
            mentionShare: false,
            spellcheck: false,
            goback: false,
            chat: false,
            customer: {
              name: "太原嘉丰伟业科技有限公司",
              address: "北张小区D区一单元2802",
              mail: "2776676770@qq.com",
              www: "http://www.tyjfwy.com/public/index.php",
              info: "Some additional information",
              logo: "http://www.tyjfwy.com/dist/admin/images/company.png",
            },
            uiTheme: "default-light",
            zoom: 100, //  缩放比例为 100
          },
          // callbackUrl
        },
        events: {
          onAppReady: () => {
            console.log("ready");
          },
          onDownloadAs: (e) => {
            console.log(e);
            onChange(e.data);
          },
          onError: (e) => {
            throw new Error(e);
          },
          onWarning: (e) => {
            console.warn(e);
          },
          onInfo: (e) => {
            console.log(e);
          },
        },
      };
    if (saveUrl) config.editorConfig.callbackUrl = saveUrl;
    if (mode === "edit") config.document.permissions.edit = true;
    else if (mode === "view") config.document.permissions.edit = false;
    else if (mode === "preview")
      config.editorConfig.embedded = {
        embedUrl: url,
        fullscreenUrl: url,
        shareUrl: url,
        toolbarDocked: "top",
      };
    /* global DocsAPI */
    docEditor.current = new DocsAPI.DocEditor("placeholder" + key, config); //防止每次组件使用同一DOM，动态生成key,但是DOM需初始绑定id，不能动态绑定
    console.log(config, docEditor);
    docEditor.current.showMessage("aaz");
  }
  /**
   * 判断当前平台
   */
  function judgePlatform() {
    if (mode === "preview") {
      // 嵌入式预览
      platform.current = "embedded";
      return;
    }
    platform.current = "desktop"; // 浏览平台
    if (
      /AppleWebKit.*Mobile/i.test(navigator.userAgent) ||
      /MIDP|SymbianOS|NOKIA|SAMSUNG|LG|NEC|TCL|Alcatel|BIRD|DBTEL|Dopod|PHILIPS|HAIER|LENOVO|MOT-|Nokia|SonyEricsson|SIE-|Amoi|ZTE/.test(
        navigator.userAgent
      )
    ) {
      if (window.location.href.indexOf("?mobile") < 0) {
        try {
          if (
            /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
          ) {
            platform.current = "mobile";
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  }
  useMount(() => {
    judgePlatform();
    init();
    return () => {
      destroy();
    };
  }, [fileURL]);
  return (
    <div className="office-editor" style={{ height }}>
      <div id={"placeholder" + key}></div>
    </div>
  );
}
export default Office;
