import Table from "@components/Table";
import uface from "@widget/uface";
import { Button } from "antd";
import { pick } from "@utils/field";
import api from "@api";
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
export default () => {
  const operate = (request, query, reload) =>
    uface.makeForm({
      title: (query ? "编辑" : "新增") + "平台管理员",
      initialValue: query,
      request,
      rules: {
        phone: {
          phone: true,
        },
      },
      columns: [
        query
          ? null
          : {
              render: (_, key) => (
                <div
                  key={key}
                  style={{
                    width: "100%",
                    backgroundColor: "#FFFBE6",
                    border: "1px solid #FFE2A9",
                    padding: "12px 16px",
                    marginBottom: "22px",
                  }}
                >
                  <ExclamationCircleOutlined
                    style={{ color: "#FAAD14", marginRight: "10px" }}
                  />
                  新增用户时默认初始密码为123456
                </div>
              ),
            },
        {
          title: "用户名",
          name: "nickname",
          formItemProps: {
            required: true,
          },
        },
        {
          title: "手机号",
          name: "phone",
          formItemProps: {
            required: true,
          },
          fieldProps: {
            maxLength: 11,
          },
        },
        {
          title: "管理部门",
          name: "dep_id_str",
          valueType: "tree:select",
          request: "DepUser/getDepListByRule",
          formItemProps: {
            required: true,
          },
          fieldProps: {
            labelName: "dep_name",
            valueName: "id",
            multiple: true,
            treeCheckable: true,
            treeCheckStrictly: true,
          },
        },
        {
          title: "权限组",
          name: "group_id_str",
          valueType: "select",
          formItemProps: {
            required: true,
          },
          fieldProps: {
            // mode: "multiple",
            labelName: "group_name",
            valueName: "id",
          },
          request: () =>
            api["Auth/getUserGroupList"]({ data: { module: "admin" } }),
        },
        {
          title: "备注",
          name: "remark",
          valueType: "textarea",
        },
      ],
      syncToRequest: (res, files) => ({
        data: {
          ...res,
          dep_id_str: res.dep_id_str.join(","),
          group_id_str: res.group_id_str,
        },
        files,
      }),
      success() {
        reload();
      },
    });
  const columns = [
    {
      title: "姓名",
      key: "nickname",
    },
    {
      title: "手机号",
      key: "phone",
    },
    {
      title: "管理部门",
      key: "dep_name",
    },
    {
      title: "权限组",
      key: "group_name",
    },
    {
      title: "备注",
      key: "remark",
    },
    {
      width: 100,
      title: "操作",
      valueType: "operation",
      render: (row, action) => [
        <a
          onClick={() =>
            operate(
              "Auth/editUserAdmin",
              pick(
                row,
                "id",
                "phone",
                "nickname",
                [
                  "dep_id_str",
                  ({ dep_id_str }) => ({
                    dep_id_str: dep_id_str.split(",").map(Number),
                  }),
                ],
                [
                  "group_id_str",
                  ({ group_id_str }) => ({
                    group_id_str: group_id_str
                      ? Number(group_id_str)
                      : undefined,
                  }),
                ],
                "remark"
              ),
              action.reload
            )
          }
        >
          编辑
        </a>,
        <a
          className="danger-color"
          onClick={() =>
            action.delete("Auth/delUserAdmin", { id: row.id }, row.nickname)
          }
        >
          删除
        </a>,
      ],
    },
  ];
  return (
    <Table
      request="Auth/getUserAdminList"
      headerTitle="平台管理员"
      toolbar={{
        operateRender: (action) => [
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() =>
              operate("Auth/addUserAdmin", undefined, action.reload)
            }
          >
            新增
          </Button>,
        ],
      }}
      search={{
        columns: [
          {
            title: "部门",
            name: "dep_id",
            valueType: "tree:select",
            request: "DepUser/getDepListByRule",
            fieldProps: {
              labelName: "dep_name",
              valueName: "id",
            },
          },
          { name: "keywords", placeholder: "可筛选姓名/手机号" },
        ],
      }}
      columns={columns}
    ></Table>
  );
};
