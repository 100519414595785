import {
    storage,
} from '@config';
import storageCache from './storageCache';
import typeUtil from '../typeUtil';
const isJSON = typeUtil('isJSON');
function get(key) {
    key = window.location.host + '-' + key;
    if (storage) {
        if ('localStorage' === storage) {
            return localStorage.getItem(key)
        } else if ('sessionStorage' === storage) {
            return sessionStorage.getItem(key)
        } else {
            return localStorage.getItem(key)
        }
    } else {
        return localStorage.getItem(key)
    }
}
export default function (key){
	if(storageCache[key]) return storageCache[key];
    const value=get(key);
    return value?isJSON(value)?JSON.parse(value):value:value;
}