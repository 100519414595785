import { Button, Row, Col } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useMount } from "@hooks";
import api from "@api";
import { useNavigate, Link } from "react-router-dom";
import { uface } from "@widget";
import { useModel } from "@model";
import Form, { FormMaker } from "@components/Form";
import md5 from "@/plugins/md5";
import { pick } from "@utils/field";
const Field = FormMaker.Field;

const Login = () => {
  const naviate = useNavigate();
  const { setUserinfo } = useModel("login", ({ setUserinfo }) => ({
    setUserinfo,
  }));

  const handleSubmit = (data, files) => {
    api["Login/addCompany"]({
      data: {
        data: pick(data, "*", "!repeatPassword", ({ password }) => ({
          password: md5(password),
        })),
      },
      files: files,
    }).then((res) => {
      if (res.result) {
        naviate("/verify", { replace: true });
      }
    });
  };

  return (
    <div className="register">
      <img
        src={require("@/static/images/register-banner.png")}
        width="100%"
        height="300px"
        alt=""
      />
      <div className="login-form-box">
        <div className="back-btn" onClick={() => window.history.back()}>
          <LeftOutlined /> 返回
        </div>
        <div className="login-form">
          <h1>注册</h1>
          <Form
            labelAlign="left"
            rules={{
              password: {
                eqeqTo: "repeatPassword",
              },
            }}
            onSubmit={handleSubmit}
          >
            <Field
              title="公司logo"
              name="comp_pic"
              valueType="upload:image"
              limit={1}
            ></Field>
            <Row gutter={18}>
              <Col span={12}>
                <Field
                  title="公司名称"
                  name="company_name"
                  autoComplete="off"
                  autoCapitalize="off"
                  formItemProps={{
                    required: true,
                  }}
                ></Field>
              </Col>
              <Col span={12}>
                <Field
                  title="公司地址"
                  name="address"
                  autoComplete="off"
                  autoCapitalize="off"
                ></Field>
              </Col>
            </Row>
            <Row gutter={18}>
              <Col span={12}>
                <Field
                  title="负责人姓名"
                  name="username"
                  autoComplete="off"
                  autoCapitalize="off"
                  formItemProps={{
                    required: true,
                  }}
                ></Field>
              </Col>
              <Col span={12}>
                <Field
                  title="负责人电话"
                  name="phone"
                  autoComplete="off"
                  autoCapitalize="off"
                  maxLength={11}
                  formItemProps={{
                    required: true,
                  }}
                ></Field>
              </Col>
            </Row>
            <Field
              title="密码"
              name="password"
              valueType="password"
              formItemProps={{
                required: true,
              }}
            ></Field>
            <Field
              title="确认密码"
              name="repeatPassword"
              valueType="password"
              formItemProps={{
                required: true,
              }}
            ></Field>
            <Field
              title="公司简介"
              name="company_content"
              valueType="textarea"
              autoSize={{ minRows: 5, maxRows: 20 }}
            ></Field>
            <div className="flex flex-center">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{
                  borderColor: "transparent",
                  backgroundColor: "#4E6EF2",
                  borderRadius: "28px",
                  width: "100px",
                  height: "42px",
                }}
              >
                立即注册
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
