//  blob流和base64，以及file和base64的相互转换
// 1.file文件转换为base64，得到base64格式图片
export function filetoBase64(file) {
  return new Promise((resolve) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result); //获取到base64格式图片
    };
  });
}
// 2.base64转换为file

export function dataURLtoFile(dataurl, filename) {
  //将base64转换为文件
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {
    type: mime,
  });
}

// blob和base64
// 3.base64转换为blob流

export function dataURItoBlob(base64Data) {
  //console.log(base64Data);//data:image/png;base64,
  var byteString;
  if (base64Data.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(base64Data.split(",")[1]); //base64 解码
  else {
    byteString = unescape(base64Data.split(",")[1]);
  }
  var mimeString = base64Data.split(",")[0].split(":")[1].split(";")[0]; //mime类型 -- image/png

  // var arrayBuffer = new ArrayBuffer(byteString.length); //创建缓冲数组
  // var ia = new Uint8Array(arrayBuffer);//创建视图
  var ia = new Uint8Array(byteString.length); //创建视图
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  var blob = new Blob([ia], {
    type: mimeString,
  });
  return blob;
}
// 4.blob流转换为base64

export function blobToDataURI(blob, callback) {
  var reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onload = function (e) {
    callback(e.target.result);
  };
}
/**
 * 即字符串的编码方法是确定的。假定字符串采用UTF-16编码（JavaScript的内部编码方式），可以自己编写转换函数。
 */
export function strtoBufferInt16(str) {
  // 每个字符占用2个字节
  const buffer = new ArrayBuffer(str.length * 2),
    bufferView = new Int16Array(buffer);
  for (let i = 0; i < str.length; i++) {
    bufferView[i] = str.charCodeAt(i);
  }
  return buffer;
}

function strtoBufferInt8(str) {
  const buffer = new ArrayBuffer(str.length * 3),
    bufferView = new Int8Array(buffer);
  for (let i = 0; i < str.length; i++) {
    bufferView[i] = str[i];
  }
}
/**
 * 用Uint16Array视图转换的并不行，因为utf-8编码字符字节编码长度不一定是两个字节,英文是1字节，中文2-3字节，其他表情符号4字节。
 */
export function strtoBuffer(str) {
  return new Promise((resolve) => {
    const blob = new Blob([str], {
        type: "text/plain",
      }),
      fileReader = new FileReader();
    fileReader.onload = function (e) {
      resolve(e.target.result);
    };
    fileReader.readAsArrayBuffer(blob);
  });
}
export function buffertoStr(buffer) {
  return new Promise((resolve) => {
    const blob = new Blob([buffer]),
      fileReader = new FileReader();
    fileReader.onload = function (e) {
      resolve(e.target.result);
    };
    fileReader.readAsText(blob, "utf-8");
  });
}
export function filetoBuffer(file) {
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      resolve(e.target.result);
    };
    fileReader.readAsArrayBuffer(file);
  });
}

//建立一个可存取到该file的url
export function getObjectURL(file) {
  var url = null;
  if (window.createObjectURL != undefined) {
    // basic
    url = window.createObjectURL(file);
  } else if (window.URL != undefined) {
    // mozilla(firefox)
    url = window.URL.createObjectURL(file);
  } else if (window.webkitURL != undefined) {
    // webkit or chrome
    url = window.webkitURL.createObjectURL(file);
  }
  return url;
}

/**
 * 本地download file
 * @param url string / Blob /File [limit:同源URL]
 * @param name 文件名
 * @param method url/file
 * @param type mime type
 */
export function download({
  url = "test",
  name,
  mode = "url",
  type = "txt",
} = {}) {
  // when url is file
  if (mode === "file" || typeof url !== "string" || !url.startsWith("http")) {
    const map = {
      txt: "text/plain",
      excel:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8", // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
    };
    mode = "file";
    if (url instanceof Blob) url = URL.createObjectURL(url);
    else url = URL.createObjectURL(new Blob([url], { type: map[type] }));
  }
  const link = document.createElement("a");
  link.download =
    mode === "file" ? name || "文件" : name || url.match(/([^/]*)$/)[1];
  if (mode === "url")
    link.href = url + "?response-content-type=application/octet-stream";
  else link.href = url;
  link.style.display = "none";
  link.target = "_blank";
  link.innerText = name || "文件";
  document.body.appendChild(link);
  link.click();
  console.log(url);
  document.body.removeChild(link);
  if (mode === "file") URL.revokeObjectURL(url);
}
//是否是office文件
export function isOffice(path) {
  return /.*\.(doc|ppt|xls|docx|pptx|xlsx)$/.test(path);
}
