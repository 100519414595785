import { Link } from "react-router-dom";
import { purefn } from "@widget";
import { useModel } from "@model";
/**
 * <Link to  query></Link>
 */
export default ({ to: toProp, query, children, ...restProps }) => {
  const { hasRoute } = useModel("router");
  let to = toProp;
  if (typeof query === "object") to += "?" + purefn.ObjUtil.queryString(query);
  return hasRoute(toProp) ? (
    <Link to={to} onClick={(e) => e.stopPropagation()} {...restProps}>
      {children}
    </Link>
  ) : null;
};
