import { Spin } from "antd";
import { useEffect, useMemo, useRef } from "react";
/**
 * 场景 防止初次加载，因为初始值为鵼，导致slot里遍历数据报错 undefined of xxxx
 */
const Page = ({ spinning, children: childrenProps }) => {
  const isLoadingFinish = useRef(false);
  useEffect(() => {
    if (spinning) isLoadingFinish.current = true;
  }, [spinning]);
  const children = useMemo(() => {
    if (isLoadingFinish.current) return childrenProps;
    else return null;
  }, [children]);
  return <Spin spinning={spinning}></Spin>;
};
export default Page;
