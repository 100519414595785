import md5 from "@/plugins/md5";
import { purefn, uface } from "@widget";
import emitter from "@emitter";
import { tokenName } from "@config";
const encryptName = "official_key";

// const getType=purefn.typeUtil('getType');
// function toJSON(param) {
//     // 将参数转为json
//     Object.keys(param).forEach(key => {
//         if (typeof param[key] === 'object') {
//             param[key] = JSON.stringify(param[key]);
//         }
//     });
//     return param;
// }
/**
// 加密参数 所有接口都进行加密 过滤空字符串  只加密简单类型的字段
//php后台会将布尔值转换为数字  因此需转换为数字
 * @param 处理参数
*/
function encrypt(param, namespace) {
  param = Object.assign({}, param);
  // 加密账号密码
  Object.keys(param).forEach((key) => {
    if (key.toLowerCase().includes("password")) param[key] = md5(param[key]);
    // 处理element数据格式为YYYY-MM-DD
    // if (key.includes("$date")) {
    //   if (typeof param[key] === "string")
    //     param[key.split("$date")[0]] = datefn.day(param[key]).value;
    //   else
    //     param[key.split("$date")[0]] = param[key].map(
    //       (item) => datefn.day(item).value
    //     );
    //   delete param[key];
    // }
  });
  //     添加部分公共caseid 优先级：链接的参数>缓存参数
  const query = purefn.StrUtil.queryString(window.location.href, {
    fullMatch: true,
  });
  // if(!param||(getType(param)==='array'&&param.length<=0)||(getType(param)==='object')&&Object.keys(param).length<=0) return param;

  const nonceStr = purefn.StrUtil.radomString(5) + Date.now();
  // 1.拼接公共参数
  delete param.nonceStr;
  param.nonceStr = nonceStr;
  delete param.sign;
  // 2.去空 按字典排序参数 格式化参数 添加key md5加密 转换大写 得到sign
  // console.log(param,purefn.ObjUtil.queryString(new purefn.ObjUtil(param).map(item=>typeof item==='boolean'?Number(item):item).filter(item=>typeof item!=='object').clearEmpty().sort().value)+ '&key=coalmsg_token')
  // param.sign = md5(purefn.ObjUtil.queryString(new purefn.ObjUtil(param).map(item=>typeof item==='boolean'?Number(item):item).filter(item=>typeof item!=='object').clearEmpty().sort().value)+ '&key=coalmsg_token').toUpperCase();
  console.log(
    "akakkaak",
    purefn.ObjUtil.queryString(
      new purefn.ObjUtil(param)
        .clearEmpty()
        .filter((item) => typeof item !== "object")
        .map((d) => (d && d.replace ? d.replace(/\r|\n/g, "") : d))
        .sort().value,
      { encode: false }
    ) +
      "&key=" +
      encryptName,
    purefn.ObjUtil.queryString(
      new purefn.ObjUtil(param)
        .clearEmpty()
        .filter((item) =>
          typeof item === "object"
            ? false
            : item
            ? // ? !/\r|\n/g.test(item) && String(item).length <= 200
              String(item).length <= 200
            : true
        )
        .map((d) => (d && d.replace ? d.replace(/\r|\n|\s/g, "") : d))
        .sort().value,
      { encode: false }
    ) +
      "&key=" +
      encryptName
  );
  param.sign = md5(
    purefn.ObjUtil.queryString(
      new purefn.ObjUtil(param)
        .clearEmpty()
        .filter((item) => typeof item !== "object")
        .map((d) => String(d).replace(/[^\u4E00-\u9FA5A-Za-z0-9_.]/g, ""))
        .sort().value,
      { encode: false }
    ) +
      "&key=" +
      encryptName
  ).toUpperCase();
  return param;
}
let loadingNum = 0;
function handleData(interceptor) {
  let loading;
  interceptor.request.use(function (config) {
    if (config.loading) {
      // loading = uface.showLoading({
      // 	loadingTarget: config.loadingTarget
      // });
      console.log("loaddddding", loadingNum);
      if (loadingNum == 0) uface.showLoading();
      loadingNum++;
    }
    config.data = encrypt(config.data, config.namespace);
    return config;
  });
  const hasOwnProperty = Object.prototype.hasOwnProperty;
  let timeout,
    duration = 400;
  interceptor.response.use(
    function (res) {
      if (res.config.canResponse === false) return Promise.resolve();
      return new Promise((resolve, reject) => {
        try {
          const { data: sourceData, config } = res;

          // handle loading when --
          duration += 20;
          if (config.loading) {
            if (loadingNum > 0) loadingNum--;
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => {
              if (loadingNum == 0) {
                // 防止有请求同时开始同时结束，导致结果总是count=1，给予一定间隔可以分别判断
                duration = 400;
                // 当多个请求有先后顺序时延迟判断，等待下一个请求是否进入请求池
                uface.hideLoading();
              }
            }, duration);
          }
          if (
            typeof sourceData === "string" ||
            typeof sourceData === "number" ||
            sourceData instanceof Blob
          ) {
            sourceData.__proto__.header = res.header;
            resolve(sourceData);
            return false;
          }
          const { check, data = {} } = sourceData;
          if (check) {
            const { msg, code } = check;
            if (check.token && uface.getStorage(tokenName) != check.token)
              uface.setStorage(tokenName, check.token);
            if (config.check) {
              if (code == 2) {
                // token失效
                uface.removeStorage("token");
                uface
                  .showToast({
                    title: msg,
                    icon: "warning",
                  })
                  .then(() => {
                    uface.jump({
                      mode: "assign",
                      path: window.location.origin + "/login",
                    });
                  });
                return false;
              } else if (code != 0) {
                uface.showToast({
                  title: msg,
                  icon: "warning",
                });
              }
            }
          }
          // 处理富文本
          // if (data && hasOwnProperty.call(data, 'content') && data.content && data.content.includes('>'))
          // 	data.content = data.content.replace(
          // 		/(<img[^>]*)src="([^>"]*)"([^>]*>)/g,
          // 		function (match, $1, $2, $3) {
          // 			if ($2.includes('http')) return `${$1} style="max-width:100%;" src="${$2}"${$3}`;
          // 			return `${$1} style="max-width:100%;" src="${process.env.VUE_APP_BACKURL}${$2}"${$3}`;
          // 		});
          if (
            data &&
            res.config.toast &&
            !hasOwnProperty.call(data, "page") &&
            hasOwnProperty.call(data, "result") &&
            hasOwnProperty.call(data, "msg") &&
            data.msg
          ) {
            // 表单提交
            uface
              .showToast({
                title: data.msg,
                icon: data.result ? "success" : "warning",
              })
              .then(() => {
                resolve(data);
              });
          } else {
            resolve(data);
          }
          emitter.emit("afterRequest");
        } catch (err) {
          uface.hideLoading();
          console.warn(err);
          console.warn("接口结构错误，请立刻更正!报错接口：" + res.config.url);
          uface.showToast({
            title: "服务器错误，请联系管理员!",
            icon: "warning",
          });
          return Promise.reject({
            message: "接口结构错误，请立刻更正!报错接口：",
            config: res.config,
          });
        }
      });
    },
    function (err) {
      // when error
      uface.hideLoading(loading);
      console.warn(err);
      if (err.toJSON && err.toJSON.message)
        uface.showToast({
          title: err.toJSON.message,
          icon: "warning",
        });
      return Promise.reject(err);
    }
  );
}

function cleanData(data) {
  let temp = {};
  Object.keys(data || {}).forEach((key) => {
    if (key === "nonceStr" || key === "sign") return;
    temp[key] = data[key];
  });
  return temp;
}
/**
 * 生成请求的识别key，相同请求key相同
 */
function generateReqKey(config) {
  return config.urlRaw + config.method + JSON.stringify(cleanData(config.data));
}
/**
 * 取消重复请求
 * 流程：A发出后，A1跟随发出，判断hash中key，在一段时间内请求不断涌入延时判断重复项
 * 且若有A得到response后A!发出，取消ajax，直接返回缓存的response
 */
function cancelRepeatRequest(interceptor) {
  const pendingRequest = new Map();
  let timer = null;
  interceptor.request.use(function (config) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      pendingRequest.clear();
      timer = null;
    }, config.cancelRepeatRequestInterval);
    if (!config.cancelRepeatRequest) return config;
    const key = generateReqKey(config);
    if (pendingRequest.has(key)) {
      // pendingRequest.delete(key)
      if (pendingRequest.get(key).statusCode) {
        config.cancelRepeatRequestResponse = pendingRequest.get(key);
        return config;
      } else
        return Promise.reject({
          message: "request cancel",
          config,
        });
      // return config;
    }
    // pendingRequest.set(key, config)
    return config;
  });
  interceptor.response.use(function (res) {
    const key = generateReqKey(res.config);
    if (pendingRequest.get(key)) pendingRequest.set(key, res);
    return res;
  });
}

export { encrypt, handleData, cancelRepeatRequest };
