import Table from "@components/Table";
import { uface } from "@widget";
import { Button, Tag, Descriptions, Image } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import api from "@api";
/**
 * 类别
 */
export default () => {
  const showOperate = (request, data = {}, reload) => {
    uface.makeForm({
      title: "新增类别",
      request,
      initialValue: data,
      columns: [
        {
          title: "名称",
          name: "type_name",
        },
        {
          title: "排序",
          type: "number",
          name: "sort",
        },
      ],
      success() {
        reload();
      },
    });
  };
  const columns = [
    {
      title: "类别",
      key: "type_name",
    },
    {
      title: "排序",
      key: "sort",
    },
    {
      width: 120,
      title: "操作",
      valueType: "operation",
      render: (row, action) => [
        <a
          onClick={(e) => {
            e.stopPropagation();
            showOperate(
              "Client/editCaseType",
              {
                type_name: row.type_name,
                id: row.id,
                sort: row.sort,
              },
              action.reload
            );
          }}
        >
          编辑
        </a>,
        <a
          className="danger-color"
          onClick={(e) => {
            e.stopPropagation();
            action.delete("Client/delCaseType", { id: row.id }, row.type_name);
          }}
        >
          删除
        </a>,
      ],
    },
  ];
  const searchColumns = [
    { name: "keywords", placeholder: "可输入类别名称筛选" },
  ];
  return (
    <div>
      <Table
        columns={columns}
        request="Client/pageGetCaseType"
        search={{ columns: searchColumns }}
        toolbar={{
          operateRender: (action) => [
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() =>
                showOperate("Client/addCaseType", {}, action.reload)
              }
            >
              新增
            </Button>,
          ],
        }}
        onRow={(row) => ({
          onClick: () =>
            uface.showDrawer({
              title: "预览",
              width: 600,
              showCancel: false,
              showConfirm: false,
              //   getContainer: () => document.querySelector(".admin-box"),
              content: (
                <Descriptions
                  column={1}
                  bordered
                  labelStyle={{ width: "90px" }}
                >
                  <Descriptions.Item label="名称">
                    {row.type_name}
                  </Descriptions.Item>
                  <Descriptions.Item label="排序">{row.sort}</Descriptions.Item>
                </Descriptions>
              ),
            }),
        })}
      ></Table>
    </div>
  );
};
