function _go(e) {
    return e < 10 ? "0" + e : "" + e; //如果取得的数字为个数则在其前面增添一个0
}
/**
 * 计算time1,time2
 * @param {*} date 
 */
function _computed(date) {
    const time1 = new Date(); //获取当前时间 获取的市1970年1年1月日到现在的毫秒数(必须写在函数或者定时器内 每一次变化都要重新获取当前时间)
    let ms=time1.getTime();
    if (date.second) ms+=date.second*1000;
    if (date.minute) ms+=date.minute*1000*60;
    if (date.day) ms+=date.day*1000*60*24;
    // var time2=new Date(2018,11,20,17,20,10)
    const time2 = new Date(ms); //设置需要到达的时间 也是获取的毫秒数
    return {
        time1,  //当前时间
        time2   //截止时间
    };
}
// 倒计时
function _countDown_go(time1,time2,interval,fn) {
    var conS = Math.floor((time2.getTime() - time1.getTime()) / 1000); //获得差值除以1000转为秒
    var day = _go(Math.floor(conS / 86400)); //  差值/60/60/24获取天数
    var hour = _go(Math.floor(conS % 86400 / 3600)); //   取余/60/60获取时(取余是获取conS对应位置的小数位)
    var minute = _go(Math.floor(conS % 86400 % 3600 / 60)); //  取余/60获取分
    var second = _go(Math.floor(conS % 60)); //取总秒数的余数
    fn({day,hour,minute,second});
    if (conS <= 0) { //倒计时完成 执行功能，
//        clearInterval(interval); //执行功能时要清除时间函数
        fn('stop');
    }else{
        setTimeout(
        () => {
            _countDown_go(new Date(),time2,interval, fn);
        }, interval); //设置定时器 每一秒执行一次
    }
}
/**
* countDown({
moment:(e)=>{
e:stop/{day,hour,minute,second}
}
})
*/
function countDown({expirDate={minute:1}, interval=1,moment}={}) {
    interval=interval*1000;
    let {
        time1,
        time2
    } = _computed(expirDate);
    console.log(time2)
    _countDown_go(new Date(),time2,interval, moment);
//    const intervalID= setTimeout(
//        () => {
//            _countDown_go(new Date(),time2,interval, moment);
//        }, interval); //设置定时器 每一秒执行一次
}
export default countDown;