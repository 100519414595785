import showToast from "../../uShowToast";
import load from "@utils/dynamicLoadScript";
import api from "@api";
const apiSrc = "https://gosspublic.alicdn.com/aliyun-oss-sdk-6.8.0.min.js";
/**
 * oss 上传 https://help.aliyun.com/document_detail/64047.html
 * @param {*} file
 * @returns
 */
export default function ossUpload(file) {
  return new Promise((resolve) => {
    load(apiSrc, (err) => {
      if (err) {
        showToast({
          title: err.message,
          icon: "error",
        });
        return;
      }
      api.base
        .http({
          url: "Demo/getStsToken",
        })
        .then((res) => {
          console.log(res);
          console.time();
          // 创建oss对象
          const client = new window.OSS({
            // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
            region: "oss-cn-beijing",
            // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
            accessKeyId: res.accessKeyId,
            accessKeySecret: res.accessKeySecret,
            // 从STS服务获取的安全令牌（SecurityToken）。
            stsToken: res.securityToken,
            refreshSTSToken: async () => {
              // 向您搭建的STS服务获取临时访问凭证。
              const info = await api.base.http({
                url: "Demo/getStsToken",
              });
              return {
                accessKeyId: info.accessKeyId,
                accessKeySecret: info.accessKeySecret,
                stsToken: info.securityToken,
              };
            },
            // 刷新临时访问凭证的时间间隔，单位为毫秒。
            refreshSTSTokenInterval: 300000,
            // 填写Bucket名称。
            bucket: "lawyercs",
          });
          // object表示上传到OSS的文件名称。
          // file表示浏览器中需要上传的文件，支持HTML5 file和Blob类型。
          client
            .put("a/object", file)
            .then(function (r1) {
              console.log("put success: %j", r1);
              console.timeEnd();
            })
            .catch(function (err) {
              console.error("error: %j", err);
            });
        });
    });
  });
}
