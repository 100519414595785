import curry from './core/curry.js';
import clone from './core/clone.js';
import merge from './core/merge.js';
import judgeif from './core/judgeif.js';
import ArrUtil from './core/ArrUtil.js';
import ObjUtil from './core/ObjUtil.js';
import StrUtil from './core/StrUtil.js';
import typeUtil from './core/typeUtil';
import parallel from './core/parallel.js';
import {pipeSignal as pipe,pipeMultiple} from './core/pipe.js';
import serial from './core/serial.js';
import verify from './core/verify';
import throttle from './core/throttle';
import debounce from './core/debounce';
import deepEqual from './core/deepEqual.js';

/**
 * [purefn description] 功能函数
 * @type {Object}
 */
const purefn = {
    curry,
    clone,
    merge,
    judgeif,
    ArrUtil,
    ObjUtil,
    StrUtil,
    typeUtil,
    parallel,
    pipe,
    pipeMultiple,
    serial,
    verify,
    throttle,
    debounce,
	deepEqual
};
export default purefn;
