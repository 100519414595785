import { Empty, Checkbox, Spin } from "antd";
import { UngroupOutlined } from "@ant-design/icons";
import useFetchData from "./useFetchData";
import { useEffect } from "react";
export const NotFound = (data, title) =>
  data.length <= 0 ? (
    <div style={{ textAlign: "center" }}>
      <UngroupOutlined style={{ fontSize: "28px" }} />
      <div>暂无{title || ""}数据</div>
    </div>
  ) : (
    <div style={{ textAlign: "center" }}>
      <UngroupOutlined style={{ fontSize: "28px" }} />
      <div>暂未找到{title || ""}</div>
    </div>
  );
const CheckboxField = (props) => {
  const {
    name,
    title,
    isSearch,
    valueEnum,
    request,
    labelName = "label",
    valueName = "value",
    rowKey,
    options,
    value,
    onChange,
    onViewValueChange = () => {},
    ...restProps
  } = props;
  const { loading, data } = useFetchData(request);
  const getViewValue = (e) =>
    (options || data).find((item) => item[valueName] == e) &&
    (options || data).find((item) => item[valueName] == e)[labelName];
  useEffect(() => {
    options instanceof Array
      ? onViewValueChange(getViewValue(restProps.value))
      : onViewValueChange(value ? options : "");
  });
  console.log("akakka", value);
  return options instanceof Array ? (
    <Spin spinning={loading}>
      <Checkbox.Group
        value={value || []}
        onChange={(e) => {
          onViewValueChange(getViewValue(e));
          onChange(e);
        }}
        {...restProps}
      >
        {/* <Select.Option value={0 || "" || undefined || null}>无</Select.Option> */}
        {(options || data).map((option) => (
          <Checkbox
            key={
              (rowKey
                ? typeof rowKey === "function"
                  ? rowKey(option)
                  : rowKey
                : undefined) || option[valueName]
            }
            value={option[valueName]}
          >
            {option[labelName]}
          </Checkbox>
        ))}
      </Checkbox.Group>
    </Spin>
  ) : (
    <Checkbox
      checked={value}
      onChange={(e) => {
        onViewValueChange(e ? options : "");
        onChange(e.target.checked);
      }}
      {...restProps}
    >
      {options}
    </Checkbox>
  );
};
export default CheckboxField;
