import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Layout from "./layout";
import { useMount } from "@hooks";
import registerUface from "@utils/registerUface";
export default () => {
  useMount(() => {
    registerUface();
  });
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login></Login>} />
        <Route path="/register" element={<Register></Register>} />
        <Route path="/404" element={<NotFound></NotFound>} />
        <Route path="/*" element={<Layout></Layout>} />
        <Route path="*" element={<NotFound></NotFound>} />
      </Routes>
    </Router>
  );
};
