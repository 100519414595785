import React, { useContext, useEffect, useMemo, useRef } from "react";
import { Context as FieldContext } from "../useProvider";
import purefn from "@widget/purefn";
const numReg = /^[1-9]\d*$/;
const toNumber = (number) =>
  numReg.test(number)
    ? Number.isNaN(Number(number))
      ? number
      : Number(number)
    : number;
const defaultValueFormat = (valuePropName, ...rest) => {
  const event = rest[0];
  if (
    event &&
    event.target &&
    typeof event.target === "object" &&
    valuePropName
  )
    return toNumber(event.target[valuePropName]);
  return event;
};
const isInValid = (val) =>
  val === undefined || val === null || Number.isNaN(val);

function getUpdateType(valueType) {
  if (valueType && valueType.includes("upload")) return "updateFileValue";
  return "updateValue";
}
/**
 * 控制子元素行为，自动提交value到store
 */
export default ({
  trigger = "onChange",
  valuePropName = "value",
  name,
  children,
  initialValue,
  valueType,
  updateType: updateTypeProps, //变更类型，将store分为正常表单值和files，提交network分开读取
  updater,
  readonly,
  ...restProps
}) => {
  const updateType = updateTypeProps || getUpdateType(valueType);
  const throughConetxt = useContext(FieldContext);
  const {
    valueFormat = defaultValueFormat,
    emit,
    getFieldValue,
    getStore,
  } = throughConetxt;
  const getChild = (children) => {
    if (typeof children === "function") {
    }
    return { child: children[0], isFunctional: false };
  };
  const getControllProps = (childProps) => {
    const controlProps = { ...restProps, ...childProps },
      val = getFieldValue(name);
    console.log("useForm field", val, name, getStore());
    /**
     * 处理数字类型
     * 0作为默认值处理
     */
    controlProps[valuePropName] = isInValid(val)
      ? undefined
      : typeof val === "string" && numReg.test(val)
      ? Number(val)
      : val;
    controlProps[trigger] = (e, raw) => {
      const newVal = valueFormat(valuePropName, e);
      emit({
        type: updateType,
        name,
        value: newVal,
        raw, //原始数据，select完整项
      });
    };
    controlProps.onViewValueChange = (e) => {
      const newVal = valueFormat(valuePropName, e);
      emit({
        type: "updateRawText",
        name,
        value: newVal,
      });
    };
    return controlProps;
  };
  let prevInitial = useRef(null);
  useEffect(() => {
    /**
     * 初始化store field
     * files场景 当files填充图片等内容写入store后，清空其值，避免提交
     */
    emit({
      type: "initName",
      updateType,
      name,
    });
  }, []);
  useEffect(() => {
    if (!purefn.deepEqual(prevInitial.current, initialValue)) {
      prevInitial.current = initialValue;
      console.log(
        "kkkkkkkkkkkkkkkkkkkk",
        prevInitial.current,
        initialValue,
        !isInValid(initialValue),
        name
      );
      if (!isInValid(initialValue))
        emit({
          type: updateType,
          name,
          value: initialValue,
        });
    }
  }, [initialValue]);
  if (readonly) return getFieldValue(name);
  return useMemo(() => {
    if (typeof children === "function") return children(getControllProps());
    else if (React.isValidElement(children))
      return React.Children.map(children, (child) => {
        return React.cloneElement(child, getControllProps(child.props));
      });
    else return children;
  }, [updater]);
};
