export function translateCase(str) {
  if (!/[A-Z]/.test(str)) return str;
  return str.replace(/([A-Z])/, "-$1").toLocaleLowerCase();
}
const ifFalsy = (value) =>
  value === false ||
  value === null ||
  value === undefined ||
  Number.isNaN(value);
/**
 * 命名类名
 */
export default function className(...rest) {
  return rest.filter(Boolean).reduce((accu, cur) => {
    if (cur instanceof Array)
      cur.forEach((item) => {
        accu += className(item);
      });
    else if (cur instanceof Object)
      Object.keys(cur).forEach((key) => {
        if (!ifFalsy(cur[key])) accu += " " + key;
      });
    else if (typeof cur === "string") accu += " " + cur;
    return accu;
  }, "");
}
