import ImageCompress from "@/utils/ImageCompress";
import typeUtil from "../../typeUtil";
import utils from "../utils";
import ossUpload from "./oss";
import clone from "../../clone";
import { isInValid } from "@utils/is";
const getType = typeUtil("getType"),
  hasOwnProperty = Object.prototype.hasOwnProperty;
const SLICE_SIZE = 30;
/**
 * 绑定键值对到表单结果 可处理深层嵌套
 * @param {*} formData @type FormData
 * @param {*} data @type Object
 */
function bindFormData(formData, data, dataKey = "") {
  const type = typeUtil("getType")(data);
  if (type === "Object") {
    Object.keys(data).forEach((key) => {
      bindFormData(formData, data[key], dataKey ? `${dataKey}[${key}]` : key);
    });
  } else if (type === "Array") {
    if (typeUtil("isTrue")(data)) {
      data.forEach((item, index) =>
        bindFormData(formData, item, `${dataKey}[${index}]`)
      );
    } else {
      formData.append(dataKey, data);
    }
  }
  //    (
  //     type === "String" ||
  //     type === "Number" ||
  //     type === "Boolean"
  //     // type === null ||
  //     // type === undefined
  //   )
  else {
    // 请求正文 formData会将boolean转成sring
    // formData.append(dataKey, typeof data==='boolean'?Number(data):data);
    formData.append(dataKey, isInValid(data) ? "" : data);
  }
}
function slice(file, piece = 1024 * 1024 * 5) {
  const totalSize = file.size,
    chunks = [];
  let start = 0,
    end = start + piece;
  while (start < totalSize) {
    let blob = file.slice(start, end);
    chunks.push(blob);
    start = end;
    end = start + piece;
  }
  return chunks;
}
// function devide(file, name) {
//   const chunks = slice(file);
//   console.log(chunks);
//   const formData = new FormData();
//   chunks.forEach((chunk) => {
//     formData.append("file", chunk);
//     post({ files: [{ file: chunk, name }] });
//   });
// }
export default async function (config) {
  let { data, header, files = null } = config;
  console.log("atdytasydutasy", config);
  let formData = null;
  if (utils.isFormData(data)) {
    delete header["Content-Type"]; // Let the browser set it
    formData = data;
  }
  // 处理文件上传
  /**
   * https://zhuanlan.zhihu.com/p/68271019
   * 1 当使用files file和fileName不生效
   * files: [{name:'xxx',file:File}]
   * 2 传入object
   * {pic:File}
   * {pic:[File,File]}
   * 3
   * fileName:'xxx'
   * file:File
   */
  if (
    files &&
    ((typeUtil("getType")(files) === "Array" && files.length > 0) ||
      (typeUtil("getType")(files) === "Object" &&
        Object.keys(files).length > 0))
  ) {
    // 如果使用formdata 自动加boundary  无需定义content-type
    // header["content-type"] = "multipart/form-data";
    if ("Content-Type" in header) delete header["Content-Type"];
    if (!formData) formData = new FormData();
    console.log(data, "formdata", files);
    bindFormData(formData, data);
    if (typeUtil("getType")(files) === "Array")
      for (let [index, ele] of files.entries()) {
        const file = ele.file;
        if (file) {
          // if(file.size/1024/1024>SLICE_SIZE) devide(file,formData,ele.name || 'file');
          // if(file.size/1024/1024>SLICE_SIZE) ossUpload(file);
          formData.append(
            ele.name || "file",
            file.type.includes("image")
              ? await new ImageCompress(file).compress()
              : file
          );
        }
      }
    else if (typeUtil("getType")(files) === "Object")
      for (let [name, file] of Object.entries(files)) {
        if (!file) continue;
        if (typeUtil("getType")(file) === "Array") {
          for (let [i, itemFile] of file.entries()) {
            formData.append(
              name || "file",
              itemFile.type.includes("image")
                ? await new ImageCompress(itemFile).compress()
                : itemFile
            );
          }
          //   file.forEach(async (itemFile) => {
          //     console.log(
          //       "s1s1s1s1s1s",
          //       name,
          //       itemFile,
          //       itemFile.type.includes("image")
          //         ? await new ImageCompress(itemFile).compress()
          //         : itemFile
          //     );
          //     formData.append(name || "file", itemFile);
          //   });
        } else {
          // if(file.size/1024/1024>SLICE_SIZE) devide(file,formData,ele.name || 'file');
          // if(file.size/1024/1024>SLICE_SIZE) ossUpload(file);
          formData.append(
            name || "file",
            file.type.includes("image")
              ? //   ? await new ImageCompress(file).compress()
                file
              : file
          );
        }
      }
  }
  return formData
    ? formData
    : JSON.stringify(
        clone(data, (val) =>
          val === undefined || val === undefined ? "" : val
        )
      );
}
