import { Empty, Cascader, Spin, Divider, Space, Input, Typography } from "antd";
import { UngroupOutlined, PlusOutlined } from "@ant-design/icons";
import useFetchData from "./useFetchData";
import { useEffect, useState } from "react";
import { useRefState } from "@hooks";
import { useZIndex } from "@utils/hooks";
import { appendStyle } from "@utils/dom";
import { random } from "@utils/str";
export const NotFound = (data, title) =>
  data.length <= 0 ? (
    <div style={{ textAlign: "center" }}>
      <UngroupOutlined style={{ fontSize: "28px" }} />
      <div>暂无{title || ""}数据</div>
    </div>
  ) : (
    <div style={{ textAlign: "center" }}>
      <UngroupOutlined style={{ fontSize: "28px" }} />
      <div>暂未找到{title || ""}</div>
    </div>
  );
const Field = (props) => {
  const {
    name,
    title,
    valueEnum,
    value,
    request,
    query,
    isSearch,
    defaultFirstOption,
    create = false,
    searchCreate = false,
    labelName = "label",
    valueName = "value",
    filterOption = (input, option) => {
      return option.children.includes(input.toLowerCase());
    },
    options,
    onChange,
    onViewValueChange = () => {},
    ...restProps
  } = props;
  const { nextZIndex } = useZIndex();
  const [maskClass, setMaskClass] = useState(random(6, "k-antd-mask"));
  useEffect(() => {
    const el = appendStyle(`.${maskClass}{z-index:${nextZIndex()};}`);
    return () => {
      el.remove();
    };
  }, []);
  const [createName, setCreateName] = useState("");
  const { loading, data, setData, reload } = useFetchData(request, { query });
  const getViewValue = (e) =>
    e instanceof Array
      ? e
          .map((val) =>
            (options || data).find((item) => item[valueName] === val)
          )
          .map((item) => item && item[labelName])
          .filter(Boolean)
          .join(",")
      : (options || data).find((item) => item[valueName] == e) &&
        (options || data).find((item) => item[valueName] == e)[labelName];
  useEffect(() => {
    onViewValueChange(getViewValue(value));
  }, [value]);
  useEffect(() => {
    if (options) setData(options);
  }, [options]);
  useEffect(() => {
    if (data && data[0] && !restProps.value && defaultFirstOption)
      onChange(restProps.multiple ? [data[0][valueName]] : data[0][valueName]);
  }, [data]);
  const [search, setSearch] = useRefState([]);
  return (
    <Spin spinning={loading}>
      <Cascader
        showSearch
        allowClear
        placeholder={`请选择${title || ""}`}
        popupClassName={maskClass}
        notFoundContent={NotFound(data, title)}
        value={value || undefined}
        options={data}
        onChange={(e) => {
          onViewValueChange(getViewValue(e));
          onChange(
            e,
            data.find((d) => d[valueName] == e)
          );
        }}
        //搜索值可作为select内容
        {...(searchCreate
          ? {
              showSearch: true,
              onSearch: (e) => setSearch([...(search.current || []), e]),
              onBlur: () => {
                if (search.current.length < 2) return;
                const lastVal =
                  search.current[
                    search.current.length -
                      (restProps.notFoundContent === null ? 1 : 2)
                  ];
                onViewValueChange(lastVal);
                onChange(lastVal);
                setSearch([]);
              },
              onKeyUp: (e) => {
                if (search.current.length < 2) return;
                if (e.key === "Enter") {
                  const lastVal =
                    search.current[
                      search.current.length -
                        (restProps.notFoundContent === null ? 1 : 2)
                    ];
                  onViewValueChange(lastVal);
                  onChange(lastVal);
                  setSearch([]);
                }
              },
            }
          : {})}
        dropdownRender={(menu) =>
          create ? (
            typeof create === "function" ? (
              create(menu, reload)
            ) : (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Space align="center" style={{ padding: "0 8px 4px" }}>
                  <Input
                    placeholder="请输入添加项"
                    value={createName}
                    onChange={(e) => setCreateName(e.target.value)}
                  />
                  <Typography.Link
                    onClick={(e) => {
                      e.preventDefault();
                      if (createName !== undefined && createName !== "") {
                        setData([
                          { [labelName]: createName, [valueName]: createName },
                          ...data,
                        ]);
                        setCreateName("");
                      }
                    }}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <PlusOutlined /> 添加
                  </Typography.Link>
                </Space>
              </>
            )
          ) : (
            menu
          )
        }
        {...restProps}
      ></Cascader>
    </Spin>
  );
};
export default Field;
