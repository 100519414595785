/* eslint-disable */
export const toUpperCase = (str) => {
  if (str[0]) {
    return str.replace(str[0], str[0].toUpperCase());
  } else {
    return "";
  }
};

export const toLowerCase = (str) => {
  if (str[0]) {
    return str.replace(str[0], str[0].toLowerCase());
  } else {
    return "";
  }
};

// 驼峰转换下划线
export const toSQLLine = (str) => {
  if (str == "ID") return "ID";
  return str.replace(/([A-Z])/g, "_$1").toLowerCase();
};

// 下划线转换驼峰
export const toHump = (name) => {
  return name.replace(/\_(\w)/g, function (all, letter) {
    return letter.toUpperCase();
  });
};

/**
 * directive
 * 自定义指令：
 * hiddenDv
 * */
//remove all window valid api
function safeEval(
  code,
  validVariableName = (variable) => !["0", "safeEval"].includes(variable)
) {
  let safeCode = "";
  if (typeof window !== undefined) {
    Object.keys(window)
      .filter((variable) => Object.hasOwnProperty.call(window, variable))
      .filter(Boolean)
      .filter(validVariableName)
      .forEach((key) => (safeCode += `var ${key}=undefined;`));
  }
  return Function(`${safeCode}${code}`)();
}
export function parseCode(code, hasReturn = true) {
  return safeEval(`${hasReturn ? "return" : ""} ${code}`);
}
export function directive(code, data) {
  try {
    const operators = ["==", "===", "<", ">", "<=", ">=", "!="];
    return parseCode(`with(${JSON.stringify(data)}){return ${code};}`, false);
  } catch {
    return false;
  }
}
