// 是否全屏
export const isFullScreen = () =>
  document.fullScreen || document.webkitIsFullScreen || document.mozFullScreen;
/**  进入全屏
	 * if (this.isFullScreen) exitFullscreen();
                else requestFullscreen(document.querySelector(this.el||null));
                this.isFullScreen = !this.isFullScreen;
	 * */
export const requestFullscreen = (el = null) => {
  const ele = el || document.documentElement;
  const rfs =
    ele.requestFullscreen ||
    ele.webkitRequestFullscreen ||
    ele.mozRequestFullScreen ||
    ele.msRequestFullscreen;

  // 如果全屏，返回
  if (isFullScreen()) return;

  if (rfs) {
    rfs.call(ele);
  } else if (typeof window.ActiveXObject !== "undefined") {
    const wscript = new window.ActiveXObject("WScript.Shell");
    if (wscript) {
      wscript.SendKeys("{F11}");
    }
  }
};

// // 例子 1：整个网页全屏
// requestFullscreen();
// // 例子 2：视频部分全屏
// requestFullscreen(document.querySelector('#video'));
// 退出全屏
export const exitFullscreen = () => {
  const ele = document;
  const efs =
    ele.exitFullscreen || ele.webkitExitFullscreen || ele.mozCancelFullScreen;

  // 如果不是全屏，返回
  if (!isFullScreen()) return;

  if (efs) {
    efs.call(ele);
  } else if (typeof window.ActiveXObject !== "undefined") {
    const wscript = new window.ActiveXObject("WScript.Shell");
    if (wscript) {
      wscript.SendKeys("{F11}");
    }
  }
};
// 适配mac https://blog.csdn.net/hbhaida/article/details/107401518
