import utils from '../utils';
export default function (config) {
    let {
        url,
        method = "post",
        data = {},
        header = {},
        timeout = 1000 * 60
    } = config;
    let requestData=data;
        if (utils.isFormData(requestData)) {
            if(config.files){
                config.files.forEach(item=>{
                    requestData.append(item.name,item.file);
                })
            }
        }else{
            requestData=JSON.stringify(requestData);
        }
    return fetch({url,method,body:data});
}