import ReactDOM from "react-dom/client";
import emitter from "@emitter";
/**
 * component React Element或function component
 * getContainer是函数，返回HTMLElement。返回结果将作为plugins挂载的容器
 */
export function createInstance(
  component,
  { getContainer, isRender = true } = {}
) {
  let Component = component;
  const container = isRender
    ? document.createElement("div")
    : document.createDocumentFragment(); //无法render,处理atnd-modal（内部插入元素）
  if (typeof Component === "function")
    Component = <Component container={container}></Component>;
  if (getContainer) {
    const root = getContainer();
    root.appendChild(container);
  } else {
    document.body.appendChild(container);
  }
  const instance = ReactDOM.createRoot(container);
  instance.render(Component);
  // if (getContainer) {
  //   const root = getContainer();
  //   root.appendChild(div);
  // } else document.body.appendChild(div);
  emitter.on("locationChange", () => removeInstance(instance));
  return instance;
}
export function removeInstance(ReactInstance) {
  console.log("when remove", ReactInstance);
  if (ReactInstance) ReactInstance.unmount();
  //   if (HTMLDOM.parentNode) HTMLDOM.parentNode.removeChild(HTMLDOM);
  //   else if (HTMLDOM.remove) HTMLDOM.remove();
}
