import simpleList from '@/utils/request';
/**
 * 模块输出 https://juejin.cn/post/6844904154574356493
*/
const apiContext=require.context('./',true,/\.js$/),api={};
apiContext.keys().forEach(key=>{
    const apiName=key.split('/')[1].replace(/\.\w+$/,'');
    if(apiName==='index') return
    api[apiName] = simpleList(apiContext(key).default, apiName);
})
function getAPI(method, api) {
	method.split(/\W/).forEach(key => {
		api = api[key];
	});
	return api;
}
//     首字母小写
function initialLower(str) {
	const strF=str.slice(0, 1),strFLow=strF.toLocaleLowerCase()
	return (str.split(/\W/)[0]==='index'?str[0]:strFLow) + str.slice(1);
}
export default new Proxy(api, {
	get(target, key, receiver) {
		if (key.split(/\W/)[1]){
			if(target[initialLower(key).split(/\W/)[0]]&&getAPI(initialLower(key), target)) return getAPI(initialLower(key), target);
			// else  return rest=> api.base.http(Object.assign({},{url:key},rest));
			else return simpleList(key);
		}
		else return Reflect.get(target, initialLower(key), receiver);
	}
});