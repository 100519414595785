const TableCell = ({ row, rowIndex, column, ...rest }) => {
  if (column && column.disabled && column.disabled(row))
    return <td disabled={true}></td>;
  if (column && column.bodyCellProps)
    return <td {...rest} {...column.bodyCellProps(row, rowIndex, column)}></td>;
  if (column && column.bodyCellRender)
    return column.bodyCellRender(row, rowIndex, rest);
  return <td {...rest}></td>;
};
export default TableCell;
