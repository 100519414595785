/**
 * https://juejin.cn/post/6844903838185439246
 * 节流 一段时间内只执行一次  稀释操作频率高的动作
 * @param {*} fn
 * @param {*} time
 */
export default function (fn, time) {
  let isdelay = false;
  return function () {
    if (isdelay) return;
    setTimeout(() => {
      fn.apply(this, arguments);
      isdelay = false;
    }, time);
    isdelay = true;
  };
}
