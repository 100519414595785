import { useEffect, useState } from "react";
/**
 * element-plus/packages/lib/use-z-index/index.ts
 * element-plus/packages/hooks/use-global-config/index.ts
 * */
let zIndex = 0;

const initialZIndex = 1000; // TODO: move to @element-plus/constants
export default function useZIndex() {
  const [currentZIndex, setCurrentZIndex] = useState(null);
  const nextZIndex = () => {
    if (currentZIndex) return currentZIndex;
    zIndex++;
    const increaseZIndex = initialZIndex + zIndex;
    setCurrentZIndex(increaseZIndex);
    return increaseZIndex;
  };

  return {
    initialZIndex,
    currentZIndex,
    nextZIndex,
  };
}
