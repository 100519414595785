import jump from "./core/uJump";
import showLoading from "./core/uShowLoading";
import hideLoading from "./core/uHideLoading";
import showToast from "./core/uShowToast";
import showModal from "./core/uShowModal.js";
import getStorage from "./core/storage/uGetStorage";
import setStorage from "./core/storage/uSetStorage";
import removeStorage from "./core/storage/uRemoveStorage";
import chooseFile from "./core/uChooseFile";
import print from "./core/uPrint";
import Dom from "./core/uDom";
import uPlugin from "./core/uPlugin";
// 模拟函数重载
// function addMethod(object, name, fn) {
// 	var old = object[name];
// 	object[name] = function(...rest) {
// 		if (fn.length === rest.length) {
// 			return fn.apply(this, rest);
// 		} else if (typeof old === "function") {
// 			return old.apply(this, rest);
// 		}
// 	};
// }

/**
 * [uface description]    对于face API的封装
 * @type {Object}
 */
const uface = {
  jump,
  showLoading,
  hideLoading,
  showToast,
  //   showModal,
  getStorage,
  setStorage,
  removeStorage,
  chooseFile,
  print,
  Dom,
};

export default uPlugin(uface);
