import Table from "@components/Table";
import { uface } from "@widget";
import { Button } from "antd";
import { pick } from "@utils/field";
import { PlusOutlined } from "@ant-design/icons";
/**
 * 职务
 */
export default () => {
  const showOperate = (request, data, reload) => {
    uface.makeForm({
      title: (data ? "编辑" : "新增") + "职务",
      request,
      initialValue: data || {},
      columns: [
        {
          title: "职务名称",
          name: "job_name",
          formItemProps: {
            required: true,
          },
        },
      ],
      success() {
        reload();
      },
    });
  };
  const columns = [
    {
      title: "职务",
      key: "job_name",
    },
    {
      width: 100,
      title: "操作",
      valueType: "operation",
      render: (row, action) => [
        <a
          onClick={() =>
            showOperate(
              "CompanyUser/editJob",
              pick(row, "id", "job_name"),
              action.reload
            )
          }
        >
          编辑
        </a>,
        <a
          className="danger-color"
          onClick={() =>
            action.delete("CompanyUser/delJob", { id: row.id }, row.job_name)
          }
        >
          删除
        </a>,
      ],
    },
  ];
  const searchColumns = [{ name: "keywords", placeholder: "请输入职务搜索" }];
  return (
    <div>
      <Table
        columns={columns}
        request="CompanyUser/getJobList"
        search={{ columns: searchColumns }}
        toolbar={{
          operateRender: (action) => [
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() =>
                showOperate("CompanyUser/addJob", undefined, action.reload)
              }
            >
              新增
            </Button>,
          ],
        }}
      ></Table>
    </div>
  );
};
