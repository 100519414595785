import { Breadcrumb, Card, Button } from "antd";
import { useModel } from "@model";
import { Routes, Route } from "react-router-dom";
import React from "react";
import { handlerNestedPath } from "@utils/asyncRouter";
import { useMount } from "@hooks";
import Iconfont from "@components/Iconfont";
import { LeftOutlined } from "@ant-design/icons";
import uface from "@widget/uface";
import Link from "@components/Link";

const MyBreadcrumb = () => {
  const { getBreadcrumbList } = useModel("router", ({ getBreadcrumbList }) => ({
    getBreadcrumbList,
  }));
  const breadcrumbList = getBreadcrumbList(window.location.pathname);
  return breadcrumbList && breadcrumbList.length > 0 ? (
    <Card
      style={{ marginBottom: "14px", position: "sticky", top: 0, zIndex: 99 }}
      bodyStyle={{ padding: "16px 24px" }}
    >
      <div className="flex flex-middle">
        {breadcrumbList.filter((d) => d.Component).length > 1 ? (
          <>
            <div
              className="back-btn"
              onClick={() => uface.jump({ mode: "back" })}
            >
              <LeftOutlined />
              &nbsp;&nbsp;返回
            </div>
            <div
              style={{
                width: "1px",
                height: "22px",
                borderRight: "1px solid #DDDDDD",
                margin: "0 22px",
              }}
            ></div>
          </>
        ) : null}
        <Breadcrumb separator=">">
          {breadcrumbList.map((item) => (
            <Breadcrumb.Item key={item.id}>
              {/*{item.icon?<Iconfont type={item.icon}></Iconfont>:null}*/}
              {item.Component ? (
                <Link to={item.path}>{item.title}</Link>
              ) : (
                <span>{item.title}</span>
              )}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div>
    </Card>
  ) : null;
};
export default MyBreadcrumb;
