import typeUtil from "../../typeUtil";
/**
 * 处理返回值类型
 */
export default function (request) {
	//blob是XMLHTTPRequest内置类型
  if (request.responseType === "blob" || request.response instanceof Blob)
    return request.response;
  return typeUtil("isJSON")(request.responseText)
    ? JSON.parse(request.responseText)
    : request.responseText; //这里获取的是响应文本，也可以获得响应xml或JSON
}
