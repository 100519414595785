const list = {
  uploadPic: "Base/richPic", //新增
  // 信息类型
  addNewsType: "News/addNewsType",
  editNewsType: "News/editNewsType",
  delNewsType: "News/delNewsType",
  getNewsTypeList: "News/getNewsTypeList",
  // 公示信息
  addNews: "News/addNews",
  editNews: "News/editNews",
  getNewsList: "News/getNewsList",
  delNews: "News/delNews",
};

export default list;
