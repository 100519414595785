import "./index.less";
/**
 * docs:
 * https://panjiachen.github.io/vue-element-admin-site/feature/component/rich-editor.html#tinymce
 * https://www.tiny.cloud/docs/tinymce/6/react-cloud/
 */
import { Editor } from "@tinymce/tinymce-react";
import plugins from "./plugins";
import toolbar from "./toolbar";
import uface from "@widget/uface";

// why use this cdn, detail see https://github.com/PanJiaChen/tinymce-all-in-one
// const tinymceCDN = 'https://cdn.jsdelivr.net/npm/tinymce-all-in-one@4.9.3/tinymce.min.js'
// const tinymceCDN = 'https://cdn.tiny.cloud/1/dg3snijpxretiti4pvequml5wi8dtbu9lhzqofehazdrkfop/tinymce/5/tinymce.min.js'
// const tinymceCDN = 'https://cdn.jsdelivr.net/npm/tinymce-all@5.4.2/tinymce.min.js'

import htmlToWord from "./htmlToWord";
import api from "@api";
import { dataURLtoFile } from "@/utils/file";
import { useEffect, useRef, useState } from "react";
import { Spin } from "antd";
const languageTypeList = {
  en: "en",
  zh: "zh_CN",
  es: "es_MX",
  ja: "ja",
};
// Promise.allSettled ， polyfill
if (!Promise.allSettled) {
  const rejectHandler = (reason) => ({ status: "rejected", reason });
  const resolveHandler = (value) => ({ status: "fulfilled", value });
  Promise.allSettled = (promises) =>
    Promise.all(
      promises.map((promise) =>
        Promise.resolve(promise).then(resolveHandler, rejectHandler)
      )
      // 每个 promise 需要用 Promise.resolve 包裹下
      // 以防传递非 promise
    );
}
export default ({ fileName, height = 1000, value, onChange = () => {} }) => {
  const [loading, setLoading] = useState(true);
  const editorRef = useRef(null);
  const hasChange = useRef(null);
  const destroyTinymce = () => {
    if (editorRef.current) {
      editorRef.current.destroy();
    }
  };
  const change = () => {
    // 当父级页面请求到设置值完成可修改编辑器的内容，若手动修改编辑器内容再监听到watch变动会导致光标一直在首行
    /**
     * hasInit 防止window.tinymce没有初始化完成导致调用get报错
     * hasChange 当用户修改编辑器时不再更新value，防止用户修改的内容emit后又通过value重新setContent导致光标在首行
     */
    if (!this.hasChange && this.hasInit) {
      this.$nextTick(() => editorRef.current.setContent(value || ""));
    } else this.initValue = value; //防止tinymce未初始化导致modalValue传进的值丢失
  };

  const setContent = (value) => {
    editorRef.current.setContent(value);
  };
  const getContent = () => {
    return editorRef.current.getContent();
  };
  /**
   * https://blog.csdn.net/linchui22/article/details/111032429
   * https://www.tiny.cloud/docs/ui-components/typesoftoolbarbuttons/#basicbutton
   * https://segmentfault.com/a/1190000023456974
   * add bar https://www.tiny.cloud/docs/tinymce/6/custom-toolbarbuttons/
   */
  const addPlugin = (editor) => {
    // 注册一个菜单项名称 menu/menubar
    editor.ui.registry.addMenuItem("official", {
      text: "官网",
      onAction: function () {
        window.open("http://www.tyjfwy.com/");
      },
    });
    // 注册按钮
    editor.ui.registry.addButton("uploadimage", {
      // icon https://www.tiny.cloud/docs/advanced/editor-icon-identifiers/
      icon: "gallery",
      tooltip: "多图上传",
      onAction() {
        uface.makeForm({
          title: "多图上传",
          request: "base.uploadPic",
          columns: [
            {
              valueType: "upload:image",
              name: "file[]",
            },
          ],
          success(res) {
            res.data.forEach((v) =>
              editorRef.current.insertContent(
                `<img style='max-width:100%;' src="${v}" />`
              )
            );
          },
        });
      },
    });
    // pdf
    editor.ui.registry.addButton("uploadfile", {
      // icon https://www.tiny.cloud/docs/advanced/editor-icon-identifiers/
      icon: "document-properties",
      tooltip: "多文件上传",
      onAction() {
        // editorRef.current.insertContent(
        //   `<iframe width='100%' height='500' src="http://officialapi.tyjfwy.com/public/uploads/images/rich_pic/20230519/b0391d3b4bdf8e37c5d47412fbbf5035.pdf" ></iframe>`
        // );
        // return;
        uface.makeForm({
          title: "多文件上传",
          request: "base.uploadPic",
          columns: [
            {
              name: "title",
              title: "文件标题",
              formItemProps: {
                required: true,
              },
            },
            {
              valueType: "upload:file",
              name: "file[]",
            },
          ],
          success(res, form) {
            console.log("pdf", res);
            res.data.forEach((v) =>
              editorRef.current.insertContent(
                // `<iframe width='100%' height='500' src="${v}" ></iframe>`
                `<a href="${v}" target="_blank">${form.getFieldValue(
                  "title"
                )}</a>&nbsp;&nbsp;`
              )
            );
          },
        });
      },
    });
    editor.ui.registry.addButton("exportWord", {
      icon: "export",
      tooltip: "导出word",
      onAction() {
        let html = getContent();
        if (html)
          html = html.replace(/<img.*?>/g, (match) => {
            if (match) {
              const reg = /<img(.*)style="(.*?)"(.*)>/;
              const reg1 = /<img(.*)width="(.*?)"(.*)>/;
              if (reg1.test(match)) {
                const [match1, s1, s2, s3] = match.match(reg1);
                return `<img${s1} width="${s2 > 600 ? 600 : s2}" ${s3}>`;
              } else if (reg.test(match)) {
                const [match1, s1, s2, s3] = match.match(reg);
                return `<img${s1} style="${s2}max-width:100%;" width="600" ${s3}>`;
              } else {
                const [match1, s1] = match.match(/<img(.*)>/);
                return `<img style="max-width:100%;" width="600" ${s1}>`;
              }
            } else return match;
            return process.env.NODE_ENV === "development"
              ? match
              : match.replace("http", "http");
          });
        console.log("html", html);
        htmlToWord({
          html,
          fileName,
        });
      },
    });
  };
  useEffect(() => {
    return () => {
      destroyTinymce();
    };
  }, []);
  useEffect(() => {
    if (hasChange.current) {
      hasChange.current = false;
      return;
    }
    if (editorRef.current) editorRef.current.setContent(value);
  }, [value]);
  return (
    <Spin spinning={loading} wrapperClassName="mw-100">
      <Editor
        apiKey="dg3snijpxretiti4pvequml5wi8dtbu9lhzqofehazdrkfop"
        onInit={(evt, editor) => (editorRef.current = editor)}
        // initialValue={value}
        init={{
          contextmenu: "link | official", //右键菜单
          branding: false, // （隐藏右下角技术支持）水印“Powered by TinyMCE”
          // statusbar: false,//隐藏底部状态栏
          content_style: "img {max-width:100%;} p{margin:0;padding:0;}", //限制插入的图片大小
          paste_data_images: true, //图片是否可粘贴
          language: languageTypeList["zh"],
          height,
          body_class: "panel-body",
          font_css: "/font/font.css",
          object_resizing: false,
          toolbar, //分组工具栏控件
          menubar: "file edit insert view format table", //菜单栏
          //   配置中文字体 https://www.tiny.cloud/docs/tinymce/6/user-formatting-options/#preview_styles
          font_size_formats:
            "8px 9px 10px 11px 12px 13px 14px 15px 16px 17px 18px 24px 36px",
          font_family_formats:
            "微软雅黑='微软雅黑';宋体='宋体';黑体='黑体';仿宋='仿宋';楷体='楷体';隶书='隶书';方正准圆简体='方正准圆简体';方正粗黑宋简体='方正粗黑宋简体';幼圆='幼圆';Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings",
          toolbar_sticky: true,
          toolbar_sticky_offset: 120,
          //   external_plugins: {
          //     //     //    https://github.com/9kg/tinymce_powerpaste
          //     toolbarsticky:
          //       (window.location.origin.includes("localhost")
          //         ? "http://officialmanage.tyjfwy.com"
          //         : window.location.origin) +
          //       "/plugin/toolbarsticky/plugin.min.js",
          //   },
          //     powerpaste_word_import: "propmt", // 参数可以是propmt, merge, clear，效果自行切换对比
          //     powerpaste_html_import: "propmt", // propmt, merge, clear
          //     powerpaste_allow_local_images: true,
          //     paste_data_images: true,
          plugins: plugins, //插件(比如: advlist | link | image | preview等)
          //选中时出现的快捷工具，与插件有依赖关系
          quickbars_selection_toolbar:
            "bold italic forecolor | link blockquote quickimage",
          end_container_on_empty_block: true,
          // powerpaste_word_import: 'clean',
          //   powerpaste_word_import: "merge", // 是否保留word粘贴样式 clean | merge
          code_dialog_height: 450,
          code_dialog_width: 1000,
          advlist_bullet_styles: "square",
          advlist_number_styles: "default",
          default_link_target: "_blank",
          link_title: false,
          nonbreaking_force_tab: true, // inserting nonbreaking space &nbsp; need Nonbreaking Space Plugin
          init_instance_callback: (editor) => {
            setLoading(false);
            if (value) editor.setContent(value);
            //   editor.on('NodeChange',()=>{
            // 	  console.log('a1')
            //   })
            //   editor.on('Change',()=>{
            // 	  console.log('a2')
            //   })
            //   editor.on('KeyUp',()=>{
            // 	  console.log('a3')
            //   })
            //   editor.on('SetContent',()=>{
            // 	  console.log('a4')
            //   })
            //360浏览器出现初始化时NodeChange被触发，导致hasChange为true，modalValue无法setContent
            //   editor.on('NodeChange Change KeyUp SetContent', () => {
            editor.on("Change KeyUp SetContent", () => {
              hasChange.current = true;
              onChange(editor.getContent());
              //   hasChange.current = false; //防止引起modalValue变化导致SetContent进入循环  更改完立即变更，防止外部更改无法更新
            });
          },
          setup(editor) {
            addPlugin(editor);
          },
          // it will try to keep these URLs intact
          // https://www.tiny.cloud/docs-3x/reference/configuration/Configuration3x@convert_urls/
          // https://stackoverflow.com/questions/5196205/disable-tinymce-absolute-to-relative-url-conversions
          convert_urls: false,
          /**
           * {//自定义插入图片函数 blobInfo: 本地图片blob对象, {base64,blob,blobUri,filename,name,uri,id}
           * succFun(url|string)： 成功回调（插入图片链接到文本中）,
           * failFun(string)：失败回调
           */
          images_upload_handler(blob) {
            // const base64=blob.base64();
            // console.log(dataURLtoFile('data:image/jpeg;base64,'+blob.base64(),blob.filename()),blob.filename())
            // console.log(blob,file)
            // success('https://t7.baidu.com/it/u=1819248061,230866778&fm=193&f=GIF')
            return new Promise((resolve) => {
              api.base
                .uploadPic({
                  //base格式 data:image/jpeg;base64,base64码
                  files: [
                    {
                      file: dataURLtoFile(
                        "data:image/jpeg;base64," + blob.base64(),
                        blob.filename()
                      ),
                      name: "file",
                    },
                  ],
                })
                .then(({ data: list }) => {
                  resolve(list[0]);
                });
            });
          },
        }}
      ></Editor>
    </Spin>
  );
};
