import { Empty, Spin } from "antd";
import { download, isOffice } from "@utils/file";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import Image from "@components/Image";
import uface from "@widget/uface";
import Office from "@components/Office";
import "./index.less";
import { useRef, useState } from "react";
function Frame({ src }) {
  const [loading, setLoading] = useState(true);
  const iframe = useRef(null);
  return (
    <Spin spinning={loading} wrapperClassName="h-100">
      <iframe
        ref={iframe}
        src={
          isOffice(src)
            ? `https://view.officeapps.live.com/op/view.aspx?src=${src}`
            : src
        }
        style={{
          width: "100%",
          height: "100%",
        }}
        onLoad={() => {
          setLoading(false);
          const iframeDoc =
            iframe.current &&
            (iframe.current.contentWindow || iframe.current.contentDocument);
          console.log(iframeDoc.document);
          //   if (iframeDoc && iframeDoc.document)
          //     iframeDoc.document.body.style = {
          //       backgroundColor: "#fff",
          //       color: "#000",
          //     };
        }}
      ></iframe>
    </Spin>
  );
}
const Media = ({ data = [] }) => {
  return data.length > 0 ? (
    <div className="media-list">
      {data.map((d) =>
        d.type == 1 ? (
          <Image key={d.id} src={d.full_path}></Image>
        ) : d.type == 3 ? (
          <video key={d.id} src={d.full_path} controls>
            <source src={d.src} type="video/mp4" />
            <source src={d.src} type="video/ogg" />
            <source src={d.src} type="video/webm" />
          </video>
        ) : (
          <div key={d.id} className="file-item">
            {d.title}
            <div className="my-mask">
              <div
                className="flex flex-middle"
                onClick={() =>
                  uface.showModal({
                    title: d.title,
                    width: "80vw",
                    height: "80vh",
                    footer: null,
                    // content: <Frame src={d.full_path}></Frame>,
                    content: <Office url={d.full_path}></Office>,
                  })
                }
              >
                预览
                <EyeOutlined
                  style={{ fontSize: "22px", color: "#fff", marginLeft: "6px" }}
                />
              </div>
              <div
                className="flex flex-middle"
                onClick={() => download({ url: d.full_path, name: d.title })}
              >
                下载
                <DownloadOutlined
                  style={{ fontSize: "22px", color: "#fff", marginLeft: "6px" }}
                />
              </div>
            </div>
          </div>
        )
      )}
    </div>
  ) : (
    <Empty description="暂无附件"></Empty>
  );
};
export default Media;
