
import {
    storage,
} from '@config'
import storageCache from './storageCache';
export default function removeStorage(key) {
    key = window.location.host+'-'+key;
	delete storageCache[key];
    if (storage) {
        if ('localStorage' === storage) {
            return localStorage.removeItem(key)
        } else if ('sessionStorage' === storage) {
            return sessionStorage.clear()
        } else {
            return localStorage.removeItem(key)
        }
    } else {
        return localStorage.removeItem(key)
    }
}