import { Card, Table, Space, Button } from "antd";
import useFetchData from "./useFetchData";
import React, {
  useEffect,
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
  useMemo,
} from "react";
import { pageParams } from "@config";
import ToolBar from "./ToolBar";
import ResizeableTitle from "./ResizeableTitle";
import TableCell from "./TableCell";
import Form, { useForm, FormMaker } from "../Form";
import { PlusCircleOutlined } from "@ant-design/icons";
import { directive } from "@utils/stringFun";
import { isPromise } from "@utils/is";
import { getOffsetTop, contains } from "@utils/dom";
import "./index.less";
import uface from "@widget/uface";
import purefn from "@widget/purefn";
const { Field } = FormMaker;
/**
 * @description Table渲染实现
 * @param editable {
 *   form  可编辑表格的 form 实例，使用 Form.useForm 生成后使用
 * }
 *
 */
const TableRender = (props) => {
  const {
    components,
    beforeRender,
    action,
    toolBarDom,
    toolBarOptions,
    editable,
    formInstance,
    ...rest
  } = props;
  //   const [formInstance] = useForm(editable?.form, {
  //     identity: "Table",
  //     canUpdate: true,
  //   });
  const handleColumns = (columns, i = "") =>
    columns.filter(Boolean).map((item, index) => {
      const rowKey = `${i}-${index}-${item.key}`; //唯一标识key,防止字段重复
      if (item.children)
        return {
          ellipsis: true,
          align: item.align || (rest.bordered ? "center" : undefined),
          ...item,
          children: handleColumns(item.children),
        };
      return {
        className: index % 2 === 0 ? "table-even-row" : "table-odd-row",
        ellipsis: true,
        align: item.align || (rest.bordered ? "center" : undefined),
        ...item,
        dataIndex: item.key,
        key: rowKey,
        onHeaderCell: (column) => ({
          width: column.width,
          slash: column.slash,
          onResize: (_, { size }) =>
            props.setColumns((prevColumns) =>
              prevColumns.map((d, i) => ({
                ...d,
                width: i == index ? size.width : d.width,
              }))
            ),
        }),
        onCell: (row, rowIndex) => ({ row, rowIndex, column: item }),
        ...(item.valueType === "operation"
          ? {
              fixed: "right",
              render: (_, row) => {
                const renderDom = item
                  .render(row, action)
                  .filter(Boolean)
                  .map((child, i) =>
                    React.cloneElement(child, { key: i, ...child.props })
                  );
                return <Space>{renderDom}</Space>;
              },
            }
          : editable
          ? {
              render: item.render
                ? (_, row, index) => {
                    const {
                      hidden,
                      disabled,
                      formItemProps,
                      render,
                      ...restItem
                    } = item;
                    const name = [index, restItem.key];
                    const FieldContent = (
                      <Field
                        name={name}
                        // bordered={false}
                        formItemProps={Object.assign(
                          {},
                          { showLabel: false },
                          formItemProps
                        )}
                        {...restItem}
                      ></Field>
                    );
                    return item.render(
                      formInstance.getStore()[index] || {},
                      FieldContent
                    );
                  }
                : (_, row, index) => {
                    // 避免因为hidden加到html属性上导致无法显示
                    const {
                      hidden,
                      disabled,
                      editable = true,
                      formItemProps,
                      readonly,
                      render,
                      ...restItem
                    } = item;
                    const name = [index, restItem.key];
                    console.log("readonly", readonly, name);
                    if (readonly) {
                      if (typeof readonly === "function" && readonly(row))
                        return <Field readonly={readonly} name={name}></Field>;
                      return <Field readonly={readonly} name={name}></Field>;
                    }
                    const FieldContent = (
                      <Field
                        name={name}
                        // bordered={false}
                        disabled={
                          typeof disabled === "function"
                            ? disabled(row)
                            : !!disabled
                        }
                        formItemProps={Object.assign(
                          {},
                          { showLabel: false },
                          formItemProps
                        )}
                        {...restItem}
                      ></Field>
                    );
                    const isHidden = hidden
                      ? directive(hidden, formInstance.getStore()[index])
                      : false;
                    return isHidden ? null : FieldContent;
                  },
            }
          : item.render
          ? {
              render: (_, row, i) => item.render(row, action, i),
            }
          : {}),
      };
    });
  const getTableProps = () => ({
    ...rest,
    size: rest.size || (editable ? "small" : "middle"),
    rowKey: editable ? "autoID" : rest.rowKey || "id",
    loading: action.loading,
    columns: handleColumns(props.columns),
    dataSource: action.data,
    pagination: action.pagination,
    onChange: (pagination, filters, sorter) => {
      action.onPageChange(pagination, filters, sorter);
    },
    components: {
      header: {
        cell: ResizeableTitle,
      },
      body: {
        cell: TableCell,
      },
    },
  });
  const tableProps = getTableProps();
  const baseTable = (
    <Table
      {...tableProps}
      className={
        "base-table " +
        (tableProps.className || "") +
        (rest.onRow ? " on-row" : "")
      }
    ></Table>
  );
  const CreateButton = useMemo(() => {
    return ({ isWrapper = true, style = {}, onClick, ...props }) => {
      const btn = (
        <Button
          //    ghost
          // style={{color:'#494d61'}}
          type="text"
          icon={<PlusCircleOutlined />}
          className="create-button"
          onClick={(e) => (onClick ? onClick(e) : action.addData())}
          {...props}
        >
          {editable.createText || "添加数据"}
        </Button>
      );
      return isWrapper ? (
        <div className="create-button-wrapper" style={style}>
          {btn}
        </div>
      ) : (
        btn
      );
    };
  }, [action.data]);
  const createButtonDom = editable?.createRender ? (
    editable.createRender(CreateButton, action)
  ) : (
    <CreateButton></CreateButton>
  );
  const isFormChanged = useRef(null);
  const tableContent = editable ? (
    <>
      <Form
        component={components && components.form}
        form={formInstance}
        onChange={(...e) => {
          editable.onChange && editable.onChange(...e);
          return;
          isFormChanged.current = true;
          //    * 由于form可能修改非表单控件（如表格仅显示字段，而不使用Form Field,场景：某些计算合计），需要table实时render
          //    * primary key不能变，否则引起form field dom重新渲染,光标失去焦点
          const formData = formInstance.getStore();
          console.log("render table form", formData);
          if (!purefn.deepEqual(formData, action.getData()))
            action.setData(formData, { autoID: false });
          isFormChanged.current = false;
          //   浏览器缓存
          if (editable.storage && editable.storage.key)
            uface.setStorage(editable.storage.key, formData);
        }}
        onUpdateValue={(e) => {
          if (editable.onChange) editable.onChange(e);
          if (editable.onUpdateValue) editable.onUpdateValue(e);
        }}
      >
        {baseTable}
      </Form>
      {createButtonDom}
    </>
  ) : (
    baseTable
  );
  //   useEffect(() => {
  //     if (editable) {
  //       if (
  //         isFormChanged.current &&
  //         purefn.deepEqual(action.data, formInstance.getStore())
  //       )
  //         return;
  //       if (
  //         editable.storage &&
  //         editable.storage.key &&
  //         uface.getStorage(editable.storage.key)
  //       )
  //         formInstance.setStore(uface.getStorage(editable.storage.key));
  //       // 只在table传入的datasource初始化form以及data变更，未在form变更时赋值table data-source table只是起容器作用
  //       else formInstance.setStore(action.data);
  //     }
  //   }, [action.data]);
  const Wrapper = components && components.wrapper ? components.wrapper : Card;
  const tableArea = toolBarOptions ? (
    <Wrapper className="table-area">
      {beforeRender && beforeRender(action)}
      {toolBarDom}
      {tableContent}
    </Wrapper>
  ) : (
    <>
      {beforeRender && beforeRender(action)}
      {tableContent}
    </>
  );
  const renderTable = () => {
    if (props.tableRender) return props.tableRender(props, tableArea);
    return tableArea;
  };
  return renderTable();
};
// 筛选项
const getFilterColumns = (searchProps = {}, propsColumns) =>
  (searchProps.columns || [])
    .concat(
      propsColumns
        .filter(
          (item) => item.key && item.search && item.valueType !== "operation"
        )
        .map((item) => ({ ...item, name: item.key }))
    )
    //表单项用到search，不能传递给下层
    .map(({ search, ...item }, index) => ({
      ...item,
      sort: item.sort === undefined ? index : item.sort,
    }))
    .sort((a, b) => a.sort - b.sort);
const FormRender = ({
  after,
  search = {},
  form,
  columns: propsColumns,
  onSubmit,
}) => {
  const columns = getFilterColumns(search, propsColumns).map((item, index) => ({
    ...item,
    hidden: index > 2,
  }));
  return columns.length > 0 ? (
    // <div className="table-search">
    <FormMaker
      grid={false}
      after={after}
      layoutType="QueryFilter"
      columns={columns}
      submitter={false}
      form={form}
      onSubmit={onSubmit}
    ></FormMaker>
  ) : // </div>
  null;
};
function translateClassList(classList) {
  let temp = "";
  for (let i = 0; i < classList.length; i++) {
    temp += "." + classList[i];
  }
  return temp;
}
/**
 * 表单enter下一行聚焦
 */
function bindEnter(e) {
  if (!e.target.className.includes("ant-input")) return;

  const nodeClass =
    e.target.nodeName.toLocaleLowerCase() +
    translateClassList(e.target.classList);
  const tdDom = new uface.Dom({ el: e.target }).parent("td");
  let index = tdDom.index() + 1;
  if (e.key === "Enter" || e.key === "ArrowDown") {
    e.preventDefault();
    e.stopPropagation();
    let nextTr = tdDom.el.parentNode.nextSibling,
      nextTd = nextTr && nextTr.querySelector(`td:nth-child(${index})`),
      nextFormField;
    if (!nextTr) return;
    if (nextTd && nextTd.querySelector(nodeClass)) {
      nextFormField = nextTd.querySelector(nodeClass);
    } else {
      while (!nextTd && nextTd.querySelector(nodeClass)) {
        nextTr = nextTr.nextSibling;
        nextTd = nextTr.querySelector(`td:nth-child(${index})`);
      }
      nextFormField = nextTd.querySelector(nodeClass);
    }
    if (nextFormField) nextFormField.focus();
  } else if (e.key === "ArrowUp") {
    e.preventDefault();
    e.stopPropagation();
    let prevTr = tdDom.el.parentNode.previousSibling,
      prevTd = prevTr && prevTr.querySelector(`td:nth-child(${index})`),
      prevFormField;
    if (!prevTr) return;
    if (prevTd && prevTd.querySelector(nodeClass)) {
      prevFormField = prevTd.querySelector(nodeClass);
    } else {
      while (!prevTd && prevTd.querySelector(nodeClass)) {
        prevTr = prevTr.previousSibling;
        prevTd = prevTr.querySelector(`td:nth-child(${index})`);
      }
      prevFormField = prevTd.querySelector(nodeClass);
    }
    if (prevFormField) prevFormField.focus();
  }
}
/**
 * @description Table 特性实现
 * @param search 是否显示搜索表单，传入对象时为搜索表单的配置
 *     @search filterType  searchText
 * @param columns
 *   @columns [title 表头
 *    key 映射字段
 *   search 配置列的搜索相关，false 为隐藏
 * ]
 *components 覆盖默认的 Table 元素
 *  form
 */
const TableProvider = (props, ref) => {
  const {
    pagination: propsPagination,
    dataSource,
    defaultData,
    request,
    autoRequest,
    query,
    search,
    columns: columnsProp,
    headerTitle,
    toolbar = {},
    style,
    ...rest
  } = props;
  const {
    operateRender: operateBarRender,
    render: toolBarRender,
    afterRender: afterToolBarRender,
    options: toolBarOptions = { reload: true, setting: true, fullScreen: true },
  } = toolbar;
  const pagination =
    typeof propsPagination === "object"
      ? propsPagination
      : {
          hideOnSinglePage: true,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `第${range[0]}-${range[1]}条  总共${total}条`,
          defaultCurrent: 1,
          defaultPageSize: pageParams.pageSize,
          pageSize: pageParams.pageSize,
          current: 1,
          pageSizeOptions: [10, 15, 20, 50, 100],
        };
  const [formInstance] = useForm(rest?.editable?.form);
  const [queryFormInstance] = useForm(search?.form);
  const tableRef = useRef(null);

  const ajustHeight = () => {
    const tableEl =
      tableRef.current && tableRef.current.querySelector(".ant-table");
    const offsetTop = getOffsetTop(tableEl),
      subHeight = offsetTop + 100;
    setTimeout(() => {
      if (!tableEl) return;
      console.log(
        "ayayayayya",
        subHeight,
        tableEl.scrollHeight,
        document.body.clientHeight,
        tableEl.scrollHeight > document.body.clientHeight - subHeight,
        contains(document.querySelector(".admin-box"), tableEl)
      );
      if (
        offsetTop < document.body.clientHeight &&
        contains(document.querySelector(".admin-box"), tableEl) &&
        tableEl.scrollHeight > document.body.clientHeight - subHeight
      )
        setScroll({
          x: "100%",
          y:
            document.body.clientHeight - subHeight < 400
              ? 300
              : document.body.clientHeight - subHeight,
        });
      else setScroll({ x: "100%" });
    }, 100);
  };
  const action = useFetchData({
    request,
    autoRequest,
    query,
    syncSearchParam: !!search,
    form: formInstance,
    defaultData,
    dataSource,
    pageSize: rest?.editable?.pageSize || 10,
    pagination,
    queryFormInstance,
    ajustHeight,
    editable: rest?.editable,
  });
  const [columns, setColumns] = useState(
    typeof columnsProp === "function"
      ? columnsProp(action)
      : columnsProp.filter(Boolean)
  );
  useImperativeHandle(ref, () => action);
  useEffect(() => {
    if (typeof columnsProp === "function") {
      const columnsRes = columnsProp(action).filter(Boolean);
      if (isPromise(columnsRes))
        columnsRes.then((res) => setColumns(res.filter(Boolean)));
      else setColumns(columnsRes.filter(Boolean));
    } else setColumns(columnsProp.filter(Boolean));
  }, [columnsProp]);
  useEffect(() => {
    if (typeof columnsProp === "function") {
      const columnsRes = columnsProp(action).filter(Boolean);
      if (isPromise(columnsRes))
        columnsRes.then((res) => setColumns(res.filter(Boolean)));
      else setColumns(columnsRes.filter(Boolean));
    }
  }, [action.data]);
  const searchArea = ({ after }) =>
    search ? (
      <FormRender
        after={after}
        form={queryFormInstance}
        columns={columns}
        search={search}
        loading={action.loading} //TODO 当loading禁用
        onSubmit={search.onSubmit ? search.onSubmit : action.reload}
      ></FormRender>
    ) : null;
  const toolbarArea =
    toolBarRender === false ? (
      afterToolBarRender ? (
        afterToolBarRender(action)
      ) : null
    ) : (
      <>
        <ToolBar
          queryForm={queryFormInstance}
          filterColumns={getFilterColumns(search, columns)}
          searchArea={searchArea}
          headerTitle={headerTitle}
          operateBarRender={operateBarRender}
          toolBarRender={toolBarRender}
          columns={columns}
          toolBarOptions={toolBarOptions}
          action={action}
          onColumnChange={(e) => setColumns(e)}
        ></ToolBar>
        {afterToolBarRender ? afterToolBarRender(action) : null}
      </>
    );
  const [scroll, setScroll] = useState(rest.scroll || { x: "100%" });
  useEffect(() => {
    //   在admin-box上注册事件防止组件更新后DOM更新，无法监听事件
    const wrapperEl =
      tableRef.current && tableRef.current.querySelector(".ant-table");
    if (wrapperEl) wrapperEl.addEventListener("keyup", bindEnter);

    return () => {
      wrapperEl.removeEventListener("keyup", bindEnter);
    };
  }, [action.data]);
  useEffect(() => {
    if (rest.scroll) return;
    //编辑模式下，scroll改变会导致Form子组件重新加载
    if (rest.editable) return;
    ajustHeight();
    const ajustHeightD = purefn.debounce(ajustHeight, 600);
    window.addEventListener("resize", ajustHeightD);
    return () => {
      if (rest.editable) window.removeEventListener("resize", ajustHeightD);
    };
  }, [action.data]);
  return (
    <div className="pro-table" style={style} ref={tableRef}>
      {/* {searchArea}*/}
      <TableRender
        bordered={
          rest.bordered === undefined
            ? rest.editable
              ? true
              : false
            : rest.bordered
        }
        toolBarDom={toolbarArea}
        toolBarOptions={toolBarOptions}
        columns={columns}
        setColumns={setColumns}
        action={action}
        scroll={scroll}
        formInstance={formInstance}
        {...rest}
      ></TableRender>
    </div>
  );
};
export default forwardRef(TableProvider);
export * from "./components";
