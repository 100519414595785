import { FilterOutlined } from "@ant-design/icons";
import { Popover, Checkbox, Button } from "antd";
import Form, { FormMaker, useForm } from "@components/Form";
import { useEffect, useState } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import { isSameName, set, get } from "@utils/field";
import "./index.less";
const Field = FormMaker.Field;
const ViewFilter = ({ style = {}, columns, form, onChange } = {}) => {
  const viewStore = form.getRaw("text");
  const viewFilter = Object.keys(viewStore)
    .filter((key) => viewStore[key] !== undefined && viewStore[key] !== "")
    .map((key) => ({
      ...columns.find((item) => isSameName(item.name, key)),
      value: viewStore[key],
    }))
    .filter((d) => d.checked);
  return (
    <div className="filter-view" style={style}>
      {viewFilter.map((item, index) => (
        <div className="item" key={index}>
          <span className="title"> {item.title && `${item.title}：`}</span>
          <span className="text">{item.value}</span>

          <CloseCircleOutlined
            style={{ fontSize: "14px", color: "#3e3d3d" }}
            onClick={() => {
              form.setFieldValue(item.name, undefined);
              onChange(
                columns.map((c) => ({
                  ...c,
                  checked: isSameName(item.name, c.name) ? false : c.checked,
                }))
              );
            }}
          />
        </div>
      ))}
      {viewFilter.length > 0 ? (
        <div
          className="flex flex-middle blue-color pointer"
          onClick={() => {
            columns.forEach((item) => form.setFieldValue(item.name, undefined));
            onChange(columns.map((d) => ({ ...d, checked: false })));
          }}
        >
          清除筛选
        </div>
      ) : null}
    </div>
  );
};
/**
 * columns:
 * [
    {
      title: "账户名",
      name: "account_name",
    },
  ]
*/
const Filter = ({
  children,
  columns: propsColumns,
  onChange: onPropsChange = () => {},
  ...rest
}) => {
  const [form] = useForm();
  const [open, setOpen] = useState(false);
  const [columns, setColumns] = useState(
    propsColumns.map((d) => ({ ...d, checked: false }))
  );
  useEffect(() => {
    setColumns(
      propsColumns.map((d) => ({
        ...d,
        checked: columns.find((e) => isSameName(e.name, d.name)).checked,
      }))
    );
  }, [propsColumns]);
  const onChange = (e, columns) => {
    const checkedName = columns.filter((d) => d.checked).map((d) => d.name);
    onPropsChange(
      checkedName.reduce((accu, cur) => {
        set(accu, cur, get(e, cur));
        return accu;
      }, {})
    );
  };
  const content = (
    <div className="filter-wrap">
      <div className="operate">
        <Button
          onClick={() => {
            const columnsChanged = columns.map((d) => ({
              ...d,
              checked: false,
            }));
            form.clearFieldsValue();
            onChange(form.getFieldsValue(), columnsChanged);
            setOpen(false);
            setColumns(columnsChanged);
          }}
        >
          清除
        </Button>
        <Button
          type="primary"
          onClick={() => {
            onChange(form.getFieldsValue(), columns);
            setOpen(false);
          }}
        >
          完成
        </Button>
      </div>
      <div className="filter-content">
        <Form form={form}>
          {columns.map((d) => {
            const { checked, title, ...p } = d;
            return (
              <div key={d.name} className="item">
                <div>
                  <Checkbox
                    checked={checked}
                    onChange={(e) =>
                      setColumns(
                        columns.map((c) => ({
                          ...c,
                          checked: isSameName(d.name, c.name)
                            ? e.target.checked
                            : c.checked,
                        }))
                      )
                    }
                  >
                    {d.title}
                  </Checkbox>
                </div>
                <Field
                  {...p}
                  formItemProps={{
                    shouladUpdate: true,
                    style: { height: checked ? "32px" : 0 },
                  }}
                />
              </div>
            );
          })}
        </Form>
      </div>
    </div>
  );
  return (
    <div className="pop-filter">
      <Popover
        trigger="click"
        placement="right"
        overlayClassName="filter-popup"
        overlayInnerStyle={{ padding: 0 }}
        open={open}
        content={content}
        {...rest}
      >
        <div onClick={() => setOpen(!open)}>
          {children ? (
            children
          ) : (
            <Button type="primary" ghost icon={<FilterOutlined />}>
              筛选
            </Button>
          )}
        </div>
      </Popover>
      <ViewFilter
        form={form}
        columns={columns}
        onChange={(e) => {
          onChange(form.getFieldsValue(), e);
          setColumns(e);
        }}
      ></ViewFilter>
    </div>
  );
};
export default Filter;
