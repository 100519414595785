import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import locale from "antd/lib/locale/zh_CN";
import { DatePicker, ConfigProvider } from "antd";
import { useMemo, useState, useEffect } from "react";
import { useZIndex } from "@utils/hooks";
dayjs.locale("zh-cn");
export default ({
  title,
  value,
  disabledStartDate,
  onViewValueChange = () => {},
  onChange,
  valueFormater = (picker) =>
    ({
      year: "YYYY",
      month: "YYYY-MM",
      time: "HH:mm",
    }[picker] || "YYYY-MM-DD"),
  ...rest
}) => {
  const { nextZIndex } = useZIndex();
  useEffect(() => {
    onViewValueChange(value);
  });
  //   const [momentDate, setMomentDate] = useState(value ? moment(value) : value);
  const momentDate = useMemo(
    () => (value ? dayjs(value, valueFormater(rest.picker)) : value),
    [value]
  );
  return (
    <ConfigProvider locale={locale}>
      <DatePicker
        value={momentDate}
        placeholder={`请选择${title || ""}`}
        popupStyle={{
          zIndex: nextZIndex(),
        }}
        disabledDate={(currentDate) =>
          disabledStartDate
            ? dayjs(currentDate).isBefore(disabledStartDate)
            : false
        }
        onChange={(_, date) => {
          //   setMomentDate(_);
          onViewValueChange(date);
          onChange(date);
        }}
        {...rest}
      ></DatePicker>
    </ConfigProvider>
  );
};
