import { Button, Popover } from "antd";
import {
  PlusOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import "./index.less";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMount, useDrag } from "@hooks";
import purefn from "@widget/purefn";
import classNameFn from "@utils/className";
import { random } from "@utils/str";
const isValid = (val) => val !== null && val !== undefined;
const renderContent = (content, ...rest) =>
  isValid(content)
    ? typeof content === "function"
      ? content(...rest)
      : content
    : null;
// const addIDForTree = (treeData) =>
//   treeData.reduce((accu, cur) => {
//     accu.push({
//       ...cur,
//       id: Math.floor(Math.random() * 100000),
//       children:
//         cur.children && cur.children.length > 0
//           ? addIDForTree(cur.children)
//           : null,
//     });
//     return accu;
//   }, []);
export function randomID() {
  //   return Math.floor(Math.random() * 100000);
  return random();
}

Array.prototype.deepReduce = function (fn, initial) {
  const initialBak = purefn.clone(initial);
  return this.reduce((accu, cur, ...rest) => {
    return fn(
      accu,
      {
        ...cur,
        children:
          cur.children && cur.children.length > 0
            ? cur.children.deepReduce(fn, [])
            : null,
      },
      ...rest
    );
  }, []);
};
const TreeFlowItem = ({ node, children, className, operates, onChange }) => {
  const createButton = (
    <Button type="primary" shape="circle" icon={<PlusOutlined />}></Button>
  );
  return (
    <div
      className={classNameFn("node-wrap", className)}
      style={node.style || {}}
    >
      <div className="node-vertical-line"></div>
      <div className="node" style={node.nodeStyle || {}}>
        <div className="title" style={node.titleStyle || {}}>
          <div>{node.title}</div>
          <CloseOutlined
            className="close-icon"
            onClick={(e) => {
              e.stopPropagation();
              const parent = operates.deepFind((e) =>
                e.children && e.children.length > 0
                  ? e.children.find((item) => item.id == node.id)
                  : false
              );
              if (node.onDeleteNode) {
                node.onDeleteNode(node, parent);
                return;
              }
              onChange &&
                onChange(
                  parent.children.length == 2
                    ? operates.setNode(parent.id, { ...parent, children: [] })
                    : node.children && node.children.length == 1
                    ? operates.setNode(node.id, node.children[0])
                    : operates.delNode(node.id)
                );
            }}
          />
        </div>
        <div className="content">{renderContent(node.content, node)}</div>
        {node.validateState && node.validateState.result === false ? (
          <Popover className="validate-error" content={node.validateState.msg}>
            <ExclamationCircleOutlined style={{ color: "rgb(242, 86, 67)" }} />
          </Popover>
        ) : null}
      </div>
      <div className="create-node-btn">
        {renderContent(node.createButton, {
          createButton: createButton,
          node: node,
        }) || createButton}
      </div>
      {children}
    </div>
  );
};
const TreeFlow = (props) => {
  const { treeData, className, onChange, operates } = props;
  return (
    <React.Fragment>
      <div
        className={`nodes-wrap ${className || ""}`}
        style={{ display: treeData.length > 1 ? "flex" : "block" }}
      >
        {treeData.map((node, index) => (
          <React.Fragment key={node.id}>
            <TreeFlowItem node={node} operates={operates} onChange={onChange}>
              {treeData.length > 1 ? (
                index === 0 ? (
                  <React.Fragment>
                    <div className="top-left-cover-line"></div>
                    <div className="top-bottom-cover-line"></div>
                  </React.Fragment>
                ) : index === treeData.length - 1 ? (
                  <React.Fragment>
                    <div className="top-right-cover-line"></div>
                    <div className="bottom-right-cover-line"></div>
                  </React.Fragment>
                ) : null
              ) : null}
              {node.children && node.children.length > 0 ? (
                <TreeFlow
                  className={classNameFn(
                    "branch-box-wrap",
                    node.children.length > 1
                      ? "border-top border-bottom multi-node"
                      : ""
                  )}
                  treeData={node.children}
                  operates={operates}
                  onChange={onChange}
                ></TreeFlow>
              ) : null}
            </TreeFlowItem>
          </React.Fragment>
        ))}
      </div>
      {treeData.length > 1 ? <div className="branch-box-end-line"></div> : null}
    </React.Fragment>
  );
};
//调用子组件方法 https://blog.csdn.net/qq_36990322/article/details/109858890
const TreeFlowWrap = (props, ref) => {
  const dragRef = useRef(null);
  const operates = {
    reset: () => {
      if (dragRef.current) dragRef.current.reset();
    },
    addIDForTree: (...rest) => addIDForTree(...rest, props.treeData),
    insertAfter: (...rest) => insertAfter(...rest, props.treeData),
    setNode: (...rest) => setNode(...rest, props.treeData),
    delNode: (...rest) => delNode(...rest, props.treeData),
    deepFind: (...rest) => deepFind(...rest, props.treeData),
    getRootBranch: (...rest) => getRootBranch(...rest, props.treeData),
    reduceParent: (...rest) => reduceParent(...rest, props.treeData),
  };
  useImperativeHandle(ref, () => operates);
  const treeFlowDom = useMemo(() => {
    return <TreeFlow {...props} operates={operates}></TreeFlow>;
  }, [props.treeData]);
  return useDrag({
    onRef: (ref) => (dragRef.current = ref),
    render: ({ moveX, moveY }) => (
      <div
        className="tree-flow"
        style={{ transform: `translateX(${moveX}px) translateY(${moveY}px)` }}
      >
        {treeFlowDom}
        <div className="end-node">
          <div className="end-node-circle"></div>
          <div>流程结束</div>
        </div>
      </div>
    ),
  });
};
export default forwardRef(TreeFlowWrap);
export function addIDForTree(treeData) {
  return treeData.deepReduce((accu, cur) => {
    accu.push({
      ...cur,
      id: randomID(),
    });
    return accu;
  }, []);
}
export function deepFind(fn, arr) {
  return arr.reduce((accu, cur) => {
    if (fn(cur)) return accu || cur;
    if (cur.children && cur.children.length > 0)
      return accu || deepFind(fn, cur.children);
    return accu;
  }, false);
}
export function reduceParent(id, fn, treeData, accu = false) {
  const parent = deepFind(
    (e) =>
      e.children && e.children.length > 0 && e.children.find((p) => p.id == id),
    treeData
  );
  if (!parent) return accu;
  return reduceParent(parent.id, fn, treeData, fn(parent, accu));
}
export function getRootBranch(node, treeData) {
  const parent = deepFind(
    (e) =>
      e.children &&
      e.children.length > 0 &&
      e.children.find((p) => p.id == node.id),
    treeData
  );
  if (!parent) return node;
  if (parent.node_type == 1) return node;
  return getRootBranch(parent, treeData);
}
export function insertAfter(id, node, treeData) {
  return treeData.deepReduce((accu, cur) => {
    accu.push(cur);
    if (cur.id === id) accu.push({ id: randomID(), ...node });
    return accu;
  }, []);
}
export function setNode(id, node, treeData) {
  return treeData.deepReduce((accu, cur) => {
    if (cur.id === id) accu.push(node);
    else accu.push(cur);
    return accu;
  }, []);
}
export function delNode(id, treeData) {
  return treeData.deepReduce((accu, cur) => {
    if (cur.id !== id) accu.push(cur);
    return accu;
  }, []);
}
