import { EllipsisOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
/**
 * 简单的下拉列表按钮集中
 */
const TableDropDown = ({ menus, onClick, children }) => {
  const isShow = menus.filter(Boolean).length > 0;
  return isShow ? (
    <Dropdown menu={{ items: menus.filter(Boolean) }}>
      <a>{children ? children : <EllipsisOutlined></EllipsisOutlined>}</a>
    </Dropdown>
  ) : null;
};
export default TableDropDown;
