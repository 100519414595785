import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import locale from "antd/lib/locale/zh_CN";
import { DatePicker, ConfigProvider } from "antd";
import { useMemo, useState } from "react";
import { useEffect } from "react";
dayjs.locale("zh-cn");
const { RangePicker } = DatePicker;
export default ({
  title,
  placeholder,
  value,
  onViewValueChange = () => {},
  disabledStartDate,
  onChange,
  format = "YYYY-MM-DD",
  ...rest
}) => {
  useEffect(() => {
    onViewValueChange(value && (value.filter(Boolean) || []).join(" ~ "));
  });
  //   const [momentDate, setMomentDate] = useState(value ? moment(value) : value);
  console.log("alallalal", value);
  const momentDate = useMemo(
    () =>
      value ? value.filter(Boolean).map((item) => dayjs(item, format)) : [],
    [value]
  );
  return (
    <ConfigProvider locale={locale}>
      <RangePicker
        value={momentDate}
        placeholder={placeholder || [`开始日期`, `结束日期`]}
        onChange={(_, date) => {
          //   setMomentDate(_);
          onViewValueChange((date.filter(Boolean) || []).join(" ~ "));
          onChange(date.filter(Boolean) || []);
        }}
        format={format}
        {...rest}
      ></RangePicker>
    </ConfigProvider>
  );
};
