import { useContext, useEffect, useState, useRef } from "react";
import context from "./context.js";
import { purefn } from "@widget";
export function useModel(namespace, updater) {
  if (!namespace) throw new Error("namespace must pass");

  const dispatch = useContext(context);
  //缓存updater
  const updaterRef = useRef({});
  updaterRef.current = updater;
  const [state, setState] = useState(() =>
    updaterRef.current
      ? updaterRef.current(dispatch.data[namespace])
      : dispatch.data[namespace]
  );
  let isMount = useRef(false);
  const stateRef = useRef(state);
  stateRef.current = state;
  useEffect(() => {
    isMount.current = true;
    return () => {
      isMount.current = false;
    };
  }, []);
  useEffect(() => {
    const handler = (e) => {
      if (!isMount.current) {
        // 如果 handler 执行过程中，组件被卸载了，则强制更新全局 data
        setTimeout(() => {
          dispatch.data[namespace] = e;
          dispatch.update(namespace);
        });
      } else {
        if (updaterRef.current) {
          const currentState = updaterRef.current(e);
          const previousState = stateRef.current;
          if (!purefn.deepEqual(currentState, previousState)) {
            setState(currentState);
          }
        } else {
          setState(e);
        }
      }
    };
    try {
      dispatch.callbacks[namespace].add(handler);
      dispatch.update(namespace);
    } catch (e) {
      dispatch.callbacks[namespace] = new Set();
      dispatch.callbacks[namespace].add(handler);
      dispatch.update(namespace);
    }
    return () => {
      dispatch.callbacks[namespace].delete(handler);
    };
  }, [namespace]);
  return state;
}
