import { TreeSelect, Spin } from "antd";
import useFetchData from "./useFetchData";
import { NotFound } from "./Select";
import { useEffect } from "react";
import { useZIndex } from "@utils/hooks";
export default ({
  title,
  options,
  labelName = "label",
  request,
  query,
  isSearch,
  valueName = "value",
  childrenName = "children",
  filterTreeNode: filterTreeNodeProps,
  value,
  onChange,
  onViewValueChange = () => {},
  ...rest
}) => {
  const { nextZIndex } = useZIndex();
  //处理Warning: Invalid prop `value` supplied to `TreeSelect`. You should use { label: string, value: string | number } or [{ label: string, value: string | number }] inste
  const getTreeDescValue = (val, arr = options || data) =>
    arr.reduce((accu, cur) => {
      if (accu) return accu;
      if (cur[valueName] == val) {
        if (rest.treeCheckStrictly)
          return { label: cur[labelName], value: cur[valueName] };
        else return cur[valueName];
      }
      if (cur[childrenName] && cur[childrenName].length > 0)
        return getTreeDescValue(val, cur[childrenName]);
      return accu;
    }, undefined);
  const handleValue = (e, arr) =>
    e instanceof Array
      ? e.map((val) => getTreeDescValue(val)).filter(Boolean)
      : getTreeDescValue(e);

  const getTreeViewValue = (val, arr = options || data) =>
    arr.reduce((accu, cur) => {
      if (accu) return accu;
      if (cur[valueName] == val) return cur[labelName];
      if (cur[childrenName] && cur[childrenName].length > 0)
        return getTreeViewValue(val, cur[childrenName]);
    }, "");
  const getViewValue = (e) => {
    return e instanceof Array
      ? e
          .map((val) => getTreeViewValue(val))
          .filter(Boolean)
          .join(",")
      : getTreeViewValue(e);
  };
  useEffect(() => {
    onViewValueChange(getViewValue(value));
  });

  const filterTreeNode =
    filterTreeNodeProps ||
    ((input, treeNode) => {
      return treeNode[labelName].includes(input);
    });
  const { loading, data } = useFetchData(request, { query });
  return (
    <Spin spinning={loading}>
      <TreeSelect
        // style={{ width: "100%" }}
        dropdownStyle={{
          maxHeight: 400,
          overflow: "auto",
          zIndex: nextZIndex(),
        }}
        allowClear
        placeholder={`请选择${title || ""}`}
        showSearch
        treeLine={true}
        treeData={options || data}
        fieldNames={{
          label: labelName,
          value: valueName,
          children: childrenName,
        }}
        filterTreeNode={filterTreeNode}
        value={handleValue(value || undefined, options || data)}
        onChange={(e) => {
          onChange &&
            onChange(
              rest.treeCheckStrictly
                ? e instanceof Array
                  ? e.map((item) => item.value)
                  : e.value
                : e
            );
          onViewValueChange(getViewValue(e));
        }}
        notFoundContent={NotFound(data, title)}
        {...rest}
      ></TreeSelect>
    </Spin>
  );
};
