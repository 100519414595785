import { Empty, Radio, Spin } from "antd";
import { UngroupOutlined } from "@ant-design/icons";
import useFetchData from "./useFetchData";
import { useEffect } from "react";
export const NotFound = (data, title) =>
  data.length <= 0 ? (
    <div style={{ textAlign: "center" }}>
      <UngroupOutlined style={{ fontSize: "28px" }} />
      <div>暂无{title || ""}数据</div>
    </div>
  ) : (
    <div style={{ textAlign: "center" }}>
      <UngroupOutlined style={{ fontSize: "28px" }} />
      <div>暂未找到{title || ""}</div>
    </div>
  );
const RadioField = (props) => {
  const {
    name,
    title,
    valueEnum,
    isSearch,
    request,
    labelName = "label",
    valueName = "value",
    rowKey,
    options,
    onChange,
    onViewValueChange = () => {},
    ...restProps
  } = props;
  const { loading, data } = useFetchData(request);
  const getViewValue = (e) =>
    (options || data).find((item) => item[valueName] == e) &&
    (options || data).find((item) => item[valueName] == e)[labelName];
  useEffect(() => {
    onViewValueChange(getViewValue(restProps.value));
  });
  return (
    <Spin spinning={loading}>
      <Radio.Group
        onChange={(e) => {
          const val = e.target.value;
          onViewValueChange(getViewValue(val));
          onChange(e, getViewValue(val));
        }}
        {...restProps}
      >
        {/* <Select.Option value={0 || "" || undefined || null}>无</Select.Option> */}
        {(options || data).map((option) => (
          <Radio
            key={
              (rowKey
                ? typeof rowKey === "function"
                  ? rowKey(option)
                  : rowKey
                : undefined) || option[valueName]
            }
            value={option[valueName]}
          >
            {option[labelName]}
          </Radio>
        ))}
      </Radio.Group>
    </Spin>
  );
};
export default RadioField;
