import { useEffect, useState } from "react";

const useDrag = ({ render, onRef }) => {
  const [move, setMove] = useState({ moveX: 0, moveY: 0 });
  const [canMove, setCanMove] = useState(false);
  const onStart = ({ clientX, clientY }) => {
    setMove({ ...move, startX: clientX, startY: clientY });
    setCanMove(true);
  };
  onRef({
    reset() {
      setMove({ moveX: 0, moveY: 0 });
      setCanMove(false);
    },
  });
  useEffect(() => {
    const onMouseMove = ({ clientX, clientY }) => {
      if (!canMove) return;
      setMove({
        ...move,
        moveX: move.moveX + clientX - move.startX,
        moveY: move.moveY + clientY - move.startY,
      });
    };
    document.addEventListener("mousemove", onMouseMove);
    const onMouseUp = () => setCanMove(false);
    document.addEventListener("mouseup", onMouseUp);
    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [canMove]);
  return (
    <div
      className="drag"
      style={{
        cursor: "move",
        userSelect: "none",
        overflow: "hidden",
        height: "100%",
      }}
      onMouseDown={onStart}
    >
      {render(move)}
    </div>
  );
};
export default useDrag;
