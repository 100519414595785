import Table from "@components/Table";
import { Button, Tag, Image } from "antd";
import Link from "@components/Link";
import { TableDropDown } from "@components/Table";
import uface from "@widget/uface";
import api from "@api";
import { PlusOutlined } from "@ant-design/icons";
/**
 * 信息
 */
export default () => {
	const columns = [
		{
			title: "标题",
			key: "news_title",
		},
		{
			title: "简介",
			key: "news_intro",
		},
		{
			title: "显示方式",
			width: 120,
			render: (row) => <Tag color="purple">{row.show_typename}</Tag>,
		},
		{
			title: "阅读次数",
			width: 80,
			key: "read_num",
		},
		{
			width: 140,
			title: "操作",
			valueType: "operation",
			render: (row, action) => [
				<Link to="/news/news/operate" query={{ id: row.newsid }}>
					编辑
				</Link>,
				<a
					className="danger-color"
					onClick={(e) => {
						e.stopPropagation();
						action.delete(
							"News/deleteNews",
							{ id: row.newsid },
							row.news_title
						);
					}}
				>
					删除
				</a>,
			],
		},
	];
	const searchColumns = [
		{ name: "keywords", placeholder: "可输入标题名、摘要搜索" },
	];
	return (
		<div>
			<Table
				rowKey="newsid"
				columns={columns}
				request="News/newsManage"
				search={{ columns: searchColumns }}
				toolbar={{
					operateRender: () => [
						<Link to="/news/news/operate">
							<Button type="primary" icon={<PlusOutlined />}>
								新增
							</Button>
						</Link>,
					],
				}}
				onRow={(e) => ({
					onClick: () =>
						api["News/newsDetail"]({ data: { id: e.newsid } }).then((row) =>
							uface.showDrawer({
								title: "预览",
								width: 600,
								showCancel: false,
								showConfirm: false,
								//   getContainer: () => document.querySelector(".admin-box"),
								content: (
									<div className="new-detail">
										<h1>{row.news_title}</h1>
										<div className="news-date">{row.publishdate}</div>
										<h2>简介：</h2>
										<div className="news-intro">{row.news_intro}</div>
										<h2>内容详情：</h2>
										<div
											className="news-content"
											dangerouslySetInnerHTML={{ __html: row.news_content }}
										></div>
									</div>
								),
							})
						),
				})}
			></Table>
		</div>
	);
};
