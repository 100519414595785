import FormItem from "./FormItem";
import { set } from "@utils/field";
import { useContext } from "react";
import { Context } from "./useProvider";
const FormDependency = ({ name, children, ...rest }) => {
  const formContext = useContext(Context);
  const values = (typeof name==='string'?[name]:name).reduce((accu, cur) => {
    set(accu, cur, formContext.getFieldValue(cur));
    return accu;
  }, {});
  return children(values, formContext);
};
export default FormDependency;
