export function download(imgsrc, name, { qulity = 0.9 } = {}) {
  if (!imgsrc) return;
  if (!name) name = imgsrc.match(/\/([^/\\]*\.[^/\\]+?$)/)[1];
  var image = new Image();
  // 解决跨域 Canvas 污染问题
  image.setAttribute("crossOrigin", "anonymous");
  image.onload = function () {
    var canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    var context = canvas.getContext("2d");
    context.drawImage(image, 0, 0, image.width, image.height);
    var url = canvas.toDataURL("image/png", qulity); //得到图片的base64编码数据
    var a = document.createElement("a"); // 生成一个a元素
    var event = new MouseEvent("click"); // 创建一个单击事件
    a.download = name ? name + ".png" : "photo.png"; // 设置图片名称
    a.href = url; // 将生成的URL设置为a.href属性
    a.dispatchEvent(event); // 触发a的单击事件
  };
  image.src = imgsrc;
}

//补0
const add0 = (m) => {
  return m < 10 ? "0" + m : m;
};
//格式化时间
const timeFormat = () => {
  var time = new Date();
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  let res = y + add0(m) + add0(d) + add0(h) + add0(mm) + add0(s);
  return res;
};

export function downloadbyURL(url) {
  let time = timeFormat();
  var a = document.createElement("a"); // 创建一个a节点插入的document
  var event = new MouseEvent("click"); // 模拟鼠标click点击事件
  a.download = "img_" + time + ".jpg"; // 设置a节点的download属性值
  a.href = url; // 将图片的src赋值给a节点的href
  a.dispatchEvent(event);
  a.remove(); // 下载之后把创建的元素删除
}
