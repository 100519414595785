import { Card, Button, Image, Space, Spin, Empty } from "antd";
import { useRequest } from "@hooks";
import api from "@api";
import uface from "@widget/uface";
import { pick } from "@utils/field";
import { useLocation } from "react-router-dom";
import "./index.less";
import { useMemo } from "react";
function showOperate(title, request, data, reload) {
	uface.makeForm({
		title,
		request,
		initialValue: data || {},
		syncToRequest: (data, files) => ({
			data: pick(data, "*", "!swiper_big[]", "!swiper_sml[]"),
			files,
		}),
		columns: [
			{
				title: "大图",
				name: "swiper_big[]",
				valueType: "upload:image",
				limit: 1,
			},
			{
				title: "小图",
				name: "swiper_sml[]",
				valueType: "upload:image",
				limit: 1,
			},
			{
				title: "排序",
				name: "swiper_sort",
				type: "number",
			},
			{
				title: "跳转链接(PC)",
				name: "jump_url",
			},
			{
				title: "跳转链接(手机端)",
				name: "jump_url2",
			},
		],
		success() {
			reload();
		},
	});
}
const positionMap = {
	home: 1,
	product: 3,
	news: 4,
	about: 5,
	solution: 6,
	company: 7,
};
export default function Page() {
	const location = useLocation();
	const swiperposid = useMemo(() => {
		const key = location.pathname.match(/^\/(.*)\/.*$/)[1];
		return key && positionMap[key];
	}, [location]);
	const { data, reload, loading } = useRequest({
		request: "Swiper/getSwiperList",
		config: { data: { swiperposid } },
	});
	return (
		<div className="banner">
			<div className="caption">
				<Button
					type="primary"
					onClick={() =>
						showOperate("新增轮播", "Swiper/addSwiper", { swiperposid }, reload)
					}
				>
					新增轮播图
				</Button>
			</div>
			<Spin spinning={loading}>
				<ul>
					{data.map((d) => (
						<li key={d.swiperid}>
							<Image src={d.swiper_big_full}></Image>
							<div className="flex flex-right mt10">
								<Space>
									{d.jump_url ? (
										<a href={d.jump_url} target="_blank">
											<Button type="primary" ghost>
												入口
											</Button>
										</a>
									) : null}
									<Button
										type="primary"
										onClick={() =>
											showOperate(
												"修改轮播",
												"Swiper/editSwiper",
												pick(
													d,
													"swiperid|id",
													"swiper_sort",
													"jump_url",
													"jump_url2",
													({ swiper_big_full, swiper_sml_full }) => ({
														"swiper_big[]": swiper_big_full,
														"swiper_sml[]": swiper_sml_full,
													})
												),
												reload
											)
										}
									>
										修改
									</Button>
									<Button
										type="primary"
										danger
										onClick={(e) => {
											e.stopPropagation();
											uface
												.showModal({
													content: "确定要删除轮播图吗?",
												})
												.then((confirm) => {
													if (confirm) {
														api["Swiper/delSwiper"]({
															data: { id: d.swiperid },
														}).then((res) => {
															if (res.result) reload();
														});
													}
												});
										}}
									>
										删除
									</Button>
								</Space>
							</div>
						</li>
					))}
				</ul>
				{data.length <= 0 ? <Empty></Empty> : null}
			</Spin>
		</div>
	);
}
