/**
 * 对浮点数或整数进行四舍五入
 * num number
 * floatNum 要保留的小数位数
 */
export function round(num, floatNum = 0) {
  if (Number.isNaN(num)) return 0;
  if (num === Infinity || num === -Infinity) return 0;
  const str = String(Math.abs(num));
  if (floatNum === 0) return Math.round(num);
  if (str.includes(".")) {
    const [integer] = str.split(".");
    return Number(
      num.toPrecision(
        Math.abs(num) > 1 ? String(integer).length + floatNum : floatNum
      ) //数值 0.12312.toPrecision(2) 0.12
    );
  } else return Math.round(num);
}

export function sum(...rest) {
  return rest.reduce((accu, cur) => {
    return accu + (Number.isNaN(Number(cur)) ? 0 : Number(cur));
  }, 0);
}
//向上取数，补足一个位
export function ceil(num) {
  const str = String(num),
    len = str.length,
    firstN = Number(str.slice(0, 1));
  return Number(
    (firstN === 9 ? "10" : firstN + 1) +
      (len <= 1
        ? ""
        : Array(len - 1)
            .fill(0)
            .join(""))
  );
}

export function fillZero(num) {
  return num > 0 ? "0" + Number(num) : num;
}
