import React, { useState } from "react";
// 连接Form和(Field/FormItem) 由于Context.Provider value会覆盖，因此虽然多个form嵌套引用的统一context，但是value各自分离
const Context = React.createContext({});
export default () => {
  const [fields, setFields] = useState([]);
  return {
    fields,
    setFields,
    addField: (item) => {
      setFields([...fields, item]);
    },
  };
};
export { Context };
