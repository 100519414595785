import RichText from "@components/RichText";
import Form, { useForm } from "@components/Form";
import { Input, Card, Button } from "antd";
import api from "@api";
import { useNavigate } from "react-router-dom";
import { useUrlQuery, useMount } from "@hooks";
import uface from "@widget/uface";
export default () => {
  const [{ id }] = useUrlQuery();
  const navigate = useNavigate();
  const [formInstance] = useForm();
  const getDetail = () =>
    api["BaseMsg/getSwiperContent"]({ data: { id } }).then((res) => {
      formInstance.setFields(res);
    });
  const submit = (e) => {
    api["BaseMsg/editSwiperContent"]({ data: { id, ...e } }).then((res) => {
      if (res.result) navigate("/base/swiper");
    });
  };
  useMount(() => {
    getDetail();
  });
  return (
    <Card>
      <Form
        form={formInstance}
        submitter={{ container: ".admin-box" }}
        onCancel={() => uface.jump()}
        onSubmit={submit}
      >
        <Form.Item required title="标题" name="swiper_title">
          <Input placeholder="请输入标题"></Input>
        </Form.Item>
        <Form.Item title="摘要" name="swiper_intro">
          <Input.TextArea rows={4}></Input.TextArea>
        </Form.Item>
        <Form.Item title="内容" name="content">
          <RichText></RichText>
        </Form.Item>
      </Form>
    </Card>
  );
};
