// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from "echarts/core";
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart, PieChart, LineChart } from "echarts/charts";
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
} from "echarts/components";
// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from "echarts/features";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";

import { useRef, useEffect } from "react";

// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BarChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  PieChart,
  LineChart,
  LegendComponent,
]);

/**
 * const Report=()=>{
	const options={
  title: {
    text: 'demo'
  },
  tooltip: {},
  xAxis: {
    data: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子']
  },
  yAxis: {},
  series: [
    {
      name: '销量',
      type: 'bar',
      data: [5, 20, 36, 10, 10, 20]
    }
  ]
};
	return <div style={{width:'400px',height:'300px'}}>
	<Chart options={options} />
	</div>
}
*/
const Chart = ({ options, style = {} }) => {
  const el = useRef(null);
  const echartRef = useRef(null);
  useEffect(() => {
    //初始化图表，设置配置项
    if (!echartRef.current) echartRef.current = echarts.init(el.current);
    echartRef.current.setOption(options);
  }, []);
  useEffect(() => {
    if (echartRef.current) echartRef.current.setOption(options);
  }, [options]);
  return (
    <div
      ref={el}
      style={Object.assign({}, { width: "100%", height: "100%" }, style)}
    ></div>
  );
};
export default Chart;
