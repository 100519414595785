import { Input, Popover, Tabs, Spin, Pagination } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useRequest, useMount } from "@utils/hooks";
import { useRef, useState, useLayoutEffect, useEffect } from "react";
import { useZIndex } from "@utils/hooks";
import { contains } from "@utils/dom";
import { random } from "@utils/str";
import api from "@api";
import { PageSelectItem as SelectItem } from "../PageSelect";
import "./index.less";
const pageSize = 10;
// const SelectItem = ({
//   width,
//   request,
//   labelName,
//   valueName,
//   addOperate,
//   onChange = () => {},
// }) => {
//   const { data, loading, reload } = useRequest({
//     request,
//     data: { data: { pageSize }, loading: false },
//     // onSuccess: (res) => {
//     //   const selected = res.data.find((d) => d[valueName] == value);
//     //   console.log(selected, value, "iiiiiiiiiiiiiiiiiiiiiii");
//     //   if (selected) onChange(selected);
//     //   return res;
//     // },
//   });
//   const [current, setCurrent] = useState(1);
//   return (
//     <Spin spinning={loading}>
//       {loading ? null : (
//         <div className="tabs-select-item" style={{ width }}>
//           <div className="content">
//             {addOperate ? (
//               <div
//                 className="add-btn"
//                 onClick={(e) => {
//                   e.stopPropagation();
//                   addOperate(reload);
//                 }}
//               >
//                 <PlusOutlined
//                   style={{ color: "#6da0e4", marginRight: "10px" }}
//                 />
//                 新增
//               </div>
//             ) : null}
//             {data.data.map((d) => (
//               <div
//                 className="item"
//                 key={d[valueName]}
//                 onClick={() => onChange(d)}
//               >
//                 {d[labelName]}
//               </div>
//             ))}
//           </div>
//           <Pagination
//             size="small"
//             style={{ textAlign: "center" }}
//             pageSize={pageSize}
//             total={data.page.rowcount}
//             current={current}
//             onChange={(page) => {
//               setCurrent(page);
//             }}
//           />
//         </div>
//       )}
//     </Spin>
//   );
// };
/**
 * tabs [{label,key}]
 */
const TabsSelect = ({
  title,
  defaultActiveKey,
  placeholder,
  tabs = [],
  labelName = "label",
  valueName = "value",
  value,
  onChange = () => {},
}) => {
  const popoverRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const inputRef = useRef(null);
  const [width, setWidth] = useState("");
  const { nextZIndex } = useZIndex();
  useLayoutEffect(() => {
    setTimeout(() => {
      if (inputRef.current) setWidth(inputRef.current.input.offsetWidth);
    }, 140);
  }, []);
  useEffect(() => {
    function clickoutside(e) {
      const popup =
        popoverRef.current && popoverRef.current.popupRef.current
          ? popoverRef.current.popupRef.current.getElement()
          : null;
      const trigger =
        popoverRef.current && popoverRef.current.triggerRef.current
          ? popoverRef.current.triggerRef.current.input.parentNode
          : null;
      if (e.target.tagName === "BODY" && e.target === document.body) return;
      if (contains(popup, e.target) || contains(trigger, e.target)) return;
      console.log(popup, trigger, e.target, "auauauauauau");
      setSearchText(null);
      setOpen(false);
    }
    //等待DOM初始化完成，避免刚进入点击出现弹窗后关闭
    document.addEventListener("click", clickoutside);
    return () => {
      document.removeEventListener("click", clickoutside);
    };
  }, []);
  useEffect(() => {
    if (typeof value === "object") {
      setSelected(value[labelName]);
      onChange(value[valueName]);
    } else if (!selected && value && tabs[0] && tabs[0].request)
      api[tabs[0].request]({ loading: false, data: { id: value } }).then(
        (res) => {
          if (res.data && res.data[0]) {
            setSelected(res.data[0][labelName]);
            onChange(res.data[0][valueName], res.data[0]);
          }
        }
      );
  }, [value]);
  //   useEffect(()=>{
  // if(value&&tabs[0]) tabs[0].request({data:{id:value}}).then(res=>{
  //   if(res.data&&res.data[0]){
  //     setSelected(res.data[0][labelName]);
  //       onChange(res.data[0][valueName]);
  //   }
  // })
  //   },[])
  const items = tabs.map((d, i) => ({
    label: d.label,
    key: d.key || i,
    children: (
      <SelectItem
        width={width}
        labelName={labelName}
        valueName={valueName}
        {...d}
        value={value}
        searchText={searchText}
        onChange={(e) => {
          setSearchText(undefined);
          setSelected(e[labelName]);
          onChange(e[valueName], e);
          setOpen(false);
        }}
      />
    ),
  }));
  return (
    <Popover
      ref={popoverRef}
      open={open}
      placement="bottom"
      content={<Tabs defaultActiveKey={defaultActiveKey} items={items}></Tabs>}
      trigger="click"
      zIndex={nextZIndex()}
    >
      <Input
        ref={inputRef}
        placeholder={placeholder || "请选择" + title}
        allowClear
        value={searchText || selected}
        onFocus={() => setOpen(true)}
        onChange={(e) => {
          setSearchText(e.target.value);
          if (e.type === "click" && selected) {
            setSelected(undefined);
            onChange(undefined);
            e.target.blur();
          }
        }}
      ></Input>
    </Popover>
  );
};
export default TabsSelect;
