class JudgeTree {
    constructor() {
        this.stack = [];
        this.operator = '->';
        this.globalVarObj = {}; //内部全局变量
        this.single = null;
    }

    // 检测tag是否有效
    isValidTag(tag) {
        const node = {
            self: '',
            parent: ''
        };
        const tagArr = tag.split(this.operator);
        const actions = {
            1: () => {
                node.self = tagArr[0];
            },
            2: () => {
                if (tagArr.indexOf('') !== -1) {
                    throw (new Error('tag !=== \'\'!!!!!'));
                }
                node.self = tagArr[1];
                node.parent = tagArr[0];
                // 判断tag是否存在  父节点应已存在于状态中，子节点应不存在于状态中
                if (this.getTagIndex(node.parent) === -1) {
                    throw (new Error(`tag '${node.parent}' is not existed!`));
                }

                if (this.getTagIndex(node.self) !== -1) {
                    throw (new Error(`tag '${node.self}'is existed!`));
                }
            },
            default: () => {
                throw (new Error('tag is incorrect! eg: a or a->b'));
            }
        };
        const action = actions[tagArr.length] || actions.default;
        action();
        return node;
    }

    // get tag index
    /**
     * 
     * @param {*} tag 分支标记
     * @returns index int 所在分支的序号
     */
    getTagIndex(tag) {
        for (let [index, element] of this.stack.entries()) {
            if (element.self === tag) {
                return index;
            }
        }
        return -1;
    }
    /**
     * 检测父节点是否有效
     * @param {*} parent 
     */
    checkParent(parent) {
        const node = this.stack[this.getTagIndex(parent)];
        if (node === -1) {
            throw (new Error('tag is incorrect! eg: a or a->b'));
        }
        if (node.parent !== '' && node.condition) {
            // 递归终止条件：若当前分支父级条件为真时
            return this.checkParent(node.parent);
        }
        return node.condition;
    }
    // 注入分支函数
    inject(condition, tag, func) {
        const tagObj = this.isValidTag(tag);
        const {
            parent,
            self
        } = tagObj;
        this.stack.push({
            condition,
            func,
            parent,
            self
        });
        return this;
    }
    /**
     * 使用规则
     */
    use() {
        for (const node of this.stack) {
            if (!node.condition) {
                continue;
            }
            if (node.parent === '' || this.checkParent(node.parent)) {
                node.func(this.globalVarObj);
            }
        }
    }
    /**
     * 销毁数据
     */
    destory() {
        // 清空对象，防止下次调用还保留状态
        this.stack = [];
        this.globalVarObj = {};
    }
}
export default  JudgeTree;