import React from "react";
import getModel from "./getModel.js";
import Context from "./context";
import Dispatch from "./Dispatch";
import Executor from "./Executor";
export const models = getModel();
const dispatch = new Dispatch();
const Provider = ({ children }) => {
  return (
    <Context.Provider value={dispatch}>
      {Object.entries(models).map((item) => (
        <Executor
          key={item[0]}
          namespace={item[0]}
          init={item[1]}
          onUpdate={(data) => {
            const namespace = item[0];
            dispatch.data[namespace] = data;
            dispatch.update(namespace);
          }}
        ></Executor>
      ))}
      {children}
    </Context.Provider>
  );
};
/**
 * ReactDom.render(container(<App/>),div)
 *
 */
function container(container) {
  if (!React.isValidElement(container)) throw new Error("container wrong!!!");
  return React.createElement(Provider, null, container);
}
export { Provider, container };
