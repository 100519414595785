import typeUtil from './typeUtil';

const getType = typeUtil('getType');

/**
 * 有限状态机
 */
class Machine {
    constructor(stateConfig) {
        // 状态描述配置
        this.stateConfig = stateConfig;
        this.init();
    }

    init() {
        // 当前状态
        this.state = this.stateConfig.initial;
        this.lastState = '';
        // 状态离开回调集合
        this.onExitMap = {};
        // 状态进入回调集合
        this.onEntryMap = {};
        // 状态改变回调
        this.stateChangeMap = [];
        if (this.stateConfig.on.stateChange) {
            this.stateChangeMap.push(this.stateConfig.on.stateChange);
        }
        // 历史记录
        this.history = [this.state];
    }

    /**
     * 改变状态 transition以及生命周期函数onExit、onEntry都支持promise控制异步流程
     * @param {*} type 类型行为  描述当前状态通过该行为转变到另一个状态
     */
    transition({
                   type,
                   ...arg
               }) {
        const states = this.stateConfig.states,
        curState = this.state;
        if (!curState) throw 'curState is undefined';
        if (getType(states) !== 'object') throw 'states should be a Object';
        if (!states[curState] || !states[curState].on || !states[curState].on[type]) throw 'transition ' + type + ' fail,current state is ' + curState;
        const nextState = states[curState].on[type],
            curStateObj = states[curState],
            nextStateObj = states[nextState];
        // 状态转变 为使任务同步执行，不使用微任务
        // .then(() =>
        //     // 离开上个状态
        //     this.handleLifeCycle({
        //         lifeCycle: 'onExit',
        //         stateObj: curStateObj,
        //         arg: {
        //             exitState: curStateObj
        //         }
        //     })
        // )
        // // 改变当前状态
        // .then(() =>
        //     this.updateState({
        //         state: nextState,
        //         lastState: curState
        //     })
        // )
        // // 进入新状态
        // .then(() => this.handleLifeCycle({
        //     lifeCycle: 'onEntry',
        //     stateObj: nextStateObj,
        //     arg: {
        //         state: nextState,
        //         lastState: curState,
        //         ...arg
        //     }
        // }));
        // 离开上个状态
        this.handleLifeCycle({
            lifeCycle: 'onExit',
            stateObj: curStateObj,
            arg: {
                exitState: curStateObj
            }
        });
        // 改变当前状态
        this.updateState({
            state: nextState,
            lastState: curState
        })
        // 进入新状态
        this.handleLifeCycle({
            lifeCycle: 'onEntry',
            stateObj: nextStateObj,
            arg: {
                state: nextState,
                lastState: curState,
                ...arg
            }
        });
    }

    /**
     * 状态改变回调  只注册一次
     * @param {*} cb
     */
    onStateChange(cb) {
        cb && getType(cb) === 'function' &&  (this.stateChangeMap.push(cb));
    }
    handleStateChange(e){
        const stateChangeMap=this.stateChangeMap;
        if (stateChangeMap.length<=0) return false;
        stateChangeMap.forEach(fn=>{
            fn(e)
        });
    }
    // 状态进入回调
    onEntry(lifeCycle, cb) {
        this.onEntryMap[lifeCycle] = cb;
    }

    /**
     * 状态离开回调
     * @param {*} lifeCycle
     * @param {*} cb
     */
    onExit(lifeCycle, cb) {
        this.onExitMap[lifeCycle] = cb;
    }

    // 状态改变
    updateState({
                    state,
                    lastState
                }) {
        this.state = state;
        this.lastState = lastState;
        this.history.push(state);
        this.handleStateChange({
            state,
            lastState
        });
    }

    /**
     * 获取当前状态
     */
    getState() {
        return this.state;
    }

    /**
     * 获取上个状态
     */
    getLastState() {
        return this.lastState;
    }

    getHistory() {
        return this.history;
    }

    rollback() {
        if (!this.lastState) return false;
        const index = this.history.findIndex(item => item === this.lastState);
        this.state = this.lastState;
        if (index == 0) {
            // 只执行了一步
            this.lastState = '';
        } else {
            this.lastState = this.history[index - 1];
        }
        this.history.pop();
    }

    // 触发生命周期改变
    handleLifeCycle({
                        stateObj,
                        lifeCycle,
                        arg
                    }) {
        const cbName = stateObj[lifeCycle];
        // 执行钩子
        if (cbName) {
            const cb = typeof cbName === 'function' ? cbName : this[`${lifeCycle}Map`][cbName];
            if (cb && (getType(cb) === 'function')) cb(arg);
        }
    }

    destroy() {
        this.init();
    }
}

export default Machine;
