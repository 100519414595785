import React, { useState } from "react";
import img from "../static/images/404.png";

export default () => {
  const [animated, setAnimated] = useState("");
  return (
    <div
      className="center"
      style={{ height: "100%", background: "#ececec", overflow: "hidden" }}
    >
      <img
        src={img}
        alt="404"
        className={`animate__animated animate__bounce ${animated}`}
        onMouseEnter={() => setAnimated("animate__hinge")}
      />
    </div>
  );
};
