/**
 * 从jquery copy过来的，递归去算
 * a包含b
 *
 * @param {Element} a
 * @param {Element} b
 * @returns {boolean}
 */
export function contains(a, b) {
  if (!a) return false;
  if (a === b) return true;
  var adown = a.nodeType === 9 ? a.documentElement : a;
  var bup = b && b.parentNode;
  return a === bup || !!(bup && bup.nodeType === 1 && adown.contains(bup));
}

export function getOffsetLeft(node) {
  if (!node) return 0;
  let cur = node,
    offset = node.offsetLeft || 0;
  while (cur.offsetParent) {
    offset += cur.offsetParent.offsetLeft || 0;
    cur = cur.offsetParent;
  }
  return offset;
}
export function getOffsetTop(node) {
  if (!node) return 0;
  let cur = node,
    offset = node.offsetTop || 0;
  while (cur.offsetParent) {
    offset += cur.offsetParent.offsetTop || 0;
    cur = cur.offsetParent;
  }
  return offset;
}
export function appendStyle(style) {
  const el = document.createElement("style");
  el.innerHTML = style;
  document.head.appendChild(el);
  return el;
}
