import purefn from "@widget/purefn";
import { isInValid, isNumber, isString } from "./is";
export function isSameName(name1, name2) {
  if (typeof name1 !== "object") return name1 === name2;
  return name1.reduce((accu, cur, index) => {
    return accu && cur === name2[index];
  }, true);
}
const getType = (param) =>
  Object.prototype.toString.call(param).match(/^\[object\s(.*)\]$/)[1];
/**
 * @param store 所用库
 * @param namePath 映射字段 'a' 'a.b' ['a','b']将作为{a:{b:''}}
 * 不能根据key循环forEach，会出现{a:{a:'a'}} 同样字段嵌套的情况
 * 过滤{a:'aa'} set(store,['a','v'],'a') 访问字符串属性报错的情况，属于使用错误
 * 识别数组 ['a',1,'a'] 转为 {a:[{a}]}
 */
export function set(store, namePath, value) {
  if (isInValid(store)) return;
  if (typeof namePath !== "object") store[namePath] = value;
  else {
    let curWrap = store,
      lastIndex = namePath.length - 1,
      cursor = 0;
    while (cursor <= lastIndex) {
      const key = namePath[cursor];
      if (cursor === lastIndex) {
        curWrap[key] = value;
        return;
      }
      //   处理当store的相应字段为null/简单类型，最后设置值时必须上一级是对象
      if (
        !curWrap.hasOwnProperty(key) ||
        !curWrap[key] ||
        isNumber(curWrap[key]) ||
        isString(curWrap[key])
      ) {
        // if (typeof key === "number") {
        //   if (getType(curWrap) !== "Array") curWrap = [];
        // }
        if (cursor + 1 <= lastIndex && isNumber(namePath[cursor + 1]))
          curWrap[key] = [];
        else curWrap[key] = {};
      }
      curWrap = curWrap[key];
      cursor++;
    }
  }
}
/**
 * 获取store value
 * @param namePath 映射字段 'a' 'a.b' ['a','b']将作为{a:{b:''}}
 */
export function get(store, namePath) {
  if (isInValid(store)) return store;
  let curWrap = store,
    cursor = 0;
  if (typeof namePath !== "object") return store[namePath];
  else if (typeof namePath === "object") {
    let lastIndex = namePath.length - 1;
    while (cursor <= lastIndex) {
      const key = namePath[cursor];
      if (cursor === lastIndex) return curWrap[key];
      if (curWrap.hasOwnProperty(key)) curWrap = curWrap[key];
      cursor++;
    }
  }
  return undefined;
}

let exclude = [];
function handlePickWhenString(key, source) {
  const temp = {};
  if (!key) return;
  if (/^!\w+/.test(key)) exclude.push(key.split("!")[1]);
  // 处理exclude
  if (exclude.includes(key)) return temp;
  if (/\w+\|\w+/.test(key)) {
    const match = key.match(/(\w+)\|(\w+)/);
    temp[match[2]] = source[match[1]];
    return temp;
  }
  if (key.includes(","))
    key
      .split(",")
      .forEach((signleKey) => (temp[signleKey] = source[signleKey]));
  else if (Object.hasOwnProperty.call(source, key)) temp[key] = source[key];
  return temp;
}
function handlePickWhenArray(field, source) {
  let temp = {};
  const sourceField = typeof field[0] === "string" ? [field[0]] : field[0],
    targetHandle = field[1];
  const keys = pick(source, ...sourceField);
  if (typeof targetHandle === "function") temp = targetHandle(keys);
  else temp[targetHandle] = keys;
  return temp;
}
function handlePickWhenObject(pickObj, source) {
  let temp = {};
  Object.keys(pickObj).forEach((key) => {
    const pickArr = key.split(","); //提取多个字段规则：a,b,c
    const mapSource = pickObj[key];
    if (typeof mapSource === "function")
      temp = mapSource(pick(source, ...pickArr));
    else if (typeof mapSource === "string")
      temp[mapSource] = pick(source, ...pickArr);
  });
  return temp;
}
function handlePickWhenFunction(fn, source) {
  return fn(source);
}
// const isInvalid = (val) =>
//   val === undefined || val === null || Number.isNaN(val);
/** 重取属性 fn(this,['a','b|c','!d','*'])
 * 提取规则：[源字段：目标字段 ] item可为String/Array/Object({源字段：目标字段})
 * b|c 重命名属性
 * !d 排除d
 * * 通配符，所有属性会加到数组末尾，因此和!没有顺序关系
 */
export function pick(source, ...fields) {
  if (!source || !(source instanceof Object))
    throw new Error("must be an object!!!");
  exclude = [];
  const pickHandleMap = {
    handlePickWhenString,
    handlePickWhenArray,
    handlePickWhenObject,
    handlePickWhenFunction,
  };
  // 匹配*
  const pickFields = [...fields];
  if (fields.includes("*")) {
    Object.keys(source).forEach((key) => {
      pickFields.push(key);
    });
  }
  pickFields.push(...fields);
  return pickFields.reduce((accu, cur) => {
    if (isInValid(cur)) return accu;
    const method = pickHandleMap["handlePickWhen" + getType(cur)];
    accu = purefn.ObjUtil.merge({}, accu, method(cur, source));
    return accu;
  }, {});
}

export function isEmptyObject(obj) {
  if (!obj) return true;
  return Object.keys(obj).reduce((accu, key) => {
    return accu && (isInValid(obj[key]) || obj[key] === "");
  }, true);
}
