import "./index.less";
import Mask from "@components/Mask";
import React, { useEffect, useMemo, useState } from "react";
import className from "@utils/className";
/**
 * popup 弹窗容器
 * @description 弹出层容器，用于展示弹窗、信息提示等内容，支持上、下、左、右和中部弹出。组件只提供容器，内部内容由用户自定义
 * @property {String} mode 弹出方向（默认left）
 * @property {Boolean} mask 是否显示遮罩（默认true）
 * * duration 过渡时间
 * @property {Stringr | Number} length mode=left | 见官网说明（默认auto）
 * @property {Boolean} zoom 是否开启缩放动画，只在mode为center时有效（默认true）
 * @property {Boolean} safe-area-inset-bottom 是否开启底部安全区适配（默认false）
 * @property {Boolean} mask-close-able 点击遮罩是否可以关闭弹出层（默认true）
 * @property {Object} custom-style 用户自定义样式
 * @property {Stringr | Number} negative-top 中部弹出时，往上偏移的值
 * @property {Numberr | String} border-radius 弹窗圆角值（默认0）
 * @property {Numberr | String} z-index 弹出内容的z-index值（默认1075）
 * @property {Boolean} closeable 是否显示关闭图标（默认false）
 * @property {String} close-icon 关闭图标的名称，只能uView的内置图标
 * @property {String} close-icon-pos 自定义关闭图标位置（默认top-right）
 * @property {String} close-icon-color 关闭图标的颜色（默认#909399）
 * @property {Number | String} close-icon-size 关闭图标的大小，单位rpx（默认30）
 * @event {Function} open 弹出层打开
 * @event {Function} close 弹出层收起
 * @example <u-popup v-model="show"><view>出淤泥而不染，濯清涟而不妖</view></u-popup>
 */
/**
	 * //可选值  left/right/top/bottom/center
			mode: {
				type: String,
				default: 'center'
			},
			duration: {
				type: [Number, String],
				default: 300
			},
			modelValue: {
				type: Boolean,
				default: false
			},
			showMask: {
				type: Boolean,
				default: true
			},
			title: String,
			// 用户自定义样式
			customStyle: {
				type: Object,
				default () {
					return {
						backgroundColor: '#fff'
					};
				}
			},
			bodyStyle: {
				type: Object,
				default: () => ({
					overflow: 'auto'
				})
			}
	*/
const Popup = ({
  visible = false,
  title,
  onChange = () => {},
  afterVisible = () => {},
  customStyle = { backgroundColor: "#fff" },
  bodyStyle = { overflow: "auto" },
  duration = 300,
  mode = "center",
  showMask = true,
  children,
  ...restProps
}) => {
  let modeInner;
  if (mode.includes("center")) modeInner = "center";
  else modeInner = mode;
  const [isShow, setIsShow] = useState(false);
  const [isAnimateShow, setIsAnimateShow] = useState(false);
  const zIndex = 30;
  const style = useMemo(() => {
    let style = {};
    if (mode === "left" || mode === "right") {
      style = {
        height: "100%",
        transform: `translate3D(${mode === "left" ? "-100%" : "100%"},0,0)`,
      };
    } else if (mode === "top" || mode === "bottom") {
      style = {
        width: "100%",
        transform: `translate3D(0,${mode === "top" ? "-100%" : "100%"},0)`,
      };
    } else if (mode.includes("center")) {
      style = {};
    }
    style.transition = `all ${duration / 1000}s linear`;
    if (!mode.includes("center")) {
      style.position = "fixed";
      style["zIndex"] = zIndex || 110;
    }
    return style;
  }, [mode]);
  const centerStyle = useMemo(() => {
    if (mode.includes("center")) {
      let style = {};
      const top = mode.split("-")[1];
      style.position = "fixed";
      style["zIndex"] = zIndex || 110;
      style.left = "50%";
      if (top) {
        style.top = top;
        style.transform = "translateX(-50%)";
      } else {
        style.top = "50%";
        style.transform = "translateX(-50%) translateY(-50%)";
      }
      if (!isAnimateShow) {
        style.transform = "scale(0)";
        style.transition = `all ${duration / 1000}s linear`;
      }
      return style;
    }
    return {};
  }, [mode, isAnimateShow]);
  const maskSlotStyle = useMemo(() => {
    return {
      zIndex: zIndex + 1,
    };
  }, [zIndex]);
  /**
   * 动画进入原理
   * 先显示整个组件，一段时间后再让动画显示
   * 退出先动画退出，在关闭组件
   */
  const togger = (isShow) => {
    if (isShow) {
      setIsShow(isShow);
      setTimeout(() => {
        setIsAnimateShow(isShow);
        afterVisible(isShow);
      }, 50);
    } else {
      setIsAnimateShow(isShow);
      setTimeout(() => {
        setIsShow(isShow);
      }, duration);
      onChange(isShow);
    }
  };
  useEffect(() => {
    if (visible) togger(visible);
  }, [visible]);
  // 标记关闭是内部发生的，否则修改了value值，导致watch中对value检测，导致再执行一遍close
  // 造成@close事件触发两次
  const close = () => {
    togger(false);
    onChange(false);
  };
  // 使用if防止组件无法销毁
  return (
    <React.Fragment>
      {isShow ? (
        <div className="popup-warp">
          {showMask ? (
            <Mask
              clickAble={false}
              zIndex={zIndex - 1}
              transparent={true}
              onClose={close}
              visible={isAnimateShow}
            >
              <div style={maskSlotStyle}>{restProps.mask}</div>
            </Mask>
          ) : null}
          <div style={mode === "center" ? centerStyle : {}}>
            <div
              style={{ ...style, ...customStyle }}
              className={className("popup", [
                "mode-" + modeInner,
                isAnimateShow
                  ? modeInner === "center"
                    ? "mode-center-show"
                    : "popup-show"
                  : mode === "center"
                  ? "mode-center-hide"
                  : "",
              ])}
            >
              {title ? (
                <div className="header">
                  <div className="text">{title}</div>
                  <div className="flex flex-middle">
                    {restProps.rightArea}
                    <i
                      className="iconfont icon-shanchu"
                      onClick={() => togger(false)}
                    ></i>
                  </div>
                </div>
              ) : null}
              <div
                className="popup-body"
                style={{
                  ...bodyStyle,
                  height: title
                    ? `calc(100% ${title ? "- 61px" : ""} ${
                        restProps.footer ? "- 70px" : ""
                      })`
                    : "",
                }}
              >
                {children}
              </div>
              {restProps.footer ? (
                <div className="fixed">{restProps.footer}</div>
              ) : null}
              {restProps.footer ? <div style={{ height: "70px" }}></div> : null}
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};
export default Popup;
