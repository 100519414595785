import Table from "@components/Table";
import { Button, Space, Tree, Dropdown, Spin } from "antd";
import uface from "@widget/uface";
import { pick } from "@utils/field";
import { TableDropDown } from "@components/Table";
import { PlusOutlined, DownOutlined } from "@ant-design/icons";
import { useRequest } from "@utils/hooks";
import { useState } from "react";
import Iconfont from "@components/Iconfont";
import api from "@api";
import "./department.less";
function handleTreeData(data) {
  return data.map((d) => ({
    title: d.dep_name,
    key: d.id,
    children:
      d.children && d.children.length > 0 ? handleTreeData(d.children) : [],
  }));
}
export default ({ onChange = () => {} }) => {
  const { data, reload, loading } = useRequest({
    request: "CompanyUser/getDepList",
  });
  const operate = (request, query, reload) =>
    uface.makeForm({
      title: (query ? "编辑" : "新增") + "部门",
      request,
      initialValue: query || {},
      columns: [
        {
          title: "上级部门",
          name: ["data", "pid"],
          valueType: "tree:select",
          request: Promise.resolve(handleTreeData(data)),
          fieldProps: {
            labelName: "title",
            valueName: "key",
          },
        },
        {
          title: "部门名称",
          name: ["data", "dep_name"],
          formItemProps: {
            required: true,
          },
        },
      ],
      success() {
        reload();
      },
    });

  const [depid, setDepid] = useState([0]);

  return (
    <div className="user-department">
      <div className="flex flex-between flex-middle title">
        <h3>部门</h3>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() =>
            operate("CompanyUser/addDepartment", undefined, reload)
          }
        >
          新增
        </Button>
      </div>
      {loading ? <Spin spinning={true}></Spin> : null}
      {loading ? null : (
        <Tree
          defaultExpandAll={true}
          selectedKeys={depid}
          blockNode={true}
          treeData={handleTreeData([{ dep_name: "全部", id: 0 }, ...data])}
          onSelect={(e) => {
            onChange(e[0]);
            setDepid(e);
          }}
          titleRender={(node) => (
            <div className="node">
              <div>{node.title}</div>
              {node.key == 0 ? null : (
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: (
                          <a
                            onClick={(e) => {
                              e.stopPropagation();
                              const item = data.find((d) => d.id == node.key);
                              operate(
                                "CompanyUser/editDepartment",
                                {
                                  id: item.id,
                                  data: pick(item, "pid", "dep_name"),
                                },
                                reload
                              );
                            }}
                          >
                            编辑
                          </a>
                        ),
                      },
                      {
                        key: "2",
                        label: (
                          <a
                            className="danger-color"
                            onClick={(e) => {
                              e.stopPropagation();
                              uface
                                .showModal({
                                  title: "警告",
                                  icon: "warning",
                                  content: (
                                    <div>
                                      确定要删除
                                      <span className="red-color">
                                        {node.title}
                                      </span>
                                      吗?
                                    </div>
                                  ),
                                })
                                .then((confirm) => {
                                  if (confirm)
                                    api["CompanyUser/delDep"]({
                                      data: { id: node.key },
                                    }).then((res) => {
                                      if (res.result) {
                                        if (node.key === depid[0]) {
                                          setDepid([0]);
                                          onChange(0);
                                        }
                                        reload();
                                      }
                                    });
                                });
                            }}
                          >
                            删除
                          </a>
                        ),
                      },
                    ],
                  }}
                >
                  <a>
                    <Space>
                      更多
                      <Iconfont type="icon-xiajiantou"></Iconfont>
                    </Space>
                  </a>
                </Dropdown>
              )}
            </div>
          )}
        ></Tree>
      )}
    </div>
  );
};
