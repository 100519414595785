import { Layout } from "antd";
import Aside from "./Aside";
import Header from "./Header";
import Main from "./Main";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { uface } from "@widget";
import { useMount } from "@hooks";
import { useEffect } from "react";
import emitter from "@emitter";
import { useModel } from "@model";
const useJudgeLogin = () => {
  const navigate = useNavigate();
  // 由于navigate只能在hooks中使用,uface.jump跳转事件
  useMount(() => {
    emitter.on("navigate", navigate);
    const token = uface.getStorage("token");
    if (!token) navigate("/login");
    return () => {
      emitter.off("navigate", navigate);
    };
  });
};
export default () => {
  const location = useLocation();
  useJudgeLogin();
  const { setButton } = useModel("auth");
  const { getRouteByPath } = useModel("router");
  useEffect(() => {
    const adminBoxEl = document.querySelector(".admin-box");
    if (adminBoxEl) adminBoxEl.scroll(0, 0);
    emitter.emit("locationChange", location);
    //   监听路由，判断按钮权限
    if (location.state) setButton(location.state);
    else {
      //若不是从左侧导航进入，而是从jump跳转，没有state，手动搜索当前route
      const route = getRouteByPath(location.pathname);
      console.log("aoaoaooaoaoaoao", route, location.pathname);
      if (route) setButton({ id: route.id });
    }
  }, [location]);
  return (
    <Layout>
      <Header></Header>
      <Layout>
        <Aside></Aside>
        <Main></Main>
      </Layout>
    </Layout>
  );
};
