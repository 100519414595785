import Form, { useForm, Select } from "@components/Form";
import { Card, Input, Checkbox } from "antd";
import "./operate.less";
import { useMount } from "@utils/hooks";
import api from "@api";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUrlQuery } from "@hooks";
import uface from "@widget/uface";
const { TextArea } = Input;
const filterRepeat = (arr) => Array.from(new Set(arr));
function diffArr(arr1, arr2) {
  const temp = [];
  arr1.forEach((d) => {
    if (!arr2.includes(d)) temp.push(d);
  });
  arr2.forEach((d) => {
    if (!arr1.includes(d)) temp.push(d);
  });
  return temp;
}
// 移除不受控制的id;
const removeNoControlChild = (ids, noControlChild) => {
  const temp = [...ids];
  noControlChild.forEach((d) => {
    const index = temp.findIndex((e) => e == d);
    if (index > -1) temp.splice(index, 1);
  });
  return temp;
};
/**
 * 获取必要权限
 */
function getMustAuth(arr) {
  return arr.reduce((accu, cur) => {
    if (cur.must_show) accu.push(cur.id);
    if (cur.children && cur.children.length > 0)
      accu.push(...getMustAuth(cur.children));
    return accu;
  }, []);
}
const getNoControlChild = (arr) =>
  arr && arr.length > 0
    ? arr.reduce((accu, cur) => {
        if (cur.children && cur.children)
          accu = accu.concat(getNoControlChild(cur.children));
        if (cur.rule_type === "nav" && cur.hidden) accu = accu.concat([cur.id]);
        return accu;
      }, [])
    : [];
const AuthTable = ({ auths = [], value = [], onChange }) => {
  const hasIDInChildren = (id, arr) =>
    arr && arr.length > 0
      ? arr.reduce((accu, cur) => {
          if (accu) return accu;
          if (cur.id == id) return true;
          else if (cur.children && cur.children.length > 0)
            return hasIDInChildren(id, cur.children);
          return accu;
        }, false)
      : false;
  //   将被选中的子级的父级也选中
  const getSelectedParentIDs = (ids, arr) => {
    let temp = [];
    ids.forEach((idc) => {
      temp = temp.concat(
        arr.reduce((accu, cur) => {
          if (cur.children && cur.children.length > 0) {
            console.log(cur, idc, hasIDInChildren(idc, cur.children));
            if (hasIDInChildren(idc, cur.children)) {
              accu.push(cur.id);
              accu = accu.concat(getSelectedParentIDs(ids, cur.children));
            }
          }
          return accu;
        }, [])
      );
    });
    return temp;
  };
  const getAllcheckedChild = (arr) =>
    arr && arr.length > 0
      ? arr.reduce((accu, cur) => {
          if (cur.children && cur.children)
            accu = accu.concat(getAllcheckedChild(cur.children));
          accu = accu.concat([cur.id]);
          return accu;
        }, [])
      : [];
  //   全选/取消全选父节点
  const checkedParent = (ids, val, arr, checkedAll, unCheckedAll) => {
    arr.forEach((item) => {
      // 选中父节点下的所有子节点
      if (checkedAll && val == item.id)
        ids = ids.concat(getAllcheckedChild(item.children));
      // 删除父节点，取消父节点下所有子节点
      if (unCheckedAll && val == item.id) {
        ids = ids.filter((curID, index) => {
          console.log(
            "bbbb",
            hasIDInChildren(curID, item.children),
            curID,
            item,
            item.children
          );
          if (hasIDInChildren(curID, item.children)) return false;
          return true;
        });
      }
    });
    return ids;
  };
  useEffect(() => {
    console.log("aooaoa", filterRepeat(value.concat(getMustAuth(auths))));
    onChange(filterRepeat(value.concat(getMustAuth(auths))));
  }, [auths]);
  return (
    <Checkbox.Group
      style={{ width: "100%" }}
      value={value}
      onChange={(e) => {
        const isAdd = e.length > value.length,
          isDelete = e.length < value.length;
        const lastVal = isAdd ? e[e.length - 1] : diffArr(e, value)[0];
        /**
         * 新旧值对比，
         * 新增 新length>旧length
         *  判断新值是否是根节点，最后一项含根id 新值没有，说明新增根id,选中所有子级
         * 删除 old length> new length
         *  判断新值是否是根节点，旧值最后一项含根id 新值没有，说明删除根id,取消选中所有子级
         *
         * 场景1：子级取消选中后，留下父级，需判断新值length>旧值length才是新加父级
         */
        const authIDs = auths.map((item) => item.id);
        const isAddParent = isAdd && authIDs.includes(lastVal);
        const isDelParent = isDelete && authIDs.includes(lastVal);
        console.log(
          isAdd,
          isDelete,
          isDelParent,
          isAddParent,
          lastVal,
          auths,
          authIDs
        );
        let ids = [...e];
        console.log(
          "uuu",
          e,
          value,
          authIDs.includes(e[e.length - 1]),
          isDelParent
        );
        ids = checkedParent(ids, lastVal, auths, isAddParent, isDelParent);

        if (isAdd) ids = ids.concat(getSelectedParentIDs(ids, auths));
        onChange && onChange(filterRepeat(ids));
      }}
    >
      <table className="auth-table">
        <thead>
          <tr>
            <th>模块</th>
            <th>页面</th>
            <th>功能</th>
          </tr>
        </thead>
        <tbody>
          {auths.map((item) => {
            let parentArea,
              childArea = null;
            if (item.children && item.children.length <= 0)
              parentArea = (
                <tr key={item.id}>
                  <td>
                    {item.must_show ? (
                      <Checkbox value={item.id} disabled>
                        {item.title}
                      </Checkbox>
                    ) : (
                      <Checkbox value={item.id}>{item.title}</Checkbox>
                    )}
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              );
            else {
              parentArea = (
                <tr key={item.id}>
                  <td rowSpan={item.children.length}>
                    {item.must_show ? (
                      <Checkbox value={item.id} disabled>
                        {item.title}
                      </Checkbox>
                    ) : (
                      <Checkbox value={item.id}>{item.title}</Checkbox>
                    )}
                  </td>
                  <td>
                    <Checkbox value={item.children[0].id}>
                      {item.children[0].title}
                    </Checkbox>
                  </td>
                  <td>
                    {item.children[0].children.map((child) => (
                      <span
                        key={child.id}
                        // style={{
                        //   visibility:
                        //     child.rule_type === "nav" && child.hidden
                        //       ? "hidden"
                        //       : "visible",
                        // }}
                      >
                        <Checkbox value={child.id}>{child.title}</Checkbox>
                      </span>
                    ))}
                  </td>
                </tr>
              );
              childArea = item.children
                .filter((_, index) => index !== 0)
                .map((child) => (
                  <tr key={child.id}>
                    <td>
                      <Checkbox value={child.id}>{child.title}</Checkbox>
                    </td>
                    <td>
                      {child.children.map((thirdChild) => (
                        <Checkbox
                          key={thirdChild.id}
                          value={thirdChild.id}
                          //   style={{
                          //     visibility:
                          //       thirdChild.rule_type === "nav" &&
                          //       thirdChild.hidden
                          //         ? "hidden"
                          //         : "visible",
                          //   }}
                        >
                          {thirdChild.title}
                        </Checkbox>
                      ))}
                    </td>
                  </tr>
                ));
            }
            return (
              <React.Fragment key={item.id}>
                {parentArea}
                {childArea}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </Checkbox.Group>
  );
};
export default () => {
  const [{ id }] = useUrlQuery();
  const [auths, setAuths] = useState([]);
  const navigate = useNavigate();
  const [formInstance] = useForm();
  useMount(() => {
    api["Auth/getAdminModuleList"]({ data: { id } }).then((res) => {
      if (id) {
        const { module, group_name, desc, module_id_array, rule_list } = res;
        formInstance.setStore({
          module,
          group_name,
          desc,
          module_id_array: (module_id_array || "").split(",").map(Number),
        });
        setAuths(rule_list);
      } else setAuths(res);
    });
  });
  const submit = (e) => {
    api[id ? "Auth/editUserAdminGroup" : "Auth/addUserAdminGroup"]({
      data: {
        ...e,
        // module_id_array: filterRepeat(
        //   e.module_id_array.concat(getMustAuth(auths[e.module]))
        // ).join(","),
        module_id_array: filterRepeat(e.module_id_array).join(","),
        id,
      },
    }).then((res) => {
      if (res.result) navigate("/auth/group");
    });
  };
  return (
    <Card>
      <Form
        form={formInstance}
        submitter={{ container: ".admin-box" }}
        onCancel={() => uface.jump()}
        onSubmit={submit}
      >
        <Form.Item required title="模块" name="module">
          <Select
            placeholder="请选择模块"
            options={[
              {
                label: "平台",
                value: "admin",
              },
              {
                label: "手机",
                value: "mobile",
              },
            ]}
          ></Select>
        </Form.Item>
        <Form.Item title="权限组名称" required name="group_name">
          <Input placeholder="请输入权限组名称"></Input>
        </Form.Item>
        <Form.Dependency name={["module"]}>
          {({ module }) => {
            return module === "admin" ? (
              <Form.Item
                title="平台模块"
                required
                name="module_id_array"
                shouldUpdate={true}
              >
                <AuthTable auths={auths.admin}></AuthTable>
              </Form.Item>
            ) : module === "mobile" ? (
              <Form.Item
                title="手机模块"
                required
                name="module_id_array"
                shouldUpdate={true}
              >
                <AuthTable auths={auths.mobile}></AuthTable>
              </Form.Item>
            ) : null;
          }}
        </Form.Dependency>
        <Form.Item title="描述" name="desc">
          <TextArea rows={8}></TextArea>
        </Form.Item>
      </Form>
    </Card>
  );
};
