import { uface } from "@widget";
import { FormMaker, useForm } from "@components/Form";
import { useMount, useRequest } from "@hooks";
import { Modal, Drawer, ConfigProvider, Space, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import Table from "@components/Table";
import api from "@api";
import { useMemo, useState } from "react";
import aider from "@widget/aider";
import zhCN from "antd/lib/locale/zh_CN";
import emitter from "@emitter";
import FixedBar from "@components/FixedBar";
import { isPromise } from "@utils/is";
import { FullscreenExitOutlined, FullscreenOutlined } from "@ant-design/icons";
import { useZIndex } from "@utils/hooks";
import { theme } from "@/config";
import {
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
const icons = {
  warning: <ExclamationCircleOutlined style={{ color: "#d89614" }} />,
  error: <CloseCircleOutlined style={{ color: "#a61d24" }} />,
  success: <CheckCircleOutlined style={{ color: "#1878d2" }} />,
};
export default () => {
  /**
   * showModal
   */
  const { instance: modalInstance } = uface.register("showModal");
  modalInstance.setHooks(
    "onExecute",
    ({
      width,
      height,
      request,
      content,
      title = "提示",
      cancelText = "取消",
      confirmText = "确定",
      maskClosable = true,
      type = "confirm",
      syncToRequest = (values, files) => ({ data: values, files }), //提交参数同步
      icon,
      onRef = () => {},
      onOk = () => {},
      ...rest
    }) => {
      return new Promise((resolve, reject) => {
        modalInstance.create(
          ({ container }) => {
            const { nextZIndex } = useZIndex();
            const [visible, setVisible] = useState(true);
            const [fullscreen, setFullscreen] = useState(false);
            const whenClose = () =>
              setTimeout(() => {
                modalInstance.remove();
              }, 40);
            const onSuccess = (e) => {
              if (e.result) {
                setVisible(false);
                resolve(e || true);
              } else reject(e);
            };
            const provideRef = {
              request: (e) => {
                const param = e.files ? e : { data: e };
                if (typeof request === "string")
                  api[request](param).then(onSuccess);
                else if (typeof request === "function")
                  request(param).then(onSuccess);
                else if (isPromise(request))
                  request
                    .then((res) => Object.assign({}, res, param))
                    .then(onSuccess);
              },
              close: () => setVisible(false),
            };
            useMount(() => {
              console.log("auauauau", visible);
              onRef(provideRef);
            });
            return (
              <ConfigProvider locale={zhCN} theme={theme}>
                <Modal
                  zIndex={nextZIndex()}
                  style={fullscreen ? { top: 0, paddingBottom: 0 } : {}}
                  title={
                    <div>
                      {icons[icon] ? <>{icons[icon]}&nbsp;&nbsp;</> : null}
                      {title}
                    </div>
                  }
                  destroyOnClose={true}
                  width={fullscreen ? "100%" : width}
                  getContainer={container}
                  open={visible}
                  afterClose={whenClose}
                  forceRender
                  bodyStyle={
                    fullscreen
                      ? { height: "calc(100vh - 108px)" }
                      : {
                          maxHeight: "calc(100vh - 200px)",
                          overflow: "auto",
                          height,
                        }
                  }
                  okText={confirmText}
                  onCancel={() => {
                    resolve(false);
                    setVisible(false);
                  }}
                  onOk={() => {
                    if (request) onOk();
                    else {
                      onOk();
                      resolve(true);
                      setVisible(false);
                    }
                  }}
                  {...rest}
                >
                  <Button
                    type="text"
                    className="ant-modal-fullscreen"
                    icon={
                      fullscreen ? (
                        <FullscreenExitOutlined />
                      ) : (
                        <FullscreenOutlined />
                      )
                    }
                    onClick={() => setFullscreen(!fullscreen)}
                  ></Button>
                  {typeof content === "string"
                    ? content
                    : typeof content === "function"
                    ? content()
                    : content}
                </Modal>
              </ConfigProvider>
            );
          },
          { isRender: true }
        );
      });
    }
  );

  /**
   * showDrawer
   */
  const { instance: drawerInstance } = uface.register("showDrawer");
  drawerInstance.setHooks(
    "onExecute",
    ({
      width,
      request,
      content,
      title = "提示",
      cancelText = "取消",
      confirmText = "确定",
      closable = true,
      maskClosable = true,
      bodyStyle,
      headerStyle,
      type = "confirm",
      showCancel = true,
      showConfirm = true,
      getContainer,
      syncToRequest = (values, files) => ({ data: values, files }), //提交参数同步
      icon,
      onOk = () => {},
      onRef = () => {},
    }) => {
      return new Promise((resolve, reject) => {
        drawerInstance.create(
          ({ container }) => {
            const { nextZIndex } = useZIndex();
            const [visible, setVisible] = useState(true);
            useMount(() => {
              const onLocationChange = () => setVisible(false);
              emitter.on("locationChange", onLocationChange);
              return () => emitter.off("locationChange", onLocationChange);
            });
            const whenClose = (e) =>
              setTimeout(() => {
                if (!e) drawerInstance.remove();
              }, 40);
            const onSuccess = (e) => {
              if (e.result) {
                setVisible(false);
                resolve(e || true);
              } else reject(e);
            };
            onRef({
              request: (e) => {
                const param = e.files ? e : { data: e };
                if (typeof request === "string")
                  api[request](param).then(onSuccess);
                else if (typeof request === "function")
                  request(param).then(onSuccess);
                else if (isPromise(request))
                  request
                    .then((res) => Object.assign({}, res, param))
                    .then(onSuccess);
              },
            });
            return (
              <ConfigProvider locale={zhCN} theme={theme}>
                <Drawer
                  headerStyle={Object.assign(
                    {},
                    { backgroundColor: "#F5F8FA" },
                    headerStyle || {}
                  )}
                  title={
                    <div className="flex">
                      <div style={{ marginRight: "10px" }}>
                        {icons[icon] || null}
                      </div>
                      {title}
                    </div>
                  }
                  zIndex={nextZIndex()}
                  destroyOnClose={true}
                  width={width}
                  getContainer={false}
                  style={getContainer ? { position: "absolute" } : {}}
                  open={visible}
                  bodyStyle={Object.assign(
                    {},
                    { position: "relative" },
                    bodyStyle || {}
                  )}
                  afterOpenChange={whenClose}
                  forceRender
                  closable={false}
                  onClose={() => {
                    resolve(false);
                    setVisible(false);
                    setTimeout(() => {
                      drawerInstance.remove();
                    }, 0);
                  }}
                  //   extra={
                  //     <Space>
                  //       {showCancel ? (
                  //         <Button
                  //           onClick={() => {
                  //             resolve(false);
                  //             setVisible(false);
                  //           }}
                  //         >
                  //           取消
                  //         </Button>
                  //       ) : null}
                  //       {showConfirm ? (
                  //         <Button
                  //           onClick={() => {
                  //             if (request) event.emit("onOk");
                  //             else {
                  //               resolve(true);
                  //               setVisible(false);
                  //               whenClose(false);
                  //             }
                  //           }}
                  //           type="primary"
                  //         >
                  //           提交
                  //         </Button>
                  //       ) : null}
                  //       <CloseOutlined
                  //         style={{ cursor: "pointer" }}
                  //         onClick={() => {
                  //           setVisible(false);
                  //           whenClose(false);
                  //         }}
                  //       />
                  //     </Space>
                  //   }
                  extra={
                    <Space>
                      <CloseOutlined
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setVisible(false);
                          whenClose(false);
                        }}
                      />
                    </Space>
                  }
                >
                  {typeof content === "string"
                    ? content
                    : typeof content === "function"
                    ? content((e) => {
                        if (!e) {
                          setVisible(e);
                          whenClose(e);
                        }
                      })
                    : content}
                  {showCancel || showConfirm ? (
                    <div className="drawer-footer">
                      {showCancel ? (
                        <Button
                          onClick={() => {
                            resolve(false);
                            setVisible(false);
                          }}
                        >
                          取消
                        </Button>
                      ) : null}
                      {showConfirm ? (
                        <Button
                          onClick={() => {
                            if (request) onOk();
                            else {
                              resolve(true);
                              setVisible(false);
                              whenClose(false);
                            }
                          }}
                          type="primary"
                        >
                          提交
                        </Button>
                      ) : null}
                    </div>
                  ) : null}
                  {showCancel || showConfirm ? (
                    <div style={{ height: "60px" }}></div>
                  ) : null}
                </Drawer>
              </ConfigProvider>
            );
          },
          { getContainer }
        );
      });
    }
  );

  /**
   * makeForm 表单模态框
   */
  const { instance: makeFormInstance } = uface.register("makeForm");
  makeFormInstance.setHooks(
    "onExecute",
    /**
     * @param title 标题
     * @param columns 配置项
     *  @param initialValue
     */
    ({
      title,
      columns: columnsProp,
      layoutType = "ModalForm",
      initialValue,
      initialRequest, //远程获取columsn
      width,
      form,
      onRef,
      rules,
      getContainer,
      request,
      syncToRequest = (values, files) => ({ data: values, files }), //提交参数同步
      success = () => {},
      fail = () => {},
    } = {}) => {
      let formInstanceCall; //必须在组件生命周期使用
      makeFormInstance.create(
        ({ container }) => {
          const [formInstance] = useForm(form);
          formInstanceCall = formInstance;
          const [visible, setVisible] = useState(false);
          const {
            loading,
            data: response,
            isFinish,
            reload,
          } = useRequest({
            request: initialRequest,
          });
          const columns = useMemo(() => {
            return initialRequest
              ? typeof columnsProp === "function"
                ? isFinish
                  ? columnsProp(response)
                  : []
                : columnsProp
              : columnsProp;
          }, [isFinish, response]);
          useMount(() => {
            const onLocationChange = () => setVisible(false);
            emitter.on("locationChange", onLocationChange);
            setTimeout(() => {
              setVisible(true);
            }, 0);
            if (onRef) onRef({ form: formInstance });
            // if (initialValue) formInstance.setFields(initialValue); //不使用setStore，避免在columns中使用initialValue时数据被覆盖
            return () => emitter.off("locationChange", onLocationChange);
          });
          const onSuccess = (res) => {
            if (res.result) {
              setVisible(false);
              setTimeout(() => {
                makeFormInstance.remove();
              }, 0);
              success(res, formInstance);
            } else fail(res);
          };
          const onVisible = (e) => {
            if (!e) {
              setVisible(false);
              setTimeout(() => {
                makeFormInstance.remove();
              }, 40);
            }
          };
          return (
            <ConfigProvider locale={zhCN} theme={theme}>
              <FormMaker
                width={width}
                form={formInstance}
                rules={rules}
                title={title}
                loading={loading}
                columns={columns}
                initialValue={initialValue}
                layoutType={layoutType}
                visible={visible}
                setVisible={() => onVisible(false)}
                getContainer={false}
                style={getContainer ? { position: "absolute" } : {}}
                onCancel={() => onVisible(false)}
                afterVisible={reload}
                onSuccess={(e) => {
                  const formResult = formInstance.getFieldsValue(),
                    formFiles = formInstance.getStoreFiles();
                  const param = syncToRequest(formResult, formFiles);
                  console.log(
                    param,
                    "makeForm commit",
                    formResult,
                    formInstance.getStore()
                  );
                  if (request) {
                    if (typeof request === "string")
                      api[request](param).then(onSuccess);
                    else request(param).then(onSuccess);
                  } else {
                    const modalRes = success(
                      formResult,
                      formInstance,
                      onVisible
                    );
                    if (modalRes !== false) onVisible(false);
                  }
                }}
              ></FormMaker>
            </ConfigProvider>
          );
        },
        { getContainer }
      );
      return {
        form: formInstanceCall,
      };
    }
  );
  /**
   * makeEditTable  可编辑表格模态框
   */
  const { instance: makeEditTableInstance } = uface.register("makeEditTable");
  makeEditTableInstance.setHooks(
    "onExecute",
    ({
      title,
      request,
      columns,
      width = 600,
      createText,
      success = () => {},
      fail = () => {},
      syncToRequest = (values, files) => ({ data: { data: values }, files }), //提交参数同步
    }) => {
      makeEditTableInstance.create(
        ({ container }) => {
          const [form] = useForm();
          const [visible, setVisible] = useState(true);
          const onSuccess = (res) => {
            if (res.result) {
              setVisible(false);
              success(res);
            } else fail(res);
          };
          const whenClose = () =>
            setTimeout(() => {
              makeEditTableInstance.remove();
            }, 40);
          return (
            <ConfigProvider locale={zhCN} theme={theme}>
              <Modal
                title={title}
                destroyOnClose={true}
                width={width}
                getContainer={container}
                open={visible}
                bodyStyle={{
                  maxHeight: "calc(100vh - 200px)",
                  overflow: "auto",
                }}
                afterClose={whenClose}
                forceRender
                onCancel={() => {
                  setVisible(false);
                }}
                onOk={() => {
                  const formResult = form
                    .getStore()
                    .map(({ id, ...item }) => item); //过滤作为row-key的id，避免影响后台
                  const formFiles = form.getStoreFiles();
                  const param = syncToRequest(formResult, formFiles);
                  console.log(param);
                  if (request) {
                    if (typeof request === "string")
                      api[request](param).then(onSuccess);
                    else if (typeof request === "function")
                      request(param).then(onSuccess);
                    else if (isPromise(request))
                      request
                        .then((res) => Object.assign({}, res, param))
                        .then(onSuccess);
                  } else {
                    success(formResult);
                    setVisible(false);
                  }
                }}
              >
                <Table
                  request={async () => [{ id: 0 }]}
                  editable={{ form, createText }}
                  columns={columns}
                  toolbar={{ options: false }}
                ></Table>
              </Modal>
            </ConfigProvider>
          );
        },
        { isRender: false }
      );
    }
  );

  /**
   * 简易筛选
   */
  /**
   * showModal
   */
  const { instance: filterInstance } = uface.register("showFilter");
  filterInstance.setHooks(
    "onExecute",
    ({
      width,
      request,
      content,
      showCancel = true,
      title = "提示",
      cancelText = "取消",
      confirmText = "确定",
      maskClosable = true,
      type = "confirm",
      syncToRequest = (values, files) => ({ data: values, files }), //提交参数同步
      icon,
      onRef = () => {},
      onOk = () => {},
      ...rest
    }) => {
      return new Promise((resolve, reject) => {
        modalInstance.create(
          ({ container }) => {
            const [visible, setVisible] = useState(true);
            const [fullscreen, setFullscreen] = useState(false);
            const whenClose = () =>
              setTimeout(() => {
                modalInstance.remove();
              }, 40);
            const onSuccess = (e) => {
              if (e.result) {
                setVisible(false);
                resolve(e || true);
              } else reject(e);
            };
            const provideRef = {
              request: (e) => {
                const param = e.files ? e : { data: e };
                if (typeof request === "string")
                  api[request](param).then(onSuccess);
                else if (typeof request === "function")
                  request(param).then(onSuccess);
                else if (isPromise(request))
                  request
                    .then((res) => Object.assign({}, res, param))
                    .then(onSuccess);
              },
              close: () => setVisible(false),
            };
            useMount(() => {
              onRef(provideRef);
            });
            return (
              <ConfigProvider locale={zhCN} theme={theme}>
                <Modal
                  style={fullscreen ? { top: 0, paddingBottom: 0 } : {}}
                  title={
                    <div>
                      {icons[icon] || null}&nbsp;&nbsp;
                      {title}
                    </div>
                  }
                  destroyOnClose={true}
                  width={fullscreen ? "100%" : width}
                  getContainer={container}
                  open={visible}
                  afterClose={whenClose}
                  forceRender
                  bodyStyle={
                    fullscreen
                      ? { height: "calc(100vh - 108px)" }
                      : { maxHeight: "calc(100vh - 200px)", overflow: "auto" }
                  }
                  okText={confirmText}
                  onCancel={() => {
                    resolve(false);
                    setVisible(false);
                  }}
                  onOk={() => {
                    if (request) onOk();
                    else {
                      resolve(true);
                      setVisible(false);
                    }
                  }}
                  {...rest}
                >
                  <Button
                    type="text"
                    className="ant-modal-fullscreen"
                    icon={
                      fullscreen ? (
                        <FullscreenExitOutlined />
                      ) : (
                        <FullscreenOutlined />
                      )
                    }
                    onClick={() => setFullscreen(!fullscreen)}
                  ></Button>
                  {typeof content === "string"
                    ? content
                    : typeof content === "function"
                    ? content()
                    : content}
                </Modal>
              </ConfigProvider>
            );
          },
          { isRender: true }
        );
      });
    }
  );
};
