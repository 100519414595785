import { createInstance, removeInstance } from "./instance";
// const rewriteAPi = ["jump", "showToast", "showLoading", "defineModal"]; //已重写的函数列表 when not support Proxy
const plugins = {};
class PluginsStore {
  instance;
  method;
  hooks = {};
  constructor(method) {
    this.method = method;
  }
  create = (...rest) => {
    this.instance = createInstance(...rest);
    // plugins[this.method].instance = instance;
  };
  remove = () => {
    const method = this.method;
    if (!this.instance) return;
    removeInstance(this.instance);
    this.instance = null;
    if (this.hooks.onRemove) this.hooks.onRemove();
  };
  setHooks = (name, fn) => {
    this.hooks[name] = fn;
  };
  execute = (...rest) => {
    if (this.hooks.onExecute) return this.hooks.onExecute(...rest);
  };
}
/**
 * 插件系统
 * 使用
 * const {create,remove}=uface.register('loading',<div>loading...</div>)
 * create()
 * setTimeout(()=>{
 *   remove()
 * })
 */
function register(method) {
  const pluginsStore = new PluginsStore(method);
  plugins[method] = {
    method,
    execute: pluginsStore.execute,
  };
  return {
    instance: pluginsStore,
  };
}
const proxyAPI = (obj) => {
  return new Proxy(obj, {
    get: function (target, method, receiver) {
      if (method === "register") return register;
      if (method === "isProxy") return true;
      if (target[method]) {
        // 存在此api的重写函数
        return Reflect.get(target, method, receiver);
      } else if (plugins[method]) return plugins[method].execute;
      else
        return () => {
          throw new Error("uface hasn't this function");
        };
    },
  });
};

export default function (target) {
  return proxyAPI(target);
}
