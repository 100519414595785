import useProvider, { Context } from "./useProvider";
import FormItem from "./FormItem";
import FormDependency from "./FormDependency";
import useForm from "./useForm";
import { useMemo, useRef, useEffect } from "react";
import Field from "./useForm/Field";
import Submitter from "./Submitter";
import EventEmitter from "@widget/core/EventEmitter";
import purefn from "@widget/purefn";
import classNameFn from "@utils/className";
import { useRefState, useMount } from "@utils/hooks";
/**
 * form: 使用useForm外部控制
 * 为什么设置 defaultValue 不生效？#
因为 Field 子组件会转为受控模式。因而 defaultValue 不会生效。需要在 Form 上通过 initialValues 设置默认值。
 * model: Object,
            rules: Object,
            // right/left/top
            labelAlign: {
                validator(value) {
                    return ['left', 'right', 'justify'].includes(value);
                },
                default: 'right'
            },
			layout
            labelWidth: {
                type:[String, Number],
                default:80
            },
            wrapperWidth:[String,Number],
            // 可选值:message,toast
            errorType: {
                type: Array,
                default: () => ['message','toast']
            },
            inline: {
                type: Boolean,
                default: false
            },
			@param submitter 提交按钮相关配置 {position,searchConfig,render:()=>[] }
*/
const Form = (props) => {
  const {
    forceUpdateType,
    className,
    component: Component = "form",
    form,
    grid,
    initialValue,
    contentRender,
    submitter = false,
    onChange = () => {},
    onUpdateValue = () => {},
    labelWidth,
    errorType,
    layout,
    labelAlign = "right",
    rules,
    onCancel,
    onSubmit,
    ...restProps
  } = props;
  // const [formInstance] = useForm(form, (curUpdate, updateStack) => {
  //   if (!form) return curUpdate;
  //   if (forceUpdateType === "form") return curUpdate;
  //   else
  //     return updateStack.filter(
  //       (d) => !(typeof d.canUpdate === "boolean" && !d.canUpdate)
  //     )[0].forceUpdate;
  // });
  const [formInstance] = useForm(form, true, "form");
  console.log("form-render");
  const { setHooks, offHooks } = formInstance.getHooks();
  const storeRef = useRef(new Set());
  const { fields, addField } = useProvider();
  const eventRef = useRef(new EventEmitter());
  const throughValue = useMemo(
    () => ({
      labelWidth,
      errorType: errorType || ["message", "toast"],
      layout: layout || "horizon",
      labelAlign: labelAlign || "right",
      rules: rules || {},
      addField,
      store: storeRef.current, //保存校验地FormItem
      event: eventRef.current, //事件系统
      ...formInstance,
    }),
    // [formInstance, props]
    [formInstance, props, formInstance.getUpdater()]
  );
  //   labelwidth默认计算，取所有form-item最大值
  const widthRef = useRef([]);
  const setLabelWidth = () =>
    eventRef.current.emit("set-label-width", Math.max(...widthRef.current));
  eventRef.current.on("label-width", (width) => {
    widthRef.current.push(width);
    const setLabelWidthDebounce = purefn.debounce(setLabelWidth, 100);
    setLabelWidthDebounce();
  });
  //   判断required,则为对齐给label添加空格
  const requiredRef = useRef([]);
  const setRequired = () =>
    eventRef.current.emit(
      "set-required",
      requiredRef.current.find((d) => d)
    );
  eventRef.current.on("required", (val) => {
    requiredRef.current.push(val);
    const setRequiredDebounce = purefn.debounce(setRequired, 40);
    setRequiredDebounce();
  });
  const content = useMemo(() => {
    if (contentRender) return contentRender(formInstance);
    else return props.children;
  }, [contentRender, props.children, formInstance]);

  const submitterDom = (
    <Submitter onCancel={onCancel} submitter={submitter}></Submitter>
  );

  const resetFields = () => {
    storeRef.current.forEach((field) => {
      field.resetField();
    });
  };
  const validateField = (prop) => {
    const field = storeRef.current.filter((field) => field.prop === prop)[0];
    if (!field) {
      throw new Error(
        "[iView warn]: must call validateField with valid prop string!"
      );
    }
  };
  const validate = () =>
    new Promise((resolve) => {
      let isValid = true;
      const LEN = storeRef.current.size;
      if (LEN === 0) {
        resolve(isValid);
        return;
      }
      let cursor = 0;
      storeRef.current.forEach((item) => {
        item.validate((error) => {
          if (error) isValid = false;
        });
        if (LEN - 1 === cursor) {
          resolve(isValid);
          return;
        }
        cursor++;
      });
    });
  useEffect(() => {
    if (initialValue) formInstance.setFields(initialValue); //不使用setStore，避免在columns中使用initialValue时数据被覆盖
  }, [initialValue]);
  let [isSubmit, setIsSubmit] = useRefState(false);
  const validateTimer = useRef(null);
  useEffect(() => {
    const hooks = {
      //(val,oldVal,name)
      onUpdateValue: (e) => {
        onUpdateValue(e);
      },
      onChange: () => {
        if (validateTimer.current) clearTimeout(validateTimer.current);
        if (isSubmit.current)
          validateTimer.current = setTimeout(() => {
            validate().then(() => {});
          }, 400);
        onChange(formInstance.getStore());
      },
      onSubmit: (value) => {
        setIsSubmit(true);
        if (onSubmit)
          validate().then((isValid) => {
            // if (isValid) onSubmit(value);
            const res = formInstance.getRequestStore();
            if (isValid) {
              if (res.files) onSubmit(res.data, res.files);
              else onSubmit(res);
            }
          });
      },
    };
    setHooks(hooks);
    return () => offHooks(hooks);
  });
  return (
    <Component
      className={classNameFn("u-form", className)}
      onReset={(e) => {
        e.preventDefault();
        e.stopPropagation();
        formInstance.setStore({});
        formInstance.submit();
      }}
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        formInstance.submit();
      }}
      {...restProps}
    >
      <Context.Provider value={throughValue}>{content}</Context.Provider>
      {submitterDom}
    </Component>
  );
};
Form.Item = FormItem;
Form.Dependency = FormDependency;
export default Form;
export { Field, FormItem, useForm };
