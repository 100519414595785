import { useEffect, useMemo, useRef, useState } from "react";
import { Alert } from "antd";
import "./index.less";
import ChooseFile, { mime } from "@components/ChooseFile";
import { uface } from "@widget";
import { getObjectURL } from "@utils/file";

/**
 *  size:{
                type:String,
                default:'default'
            },
            limit:Number
*/
export default ({
  size = "default",
  limit,
  value,
  handleValue = (e) =>
    e ? (typeof e === "string" ? [e] : e.filter(Boolean) || []) : [],
  onChange,
  onDelete,
}) => {
  const files = useRef([]),
    [imgs, setImgs] = useState([]);
  const style = useMemo(() => {
    const map = {
      large: {},
      default: {
        width: "100px",
        height: "100px",
      },
      small: {
        width: "80px",
        height: "80px",
      },
    };
    return map[size] || map.default;
  }, [size]);
  const add = (uploadFiles) => {
      if (limit && limit > uploadFiles.length) {
        uface.showToast({ title: `最大上传${limit}张!`, icon: "warning" });
        return false;
      }
      setImgs([...imgs, ...uploadFiles.map((item) => getObjectURL(item))]);
      files.current.push(...uploadFiles.map((item) => item));
      onChange(files.current);
    },
    remove = (delIndex) => {
      setImgs(imgs.filter((_, index) => index !== delIndex));
      files.current.splice(delIndex, 1);
      onChange(files.current);
    };
  const [showImg, setShowImg] = useState(handleValue(value));
  useEffect(() => {
    setShowImg(handleValue(value));
  }, [value]);
  return (
    <div>
      <div className="flex flex-wrap">
        {limit == 1 && showImg.length + imgs.length == 1 ? null : (
          <div className="text-center">
            <ChooseFile count={limit} size={2048} accept="video" onChange={add}>
              <div className="upload-btn" style={style}>
                <i className="large iconfont icon-jia"></i>
                <div className="text">上传</div>
              </div>
            </ChooseFile>
          </div>
        )}
        {showImg.map((item, index) => (
          <div className="upload-btn relative" style={style} key={index}>
            <i
              className="iconfont icon-shanchu11 del-icon"
              onClick={() =>
                onDelete
                  ? onDelete(item, () =>
                      setShowImg(showImg.filter((_, i) => index !== i))
                    )
                  : setShowImg(showImg.filter((_, i) => index !== i))
              }
            ></i>
            <video style={{ width: "100%", height: "100%" }} autoPlay controls>
              <source src={typeof item === "string" ? item : item.path} />
            </video>
          </div>
        ))}
        {imgs.map((item, index) => (
          <div className="upload-btn relative" style={style} key={index}>
            <i
              className="iconfont icon-shanchu11 del-icon"
              onClick={() => remove(index)}
            ></i>
            <video style={{ width: "100%", height: "100%" }} autoPlay controls>
              <source
                src={typeof item === "string" ? item : item.path}
                type="video/mp4"
              />
              <source
                src={typeof item === "string" ? item : item.path}
                type="video/ogg"
              />
              <source
                src={typeof item === "string" ? item : item.path}
                type="video/webm"
              />
            </video>
          </div>
        ))}
      </div>
      <Alert
        message={`允许上传的格式：${mime.video.split(",").join(" ")}`}
        type="info"
        style={{ marginTop: "6px" }}
      />
    </div>
  );
};
