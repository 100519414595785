import { useEffect, useRef, useState } from "react";
import { set, get, pick, isEmptyObject } from "@utils/field";
import purefn from "@widget/purefn";
import aider from "@widget/aider";
import { random } from "@utils/str";
import { isInValid } from "@utils/is";
function spreadName(name) {
  if (typeof name === "string") return [name];
  return name;
}
/**
 * 允许name以变量方式解析
 * pic： 图片 file： 文件
 */
function handleFileName(name, file) {
  const type = /image.*/.test(file.type)
    ? "pic"
    : /video.*/.test(file.type)
    ? "video"
    : "file";
  return name.replace(/\{\$type\}/, type);
}
function handleFiles(fileobj) {
  return Object.keys(fileobj).reduce((accu, cur) => {
    const item = fileobj[cur];
    if (item) {
      if (item instanceof Array)
        item.forEach((file) =>
          accu.push({ name: handleFileName(cur, file), file })
        );
      else accu.push({ name: handleFileName(cur, item), file: item });
    }
    return accu;
  }, []);
}
/**
 * In rc-form, we support like user.name to be a name and convert value to { user: { name: 'Bamboo' } }. This makes '.' always be the route of variable, this makes developer have to do additional work if name is real contains a point like app.config.start to be app_config_start and parse back to point when submit.

* Field Form will only trade ['user', 'name'] to be { user: { name: 'Bamboo' } }, and user.name to be { ['user.name']: 'Bamboo' }
* store字段存放：name:'a'  或name:'a.b'
存储值和api提交吻合，分为data和files，最终提交给api
Field实现：value和onChange 图片上传需自行判断value并填充
*/
class FormStore {
  prevStore = {};
  store = {}; //存放表单值
  raw = {}; //存放表单原始值
  storeFiles = {}; //存放文件
  updater = random(12); //更新数，以便Form判断是否更新
  fieldChanged = {};
  forceUpdate = undefined;
  updateStack = []; //绑定组件更新函数栈，用于判断是否可更新
  emitter = new aider.EventEmitter();
  constructor(forceUpdate) {
    // this.forceUpdate = purefn.debounce(() => forceUpdate(), 0);
    this.forceUpdate = forceUpdate;
  }
  addUpdateStack = (fn) => {
    const index = this.updateStack.findIndex((d) => d.identity == fn.identity);
    if (index !== -1) {
      this.updateStack[index] = fn;
    } else this.updateStack.push(fn);
    console.log("tttttttt", this.updateStack);
    const updateStack = this.updateStack.filter((d) => d.identity === "form");
    if (updateStack.length > 1)
      console.log(
        "aiaii",
        updateStack,
        updateStack[0].forceUpdate == updateStack[1].forceUpdate,
        updateStack[0].form == updateStack[1].form
      );
    // this.addStacktimer && clearTimeout(this.addStacktimer);
    // this.addStacktimer = setTimeout(() => {
    const d = this.updateStack
      .sort((a, b) => Number(b.canUpdate) - Number(a.canUpdate))
      .find((d) => d.canUpdate);
    console.log(d, "kkkkkkk");
    //   const d = this.updateStack[this.updateStack.length - 1];
    this.setUpdate(d && d.forceUpdate);
    // }, 0);
  };
  getUpdateStack = () => {
    let temp = [];
    this.updateStack.forEach((d) => {
      if (d.identity) {
        if (temp.find((e) => e.identity == d.identity)) return;
        const item = this.updateStack.filter((e) => e.identity === d.identity);
        console.log(item, "ayayayayayayayayay");
        temp.push(item[item.length - 1]);
      } else temp.push(d);
    });
    return temp;
  };
  delUpdateStack = (fn) => {
    this.updateStack = this.updateStack.filter(fn);
  };
  setUpdate = (forceUpdate) => {
    // this.forceUpdate = purefn.debounce(() => forceUpdate(), 0);
    this.forceUpdate = forceUpdate;
  };
  getForceUpdate = () => this.forceUpdate;
  notifyUpdate = () => {
    // this.updateTimer && clearTimeout(this.updateTimer);
    // this.updateTimer = setTimeout(() => {
    console.log("notifyUpdate", this.forceUpdate, this.updater);
    this.updater = random(12);
    if (this.forceUpdate) this.forceUpdate();
    // }, 0);
  };

  getFieldValue = (name) => {
    return get(this.store, name);
  };
  getFieldsValue = (nameList, filterFun) => {
    if (!nameList && !filterFun) return purefn.clone(this.store);
  };
  setStore = (store) => {
    const prevStore = this.store;
    if (store) {
      this.store = purefn.clone(store);
      this.emitter.emit("onSetStore", store);
      this.emitter.emit("onChange", store, prevStore);
      this.notifyUpdate();
    }
  };
  getStore = () => {
    if (this.store instanceof Array) return purefn.clone(this.store);
    if (Object.keys(this.storeFiles).length > 0)
      return Object.assign({}, purefn.clone(this.store), this.storeFiles);
    return purefn.clone(this.store);
  };
  //可编辑表格
  forEach = (fn) => {
    const store = this.store;
    if (store instanceof Array) store.forEach(fn);
    else if (store instanceof Object)
      Object.keys(store).forEach((key, i) => fn(store[key], key, i));
  };
  reduce = (fn, initial) => {
    const store = this.store;
    if (store instanceof Array) return store.reduce(fn, initial);
    else if (store instanceof Object)
      return Object.keys(store).reduce(
        (accu, key, i) => fn(accu, store[key], key, i, store),
        initial
      );
  };
  /**   请求可用 场景：可编辑表格
   * 去掉autoID getStore为编辑考虑不可去掉autoID
   */
  getRequestStore = () => {
    const filesKey = Object.keys(this.storeFiles);
    let data = purefn.clone(this.store);
    if (this.store instanceof Array)
      return purefn
        .clone(this.store)
        .map((d) => pick(d, "!autoID", "*"))
        .filter((d) => (typeof d === "object" ? !isEmptyObject(d) : true));
    else if (data instanceof Object)
      data = new purefn.ObjUtil(data).filter(
        (val, key) => !filesKey.includes(key)
      ).value;
    return {
      data,
      files: handleFiles(this.storeFiles),
    };
  };
  getRaw = (key) => {
    const raw = this.raw;
    return Object.keys(raw).reduce((accu, cur) => {
      if (key) accu[cur] = raw[cur] && raw[cur][key];
      else accu[cur] = raw[cur];
      return accu;
    }, {});
  };
  setRaw = (store) => (this.raw = store);
  getStoreFiles = (name) => {
    const files = this.storeFiles;
    if (name)
      return Object.keys(files).reduce((accu, key) => {
        if (files[key] instanceof Array)
          accu = accu.concat(
            files[key].map((file) => ({
              name: key.replace(/\{\$name\}/, name),
              file,
            }))
          );
        else
          accu.push({
            name: key.replace(/\{\$name\}/, name),
            file: files[key],
          });
        return accu;
      }, []);
    else return handleFiles(files);
  };
  setFieldRawValue = (name, value) => {
    set(this.raw, name, value);
  };
  setFieldValue = (name, value, { update = true } = {}) => {
    console.log("set-file-value", name, value);
    const prevValue = get(this.store, name);
    // 设置editable单元项
    if (
      this.store instanceof Array &&
      purefn.typeUtil("getType")(value) === "object"
    )
      set(this.store, name, Object.assign({}, prevValue, value));
    else set(this.store, name, value);
    this.fieldChanged = { name, value };
    if (update) {
      this.notifyUpdate(); //值更改后必须要更新，否则受控组件显示被冻结
      this.emitter.emit("onChange", value, prevValue, name);
    }
  };
  getFieldValueChanged = () => this.fieldChanged;
  /**
   * 单独设置assign字段
   */
  setField = (name, value) => {
    console.log("ajahahah", name, value, value instanceof Array);
    if (value instanceof Array)
      set(this.store, name, value); //由于set不支持数组
    //   value.forEach((item, i) => {
    //     // this.setField([...name, i], item);
    //   });
    else if (value instanceof Object)
      Object.keys(value).forEach((key) => {
        this.setField([...name, key], value[key]);
      });
    else set(this.store, name, value);
  };
  /**
   * 覆盖store 对于复杂数据进行部分覆盖，
   * 场景 Form初始化 initName时，防止initialValue覆盖重置了initiName的字段
   */
  setFields = (fields) => {
    const prevStore = this.store;
    if (fields instanceof Array)
      fields.forEach((field) => {
        const { name, value } = field;
        set(this.store, name, value);
      });
    else if (fields instanceof Object)
      Object.keys(fields).forEach((key) => {
        this.setField([key], fields[key]);
      });
    this.notifyUpdate();
  };
  setFieldForExist = (name, value) => {
    if (value instanceof Object)
      Object.keys(value).forEach((key) => {
        if (get(this.store, name).includes(key))
          this.setField([...name, key], value[key]);
      });
    else if (value instanceof Array)
      value.forEach((item, i) => {
        this.setField([...name, i], item);
      });
    else set(this.store, name, value);
  };
  setFieldsForExist = (fields) => {
    const store = this.store;
    if (fields instanceof Object)
      Object.keys(fields).forEach((key) => {
        if (store.hasOwnProperty(key)) {
          if (typeof fields[key] === "object")
            this.setFieldForExist([key], fields[key]);
          else set(this.store, key, fields[key]);
        }
      });
  };
  clearFieldValueByStore(fields, keys = []) {
    console.log(fields);
    Object.keys(fields).forEach((key) => {
      if (typeof fields[key] === "object")
        this.clearFieldValueByStore(fields[key], [...keys, key]);
      else set(this.store, [...keys, key], undefined);
    });
  }
  clearFieldsValue = () => {
    const fields = this.store;
    if (fields instanceof Array)
      fields.forEach((field) => {
        const { name } = field;
        set(this.store, name, undefined);
      });
    else if (fields instanceof Object) this.clearFieldValueByStore(fields);

    this.notifyUpdate();
  };

  setFileValue = (name, value) => {
    this.storeFiles[name] = value;
  };
  setFiles = (fields) => {
    const prevStore = this.storeFiles;
    if (fields instanceof Array)
      fields.forEach((field) => {
        const { name, value } = field;
        set(this.storeFiles, name, value);
      });
    else if (fields instanceof Object)
      Object.keys(fields).forEach((key) => {
        set(this.storeFiles, key, fields[key]);
      });
    this.notifyUpdate();
  };
  updateValue = (name, value, raw) => {
    const prevValue = this.getFieldValue(name);
    if (get(this.store, name, value) !== value) this.setFieldValue(name, value);
    this.emitter.emit("onUpdateValue", {
      value,
      prevValue,
      name,
      raw: raw,
    });
    // this.emitter.emit("onChange", value, prevValue, name);
  };
  updateFileValue = (name, value) => {
    this.setFileValue(name, value);
  };
  submit = () => {
    const fieldsValue = this.getFieldsValue();
    this.emitter.emit("onSubmit", fieldsValue);
  };
  //   event
  emit = (action) => {
    console.log("field action", action);
    let { name, value, raw } = action;
    switch (action.type) {
      case "updateValue":
        this.setFieldRawValue([...spreadName(name), "raw"], raw);
        this.updateValue(name, value, raw);
        break;
      case "updateFileValue":
        this.updateFileValue(name, value);
        break;
      case "initName":
        /*初始化store键名，防止 setFields将多余数据加入仓库,不触发渲染  避免validate 嵌套字段fields父字段导致unudefined报错  如果直接初始化调用setStore,则如果先调用setFieldsValue填充会导致置空其他字段 TODO
         * 使用''作为初始值会导致select认为值为空而不显示placeholder
         **/
        console.log(
          "initName Field",
          name,
          get(this.store, name),
          isInValid(get(this.store, name))
        );
        if (isInValid(get(this.store, name))) {
          if (action.updateType === "updateValue")
            set(this.store, name, undefined);
          else if (action.updateType === "updateFileValue")
            set(this.storeFiles, name, undefined);
        }
        break;
      case "updateRawText":
        this.setFieldRawValue([...spreadName(name), "text"], value);
        break;

      default:
        break;
    }
  };
  //   hooks
  /**
   * @param hooks object
   */
  setHooks = (hooks) => {
    Object.keys(hooks).forEach((key) => this.emitter.on(key, hooks[key]));
  };
  setHook = (...rest) => this.emitter.on(...rest);
  offHook = (...rest) => this.emitter.off(...rest);
  offHooks = (hooks) => {
    if (hooks)
      Object.keys(hooks).forEach((key) => this.emitter.off(key, hooks[key]));
    else this.emitter.off();
  };
  //    setHooks = (hooks) => {
  //     this.hooks = { ...this.hooks, ...hooks };
  //   };
  //   offHook = (name) => delete this.hooks[name];
  //   emitHook = (name, ...rest) =>
  //     Object.keys(this.hooks).forEach((key) => {
  //       if (key && key.includes(name)) this.hooks[key](...rest);
  //     });
  getHooks = () => {
    return {
      setHooks: this.setHooks,
      setHook: this.setHook,
      offHook: this.offHook,
      offHooks: this.offHooks,
    };
  };
  getInternalFn = () => ({
    setUpdate: this.setUpdate,
    addUpdateStack: this.addUpdateStack,
    getUpdateStack: this.getUpdateStack,
    delUpdateStack: this.delUpdateStack,
    setStore: this.setStore,
    getStore: this.getStore,
    forEach: this.forEach,
    reduce: this.reduce,
    getRequestStore: this.getRequestStore,
    getRaw: this.getRaw,
    setRaw: this.setRaw,
    getStoreFiles: this.getStoreFiles,
    getFieldValue: this.getFieldValue,
    setFileValue: this.setFileValue,
    setFiles: this.setFiles,
    setFields: this.setFields,
    setFieldsForExist: this.setFieldsForExist,
    getFieldValueChanged: this.getFieldValueChanged,
    getFieldsValue: this.getFieldsValue,
    setFieldValue: this.setFieldValue,
    clearFieldsValue: this.clearFieldsValue,
    submit: this.submit,
    emit: this.emit,
    getHooks: this.getHooks,
    getUpdater: () => this.updater,
    forceUpdate: () => this.forceUpdate(),
    getForceUpdate: this.getForceUpdate,
  });
}
/**
 * canUpdate 当作为Form的hook时绑定update函数，避免使用Form的组件触发渲染（当form触发change事件时），仅在Form组件重新渲染
 * 场景，页面使用EditTable,传入useForm,dataSource字面量异步更新，导致 Table action.setData(dataSource)->form.setStore->form.onChange->useForm update->页面再次渲染->新的dataSource字面量->Table.action.setData
 * 也可用useMemo防止产生新的字面量对象
 *
 * 更新流程 从根组件-Form 若canUpdate则使用此forceUpdate,直到Form，最终没有forceUpdate则使用Form的forceUpdate
 *
 * canUpdate 强制从当前组件更新
 * identity:为区分函数组件重复渲染
 */
export default (form, canUpdate = 0, identity) => {
  const formRef = useRef(null);
  const [, setUpdate] = useState({}); //考虑到hooks旧闭包链，需要每次更新forceUpdate
  /**
   * 通过setState触发渲染，将steValue改变传递到下层  下辖所有组件都更新，考虑单独更新改变的field，提高性能
   * 抢占式注入forceUpdater,处理手动传入Form Field导致不可更新的情况
   */
  const forceUpdate = () => setUpdate({});
  if (!formRef.current) {
    if (form) {
      formRef.current = form;
    } else {
      const formStore = new FormStore();
      formRef.current = formStore.getInternalFn();
    }
  }
  useEffect(() => {
    formRef.current.addUpdateStack({
      forceUpdate,
      canUpdate,
      identity,
      form,
    });
    return () => {
      formRef.current.delUpdateStack((d) => d.forceUpdate !== forceUpdate);
    };
  }, [forceUpdate]);

  return [formRef.current];
};
