// Here is a list of the toolbar
// Detail list see https://www.tinymce.com/docs/advanced/editor-control-identifiers/#toolbarcontrols
// 组件中引入的toolbar.js文件存的是TinyMCE初始化时加载的工具栏控件, 设置的顺序即代表着在编辑器工具栏上出现的顺序
//toolbar 字体 https://www.tiny.cloud/docs/tinymce/6/available-toolbar-buttons/
const toolbar = [
  "undo redo | fontfamily fontsize blocks | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat bold italic underline strikethrough alignleft aligncenter alignright outdent indent  blockquote removeformat subscript superscript code codesample",
  "bullist numlist link image uploadimage uploadfile charmap preview anchor hr  insertdatetime media table fullscreen wordcount | searchreplace exportWord print",
];

export default toolbar;
