import Form, { Input } from "@components/Form";
import uface from "@widget/uface";
import { Card } from "antd";
import api from "@api";
const ChangePassword = () => {
  const commit = (e) => {
    const { newPassword, repeatPass, oldPassword } = e;
    if (!String(newPassword)) {
      uface.showToast({ title: "请输入密码!", icon: "warning" });
      return false;
    }
    if (newPassword !== repeatPass) {
      uface.showToast({ title: "两次密码不一致!", icon: "warning" });
      return false;
    }
    api.user
      .changPassword({
        data: { old_password: oldPassword, new_password: newPassword },
      })
      .then(({ result }) => {
        if (result) uface.jump({ mode: "back" });
      });
  };
  return (
    <Card>
      <Form
        labelWidth="200px"
        style={{ margin: "50px auto" }}
        submitter={{ container: ".admin-box" }}
        onCancel={() => uface.jump()}
        onSubmit={commit}
      >
        <Form.Item title="原密码" name="oldPassword">
          <Input type="password" />
        </Form.Item>
        <Form.Item title="新密码" name="newPassword">
          <Input type="password" />
        </Form.Item>
        <Form.Item title="确认密码" name="repeatPass">
          <Input type="password" />
        </Form.Item>
      </Form>
    </Card>
  );
};
export default ChangePassword;
