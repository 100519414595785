import Table from "@components/Table";
import uface from "@widget/uface";
import api from "@api";
import { Button, Tag, Row, Col, Descriptions, Image } from "antd";
import { pick } from "@utils/field";
import { PlusOutlined, LeftOutlined } from "@ant-design/icons";
import { filetoBase64 } from "@utils/file";
import { useRequest } from "@utils/hooks";
import Department from "./department";
import "./user.less";
import { useRef } from "react";
const descDetail = {
  姓名: "username",
  部门: "dep_name",
  手机号: "phone",
  职务: "job_name",
  权限组: "group_name",
};
const operate = (request, query, reload) => {
  uface.makeForm({
    request,
    // width: "100%",
    // getContainer: () => document.querySelector(".admin-box"),
    rules: {
      data: {
        fields: {
          phone: { phone: true },
        },
      },
    },
    syncToRequest: (data) => ({
      data: {
        ...data,
        data: {
          ...data.data,
          group_id_str: (data.data.group_id_str || []).join(","),
        },
      },
    }),
    title: (query ? "编辑" : "新增") + "用户",
    // layoutType: "DrawerForm",
    initialValue: query || {},
    columns: [
      {
        title: "姓名",
        name: ["data", "username"],
        formItemProps: {
          required: true,
        },
      },
      {
        title: "手机号",
        name: ["data", "phone"],
        formItemProps: {
          required: true,
        },
        fieldProps: {
          maxLength: 11,
        },
      },
      {
        title: "部门",
        name: ["data", "dep_id"],
        valueType: "tree:select",
        request: "CompanyUser/getDepList",
        fieldProps: {
          labelName: "dep_name",
          valueName: "id",
        },
        formItemProps: {
          required: true,
        },
      },
      {
        title: "职务",
        name: ["data", "job_id"],
        valueType: "select",
        request: api["PullDown/getJobList"](),
        labelName: "job_name",
        valueName: "id",
      },
      {
        title: "权限组",
        name: ["data", "group_id_str"],
        valueType: "select",
        request: () => api["PullDown/getUserAdminGroupList"](),
        fieldProps: {
          labelName: "group_name",
          valueName: "id",
          mode: "multiple",
        },
      },
    ],
    success() {
      reload();
    },
  });
};
export default () => {
  const columns = [
    {
      title: "用户名",
      key: "username",
      width: 160,
      render: (row) => (
        <>
          <span>{row.username}</span>
          {row.creator_sign == 1 ? (
            <span className="admin-badge">主管理员</span>
          ) : null}
        </>
      ),
    },
    {
      title: "所属部门",
      key: "dep_name",
      width: 160,
    },
    {
      title: "手机号",
      key: "phone",
    },
    {
      title: "职务",
      key: "job_name",
    },
    {
      title: "权限组",
      key: "group_name",
    },
    {
      title: "操作",
      valueType: "operation",
      width: 120,
      render: (row, action) => [
        <a
          onClick={(e) => {
            e.stopPropagation();
            operate(
              "CompanyUser/editCompanyUser",
              pick(row, "id", {
                "phone,username,job_id,group_id_str,dep_id": (d) => ({
                  data: {
                    ...d,
                    job_id: d.job_id && Number(d.job_id),
                    group_id_str: d.group_id_str
                      ? d.group_id_str.split(",").map(Number)
                      : undefined,
                  },
                }),
              }),
              action.reload
            );
          }}
        >
          编辑
        </a>,
        <a
          className="danger-color"
          onClick={(e) => {
            e.stopPropagation();
            action.delete(
              "CompanyUser/delCompanyUser",
              { id: row.id },
              row.username
            );
          }}
        >
          删除
        </a>,
      ],
    },
  ];
  const tableRef = useRef(null);
  return (
    <div className="user-page">
      <Row wrap={false}>
        <Col flex="300px">
          <Department
            onChange={(e) => {
              console.log(tableRef);
              tableRef.current.reload({ dep_id: e }, { permanent: true });
            }}
          />
        </Col>
        <Col flex="auto" style={{ padding: "24px 10px" }}>
          <Table
            ref={tableRef}
            components={{
              wrapper: "div",
            }}
            request="CompanyUser/getCompanyUserList"
            columns={columns}
            toolbar={{
              operateRender: (action) => [
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() =>
                    operate(
                      "CompanyUser/addCompanyUser",
                      undefined,
                      action.reload
                    )
                  }
                >
                  新增
                </Button>,
              ],
            }}
            search={{
              columns: [
                { name: "keywords", placeholder: "可筛选姓名/手机号/工号" },
              ],
            }}
            onRow={(row) => ({
              onClick: () =>
                uface.showDrawer({
                  width: "450px",
                  title: "个人信息",
                  // headerStyle: { display: "none" },
                  showCancel: false,
                  showConfirm: false,
                  // getContainer: () => document.querySelector(".admin-box"),
                  content: () => (
                    <div className="user-detail">
                      <div className="user-detail-info">
                        <Descriptions
                          labelStyle={{
                            width: "130px",
                            color: "#909599",
                            fontSize: "14px",
                          }}
                          column={1}
                        >
                          {Object.keys(descDetail).map((key, index) => {
                            return (
                              <Descriptions.Item label={key} key={index}>
                                {typeof descDetail[key] === "function"
                                  ? descDetail[key](row)
                                  : row[descDetail[key]]}
                              </Descriptions.Item>
                            );
                          })}
                        </Descriptions>
                      </div>
                    </div>
                  ),
                }),
            })}
          ></Table>
        </Col>
      </Row>
    </div>
  );
};
