import Table from "@components/Table";
import { Button, Image, Switch } from "antd";
import { uface } from "@widget";
import Link from "@components/Link";
import api from "@api";
import { PlusOutlined } from "@ant-design/icons";
export default () => {
  const addSwiper = (reload) =>
    uface.makeForm({
      title: "新增轮播图",
      request: "BaseMsg/addDepSwiper",
      columns: [
        {
          valueType: "imageUpload",
          name: "swiper_img",
        },
      ],
      success() {
        reload();
      },
    });
  const columns = [
    //    {
    //      title: "标题",
    //      key: "swiper_title",
    //    },{
    // 	title:'摘要',
    // 	key:'swiper_intro'
    // },
    {
      title: "图片",
      render: (row) => <Image height={50} src={row.full_swiper_img}></Image>,
    },
    {
      title: "是否启用",
      render: (row, action) => (
        <Switch
          defaultChecked={!!row.is_display}
          checked={!!row.is_display}
          onChange={(checked) => {
            uface
              .showModal({ title: `确定要${checked ? "启用" : "关闭"}吗?` })
              .then((confirm) => {
                if (confirm)
                  api["BaseMsg/setSwiperIsShow"]({
                    data: { id: row.id, display: checked },
                  }).then((res) => {
                    if (res.result) action.reload();
                  });
              });
          }}
        ></Switch>
      ),
    },
    {
      width: 100,
      title: "操作",
      valueType: "operation",
      render: (row, action) => [
        // <Link to="/base/swiper/operate" query={{ id: row.id }}>
        //   编辑
        // </Link>,
        <a
          className="danger-color"
          onClick={() =>
            action.delete(
              "BaseMsg/delDepSwiper",
              { id: row.id },
              row.swiper_title
            )
          }
        >
          删除
        </a>,
      ],
    },
  ];
  return (
    <Table
      headerTitle="轮播图列表"
      request="BaseMsg/getDepSwiperList"
      // search={{
      //   columns: [{ name: "keywords", placeholder: "请输入标题名或摘要搜索" }],
      // }}
      columns={columns}
      toolbar={{
        operateRender: (action) => [
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => addSwiper(action.reload)}
          >
            添加
          </Button>,
        ],
      }}
    ></Table>
  );
};
