import { Layout } from "antd";
import SiderMenu from "./SiderMenu";
import { useModel } from "@model";
import Collapse from "./Collapse";
import { Link } from "react-router-dom";
export default () => {
  const collapsed = useModel("ui", ({ collapsed }) => collapsed);
  return (
    <Layout.Sider
      theme="light"
      breakpoint="lg"
      collapsed={collapsed}
      style={{ overflow: "auto" }}
    >
      <SiderMenu></SiderMenu>
      {/*<div className="side-link">
        <Collapse></Collapse>
      </div>*/}
    </Layout.Sider>
  );
};
