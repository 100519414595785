import { Popover, Tabs, Spin, Pagination, Empty } from "antd";
import { Input } from "@components/Form";
import { PlusOutlined } from "@ant-design/icons";
import { useRequest, useMount } from "@utils/hooks";
import { useRef, useState, useLayoutEffect, useEffect, useMemo } from "react";
import { useZIndex } from "@utils/hooks";
import { contains } from "@utils/dom";
import { random } from "@utils/str";
import className from "@utils/className";
import api from "@api";
import purefn from "@widget/purefn";
import "./index.less";
const pageSize = 10;
const PageSelectItem = (props) => {
  const {
    width,
    request,
    query,
    labelName,
    valueName,
    addOperate,
    searchText,
    value,
    onChange = () => {},
  } = props;
  const { data, loading, reload } = useRequest({
    request,
    config: { data: { pageSize, page: 0, ...query }, loading: false },
    // onSuccess: (res) => {
    //   const selected = res.data.find((d) => d[valueName] == value);
    //   console.log(selected, value, "iiiiiiiiiiiiiiiiiiiiiii");
    //   if (selected) onChange(selected);
    //   return res;
    // },
  });
  const search = useRef(
    purefn.debounce((keywords) => reload({ keywords }), 100)
  );
  const list = useMemo(() => {
    return data.page ? data.data : data;
  }, [data]);
  useEffect(() => {
    search.current(searchText);
  }, [searchText]);
  const [current, setCurrent] = useState(1);
  return (
    <Spin spinning={loading}>
      <div className="page-select-item" style={{ width }}>
        {list && list.length > 0 ? (
          <>
            <div className="content">
              {addOperate ? (
                <div
                  className="add-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    addOperate(reload, onChange);
                  }}
                >
                  <PlusOutlined
                    style={{ color: "#6da0e4", marginRight: "10px" }}
                  />
                  新增
                </div>
              ) : null}
              {list.length > 0 ? (
                list.map((d) => (
                  <div
                    className={className(
                      "item",
                      d[valueName] == value ? "active" : null
                    )}
                    key={d[valueName]}
                    onClick={() =>
                      onChange(Object.assign({}, d, { tab: props }))
                    }
                  >
                    {d[labelName]}
                  </div>
                ))
              ) : (
                <Empty></Empty>
              )}
            </div>
            {data.page ? (
              <Pagination
                size="small"
                style={{ textAlign: "center" }}
                pageSize={pageSize}
                total={data.page.rowcount}
                current={current}
                onChange={(page) => {
                  setCurrent(page);
                  reload({ page: page - 1 });
                }}
              />
            ) : null}
          </>
        ) : (
          <Empty></Empty>
        )}
      </div>
    </Spin>
  );
};
export { PageSelectItem };
/**
 * tabs [{label,key}]
 */
const PageSelect = ({
  name,
  title,
  addonAfter,
  placeholder,
  labelName = "label",
  valueName = "value",
  value,
  showValue,
  onChange = () => {},
  ...rest
}) => {
  const popoverRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const inputRef = useRef(null);
  const [width, setWidth] = useState("");
  const { nextZIndex } = useZIndex();
  useLayoutEffect(() => {
    setTimeout(() => {
      if (inputRef.current) setWidth(inputRef.current.input.offsetWidth);
    }, 140);
  }, []);
  useEffect(() => {
    function clickoutside(e) {
      const popup =
        popoverRef.current && popoverRef.current.popupRef.current
          ? popoverRef.current.popupRef.current.getElement()
          : null;
      const trigger =
        popoverRef.current && popoverRef.current.triggerRef.current
          ? popoverRef.current.triggerRef.current.input.parentNode
          : null;
      if (e.target.tagName === "BODY" && e.target === document.body) return;
      //为了保证popover content内的内容始终有效，不能切换content内显示，否则判断为焦点移出
      //防止点中input-wrapper的span
      if (contains(popup, e.target) || contains(trigger, e.target)) return;
      setSearchText(null);
      setOpen(false);
    }
    document.addEventListener("click", clickoutside);
    return () => {
      document.removeEventListener("click", clickoutside);
    };
  }, []);
  useEffect(() => {
    if (typeof value === "object") {
      setSelected(value[labelName]);
      onChange(value[valueName], value);
    } else if (!selected && value && rest.request)
      api[rest.request]({ loading: false, data: { id: value } }).then((res) => {
        if (res.data && res.data[0]) {
          setSelected(res.data[0][labelName]);
          onChange(res.data[0][valueName], res.data[0]);
        }
      });
  }, [value]);
  return (
    <Popover
      ref={popoverRef}
      open={open}
      placement="bottom"
      content={
        <PageSelectItem
          width={width}
          labelName={labelName}
          valueName={valueName}
          value={value}
          onChange={(e) => {
            setSearchText(undefined);
            setSelected(e[labelName]);
            onChange(e[valueName], e);
            setOpen(false);
          }}
          searchText={searchText}
          {...rest}
        />
      }
      trigger="click"
      zIndex={nextZIndex()}
    >
      <Input
        ref={inputRef}
        placeholder={placeholder || "请选择" + title}
        allowClear
        addonAfter={
          typeof addonAfter === "function" ? addonAfter(name) : addonAfter
        }
        value={searchText || selected}
        showValue={showValue}
        onFocus={() => setOpen(true)}
        onChange={(e) => {
          setSearchText(e.target.value);
          if (e.type === "click" && selected) {
            setSelected(null);
            onChange(undefined);
            inputRef.current.blur();
          }
        }}
      ></Input>
    </Popover>
  );
};
export default PageSelect;
