import { FormMaker } from "@components/Form";
import { useRequest } from "@hooks";
import api from "@api";
import { pick } from "@utils/field";
import { useModel } from "@model";
import uface from "@widget/uface";
import { Card } from "antd";
const Page = () => {
  const { userinfo, setUserinfo } = useModel(
    "login",
    ({ userinfo, setUserinfo }) => ({
      userinfo,
      setUserinfo,
    })
  );
  const columns = [
    {
      title: "公司名称",
      name: ["data", "company_name"],
    },
    {
      title: "公司地址",
      name: ["data", "address"],
    },
    {
      title: "公司简介",
      name: ["data", "company_content"],
      valueType: "textarea",
      autoSize: { minRows: 5, maxRows: 20 },
    },
    {
      title: "公司logo",
      valueType: "upload:image",
      name: ["comp_pic"],
      limit: 1,
      onDelete: (d, del) => {
        uface
          .showModal({
            title: "警告",
            content: "确定要删除公司logo吗?",
            icon: "warning",
          })
          .then((confirm) => {
            if (confirm)
              api["CompanyUser/delCompanyLogo"]().then((res) => {
                if (res.result) del();
              });
          });
      },
    },
  ];
  const { data } = useRequest({
    request: "CompanyUser/getCompanyDetail",
  });
  const submit = (values, files) => {
    api["CompanyUser/editCompany"]({ data: values, files }).then((res) => {
      if (res.result) {
        api["CompanyUser/getCompanyDetail"]().then((detail) => {
          setUserinfo(Object.assign({}, userinfo, detail));
          window.location.reload();
        });
      }
    });
  };
  return (
    <Card>
      <FormMaker
        initialValue={pick(
          data,
          "company_logo|comp_pic",
          ({ id, company_name, address, company_content }) => ({
            data: {
              id,
              company_name,
              address,
              company_content,
            },
          })
        )}
        columns={columns}
        submitter={{ container: ".admin-box" }}
        onCancel={() => uface.jump()}
        onSubmit={submit}
      ></FormMaker>
    </Card>
  );
};
export default Page;
