import ObjUtil from "../../ObjUtil";
import { emitter } from "../utils";
import createError from "../helper/createError";
import defaultTransform from "./defaultTransform";
import buffer from "./buffer";
import utils from "../utils";
import handlerResponse from "./handlerResponse";
//throw cancel
function throwIfCancel(config) {
  emitter.on(config.cancelToken, () => {
    throw "request cancel";
  });
}
const transformMethod = {
  default: defaultTransform,
  buffer,
};
// 发起请求
function createXMLHttpRequest() {
  // IE
  if (window.ActiveXObject)
    return new window.ActiveXObject("Microsoft.XMLHTTP");
  //判断是否是Netscape等其他支持XMLHttpRequest组件的浏览器
  else return new XMLHttpRequest();
}
/**
 *
 * @param {*} config
 * @param timeout 超时时间 单位ms
 * https: //blog.csdn.net/qq_29569183/article/details/79259889  https://www.cnblogs.com/shiyou00/p/10385041.html  https://www.jianshu.com/p/22f82be980fb
 * https://blog.csdn.net/qq_42759386/article/details/93622287
 *  AJAX全称为“Asynchronous JavaScript and XML”（异步JavaScript和XML），是一种创建交互式网页应用的网页开发技术。Ajax的工作原理相当于在用户和服务器之间加了—个中间层(AJAX引擎),使用户操作与服务器响应异步化。并不是所有的用户请求都提交给服务器,像—些数据验证和数据处理等都交给Ajax引擎自己来做, 只有确定需要从服务器读取新数据时再由Ajax引擎代为向服务器提交请求。
 * 创建ajax异步调用步骤
 * 1.创建异步调用对象，
 * 2.设置请求参数，如请求方法等
 * 3.指定监听函数，获取返回值
 * 发送http请求
 */
export default async function (config) {
  let {
    url,
    method = "post",
    data = {},
    header = {},
    async = true,
    success = () => {},
    fail = () => {},
    complete = () => {},
    timeout = 1000 * 60 * 3,
  } = config;
  if (config.cancelRepeatRequest && config.cancelRepeatRequestResponse) {
    success(config.cancelRepeatRequestResponse);
    return;
  }
  if (!config.header) config.header = {};
  // header
  // if (!header['Content-Type']) header["Content-Type"] = "application/json;charset=UTF-8";
  // 1.创建http调用对象
  let request = createXMLHttpRequest();
  if (!request) throw new Error("can not load XMLHttpRequest");
  // handle config files
  if (!config.files && config.fileName && config.file)
    config.files = [
      {
        name: config.fileName,
        file: config.file,
      },
    ];
  request.timeout = timeout;
  // 处理get请求参数
  let requestData = Object.assign({}, data || {});
  if (method === "get")
    url += (url.includes("?") ? "" : "?") + ObjUtil.queryString(data);
  else {
    if (utils.isFormData(config.data)) config.transformData = "default";
    const handleData =
        transformMethod[config.transformData] || transformMethod.default,
      result = await handleData(config);
    requestData = result.then ? await result : result;
  }

  request.onreadystatechange = function () {
    // 0: 请求未初始化
    // 1: 服务器连接已建立
    // 2: 请求已接收
    // 3: 请求处理中
    // 4: 请求已完成， 且响应已就绪
    if (!request || request.readyState !== 4) {
      return;
    }

    // The request errored out and we didn't get a response, this will be
    // handled by onerror instead
    // With one exception: request that using file: protocol, most browsers
    // will return status as 0 even though it's a successful request
    if (
      request.status === 0 &&
      !(request.responseURL && request.responseURL.indexOf("file:") === 0)
    ) {
      return;
    }
    if (request.readyState == 4) {
      throwIfCancel(config);
      // RresponseText： 获得字符串形式的响应数据
      // responseXML： 获得XML形式的响应数据
      // status和statusText： 以数字和文本形式返回HTTP状态码
      // getAllResponseHeader()： 获取所有的响应报头
      // getResponseHeader()： 查询响应头中的某个字段的值
      // Prepare the response
      const responseHeaders =
        "getAllResponseHeaders" in request
          ? decodeURIComponent(escape(request.getAllResponseHeaders()))
              .split("\r\n")
              .reduce((accu, cur) => {
                const [key, value] = cur.split(":");
                if (!value || !key) return accu;
                accu[key.trim()] = value.trim();
                return accu;
              }, {})
          : ["server", "content-type", "date"].reduce(
              (accumulator, current) => {
                accumulator[current] = request.getResponseHeader(current);
                return accumulator;
              },
              {}
            );
      let response;
      try {
        response = {
          header: responseHeaders,
          config,
          statusCode: request.status,
          data: handlerResponse(request),
        };
      } catch (err) {
        fail(createError("返回数据格式错误", config, "RESPONSEERROR", request));
        throw new Error(err);
      }
      //判断XMLHttpRequest对象的readyState属性值是否为4，如果为4表示异步调用完成
      complete(response);
      //设置获取数据的语句 status=200说明服务器成功响应返回的结果是正确的
      if (request.status == 200 || request.status == 304) {
        //这里是获得响应的状态码，200代表成功，304代表无修改可以直接从缓存中读取
        success(response);
      } else {
        fail(
          createError(
            "请求失败，status=" + request.status,
            config,
            "REQUESTFAIL",
            request,
            response
          )
        );
      }
      request = null;
    }
  };
  // Handle browser request cancellation (as opposed to a manual cancellation)
  request.onabort = function handleAbort() {
    if (!request) {
      return;
    }
    throwIfCancel(config);

    fail(createError("Request aborted", config, "ECONNABORTED", request));

    // Clean up request
    request = null;
  };

  // Handle low level network errors
  request.onerror = function handleError() {
    // Real errors are hidden from us by the browser
    // onerror should only fire if it's a network error
    fail(createError("Network Error", config, null, request));

    // Clean up request
    request = null;
  };

  // Handle timeout
  request.ontimeout = function handleTimeout() {
    var timeoutErrorMessage = "timeout of " + config.timeout + "ms exceeded";
    if (config.timeoutErrorMessage) {
      timeoutErrorMessage = config.timeoutErrorMessage;
    }
    fail(createError(timeoutErrorMessage, config, "ECONNABORTED", request));

    // Clean up request
    request = null;
  };
  request.open(method.toUpperCase(), encodeURI(url), async);
  // 处理header  设置请求头，请求头的设置必须在xhr打开之后，并且在send之前
  // Add headers to the request
  if ("setRequestHeader" in request) {
    Object.keys(config.header).forEach((key) => {
      if (
        typeof requestData === "undefined" &&
        key.toLowerCase() === "content-type"
      ) {
        // Remove Content-Type if data is undefined
        delete config.header[key];
      } else {
        // Otherwise add header to the request
        request.setRequestHeader(key, header[key]);
      }
    });
  }
  // Add responseType to request if needed
  if (config.responseType) {
    try {
      request.responseType = config.responseType;
    } catch (e) {
      // Expected DOMException thrown by browsers not compatible XMLHttpRequest Level 2.
      // But, this can be suppressed for 'json' type as it can be parsed by default 'transformResponse' function.
      if (config.responseType !== "json") {
        throw e;
      }
    }
  }

  // Handle progress if needed
  if (typeof config.onDownloadProgress === "function") {
    request.addEventListener("progress", (e) => {
      config.onDownloadProgress(
        Object.assign({}, e, {
          percentage: e.loaded / e.total,
        })
      );
    });
  }

  // Not all browsers support upload events
  if (typeof config.onUploadProgress === "function" && request.upload) {
    request.upload.addEventListener("progress", (e) => {
      config.onUploadProgress(
        Object.assign({}, e, {
          percentage: e.loaded / e.total,
        })
      );
    });
  }
  throwIfCancel(config);
  emitter.on(config.cancelToken, () => {
    if (request) {
      request.abort();
      config.fail("cancel");
    }
  });
  request.send(method === "get" ? null : requestData);
}
