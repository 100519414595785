// import typeUtil from './typeUtil';

// const getType = typeUtil('getType');
import clone from "./clone";

const hasOwnProperty = Object.prototype.hasOwnProperty;
function merge(obj1, obj2) {
  for (let key in obj2) {
    if (hasOwnProperty.call(obj2, key)) {
      // 考虑null 也是object
      if (obj1[key] instanceof Object && hasOwnProperty.call(obj1, key)) {
        // 考虑 obj1 {a:[1,2]} obj2 {a:'xxx'}
        if (typeof obj2[key] !== "object" && obj2[key])
          obj1[key] = clone(obj2[key]);
        else obj1[key] = merge(obj1[key], obj2[key]);
      } else {
        obj1[key] = clone(obj2[key]);
      }
    }
  }
  return obj1;
}
class ObjUtl {
  constructor(obj, isClone = true) {
    if (isClone) this.obj = clone(obj);
    else this.obj = obj;
  }

  get value() {
    return this.obj;
  }

  set value(obj) {
    this.obj = obj;
  }

  get length() {
    return Object.keys(this.obj).length;
  }
  /**
   * 合并对象
   */
  static merge(source, ...rest) {
    rest.forEach((item) => {
      source = merge(source, item);
    });
    return source;
  }
  /**
   *转换对象为字符串的url
   *
   * @param {*} param
   */
  static queryString(
    obj,
    { encode = true, clearEmpty = false, exclude = [] } = {}
  ) {
    const param = obj;
    if (!param || this.isEmpty(param)) {
      return "";
    } else {
      let url = "";
      for (let [key, value] of Object.entries(param)) {
        if (
          clearEmpty &&
          (value === undefined || value === NaN || value === null)
        )
          continue;
        if (exclude.includes(key)) continue;
        value = value === null || value === undefined ? "" : value;
        if (typeof value === "object") {
          url += `${key}=${JSON.stringify(value)}&`;
        } else {
          // if (
          //   typeof value === "string" &&
          //   (value.includes("?") || value.includes("=")|| value.includes("\""))
          // )
          url += `${key}=${encode ? encodeURIComponent(value) : value}&`;
        }
      }
      return url.slice(0, -1);
    }
  }
  /**
   * 是否包含键
   * @param key
   * @returns {boolean}
   */
  includes(key) {
    return Object.hasOwnProperty.call(this.obj, key);
  }

  /**
   * [isValIn 查找对象是否有某一属性值]
   * @method isValIn
   * '@date 2018-08-18'
   * '@author zkq'
   * @param  {string}     value  [description]
   * @return {[type]}          [description]
   */
  static isValIn(value) {
    return Object.keys(this.obj).some((item) => {
      return item === value;
    });
  }

  /**
   * [isObjectEmpty 判断空对象]
   * @method isObjectEmpty
   * '@date 2018-08-18'
   * '@author zkq'
   * @param  {[type]}      obj [description]
   * @return {Boolean}         [description]
   */
  static isEmpty(obj, values = ["undefined", "null", "NaN", "empty string"]) {
    if (values.includes("undefined") && hasOwnProperty.call(obj, undefined))
      delete obj.undefined;
    if (values.includes("null") && hasOwnProperty.call(obj, null))
      delete obj.null;
    if (values.includes("NaN") && hasOwnProperty.call(obj, NaN)) delete obj.NaN;
    if (values.includes("empty string") && hasOwnProperty.call(obj, ""))
      delete obj[""];
    const LEN = Object.keys(obj).length;
    return LEN === 0;
  }

  /**
   * 去除空属性 过滤null,undefined,'',NaN
   * @returns {ObjUtl}
   */
  clearEmpty({ emptyChar = true, undefine = true } = {}) {
    let obj = this.obj;
    for (let key in obj) {
      if (
        (emptyChar && obj[key] === "") ||
        (undefine && obj[key] === undefined) ||
        obj[key] === null ||
        (typeof obj[key] === "number" && isNaN(obj[key]))
      )
        delete obj[key];
    }
    return this;
  }

  /**
   * 属性按字母排序(升序）
   * @param rule 排序规则
   * @returns {ObjUtl}
   */
  sort(rule = "up") {
    let resultObj = {},
      obj = this.obj;
    let arr = Object.keys(obj).sort();
    if (rule === "down") arr = arr.reverse();
    arr.forEach((key) => {
      resultObj[key] = obj[key];
    });
    this.obj = resultObj;
    return this;
  }

  map(cb) {
    let obj = this.obj,
      temp = {};
    for (let [key, value] of Object.entries(obj)) {
      temp[key] = cb(value, key, obj);
    }
    this.obj = temp;
    return this;
  }

  mapItem(cb) {
    let obj = this.obj,
      temp = {};
    for (let [key, value] of Object.entries(obj)) {
      temp = Object.assign({}, temp, cb(value, key, obj));
    }
    this.obj = temp;
    return this;
  }

  filter(cb) {
    let obj = this.obj,
      temp = {};
    for (let [key, value] of Object.entries(obj)) {
      if (cb(value, key, obj)) {
        temp[key] = value;
      }
    }
    this.obj = temp;
    return this;
  }

  find(cb) {
    let obj = this.obj,
      temp = {};
    for (let [key, value] of Object.entries(obj)) {
      if (cb(value, key, obj)) {
        temp.key = key;
        temp.value = value;
        break;
      }
    }
    if (Object.keys(temp).length === 0) {
      temp = undefined;
    }
    return temp;
  }

  reduce(cb, initial) {
    const obj = this.obj;
    let cursor = 0,
      accumulator = undefined;
    for (let [key, value] of Object.keys(obj)) {
      if (cursor === 0) {
        accumulator = initial
          ? initial
          : {
              key: value,
            };
        break;
      }
      accumulator = cb.call(
        undefined,
        accumulator,
        {
          key,
          value,
        },
        cursor,
        obj
      );
      cursor++;
    }
    this.obj = accumulator;
    return this;
  }

  /**
   * 获取深层属性 若不存在返回undefined
   * @param {*} key
   */
  getProperty(key) {
    if (typeof key !== "string") throw new TypeError("key must be string!");
    key = key.replace(/\[(.+)\]/g, ".$1").split("."); // 处理数组 [0]的场景
    const obj = this.obj;
    if (!hasOwnProperty.call(obj, key[0])) return undefined;
    return key.reduce((accumulator, current) => {
      if (!hasOwnProperty.call(accumulator, current)) return undefined;
      return accumulator[current];
    }, this.obj);
  }

  /**
   * 设置深层属性
   * @param key
   * @param value
   * @returns {ObjUtl}
   */
  setProperty(key, value) {
    if (!(typeof key === "string")) throw new TypeError("key must be string!");
    let temp = this.obj;
    key = key.replace(/\[([^\]]+)\]/g, ".$1").split(".");
    const field = key.pop();
    // 获取引用
    key.forEach((item) => {
      if (!temp[item]) {
        if (isNaN(Number(item))) temp[item] = {};
        else temp[item] = [];
      }
      temp = temp[item];
    });
    temp[field] = value;
    return this;
  }

  /**
   * @description 根据键删除对象的某个值
   * @param {*} key
   */
  del(key) {
    const obj = this.obj;
    let keys = Object.keys(obj);
    let temp = {};
    keys.map((item) => {
      if (item === key) {
        return false;
      }
      temp[item] = obj[item];
    });
    this.obj = temp;
    return this;
  }
}

export default ObjUtl;
