import { FullscreenExitOutlined, FullscreenOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
// 是否全屏
export const isFullScreen = () =>
  document.fullScreen || document.webkitIsFullScreen || document.mozFullScreen;
// 进入全屏
export const requestFullscreen = (el = null) => {
  const ele = el || document.documentElement;
  const rfs =
    ele.requestFullscreen ||
    ele.webkitRequestFullscreen ||
    ele.mozRequestFullScreen ||
    ele.msRequestFullscreen;

  // 如果全屏，返回
  if (isFullScreen()) return;

  if (rfs) {
    rfs.call(ele);
  } else if (typeof window.ActiveXObject !== "undefined") {
    const wscript = new window.ActiveXObject("WScript.Shell");
    if (wscript) {
      wscript.SendKeys("{F11}");
    }
  }
};

// // 例子 1：整个网页全屏
// requestFullscreen();
// // 例子 2：视频部分全屏
// requestFullscreen(document.querySelector('#video'));
// 退出全屏
export function exitFullscreen() {
  const ele = document;
  const efs =
    ele.exitFullscreen || ele.webkitExitFullscreen || ele.mozCancelFullScreen;

  // 如果不是全屏，返回
  if (!isFullScreen()) return;

  if (efs) {
    efs.call(ele);
  } else if (typeof window.ActiveXObject !== "undefined") {
    const wscript = new window.ActiveXObject("WScript.Shell");
    if (wscript) {
      wscript.SendKeys("{F11}");
    }
  }
}
const Fullscreen = ({ container }) => {
  const [isFullscreen, setIsFullscreen] = useState(isFullScreen());
  const change = () => {
    const el = container ? document.querySelector(container) : document.body;
    if (isFullscreen) exitFullscreen();
    else requestFullscreen(el);
    setIsFullscreen(!isFullscreen);
  };
  const fontStyle = {
    fontSize: "20px",
  };
  useEffect(() => {
    const onFullscreen = () => {
      setIsFullscreen(isFullScreen());
    };
    window.addEventListener("resize", onFullscreen, { passive: true });
    return () => {
      window.removeEventListener("resize", onFullscreen);
    };
  }, []);
  return isFullscreen ? (
    <FullscreenExitOutlined style={fontStyle} onClick={() => change()} />
  ) : (
    <FullscreenOutlined style={fontStyle} onClick={() => change()} />
  );
};
export default Fullscreen;
