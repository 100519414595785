import FixedBar from "@components/FixedBar";
import { Button, Space } from "antd";
const defaultButtonRender = (cancelButton, confirmButton) => (
  <>
    {cancelButton}
    {confirmButton}
  </>
);
const submitterType = {
  fixed: ({ submitter, onCancel, buttonRender = defaultButtonRender }) => (
    <FixedBar {...submitter}>
      {buttonRender(
        <Button onClick={onCancel}>取消</Button>,
        <Button htmlType="submit" type="primary">
          确定
        </Button>
      )}
    </FixedBar>
  ),
  "bottom-right": ({
    submitter,
    onCancel,
    buttonRender = defaultButtonRender,
  }) => (
    <div
      className="form-submitter bottom-right"
      style={submitter.wrapperStyle || {}}
    >
      <Space>
        {buttonRender(
          <Button onClick={onCancel}>取消</Button>,
          <Button htmlType="submit" type="primary">
            确定
          </Button>
        )}
      </Space>
    </div>
  ),
  "bottom-center": ({
    submitter,
    onCancel,
    buttonRender = defaultButtonRender,
  }) => (
    <div
      className="form-submitter bottom-center"
      style={submitter.wrapperStyle || {}}
    >
      <Space>
        {buttonRender(
          <Button onClick={onCancel}>取消</Button>,
          <Button htmlType="submit" type="primary">
            确定
          </Button>
        )}
      </Space>
    </div>
  ),
};
const Submitter = ({ submitter, onCancel }) => {
  const position = submitter.position || "fixed";
  const SubmitterBar = submitter
    ? submitterType[position.includes("fixed") ? "fixed" : position]
    : null;
  return submitter ? (
    SubmitterBar ? (
      <SubmitterBar
        onCancel={onCancel}
        submitter={submitter}
        buttonRender={submitter.buttonRender}
      ></SubmitterBar>
    ) : null
  ) : null;
};
export default Submitter;
