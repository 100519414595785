import typeUtil from "./typeUtil";
import verify from "./verify";
import clone from "./clone";
const hasOwnProperty = Object.prototype.hasOwnProperty,
  getType = typeUtil("getType");
const isValid = (val) => val !== "" && val !== null && val !== undefined;
class Validate {
  constructor(rule) {
    if (!rule || typeUtil("getType")(rule) !== "Object")
      throw new Error("rule shoud be an object");
    this.rule = clone(rule);
    this.init();
  }
  init() {
    this.initCache();
    this.initValidator();
    this.initMessages();
  }
  /**
   * 初始化缓存池
   */
  initCache() {
    this.error = []; //错误日志
    this.data = null; //param参数验证
  }
  /**
   * 初始化验证规则
   */
  initValidator() {
    const defaults = {
      required(value, ruleVal) {
        return ruleVal
          ? typeof value === "object"
            ? typeUtil("isTrue")(value)
            : isValid(value)
          : true;
      },
      phone(value, ruleVal) {
        return ruleVal && value ? verify("isMobile")(value) : true;
      },
      ip(value, ruleVal) {
        return ruleVal
          ? /^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}$/g.test(
              value
            )
          : true;
      },
      email(value, ruleVal) {
        return ruleVal ? verify("isEmail")(value) : true;
      },
      // 大小写字母,数字、字符，12-32位 至少12-32个字符，至少1个大写字母，1个小写字母和1个数字，其他可以是任意字符
      password(value, ruleVal) {
        return ruleVal
          ? /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{12,32}$/.test(value)
          : true;
      },
      number(value, ruleVal) {
        return ruleVal ? verify("isNumber")(value) : true;
      },
      idcard(value, ruleVal) {
        return ruleVal ? verify("isIdCardNo")(value) : true;
      },
      type(value, ruleVal) {
        return getType(value) === ruleVal;
      },
      /**
       *@description 校验规则 若有比较字段则比较此字段值
       * @param {*} value 相应校验字段的值
       * @param {*} ruleVal 相应校验规则的值
       * @param {*} data 需要校验的全部字段(check传入)
       * @returns
       */
      equalTo(value, ruleVal, data) {
        if (typeof ruleVal == "string" && hasOwnProperty.call(data, ruleVal)) {
          return value == data[ruleVal];
        } else {
          return value == ruleVal;
        }
      },
      /**
       * 全相等
       * @param value
       * @param ruleVal
       * @param data
       * @returns {boolean}
       */
      eqeqTo(value, ruleVal, data) {
        if (typeof ruleVal == "string" && hasOwnProperty.call(data, ruleVal)) {
          return value === data[ruleVal];
        } else {
          return value === ruleVal;
        }
      },
      /**
       * 大于
       * @param value
       * @param ruleVal
       * @param data
       * @returns {boolean}
       */
      graterTo(value, ruleVal, data) {
        if (typeof ruleVal == "string" && hasOwnProperty.call(data, ruleVal)) {
          return value > data[ruleVal];
        } else {
          return value > ruleVal;
        }
      },
      /**
       * >=
       * @param value
       * @param ruleVal
       * @param data
       * @returns {boolean}
       */
      graterToInclude(value, ruleVal, data) {
        if (typeof ruleVal == "string" && hasOwnProperty.call(data, ruleVal)) {
          return value >= data[ruleVal];
        } else {
          return value >= ruleVal;
        }
      },
      /**
       * <
       * @param value
       * @param ruleVal
       * @param data
       * @returns {boolean}
       */
      lessTo(value, ruleVal, data) {
        if (typeof ruleVal == "string" && hasOwnProperty.call(data, ruleVal)) {
          return value < data[ruleVal];
        } else {
          return value < ruleVal;
        }
      },
      /**
       * <=
       * @param value
       * @param ruleVal
       * @param field
       * @param data
       * @returns {boolean}
       */
      lessToInclude(value, ruleVal, field, data) {
        if (typeof ruleVal == "string" && hasOwnProperty.call(data, ruleVal)) {
          return value <= data[ruleVal];
        } else {
          return value <= ruleVal;
        }
      },
      //判定长度等于
      lenEqual(value, ruleVal) {
        return String(value).length === ruleVal;
      },
      /**
       * 长度大于
       * @param value
       * @param ruleVal
       * @returns {boolean}
       */
      minlength(value, ruleVal) {
        return value.length > ruleVal;
      },
      /**
       * 长度小于
       * @param value
       * @param ruleVal
       * @returns {boolean}
       */
      maxlength(value, ruleVal) {
        return value.length < ruleVal;
      },
      /**
       * 长度大于等于
       * @param value
       * @param ruleVal
       * @returns {boolean}
       */
      minlengthInclude(value, ruleVal) {
        return value.length >= ruleVal;
      },
      /**
       * 长度小于等于
       * @param value
       * @param ruleVal
       * @returns {boolean}
       */
      maxlengthInclude(value, ruleVal) {
        return value.length <= ruleVal;
      },
      /**
       * 大于
       * @param value
       * @param ruleVal
       * @returns {boolean}
       */
      min(value, ruleVal) {
        return value > ruleVal;
      },
      /**
       * 大于等于
       * @param value
       * @param ruleVal
       * @returns {boolean}
       */
      minInclude(value, ruleVal) {
        return value >= ruleVal;
      },
      /**
       * 小于
       * @param value
       * @param ruleVal
       * @returns {boolean}
       */
      max(value, ruleVal) {
        return value < ruleVal;
      },
      /**
       * 小于等于
       * @param value
       * @param ruleVal
       * @returns {boolean}
       */
      maxInclude(value, ruleVal) {
        return value <= ruleVal;
      },
      /**
       * 比较范围
       * @param value [1,3]  (2,3) (1,3]
       * @param ruleVal
       * @returns {boolean|boolean}
       */
      range(value, ruleVal) {
        const [, leftSymbol, left, right, rightSymbol] = ruleVal.match(
          /(\(|\[)(\d+)[^\d]*?(\d+)(\)|\])/
        );
        return (
          (leftSymbol === "(" ? Number(value) > left : Number(value) >= left) &&
          (rightSymbol === ")" ? Number(value) < right : Number(value) <= right)
        );
      },
    };
    this.validator = defaults;
  }
  /**
   * @description 自定义校验规则
   * @param {*} name
   * @param {*} fn
   * @param {*} msg
   */
  addRule(name, fn, msg) {
    this.validator[name] = fn;
    this.defaultsMessage[name] = msg;
  }
  /**
   * 初始化验证消息
   */
  initMessages() {
    const defaults = {
      required: "%s必填!",
      phone: "请输入正确的手机号!",
      ip: "请输入正确的IP地址!",
      email: "请输入正确的邮箱!",
      idcard: "请输入正确的身份证号!",
      password: "必须包含大小写字母,数字及任意字符，长度12-32位!",
      number: "%s必须是数字",
      type: "%s类型错误",
      equalTo: "两者输入必须一致",
      eqeqTo: "两者输入必须一致",
      graterTo: "必须大于",
      graterToInclude: "必须大于等于",
      lessTo: "必须小于",
      lessToInclude: "必须小于等于",
      lenEqual: "输入长度错误!",
      minlength: "输入字符不足",
      maxlength: "输入字符超出",
      min: "输入最小值不足",
      minInclude: "输入最小值不足",
      max: "输入最大值超出",
      maxInclude: "输入最大值超出",
      range: "不在范围内",
      rangeInclude: "不在范围内",
    };
    this.defaultsMessage = defaults;
    const rule = this.rule;

    // this.messages = this.parseRule(rule);
  }
  parseRule(rule) {
    let messages = {};
    /**
     * {
     *  name:{
     *      require:'请输入%s'
     *  }
     * }
     */
    for (let property of Object.keys(rule)) {
      // property:每个字段的规则
      messages[property] = {};
      if (rule[property].constructor === Object) {
        for (let [key, value] of Object.entries(rule[property])) {
          // key:规则名
          if (/(\S*)\|msg$/.test(key)) {
            const msgKey = key.match(/(\S*)\|msg$/)[1];
          }
          messages[property]["message"] = value;
          if (key === "fields")
            messages = Object.assign({}, messages, this.parseRule(value));
        }
      }
    }
    return messages;
  }
  /**
   *验证信息
   *
   * @param {*} key 字段名
   * @param {*} rule  单个字段的单个规则名
   *  @param {*} ruleField 当前校验字段的所有规则
   * @param {*} value 字段值
   */
  formatErrorTPL(key, rule, ruleField, value, index) {
    console.log(key, rule, ruleField, value, index);
    let msg;
    // 若传入的规则指定了msg,则使用，否则使用默认
    if (hasOwnProperty.call(ruleField, "message")) {
      msg =
        typeof ruleField.message === "function"
          ? ruleField.message({ index })
          : ruleField.message;
    } else {
      msg = this.defaultsMessage[rule];
    }
    this.error.push({
      key,
      value,
      rule,
      message: msg.replace(/%s/g, ruleField.label || ""),
    });
  }
  /**
   *字段验证
   *
   * @param {*} key 字段
   * @param {*} value 字段值
   * rule 当前字段规则
   * {required:true}
   * @returns
   * @memberof Validate
   */
  checkKeys(field, fieldValue, rule, data, index) {
    if (hasOwnProperty.call(rule, field) && rule[field]) {
      //如果此属性存在 于传入的规则中则校验
      // 开始校验 遍历字段规则
      const ruleValue = rule[field]; //当前字段的校验规则 {required:true} / [{required:true}]
      if (getType(ruleValue) === "Object")
        for (let ruleField of Object.keys(ruleValue)) {
          //ruleField:单个字段的每个规则 required
          if (ruleField === "message" || ruleField === "label") {
            //message->错误提示 name->form tpl label
            continue;
          }
          // 处理规则`fields`,判断嵌套字段
          /**
           * ruleField:fields  ruleValue {fields:{uername:{required:true}}}
           * name:{
           *  fields:{
           *      uername:{required:true}
           *  }
           * }
           *
           */
          if (ruleField === "fields" && ruleValue[ruleField]) {
            console.log("validate-field", data, field);
            if (data[field].constructor === Object)
              return this.check(data[field], ruleValue[ruleField]);
            if (data[field].constructor === Array) {
              return data[field].reduce((accu, cur, index) => {
                return this.check(cur, ruleValue[ruleField], index) && accu;
              }, true);
            }
          }
          //若有此校验规则调用校验规则 或 自定义规则
          if (
            hasOwnProperty.call(this.validator, ruleField) ||
            ruleField === "validate"
          ) {
            let ruleVal = ruleValue[ruleField]; //单个规则值
            // 转换为number比较(防止规则是比较大小等)
            const val =
              fieldValue && /\d+/.test(fieldValue)
                ? Number(fieldValue)
                : fieldValue;
            ruleVal =
              ruleVal && /\d+/.test(ruleVal) ? Number(ruleVal) : ruleVal;
            // 校验自定义规则validate
            if (typeof ruleVal === "function") {
              const validateRes = ruleVal.call(this, val, ruleVal, this.data);
              if (validateRes) return true;
              this.formatErrorTPL(
                field,
                ruleField,
                ruleValue,
                fieldValue,
                index
              );
              return false;
            } else if (
              !this.validator[ruleField].call(this, val, ruleVal, this.data)
            ) {
              // 缓存校验错误信息
              this.formatErrorTPL(
                field,
                ruleField,
                ruleValue,
                fieldValue,
                index
              );
              return false;
            }
          } else {
            this.error.push({
              message: "无效的校验规则",
              field,
              ruleField,
              fieldValue,
            });
            return false;
          }
        }
      else if (getType(rule[field]) === "Array")
        for (let rowRule of ruleValue) {
          // for(let miniRule of Object.keys(rowRule)){
          if (!this.checkKeys(field, fieldValue, { [field]: rowRule }, data))
            return false;
          // }
        }
      return true;
    } else {
      return true;
    }
  }
  /**
   *校验
   *rule
   * {
   *  field:{
   *      rule:'xxx'
   *  }
   * }
   * @param {*} data 校验的数据
   * @returns
   * @memberof Validate
   */
  check(data, rule = this.rule, index) {
    console.log("validate-data", data);
    if (!this.data) this.data = clone(data);
    // 遍历字段，按照字段顺序校验
    for (let key of Object.keys(rule)) {
      if (!this.checkKeys(key, data[key], rule, data, index)) {
        return false;
      }
    }
    return true;
  }
}
export default Validate;
