import { useEffect, useMemo, useRef, useState } from "react";
import { Image } from "antd";
import "./index.less";
import ChooseImage from "@components/ChooseImage";
import { uface } from "@widget";

/**
 *  size:{
                type:String,
                default:'default'
            },
            limit:Number
*/
export default ({
  size = "default",
  limit,
  value,
  handleValue = (e) =>
    e ? (typeof e === "string" ? [e] : e.filter(Boolean) || []) : [],
  onChange,
  onDelete,
}) => {
  const files = useRef([]),
    [imgs, setImgs] = useState([]);
  const style = useMemo(() => {
    const map = {
      large: {},
      default: {
        width: "100px",
        height: "100px",
      },
      small: {
        width: "80px",
        height: "80px",
      },
    };
    return map[size] || map.default;
  }, [size]);
  const add = (uploadFiles) => {
      if (limit && limit > uploadFiles.length) {
        uface.showToast({ title: `最大上传${limit}张!`, icon: "warning" });
        return false;
      }
      setImgs([...imgs, ...uploadFiles.map((item) => item.base64)]);
      files.current.push(...uploadFiles.map((item) => item.file));
      onChange(files.current);
    },
    remove = (delIndex) => {
      setImgs(imgs.filter((_, index) => index !== delIndex));
      files.current.splice(delIndex, 1);
      onChange(files.current);
    };
  const [showImg, setShowImg] = useState(handleValue(value));
  useEffect(() => {
    setShowImg(handleValue(value));
  }, [value]);
  return (
    <div className="flex flex-wrap">
      {limit == 1 && showImg.length + imgs.length == 1 ? null : (
        <div className="text-center">
          <ChooseImage count={9} onChange={add}>
            <div className="upload-btn" style={style}>
              <i className="large iconfont icon-jia"></i>
              <div className="text">上传</div>
            </div>
          </ChooseImage>
        </div>
      )}
      {showImg.map((item, index) => (
        <div className="upload-btn relative" style={style} key={index}>
          <i
            className="iconfont icon-shanchu11 del-icon"
            onClick={() =>
              onDelete
                ? onDelete(item, () =>
                    setShowImg(showImg.filter((_, i) => index !== i))
                  )
                : setShowImg(showImg.filter((_, i) => index !== i))
            }
          ></i>
          <Image
            src={typeof item === "string" ? item : item.path}
            width={"100%"}
            height={"100%"}
          />
        </div>
      ))}
      {imgs.map((item, index) => (
        <div className="upload-btn relative" style={style} key={index}>
          <i
            className="iconfont icon-shanchu11 del-icon"
            onClick={() => remove(index)}
          ></i>
          <Image src={item} width={"100%"} height={"100%"} />
        </div>
      ))}
    </div>
  );
};
