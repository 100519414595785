import { purefn, uface } from "@widget";
import "./index.less";
import { useMemo } from "react";
const imgExt = ".jpg|.jpeg|.gif|.bmp|.png|"; //全部图片格式类型
// https://blog.csdn.net/weixin_34128534/article/details/93630428
export const mime = {
  video:
    // "video/MP4,video/3GP,video/MKV,video/MPG,video/VOB,video/FLV,video/SWF,video/MOV,video/rmvb,video/mp3,video/wav,video/webm,.mkv",
    ".mp4,.mkv,.mov,.webm",
  //   video: "video/*,.mkv",
  excel:
    ".xlsx,.xls,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  html: "text/html",
  // 接受所有的声音文件。
  audio: "audio/*",
  videoAll: "video/*", // 接受所有的视频文件。
  image: "image/*", // 接受所有的图像文件。.png,.jpg,.gif,.tif
  pdf: ".pdf,application/pdf",
  ppt: ".ppt,.pptx",
  txt: ".txt",
  zip: ".zip,.rar,.7z",
  // https://www.cnblogs.com/cryst/p/6020478.html
  doc: ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
};
/**
	 * 
	 * // 最多可以选择的图片张数
            count: {
                type: [Number,String],
                default: -1
            },
            size: {
                type: [Number, String],
                default: ''
            },
            quality:{
                type:[Number,String],
                default:0.5
            },
            qualitySize:{
                type:[Number,String],
                default:2
            },
            disabled:{
                type:Boolean,
                default:false
            }
	*/
const ChooseFile = ({
  accept = "doc", //doc doc,excel
  onChange,
  children,
  disabled = false,
  count = -1,
  size = 500, //M
  quality = 0.5,
  qualitySize = 2,
  className = "",
  before = () => true,
}) => {
  // 接收的文件类型
  // accept表示可以选择的文件MIME类型，多个MIME类型用英文逗号分开
  const handleAccept = useMemo(() => {
    return typeof accept === "string"
      ? mime[accept] || accept
      : accept.reduce((accu, cur, index) => {
          if (index === 0) return accu + mime[cur] || cur;
          else return accu + "," + mime[cur] || cur;
        }, "");
  }, [accept]);
  console.log("handleAccept", handleAccept);
  async function handleFile(e) {
    if (!before(e)) return false;
    let event = [];
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      if (count > 0 && i + 1 > count) {
        purefn.showToast({
          title: `每次最大上传${count}个!`,
          icon: "warning",
        });
        break;
      }
      let file = files[i],
        limitSize = 1048576 * Number(size);
      if (!checkExt(file)) {
        continue;
      }
      // 检测文件大小 1024*1024=1048576(1M)
      if (size && file.size > limitSize) {
        uface.showToast({
          title: "上传文件大小不得超过" + size + "M",
          icon: "warning",
        });
        continue;
      }
      event.push(file);
    }
    if (
      purefn.typeUtil("getType")(event) === "Array" &&
      !purefn.typeUtil("isTrue")(event)
    )
      return;
    onChange(event);
    // 清空，防止无法上传同一文件
    e.target.value = "";
  }
  const checkExt = (file) => {
    const ext = file.name.substr(file.name.lastIndexOf(".")).toLowerCase();
    const accept = handleAccept.toLowerCase() || "";
    if (
      accept.includes(ext) ||
      accept.split(",").reduce((accu, cur) => {
        return accu || file.type.includes(cur.replace(/\*/g, ""));
      }, false)
    ) {
      return true;
    }
    uface.showToast({ icon: "warning", title: "该文件类型不允许上传!" });
    return false;
  };
  return (
    <div className={`wrapper ${className}`}>
      {disabled ? null : (
        <input
          type="file"
          accept={handleAccept}
          multiple={count > 1 || count < 0}
          onChange={handleFile}
        />
      )}
      {children}
    </div>
  );
};
export default ChooseFile;
