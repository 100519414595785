import Table from "@components/Table";
import uface from "@widget/uface";
import { Button } from "antd";
import Link from "@components/Link";
import { PlusOutlined } from "@ant-design/icons";
import { useModel } from "@model";
export default () => {
  const { hasButton } = useModel("auth");
  const columns = [
    {
      title: "模块",
      key: "module",
      render: ({ module }) => (module === "admin" ? "平台" : "手机"),
      valueType: "select",
      placeholder: "请选择模块",
      options: [
        {
          label: "平台",
          value: "admin",
        },
        {
          label: "手机",
          value: "mobile",
        },
      ],
    },
    {
      title: "用户组",
      key: "group_name",
    },
    {
      title: "描述",
      key: "desc",
    },
    {
      width: 100,
      title: "操作",
      valueType: "operation",
      render: (row, action) => [
        hasButton(
          <Link to="/auth/group/operate" query={{ id: row.id }}>
            编辑
          </Link>,
          "operate"
        ),
        hasButton(
          <a
            className="danger-color"
            onClick={() =>
              action.delete(
                "Auth/delUserAdminGroup",
                { id: row.id },
                row.group_name
              )
            }
          >
            删除
          </a>,
          "delete"
        ),
      ],
    },
  ];
  return (
    <Table
      headerTitle="权限组"
      request="Auth/getUserAdminGroupList"
      toolbar={{
        operateRender: () => [
          hasButton(
            <Link to="/auth/group/operate">
              <Button type="primary" icon={<PlusOutlined />}>
                新增
              </Button>
            </Link>,
            "operate"
          ),
        ],
      }}
      search={{
        columns: [
          {
            valueType: "select",
            name: "keywords",
            options: [
              {
                label: "全部",
                value: 0,
              },
              {
                label: "平台",
                value: "admin",
              },
              {
                label: "手机",
                value: "mobile",
              },
            ],
          },
        ],
      }}
      columns={columns}
    ></Table>
  );
};
