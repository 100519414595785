import load from "@utils/dynamicLoadScript";
import uface from "@widget/uface";
import { useMount } from "@utils/hooks";
import { useRef, useEffect } from "react";
import api from "@api";
const MyKey = "A35BZ-ZXS6J-YRRFM-FMR7G-UZJF5-PZF3J";
export default ({
  width = "300px",
  height = "300px",
  mapKey = MyKey,
  value,
  defaultValue = [39.98412, 116.307484],
  onChange = () => {},
}) => {
  const mapRef = useRef(null);
  let map = useRef(null),
    marker = useRef(null);

  //地图初始化函数，本例取名为init，开发者可根据实际情况定义
  const initMap = () => {
    const TMap = window.TMap;
    //定义地图中心点坐标
    const center = new TMap.LatLng(...(value || defaultValue));
    //定义map变量，调用 TMap.Map() 构造函数创建地图
    map.current = new TMap.Map(mapRef.current, {
      center: center, //设置地图中心点坐标
      zoom: 17.2, //设置地图缩放级别
    });
    //初始化marker
    marker.current = new TMap.MultiMarker({
      id: "marker-layer", //图层id
      map: map.current,
      styles: {
        //点标注的相关样式
        marker: new TMap.MarkerStyle({
          width: 25,
          height: 35,
          anchor: { x: 16, y: 32 },
        }),
      },
      geometries: [
        {
          //点标注数据数组
          id: "demo",
          styleId: "marker",
          position: new TMap.LatLng(...(value || defaultValue)),
          properties: {
            title: "marker",
          },
        },
      ],
    });
  };
  //定义事件处理方法
  const clickHandler = async (evt) => {
    const TMap = window.TMap;
    console.log(evt);
    var lat = evt.latLng.getLat().toFixed(6);
    var lng = evt.latLng.getLng().toFixed(6);
    marker.current.setGeometries([
      {
        id: "marker-layer", //图层id
        position: new TMap.LatLng(lat, lng),
      },
    ]);
    onChange([lat, lng]);
  };
  let timer = useRef(null);
  useEffect(() => {
    console.log("akakak", marker.current);
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (value && marker.current) {
        marker.current.setGeometries([
          {
            id: "marker-layer", //图层id
            position: new window.TMap.LatLng(...value),
          },
        ]);
        //修改地图中心点
        map.current.setCenter(new window.TMap.LatLng(...value));
      }
    }, 30);
  }, [value, marker]);
  useMount(() => {
    load("https://map.qq.com/api/gljs?v=1.exp&key=" + mapKey, (err) => {
      if (err) {
        uface.showToast({ title: err.message, icon: "warning" });
        return;
      }
      initMap();
      //Map实例创建后，通过on方法绑定点击事件
      map.current.on("click", clickHandler);
    });
    return () => {
      if (map) {
        map.current.off("click", clickHandler);
        map.current = null;
        marker.current = null;
      }
    };
  });
  return <div ref={mapRef} style={{ width, height }}></div>;
};
export async function getAddress(la) {
  const res = await api["MapInterface/httpRequest"]({
    loading: false,
    data: {
      http_url: `https://apis.map.qq.com/ws/geocoder/v1/?location=${la.join(
        ","
      )}&key=${MyKey}&get_poi=1`,
      method: "get",
    },
  });
  if (res && res.result) return res.result.address;
}
export async function getLocation(address) {
  const res = await api["MapInterface/httpRequest"]({
    loading: false,
    data: {
      http_url: `https://apis.map.qq.com/ws/geocoder/v1/?address=${address}&key=${MyKey}`,
      method: "get",
    },
  });
  if (res && res.result) {
    const ret = res.result.location;
    return [ret.lat, ret.lng];
  }
}
