import { useEffect, useRef, useState } from "react";
import api from "@api";
import { useMount } from "@hooks";
import { uface } from "@widget";
import { random } from "@utils/str";
import { useUrlQuery } from "@utils/hooks";
import { isInValid } from "@utils/is";
function setAutoIDInData(data) {
  return data.map((d) =>
    d.autoID ? d : Object.assign({}, d, { autoID: random(12) })
  );
}
function autoIDInData(data) {
  return data.map((d) =>
    d.autoID ? d : Object.assign({}, d, { autoID: random(12) })
  );
}
/**
 * form内部通过useContext更新所有子项
 * table 只做显示几项的容器，每次更新只更新form store长度
 */
export default ({
  form,
  request,
  autoRequest = true,
  query: defaultQuery,
  dataSource,
  pagination: initialPagination,
  queryFormInstance,
  editable,
  syncSearchParam,
  columns = [],
}) => {
  const editData = useRef(null);
  const responseRef = useRef(null);
  const onResponse = useRef(null);
  const permanentQuery = useRef({});
  const permanentRequest = useRef(null);
  const [urlQuery, setUrlQuery] = useUrlQuery();
  const [data, setTableData] = useState(setAutoIDInData(dataSource || []));
  const setData = (d) => {
    setTableData(d);
    form.setStore(d);
  };
  useEffect(() => {
    if (dataSource) setData(setAutoIDInData(dataSource));
  }, [dataSource]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState(initialPagination);
  const onPageChange = (e) => {
    setPagination({ ...pagination, ...e });
    if (syncSearchParam) setUrlQuery(getFetchPagination(e), { replace: true });
    fetch(getFetchPagination(e));
  };
  function handlePageEditResponse({ data: { page, pageSize } } = {}) {}

  const getFetchPagination = (paginfo) => ({
    pageSize: paginfo.pageSize,
    page: paginfo.current - 1, //antd第一页为1，后台所需第一页为0
    ...queryFormInstance.getStore(),
  });
  const setResponse = (res) => {
    responseRef.current = res;
    onResponse.current && onResponse.current(res);
    //   判断是否分页
    if (res.page) {
      const { data, page } = res;
      //   当前页不足自动退回
      if (data.length <= 0 && page.page > 0) fetch({ page: page.page - 1 });
      else
        setPagination((prevPagination) => ({
          ...prevPagination,
          current: page.page + 1,
          total: page.rowcount,
        }));
      setData(setAutoIDInData(data));
    } else {
      if (res instanceof Array) setData(setAutoIDInData(res));
      else setData(setAutoIDInData(res.data));
      setPagination(false);
    }
    setTimeout(() => {
      setLoading(false);
    }, 0);
  };
  const fetch = (query = {}, requestRe) => {
    const requestURL = requestRe
      ? requestRe
      : permanentRequest.current || request;
    setLoading(true);
    if (typeof requestURL === "string")
      api[requestURL]({
        data: {
          ...(defaultQuery || {}),
          ...getFetchPagination(pagination),
          ...permanentQuery.current,
          ...query,
        },
        loading: false,
      }).then(setResponse);
    else
      requestURL({
        ...(defaultQuery || {}),
        ...getFetchPagination(pagination),
        ...permanentQuery.current,
        ...query,
      }).then(setResponse);
  };
  const isValid = (val) =>
    val !== undefined && val !== null && val !== "" && !Number.isNaN(val);
  useMount(() => {
    if (!autoRequest) return;
    if (!request) {
      setLoading(false);
      setPagination(false);
      return;
    }
    if (urlQuery) {
      const { page, pageSize, ...restQuery } = urlQuery;
      //   const names = columns.map((d) =>
      //     typeof d.name === "string" ? d.name : d.name[d.name.length - 1]
      //   );
      //   const newResetQuery = Object.keys(restQuery).reduce((accu, cur) => {
      //     if (names.includes(cur)) accu[cur] = restQuery[cur];
      //     return accu;
      //   }, {});
      queryFormInstance.setStore(restQuery || {});
      //   queryFormInstance.setFieldsForExist(restQuery || {});
    }

    const { page, pageSize } = urlQuery;
    if (urlQuery && isValid(page) && isValid(pageSize)) {
      setPagination({
        ...pagination,
        current: Number(page || 0) + 1,
        pageSize: pageSize || 0,
      });
      fetch({ page: Number(page || 0), pageSize: pageSize || 0 });
    } else fetch();
  });
  //   同步syncSearchParam
  useEffect(() => {
    if (!request || !syncSearchParam) {
      return;
    }
    const { setHook, offHook } = queryFormInstance.getHooks();
    const offHookFn = () =>
      setUrlQuery(getFetchPagination(pagination), { replace: true });
    setHook("onChange", offHookFn);
    return () => offHook("onChange", offHookFn);
  }, [pagination]);
  /**
   * firstPage 是否取第一页
   * permanent 是否总保存reload请求参数
   */
  const reload = (
    query = {},
    { firstPage = true, permanent = false, request } = {}
  ) => {
    if (permanent) {
      permanentQuery.current = Object.assign({}, permanentQuery.current, query);
      if (request) permanentRequest.current = request;
    }
    if (syncSearchParam)
      setUrlQuery(
        Object.assign(
          {},
          getFetchPagination(pagination),
          firstPage ? { page: 0 } : {},
          permanent ? query : {}
        ),
        { replace: true }
      );
    fetch(Object.assign({}, query, firstPage ? { page: 0 } : {}), request);
  };
  return {
    data,
    loading,
    delete: (url, query, deleteText) => {
      uface
        .showModal({
          content: (
            <div>
              确定要删除&nbsp;
              <span className="red-color">{deleteText || ""}</span>&nbsp; 吗？
            </div>
          ),
          icon: "warning",
        })
        .then((confirm) => {
          if (confirm) {
            api[url]({ data: query }).then((res) => {
              if (res.result) reload({}, false);
            });
          }
        });
    },
    setRequest: (e) => (permanentRequest.current = e),
    reload,
    reset: () => {},
    pagination,
    setPagination,
    onPageChange,
    onResponse: (fn) => (onResponse.current = fn),
    setData: (d, { autoID } = { autoID: true }) => {
      const store = form.getStore();
      const newDate = typeof d === "function" ? d(store) : d,
        handle = () => (autoID ? autoIDInData(newDate) : newDate);
      setData(handle());
      if (editable && editable.onChange) editable.onChange({ type: "set" });
    },
    getData: () => form.getRequestStore(),
    getQuery: () => ({
      ...(defaultQuery || {}),
      ...getFetchPagination(pagination),
      ...permanentQuery.current,
    }),
    addData: (d, { index, after } = {}) => {
      const len = data.length,
        addedData = Object.assign(
          { autoID: random(12) },
          typeof d === "function" ? d(data) : d
        );
      form.setFieldValue(len, addedData);
      console.log("akakakak", [...data, addedData]);
      setTableData([...data, addedData]);
      if (editable && editable.onChange)
        editable.onChange({ type: "add", row: addedData });
    },
    delData: (row) => {
      const handle = (e) =>
        typeof row === "function"
          ? e.filter(row)
          : e.filter((d) => d.autoID != row.autoID);
      setTableData(handle(data));
      const store = form.getStore();
      form.setStore(handle(store));
      if (editable && editable.onChange)
        editable.onChange({ type: "delete", row: handle(data) });
    },
    filterData: (fn) => {
      const handle = (e) => e.filter(fn);
      setTableData(handle(data));
      const store = form.getStore();
      form.setStore(handle(store));
      if (editable && editable.onChange) editable.onChange({ type: "filter" });
    },
    setAutoIDInData,
    getResponse: (key) =>
      key
        ? responseRef.current && responseRef.current[key]
        : responseRef.current,
  };
};
