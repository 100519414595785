/* 为Provider提供初始化model和更新的钩子
再将modals嵌套一层是,modals useState导致Provider渲染，避免Provider更新导致所有子组件全局更新
**/
import { useEffect, useMemo, useRef } from "react";
export default (props) => {
  const { init, onUpdate } = props;
  const updateRef = useRef(onUpdate);
  updateRef.current = onUpdate;
  const data = init();
  // 首次执行立即返回初始值
  let isInit = useRef(false);
  //   onUpdate必须要先初始化，但不能在useEffect执行，其异步性导致useModal的dispatch.data为空 useMomo应比useEffect早一些
  useMemo(() => {
    //   onUpdate 无法在外部执行，会导致 Warning: Cannot update a component (`Unknown`) while rendering a different component，副作用放在内部
    updateRef.current(data);
  }, []);
  useEffect(() => {
    // 避免重复更新，由于models不知道什么时候更改，因此每次渲染重复计算
    if (isInit.current) updateRef.current(data);
    else isInit.current = true;
  });
  return null;
};
