import RichText from "@components/RichText";
import Form, { useForm, Select, FormMaker } from "@components/Form";
import { Input, InputNumber, Card, Button, Row, Col, Alert } from "antd";
import api from "@api";
import { useNavigate } from "react-router-dom";
import { useUrlQuery, useMount } from "@hooks";
import { useRef, useState } from "react";
import { pick } from "@utils/field";
import uface from "@widget/uface";
const { Field } = FormMaker;
function getDeepIDs(data, valueName) {
  return data.reduce((accu, cur) => {
    accu.push(cur[valueName]);
    if (cur.children && cur.children.length > 0)
      accu.push(...getDeepIDs(cur.children, valueName));
    return accu;
  }, []);
}
export default () => {
  const [{ id }] = useUrlQuery();
  const navigate = useNavigate();
  const [formInstance] = useForm();
  const submit = (data, files) => {
    if (id)
      api["Client/updateClient"]({ data: { id, ...data }, files }).then(
        (res) => {
          if (res.result) navigate("/product/index");
        }
      );
    else
      api["Client/addClient"]({ data: data, files }).then((res) => {
        if (res.result) navigate("/product/index");
      });
  };
  useMount(() => {
    if (id)
      api["Client/clientDetail"]({ data: { id } }).then((detail) => {
        formInstance.setFields(
          pick(
            detail,
            "case_title",
            "case_intro",
            "case_content",
            "case_sort",
            "seo_title",
            "seo_keyword",
            "seo_descrip",
            ({ id, type_id, case_img_full, case_sml_full, is_hot }) => ({
              is_hot: Number(is_hot),
              type_id: +type_id,
              "case_img[]": case_img_full ? [{ path: case_img_full, id }] : [],
              "case_sml[]": [{ path: case_sml_full, id }],
            })
          )
        );
      });
  });
  return (
    <Card>
      <Form
        form={formInstance}
        submitter={{ container: ".admin-box" }}
        onCancel={() => uface.jump()}
        onSubmit={submit}
      >
        <Form.Item title="所属类别" name="type_id">
          <Select
            request="Client/getCaseTypeList"
            labelName="type_name"
            valueName="id"
          ></Select>
        </Form.Item>
        <Form.Item required title="标题" name="case_title">
          <Input placeholder="请输入标题"></Input>
        </Form.Item>
        <Form.Item required title="简介" name="case_intro">
          <Input.TextArea autoSize={{ minRows: 6 }}></Input.TextArea>
        </Form.Item>
        <Field
          title="封面图(大)"
          name="case_img[]"
          valueType="upload:image"
          limit={1}
          onDelete={(d, del) => {
            uface
              .showModal({
                title: "警告",
                content: "确定要删除吗?",
                icon: "warning",
              })
              .then((confirm) => {
                if (confirm)
                  api["Client/delCaseImg"]({
                    data: { id: d.id },
                  }).then((res) => {
                    if (res.result) del();
                  });
              });
          }}
        ></Field>
        <div
          style={{ width: "50vw", marginBottom: "20px", marginLeft: "80px" }}
        >
          <Alert message="大图尺寸：640*200" type="success" />
        </div>
        <Field
          title="封面图(小)"
          name="case_sml[]"
          valueType="upload:image"
          limit={1}
          onDelete={(d, del) => {
            uface
              .showModal({
                title: "警告",
                content: "确定要删除吗?",
                icon: "warning",
              })
              .then((confirm) => {
                if (confirm)
                  api["Client/delCaseSml"]({ data: { id: d.id } }).then(
                    (res) => {
                      if (res.result) del();
                    }
                  );
              });
          }}
        ></Field>
        <div
          style={{ width: "50vw", marginBottom: "20px", marginLeft: "80px" }}
        >
          <Alert message="小图尺寸：173*126" type="success" />
        </div>
        <Form.Item title="排序" name="case_sort">
          <InputNumber placeholder="请输入排序"></InputNumber>
        </Form.Item>
        <Form.Item title="seo标题" name="seo_title">
          <Input />
        </Form.Item>
        <Form.Item title="seo关键词" name="seo_keyword">
          <Input />
        </Form.Item>
        <Form.Item title="seo描述" name="seo_descrip">
          <Input.TextArea autoSize={{ minRows: 6 }}></Input.TextArea>
        </Form.Item>
        <Form.Item required title="内容" name="case_content">
          <RichText></RichText>
        </Form.Item>
      </Form>
    </Card>
  );
};
