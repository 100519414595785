import { Modal } from "antd";
/**
 * [showModal description] 模拟弹框
 * @method showModal
 * '@date 2018-08-13'
 * '@author zkq'
 * @param  {string}  title              [标题] 必填
 * @param  {string}  content            [内容] 必填
 * @param  {Boolean} [showCancel=false] [是否显示取消按钮]
 * @param icon success / info / warning / error/confirm
 */
function showModal({
  width,
  content,
  showCancel = true,
  title = "提示",
  cancelText = "取消",
  confirmText = "确定",
  closable = true,
  maskClosable = true,
  type = "confirm",
  onRef = () => {},
  icon,
}) {
  return new Promise((resolve) => {
    const param = {
      width,
      title,
      content,
      okText: confirmText,
      cancelText: cancelText,
      closable,
      maskClosable,
      onOk: () => resolve(true),
      onCancel: () => resolve(false),
    };
    if (icon) param.icon = icon;
    console.log("alala", icon);
    if (icon === false) param.icon = "";
    const modal = Modal[type](param);
    if (onRef) onRef(modal);
  });
}
export default showModal;
