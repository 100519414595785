import Dom from "./uDom";
import * as React from "react";
import ReactDOM from "react-dom/client";
//Returns true if it is a DOM node
function isNode(o) {
  /* http://stackoverflow.com/a/384380/937891 */
  return !!(typeof Node === "object"
    ? o instanceof Node
    : o &&
      typeof o === "object" &&
      typeof o.nodeType === "number" &&
      typeof o.nodeName === "string");
}

function printFrame(frameWindow, content, options) {
  frameWindow =
    frameWindow.contentWindow || frameWindow.contentDocument || frameWindow;
  var wdoc = frameWindow.document || frameWindow.contentDocument || frameWindow;
  if (options.doctype) {
    wdoc.write(options.doctype);
  }
  if (typeof content === "string") wdoc.write(content);
  else wdoc.appendChild(content);
  wdoc.close();
  var printed = false;
  var callPrint = function () {
    if (printed) {
      return;
    }
    // Fix for IE : Allow it to render the iframe
    frameWindow.focus();
    try {
      // Fix for IE11 - printng the whole page instead of the iframe content
      if (!frameWindow.document.execCommand("print", false, null)) {
        // document.execCommand returns false if it failed -http://stackoverflow.com/a/21336448/937891
        frameWindow.print();
      }
      // focus body as it is losing focus in iPad and content not getting printed
      document.body.focus();
    } catch (e) {
      frameWindow.print();
    }
    frameWindow.close();
    printed = true;
  };
  // Print once the frame window loads - seems to work for the new-window option but unreliable for the iframe
  frameWindow.onload = callPrint;
  // Fallback to printing directly if the frame doesn't fire the load event for whatever reason
  setTimeout(callPrint, options.timeout);
}

function printContentInFrame(content, option) {
  const iframe = Dom.parseDom(
    '<iframe height="0" width="0" border="0" wmode="Opaque"/>'
  );
  new Dom({
    el: iframe,
  }).setStyle({
    position: "absolute",
    top: -999,
    left: -999,
  });
  document.body.appendChild(iframe);
  return printFrame(iframe, content, option);
  // Success
  // setTimeout(function () {
  //     // Wait for IE
  //     if (iframeCount === 0) {
  //         // Destroy the iframe if created here
  //         $iframe.remove();
  //     }
  // }, 1000);
}

function printContentInNewWindow(content, option) {
  // Open a new window and print selected content
  const iframe = window.open();
  return printFrame(iframe, content, option);
}
//兼容表单标签 https://www.cnblogs.com/steamed-twisted-roll/p/10683680.html
/**
 * @param html String 基于源代码而非元素
 */
function print({
  el = document.body,
  html = "",
  media = {},
  style = "",
  // default option
  globalStyles = true,
  mediaPrint = false,
  stylesheet = null,
  noPrintSelector = ".no-print",
  iframe = true,
  timeout = 750,
  title = null,
  doctype = "<!doctype html>",
} = {}) {
  if (!html) {
    if (!isNode(el) && typeof el === "string") el = document.querySelector(el);
    else if (!isNode(el)) throw new Error("el must instanceof HTMLElement");
  }
  let styles = [];
  // remove page number
  const defaultMedia = {
      size: "auto", //auto is the initial value /*页面设置：A4 portrait : a4 横幅*/
      margin: "0 4mm 0 4mm", //去掉页眉和页脚  this affects the margin in the printer settings https://blog.csdn.net/sinat_33750162/article/details/51908455
    },
    customMedia = Object.assign({}, defaultMedia, media),
    mediaText = Object.keys(customMedia).reduce(
      (accu, cur) => `${accu}${cur}:${customMedia[cur]};`,
      ""
    );
  styles.push(
    Dom.parseDom(`<style media="print">
            @page {
                ${mediaText}
            }
        </style>`)
  );
  // Apply the stlyes from the current sheet to the printed page
  if (globalStyles)
    styles = styles.concat(
      Dom.getElements("style, link, meta, base, title") || []
    );
  // Apply the media-print stylesheet
  if (mediaPrint) styles.push(document.querySelector("link[media=print]"));
  // Add a custom stylesheet if given
  if (stylesheet)
    styles.push(
      Dom.parseDom('<link rel="stylesheet" href="' + stylesheet + '">')
    );
  //add a custom style text
  if (style) styles.push(Dom.parseDom(`<style>${style}</style>`));
  // Create a copy of the element to print
  //   const elCopy = document.createElement("div");
  const elCopy = document.createDocumentFragment();
  if (title) {
    const titleEl = document.createElement("title");
    titleEl.innerText = title;
    elCopy.before(titleEl);
  }
  styles.forEach((ele) => {
    elCopy.appendChild(ele.cloneNode(true));
  });
  // Wrap it in a div to get the HTML markup string
  const body = document.createElement("body");
  elCopy.appendChild(body);
  if (html) {
    if (React.isValidElement(html)) {
      const div = document.createElement("div");
      ReactDOM.createRoot(div).render(
        <div ref={() => appendFrame()}>{html}</div>
      );
      body.appendChild(div);
    } else elCopy.appendChild(Dom.parseDom(html));
  } else if (el) {
    body.appendChild(el.cloneNode(true));
    appendFrame();
  }
  function appendFrame() {
    // remove unwanted elements
    if (noPrintSelector)
      elCopy.querySelectorAll(noPrintSelector).forEach((element) => {
        element.remove();
      });
    // Get the HTML markup string
    //   const content = elCopy.innerHTML;
    const content = [...elCopy.childNodes].map((n) => n.outerHTML).join("\n");
    // Destroy the copy
    //   elCopy.remove();
    if (iframe) {
      // Use an iframe for printing
      try {
        printContentInFrame(content, {
          doctype,
          timeout,
        });
      } catch (e) {
        // Use the pop-up method if iframe fails for some reason
        console.error("Failed to print from iframe", e.stack, e.message);
        printContentInNewWindow(content, {
          doctype,
          timeout,
        });
      }
    }
    // Use a new window for printing
    else
      printContentInNewWindow(content, {
        doctype,
        timeout,
      });
  }
}
export default print;
