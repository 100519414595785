import { purefn, uface, Request } from "@widget";
import { tokenName } from "@config";
import { cancelRepeatRequest, handleData } from "./tool";
const http = new Request({
  baseUrl: window.location.origin + "/api",
});
cancelRepeatRequest(http.interceptor);
handleData(http.interceptor);
const defaultConf = () => ({
  toast: true, //是否进行form返回值判断
  check: true, //是否对返回值校验
  loading: true,
  loadingTarget: ".admin-box", //loading区域
  cancelRepeatRequest: true, //开启取消重复请求
  cancelRepeatRequestInterval: 3000, //重复请求缓存间隔
  header: {
    platform: "admin",
    [tokenName]: uface.getStorage(tokenName) || "",
  },
});

export default function simpleList(list, namespace) {
  if (typeof list === "string") {
    return (rest) =>
      http.post(
        purefn.ObjUtil.merge(
          defaultConf(),
          {
            url: list,
          },
          rest
        )
      );
  }
  const res = {};
  for (let [key, value] of Object.entries(list)) {
    res[key] = function (rest) {
      const interceptorPrivate = http.getEmptyInterceptor();
      if (typeof value === "string") {
        // 若属性值为string，则直接调用方法
        let [url, method] = value.split("|");
        if (!method) method = "post";
        if (rest && rest.method) method = rest.method;
        return http[method](
          purefn.ObjUtil.merge(
            defaultConf(),
            {
              url,
              urlRaw: url,
              namespace,
            },
            rest
          )
        );
      } else if (purefn.typeUtil("getType")(value) === "Object") {
        const {
          url: urlRaw, //请求路径
          interceptor,
          config = {},
        } = value;
        let [url, method] = urlRaw.split("|");
        if (!method) method = "post";
        if (rest && rest.method) method = rest.method;
        if (interceptor) interceptor(interceptorPrivate);
        if (rest.interceptor) interceptor(interceptorPrivate);
        return http[method](
          purefn.ObjUtil.merge(
            {
              url,
              urlRaw: url,
              namespace,
            },
            defaultConf(),
            config,
            rest
          ),
          {
            interceptor: interceptorPrivate,
          }
        );
      }
    };
  }
  return res;
}
